.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: 1.2em;
  font-weight: 400;
  color: inherit
}

.h1, h1 {
  font-size: 45px
}

.h2, h2 {
  font-size: 40px
}

.h3, h3 {
  font-size: 30px
}

.h4, h4 {
  font-size: 25px
}

.h5, h5 {
  font-size: 20px
}

.h6, h6 {
  font-size: 18px
}


.flex .full-width, .table {
  width: 100%
}

.column_2 {
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  column-gap: 20px
}

.table {
  display: table
}

.table .table-cell {
  display: table-cell;
  vertical-align: middle
}

.table .align-top {
  vertical-align: top;
  text-align: center
}

.table .align-top-left {
  vertical-align: top;
  text-align: left
}

.table .align-top-right {
  vertical-align: top;
  text-align: right
}

.table .align-bot {
  vertical-align: bottom;
  text-align: center
}

.table .align-bot-left {
  vertical-align: bottom;
  text-align: left
}

.table .align-bot-right {
  vertical-align: bottom;
  text-align: right
}

.table .align-left {
  text-align: left
}

.table .align-right {
  text-align: right
}

.table .align-center {
  text-align: center
}

.flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}

.flex .flex-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  max-width: 100%
}

.absolute {
  position: absolute
}

.clearfix:after, .clearfix:before {
  content: "";
  display: table
}

.hide {
  display: none !important
}

.block {
  display: block
}

.inline {
  display: inline
}

.inline-block, .inline-block-middle {
  display: inline-block
}

.inline-block-middle {
  vertical-align: middle
}

.left {
  float: left !important
}

.z-index {
  z-index: 10
}

.relative {
  position: relative !important
}

.right {
  float: right !important
}

.text-center {
  text-align: center !important
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-normal {
  text-transform: none !important
}

.text-bold {
  font-weight: 700 !important
}

.overhide {
  overflow: hidden !important
}

.alpha {
  font-size: 39px
}

.beta {
  font-size: 33px
}

.gamma {
  font-size: 30px
}

//$$ Estilos que personalizan varias partes a la vez o que ejercen de utilities

.advanced-search .form-section:after,
.advanced-search .inline-forms:after,
.advanced-search:after,
.aside-cicle-block .list-links li:after,
.audio-media-gallery .bot-block:after,
.audio-media-gallery .bottom-nav:after,
.audio-media-gallery:after, .awarded-grid:after,
.block-animated .block-calendar:after,
.block-animated .block-ranking:after,
.block-audio .audio-ctrls:after,
.block-panels:after,
.calendar-block .generic-timeline .cover-timeline-blocks:after,
.calendar-block .generic-timeline-indicators .cover-timeline-indicators:after,
.category-list .item:after,
.clearfix:after,
.compound-blocks-modules .info-block:after,
.compound-blocks-modules:after,
.container:after,
.diary-slider .header-slider:after,
.diary-slider .move-header-slider:after,
.easy-autocomplete:after,
.exposition-block .exposition-nav:after,
.exposition-description:after,
.generic-article .generic-title:after,
.generic-article .related-info .block:after,
.generic-article .related-info .item-generic:after,
.generic-article .related-info .item-image:after,
.generic-article:after, .main-nav .social-block:after,
.newsletter-block .form-section:after,
.nomination-formulary .details .info:after,
.nomination-formulary .nominator:after,
.nomination-formulary .step-label:after,
.photo-media-gallery .bot-block:after,
.photo-media-gallery .bottom-nav:after,
.photo-media-gallery:after,
.profile-blocks .info:after,
.result-list .item:after,
.result-list .kind-of-island .cloak:after,
.row:after,
.search-results .field-search .form-section.include-btn-2:after,
.search-results .field-search .form-section.include-btn:after,
.search-results:after,
.slick-track:after,
.standar-formulary .details .info:after,
.standar-formulary .nominator:after,
.standar-formulary .step-label:after {
  clear: both;
}

.cookies-label a, .main-footer .contact-block p>a {
  text-decoration: underline;
}

.error .icon,
.form-ctrl,
.rounded-img {
  vertical-align: middle
}

.form-ctrl {
  display: inline-block;
  padding: 13px 20px
}

.link {
  display: block;
  position: absolute
}

.link {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5
}

.rounded-img {
  display: inline-block;
  border-radius: 100%;
  overflow: hidden
}

.i {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 0
}

iframe.generic-iframe {
  min-height: calc(100vh - 69px);
  margin: 0;
  padding: 0;
  display: none;
  border: 0
}

.cookies-label {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 0;
  background: rgba(0, 0, 0, .85);
  color: #fff;
  z-index: 21;
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 400
}

.cookies-label.show {
  display: block
}

.cookies-label a {
  font-weight: 700
}

.cookies-label p {
  display: inline-block;
  vertical-align: middle;
  width: -webkit-calc(100% - 120px);
  width: -moz-calc(100% - 120px);
  width: calc(100% - 120px);
  padding-right: 120px;
  margin: 0
}

.easy-autocomplete {
  width: -webkit-calc(100% - 140px) !important;
  width: -moz-calc(100% - 140px) !important;
  width: calc(100% - 140px) !important;
  float: left;
  z-index: 10
}

.easy-autocomplete:after, .easy-autocomplete:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.easy-autocomplete input {
  width: 100% !important;
  float: none
}

.easy-autocomplete .easy-autocomplete-container {
  top: 100%
}

li.atcb-item a {
  color: #000;
  font-family: Verdana;
  font-size: 14px;
  outline: 0;
  padding: 5px 15px
}

.order-by {
  display: flex;
  align-items: center;
  background: #f0f0f0;
  padding: 10px 30px;
  margin-top: 2px;
  color: #7f7f7f;
  font-size: 12px;
  line-height: 1.3em;
  letter-spacing: 1px;

  & > span {
    margin-right: 10px;
  }
  & > label {
    display: inline-block;
    vertical-align: middle;
    margin: 0 15px 0 4px;
    font-size: 13px;
    line-height: 1.2em;
    font-weight: 700;
    color: #000;
    cursor: pointer;

    @include mobile {
      margin-right: 10px;
    }
  }
}


.order-by>label {
}

.order-by>label span {
  margin-left: 8px
}

.videoFLV {
  margin-bottom: 1em
}

.common-font-style-section-for-mobile {
  color: #616161;
  font-size: 14px;
  font-weight: 500;
  display: none;
  margin: 10px 0;
  padding: 0 20px
}

.p00 {
  padding: 0 !important
}

.p10 {
  padding: 10px !important
}

.p20 {
  padding: 20px !important
}

.p30 {
  padding: 30px !important
}

.p40 {
  padding: 40px !important
}

.p50 {
  padding: 50px !important
}

.p60 {
  padding: 60px !important
}

.p70 {
  padding: 70px !important
}

.p80 {
  padding: 80px !important
}

.p90 {
  padding: 90px !important
}

.p100 {
  padding: 100px !important
}

.p110 {
  padding: 110px !important
}

.p120 {
  padding: 120px !important
}

.p130 {
  padding: 130px !important
}

.p140 {
  padding: 140px !important
}

.p150 {
  padding: 150px !important
}

.p160 {
  padding: 160px !important
}

.p170 {
  padding: 170px !important
}

.p180 {
  padding: 180px !important
}

.p190 {
  padding: 190px !important
}

.p200 {
  padding: 200px !important
}

.pl00 {
  padding-left: 0 !important
}

.pl10 {
  padding-left: 10px !important
}

.pl20 {
  padding-left: 20px !important
}

.pl30 {
  padding-left: 30px !important
}

.pl40 {
  padding-left: 40px !important
}

.pl50 {
  padding-left: 50px !important
}

.pl60 {
  padding-left: 60px !important
}

.pl70 {
  padding-left: 70px !important
}

.pl80 {
  padding-left: 80px !important
}

.pl90 {
  padding-left: 90px !important
}

.pl100 {
  padding-left: 100px !important
}

.pl110 {
  padding-left: 110px !important
}

.pl120 {
  padding-left: 120px !important
}

.pl130 {
  padding-left: 130px !important
}

.pl140 {
  padding-left: 140px !important
}

.pl150 {
  padding-left: 150px !important
}

.pl160 {
  padding-left: 160px !important
}

.pl170 {
  padding-left: 170px !important
}

.pl180 {
  padding-left: 180px !important
}

.pl190 {
  padding-left: 190px !important
}

.pl200 {
  padding-left: 200px !important
}

.pr00 {
  padding-right: 0 !important
}

.pr10 {
  padding-right: 10px !important
}

.pr20 {
  padding-right: 20px !important
}

.pr30 {
  padding-right: 30px !important
}

.pr40 {
  padding-right: 40px !important
}

.pr50 {
  padding-right: 50px !important
}

.pr60 {
  padding-right: 60px !important
}

.pr70 {
  padding-right: 70px !important
}

.pr80 {
  padding-right: 80px !important
}

.pr90 {
  padding-right: 90px !important
}

.pr100 {
  padding-right: 100px !important
}

.pr110 {
  padding-right: 110px !important
}

.pr120 {
  padding-right: 120px !important
}

.pr130 {
  padding-right: 130px !important
}

.pr140 {
  padding-right: 140px !important
}

.pr150 {
  padding-right: 150px !important
}

.pr160 {
  padding-right: 160px !important
}

.pr170 {
  padding-right: 170px !important
}

.pr180 {
  padding-right: 180px !important
}

.pr190 {
  padding-right: 190px !important
}

.pr200 {
  padding-right: 200px !important
}

.pt00 {
  padding-top: 0 !important
}

.pt10 {
  padding-top: 10px !important
}

.pt20 {
  padding-top: 20px !important
}

.pt30 {
  padding-top: 30px !important
}

.pt40 {
  padding-top: 40px !important
}

.pt50 {
  padding-top: 50px !important
}

.pt60 {
  padding-top: 60px !important
}

.pt70 {
  padding-top: 70px !important
}

.pt80 {
  padding-top: 80px !important
}

.pt90 {
  padding-top: 90px !important
}

.pt100 {
  padding-top: 100px !important
}

.pt110 {
  padding-top: 110px !important
}

.pt120 {
  padding-top: 120px !important
}

.pt130 {
  padding-top: 130px !important
}

.pt140 {
  padding-top: 140px !important
}

.pt150 {
  padding-top: 150px !important
}

.pt160 {
  padding-top: 160px !important
}

.pt170 {
  padding-top: 170px !important
}

.pt180 {
  padding-top: 180px !important
}

.pt190 {
  padding-top: 190px !important
}

.pt200 {
  padding-top: 200px !important
}

.pb00 {
  padding-bottom: 0 !important
}

.pb10 {
  padding-bottom: 10px !important
}

.pb20 {
  padding-bottom: 20px !important
}

.pb30 {
  padding-bottom: 30px !important
}

.pb40 {
  padding-bottom: 40px !important
}

.pb50 {
  padding-bottom: 50px !important
}

.pb60 {
  padding-bottom: 60px !important
}

.pb70 {
  padding-bottom: 70px !important
}

.pb80 {
  padding-bottom: 80px !important
}

.pb90 {
  padding-bottom: 90px !important
}

.pb100 {
  padding-bottom: 100px !important
}

.pb110 {
  padding-bottom: 110px !important
}

.pb120 {
  padding-bottom: 120px !important
}

.pb130 {
  padding-bottom: 130px !important
}

.pb140 {
  padding-bottom: 140px !important
}

.pb150 {
  padding-bottom: 150px !important
}

.pb160 {
  padding-bottom: 160px !important
}

.pb170 {
  padding-bottom: 170px !important
}

.pb180 {
  padding-bottom: 180px !important
}

.pb190 {
  padding-bottom: 190px !important
}

.pb200 {
  padding-bottom: 200px !important
}

.ph00 {
  padding-left: 0 !important;
  padding-right: 0 !important
}

.ph10 {
  padding-left: 10px !important;
  padding-right: 10px !important
}

.ph20 {
  padding-left: 20px !important;
  padding-right: 20px !important
}

.ph30 {
  padding-left: 30px !important;
  padding-right: 30px !important
}

.ph40 {
  padding-left: 40px !important;
  padding-right: 40px !important
}

.ph50 {
  padding-left: 50px !important;
  padding-right: 50px !important
}

.ph60 {
  padding-left: 60px !important;
  padding-right: 60px !important
}

.ph70 {
  padding-left: 70px !important;
  padding-right: 70px !important
}

.ph80 {
  padding-left: 80px !important;
  padding-right: 80px !important
}

.ph90 {
  padding-left: 90px !important;
  padding-right: 90px !important
}

.ph100 {
  padding-left: 100px !important;
  padding-right: 100px !important
}

.ph110 {
  padding-left: 110px !important;
  padding-right: 110px !important
}

.ph120 {
  padding-left: 120px !important;
  padding-right: 120px !important
}

.ph130 {
  padding-left: 130px !important;
  padding-right: 130px !important
}

.ph140 {
  padding-left: 140px !important;
  padding-right: 140px !important
}

.ph150 {
  padding-left: 150px !important;
  padding-right: 150px !important
}

.ph160 {
  padding-left: 160px !important;
  padding-right: 160px !important
}

.ph170 {
  padding-left: 170px !important;
  padding-right: 170px !important
}

.ph180 {
  padding-left: 180px !important;
  padding-right: 180px !important
}

.ph190 {
  padding-left: 190px !important;
  padding-right: 190px !important
}

.ph200 {
  padding-left: 200px !important;
  padding-right: 200px !important
}

.pv00 {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.pv10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important
}

.pv20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important
}

.pv30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important
}

.pv40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important
}

.pv50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important
}

.pv60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important
}

.pv70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important
}

.pv80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important
}

.pv90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important
}

.pv100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important
}

.pv110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important
}

.pv120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important
}

.pv130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important
}

.pv140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important
}

.pv150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important
}

.pv160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important
}

.pv170 {
  padding-top: 170px !important;
  padding-bottom: 170px !important
}

.pv180 {
  padding-top: 180px !important;
  padding-bottom: 180px !important
}

.pv190 {
  padding-top: 190px !important;
  padding-bottom: 190px !important
}

.pv200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important
}

.m00 {
  margin: 0 !important
}

.m10 {
  margin: 10px !important
}

.m20 {
  margin: 20px !important
}

.m30 {
  margin: 30px !important
}

.m40 {
  margin: 40px !important
}

.m50 {
  margin: 50px !important
}

.m60 {
  margin: 60px !important
}

.m70 {
  margin: 70px !important
}

.m80 {
  margin: 80px !important
}

.m90 {
  margin: 90px !important
}

.m100 {
  margin: 100px !important
}

.m110 {
  margin: 110px !important
}

.m120 {
  margin: 120px !important
}

.m130 {
  margin: 130px !important
}

.m140 {
  margin: 140px !important
}

.m150 {
  margin: 150px !important
}

.m160 {
  margin: 160px !important
}

.m170 {
  margin: 170px !important
}

.m180 {
  margin: 180px !important
}

.m190 {
  margin: 190px !important
}

.m200 {
  margin: 200px !important
}

.ml00 {
  margin-left: 0 !important
}

.ml10 {
  margin-left: 10px !important
}

.ml20 {
  margin-left: 20px !important
}

.ml30 {
  margin-left: 30px !important
}

.ml40 {
  margin-left: 40px !important
}

.ml50 {
  margin-left: 50px !important
}

.ml60 {
  margin-left: 60px !important
}

.ml70 {
  margin-left: 70px !important
}

.ml80 {
  margin-left: 80px !important
}

.ml90 {
  margin-left: 90px !important
}

.ml100 {
  margin-left: 100px !important
}

.ml110 {
  margin-left: 110px !important
}

.ml120 {
  margin-left: 120px !important
}

.ml130 {
  margin-left: 130px !important
}

.ml140 {
  margin-left: 140px !important
}

.ml150 {
  margin-left: 150px !important
}

.ml160 {
  margin-left: 160px !important
}

.ml170 {
  margin-left: 170px !important
}

.ml180 {
  margin-left: 180px !important
}

.ml190 {
  margin-left: 190px !important
}

.ml200 {
  margin-left: 200px !important
}

.mr00 {
  margin-right: 0 !important
}

.mr10 {
  margin-right: 10px !important
}

.mr20 {
  margin-right: 20px !important
}

.mr30 {
  margin-right: 30px !important
}

.mr40 {
  margin-right: 40px !important
}

.mr50 {
  margin-right: 50px !important
}

.mr60 {
  margin-right: 60px !important
}

.mr70 {
  margin-right: 70px !important
}

.mr80 {
  margin-right: 80px !important
}

.mr90 {
  margin-right: 90px !important
}

.mr100 {
  margin-right: 100px !important
}

.mr110 {
  margin-right: 110px !important
}

.mr120 {
  margin-right: 120px !important
}

.mr130 {
  margin-right: 130px !important
}

.mr140 {
  margin-right: 140px !important
}

.mr150 {
  margin-right: 150px !important
}

.mr160 {
  margin-right: 160px !important
}

.mr170 {
  margin-right: 170px !important
}

.mr180 {
  margin-right: 180px !important
}

.mr190 {
  margin-right: 190px !important
}

.mr200 {
  margin-right: 200px !important
}

.mt00 {
  margin-top: 0 !important
}

.mt10 {
  margin-top: 10px !important
}

.mt20 {
  margin-top: 20px !important
}

.mt30 {
  margin-top: 30px !important
}

.mt40 {
  margin-top: 40px !important
}

.mt50 {
  margin-top: 50px !important
}

.mt60 {
  margin-top: 60px !important
}

.mt70 {
  margin-top: 70px !important
}

.mt80 {
  margin-top: 80px !important
}

.mt90 {
  margin-top: 90px !important
}

.mt100 {
  margin-top: 100px !important
}

.mt110 {
  margin-top: 110px !important
}

.mt120 {
  margin-top: 120px !important
}

.mt130 {
  margin-top: 130px !important
}

.mt140 {
  margin-top: 140px !important
}

.mt150 {
  margin-top: 150px !important
}

.mt160 {
  margin-top: 160px !important
}

.mt170 {
  margin-top: 170px !important
}

.mt180 {
  margin-top: 180px !important
}

.mt190 {
  margin-top: 190px !important
}

.mt200 {
  margin-top: 200px !important
}

.mb00 {
  margin-bottom: 0 !important
}

.mb10 {
  margin-bottom: 10px !important
}

.mb20 {
  margin-bottom: 20px !important
}

.mb30 {
  margin-bottom: 30px !important
}

.mb40 {
  margin-bottom: 40px !important
}

.mb50 {
  margin-bottom: 50px !important
}

.mb60 {
  margin-bottom: 60px !important
}

.mb70 {
  margin-bottom: 70px !important
}

.mb80 {
  margin-bottom: 80px !important
}

.mb90 {
  margin-bottom: 90px !important
}

.mb100 {
  margin-bottom: 100px !important
}

.mb110 {
  margin-bottom: 110px !important
}

.mb120 {
  margin-bottom: 120px !important
}

.mb130 {
  margin-bottom: 130px !important
}

.mb140 {
  margin-bottom: 140px !important
}

.mb150 {
  margin-bottom: 150px !important
}

.mb160 {
  margin-bottom: 160px !important
}

.mb170 {
  margin-bottom: 170px !important
}

.mb180 {
  margin-bottom: 180px !important
}

.mb190 {
  margin-bottom: 190px !important
}

.mb200 {
  margin-bottom: 200px !important
}

.mh00 {
  margin-left: 0 !important;
  margin-right: 0 !important
}

.mh10 {
  margin-left: 10px !important;
  margin-right: 10px !important
}

.mh20 {
  margin-left: 20px !important;
  margin-right: 20px !important
}

.mh30 {
  margin-left: 30px !important;
  margin-right: 30px !important
}

.mh40 {
  margin-left: 40px !important;
  margin-right: 40px !important
}

.mh50 {
  margin-left: 50px !important;
  margin-right: 50px !important
}

.mh60 {
  margin-left: 60px !important;
  margin-right: 60px !important
}

.mh70 {
  margin-left: 70px !important;
  margin-right: 70px !important
}

.mh80 {
  margin-left: 80px !important;
  margin-right: 80px !important
}

.mh90 {
  margin-left: 90px !important;
  margin-right: 90px !important
}

.mh100 {
  margin-left: 100px !important;
  margin-right: 100px !important
}

.mh110 {
  margin-left: 110px !important;
  margin-right: 110px !important
}

.mh120 {
  margin-left: 120px !important;
  margin-right: 120px !important
}

.mh130 {
  margin-left: 130px !important;
  margin-right: 130px !important
}

.mh140 {
  margin-left: 140px !important;
  margin-right: 140px !important
}

.mh150 {
  margin-left: 150px !important;
  margin-right: 150px !important
}

.mh160 {
  margin-left: 160px !important;
  margin-right: 160px !important
}

.mh170 {
  margin-left: 170px !important;
  margin-right: 170px !important
}

.mh180 {
  margin-left: 180px !important;
  margin-right: 180px !important
}

.mh190 {
  margin-left: 190px !important;
  margin-right: 190px !important
}

.mh200 {
  margin-left: 200px !important;
  margin-right: 200px !important
}

.mv00 {
  margin-top: 0 !important;
  margin-bottom: 0 !important
}

.mv10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important
}

.mv20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important
}

.mv30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important
}

.mv40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important
}

.mv50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important
}

.mv60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important
}

.mv70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important
}

.mv80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important
}

.mv90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important
}

.mv100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important
}

.mv110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important
}

.mv120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important
}

.mv130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important
}

.mv140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important
}

.mv150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important
}

.mv160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important
}

.mv170 {
  margin-top: 170px !important;
  margin-bottom: 170px !important
}

.mv180 {
  margin-top: 180px !important;
  margin-bottom: 180px !important
}

.mv190 {
  margin-top: 190px !important;
  margin-bottom: 190px !important
}

.mv200 {
  margin-top: 200px !important;
  margin-bottom: 200px !important
}

@media all and (max-width:900px) {
  .cookies-label {
    text-align: center
  }
  .cookies-label p {
    width: 100%;
    padding-right: 0;
    margin-bottom: 10px;
    text-align: left
  }
}
@media all and (max-width:700px) {
  .common-font-style-section-for-mobile {
    display: block
  }
}
ul.select-ul {
  height: 55px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  list-style: none;
  padding-left: 0;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: $font_bentonsansRegular;
}

ul.select-ul li {
  height: 55px;
  background: #2b2b2b;
  border: none;
  border-radius: 1px;
  border-bottom: 1px solid #7f7f7f;
  font-size: 14px;
  padding: 20px 0px 20px 20px;
  color: white;
  z-index:1;
}

ul.select-ul li:not(.init) {
  display: none;
  background: $dark-grey;
}

ul.select-ul li:not(.init):hover,
ul.select-ul li.selected:not(.init) {
  background: $black;
}

ul.select-ul li.init {
  cursor: pointer;
  text-transform: uppercase;
  position:relative;

  &:after {
    position: absolute;
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    right:20px;
    margin-top: -3px;
    border-bottom: 1px solid $white;
    border-right: 1px solid $white;
    transform: rotate(45deg) translateY(-50%);
    transition: all 0.4s ease-in-out;
    transform-origin: 50% 0;
  }

  &.arrowRotate::after {
    transform: rotate(-135deg) translateY(-50%);
  }
}

a#submit {
  z-index: 1;
}

.selectBtn {
  height: 55px;
  background: #2b2b2b;
  border: none;
  border-radius: 1px;
  border-bottom: 1px solid #7f7f7f;
  font-size: 14px;
  padding: 20px 0px 20px 20px;
  color: white;
  text-align: left;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: $font_bentonsansRegular;
  width: 100%;
  position: relative;
  
  &:after {
    position: absolute;
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    left: 80%;
    margin-top: -3px;
    border-bottom: 1px solid $white;
    border-right: 1px solid $white;
    transform: rotate(45deg) translateY(-50%);
    transition: all 0.4s ease-in-out;
    transform-origin: 50% 0;
  }

  &.arrowRotate::after {
    transform: rotate(-135deg) translateY(-50%);
  }

  &:hover {
    background: #2b2b2b;
  }
}


.txt {
  &-left {
	text-align: left!important;
  }
  &-right {
	text-align: right!important;
  }
  &-center {
	text-align: center!important;
  }
}

.ov-hidden {
  overflow: hidden;
}


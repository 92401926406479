/***********************************************************
 * Estilos para los CPT que tengan estructura de noticias
 **********************************************************/

.news {

  header {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .feature-image-container {

      .img-with-overlay {

        .icon-play {
          font-size: 90px;
        }

        .overlay {
          @media print {
            display: none;
          }
        }
      }

      img {
        width: 100%;
        margin: 0 auto;
      }

      .footer-image {
        font-style: italic;
        font-size: 12px;
        margin-top: 5px;

        @include tablet {
          padding: 0 8px;
        }

        span {
          font-weight: bold;
          font-style: normal;
          font-family: 'bentonsansBold', sans-serif;
        }

      }

      .iframe-video-container {
        display: none;

        .iframe-border {
          background-color: #000;
          color: #fff;
          text-align: right;
          padding: 10px;
          margin: 0;
          min-height: 42px;
        }
        iframe {
          display: block;
          width: 100%;
          height: calc( 500px - 84px );
        }
      }

    }

    .title-container {

      display: flex;
      margin: 40px auto 0;
      justify-content: space-between;

      @media screen and (max-width: 1200px) {
        width: 90%;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
      }

      .main-block {

        padding-right: 20px;

        @media screen and (max-width: 768px) {
          padding-right: 0;
        }

        .epigraph {
          margin-bottom: 20px;
          font-weight: bold;
          font-size: 15px;
        }

        .title {
          margin: 0 0 40px;
          font-size: 40px;
          font-weight: bold;

          @media print {
            font-size: 30px;
          }
          @media screen and (max-width: 768px) {
            font-size: 30px;
          }
        }

        .author-info {
          margin-bottom: 20px;
        }
      }

      .social {
        font-size: 22px;

        @media print {
          display: none;
        }

        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
    }

    .primal-info {
      margin: 0 auto 40px;
      padding: 30px 0;
      border-top: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      font-size: 18px;
      line-height: 1.6em;
      font-weight: 600;

      a {
        color: $blue;
      }
      @media screen and (max-width: 1200px) {
        width: 90%;
      }
    }
  }

  .body {
    display: flex;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }

    a {
      color: $blue;
    }
    .aside-left {
      flex-basis: 200px;

      @media screen and (max-width: 1024px) {
        flex-basis: 100%;
      }

      @media print {
        display: none;
      }

      .time {
        color: #aaa;
      }
    }

    .description {
      width: calc( 100% - 480px );
      padding: 0 30px;
      font-size: 18px;
      line-height: 1.5em;

      @media screen and (max-width: 1024px) {
        width: calc( 100% - 280px );
        padding: 0 30px 0 0;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
      }

      @media print {
        width: 100%;
      }

      h2, h3 {
        font-family: bentonsansBold, sans-serif;
      }

      h2 {
        font-size: 22px;
        margin-top: 0;
      }

      h3 {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 10px;
      }

      iframe {
        width: 100%;
        display: block;
      }

      .footer-picture {
        margin: 0 0 20px;
        font-size: 12px;
        color: #aaa;
        font-style: italic;
      }
    }

    .assets {
      flex-basis: 280px;

      @media print {
        display: none;
      }
    }

  }

  /*
  ****************** PAGINA SENCILLA ******************
   */

  &.pagina-simple {

    header {
      .title-container {

        .title {
          margin-top: 0;
        }

        .social {
          padding: 10px 0 0 70px;
        }
      }
    }
    .body {
      .description {
        //width: calc( 100% - 280px );
        padding-left: 0;
      }

    }

  }
  .wp-caption-text{
    font-style: italic;
    font-size: 12px; 
    margin-top: 5px;
    width: 95vw;
  }
}
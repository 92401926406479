
//$$ Module

.module {
  position: relative;

  &.title-and-background {
    height: 540px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &::before {
      content: "";
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .bigtitle {
      position: relative;
      background: rgba(0,0,0,0.5);
      padding: 60px 30px;
      font-size: 99px;
      font-family: Oswald, sans-serif;
      font-weight: 500;
      letter-spacing: -3px;
      color: white;
      display: inline-block;
      line-height: .8em;
      margin: 0;

      b, strong {
        font-family: Oswald, sans-serif;
      }

      @include desktop-small {
        font-size: 60px;
      }

      small {
        font-weight: 200;
        font-size: .6em;
        letter-spacing: -1px;
        display: block;
        margin-bottom: 4px;
        line-height: .8em;
      }
    }
  }
}

.module.padding {
  padding: 50px 0;

  &.back-item {
    @media screen and (max-width: 768px) {
      padding-top:0;
    }
  }

  @media screen and (max-width: 480px) {
    padding:0;
  }

}

.module .title {
  font-size: 25px;
  line-height: 1.2em;
  margin-top: 0;
  margin-bottom: 1em
}

.module .title .small-information {
  display: block;
  font-size: 10px;
  line-height: 1em;
  color: #7f7f7f
}

.module .title-only {
  font-size: 24px;
  line-height: 1.2em;
  font-family: Oswald;
  font-weight: 200;
  margin: 0
}

.module.border-bottom {
  border-bottom: 1px solid #dedede
}


.back-item {
  position: relative
}

.back-item .container {
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    padding-top:20px;
  }
}

.back-item .cloack, .back-item .cover-media {
  position: absolute;
  top: 0;
  height: 100%;
  display: block
}

.back-item .cloack {
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, .45);
  z-index: 1
}

.back-item .cover-media {
  left: -1%;
  width: 102%;
  z-index: 0;
  margin: 0;
  overflow: hidden
}

.back-item .cover-media>img, .back-item .cover-media>video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  width: auto;
  min-width: 100%;
  transform: translate(-50%, -50%)
}

.back-item .cover-media>img {
  max-width: initial
}

/* COMPOUND BLOCK MODULES  */

.compound-blocks-modules .block.infographic:before, .compound-blocks-modules .cover-media>img {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%)
}

.compound-blocks-modules {
  display: block;
  width: 100%;

  &.full-article {
    width: 100vw;
  }
}

.compound-blocks-modules:after, .compound-blocks-modules:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.compound-blocks-modules.type1 .size1 {
  overflow: hidden
}

.compound-blocks-modules.type1 .size1 .content-block {
  height: 316px
}

.compound-blocks-modules.type1 .size2 {
  overflow: hidden
}

.compound-blocks-modules.type1 .size2 .content-block {
  height: 620px
}

.compound-blocks-modules.type1 .size3 {
  overflow: hidden
}

.compound-blocks-modules.type1 .size3 .content-block {
  height: 542px
}

.compound-blocks-modules.type1 .size4 {
  overflow: hidden
}

.compound-blocks-modules.type1 .size4 .content-block {
  height: 394px
}

.compound-blocks-modules.type2 .size1 {
  overflow: hidden
}

.compound-blocks-modules.type2 .size1 .content-block {
  height: 912px
}

.compound-blocks-modules.type2 .size2 {
  overflow: hidden
}

.compound-blocks-modules.type2 .size2 .content-block {
  height: 482px
}

.compound-blocks-modules.type2 .size3 {
  overflow: hidden
}

.compound-blocks-modules.type2 .size3 .content-block {
  height: 430px
}

.compound-blocks-modules.type3 {
  overflow: hidden
}

.compound-blocks-modules.type3 .content-block {
  height: 316px
}

.compound-blocks-modules.type4 .size1 {
  overflow: hidden
}

.compound-blocks-modules.type4 .size1 .content-block {
  height: 700px
}

.compound-blocks-modules.type4 .size2, .compound-blocks-modules.type4 .size3 {
  overflow: hidden
}

.compound-blocks-modules.type4 .size2 .content-block, .compound-blocks-modules.type4 .size3 .content-block {
  height: 350px
}

.compound-blocks-modules.type5 .size1 {
  overflow: hidden
}

.compound-blocks-modules.type5 .size1 .content-block {
  height: 880px
}

.compound-blocks-modules.type5 .size2 {
  overflow: hidden
}

.compound-blocks-modules.type5 .size2 .content-block {
  height: 500px
}

.compound-blocks-modules.type5 .size3 .content-block, .compound-blocks-modules.type6 .size1 .content-block {
  height: 380px
}

.compound-blocks-modules.type5 .size3, .compound-blocks-modules.type6 .size1, .compound-blocks-modules.type6 .size2 {
  overflow: hidden
}

.compound-blocks-modules.type6 .size2 .content-block {
  height: 500px
}

.compound-blocks-modules.type6 .size3 {
  overflow: hidden
}

.compound-blocks-modules.type6 .size3 .content-block {
  height: 880px
}

.compound-blocks-modules.A1 {
  overflow: hidden
}

.compound-blocks-modules.A1 .col:last-child .content-block {
  padding-left: 40px
}

.compound-blocks-modules.A1 .content-block {
  height: 300px;
  font-size: 18px;
  line-height: 1.2em
}

.compound-blocks-modules.A1 .picture-block {
  padding: 0;
  margin-left: -40px;
  font-size: 0
}

.compound-blocks-modules.C1 .col:first-child {
  width: 45%
}

.compound-blocks-modules.C1 .col:first-child .content-block {
  max-width: 430px
}

.compound-blocks-modules.C1 .col:last-child .content-block, .compound-blocks-modules.C2 .col:first-child .content-block {
  max-width: 770px
}

.compound-blocks-modules.C1 .col:last-child {
  width: 55%
}

.compound-blocks-modules.C2 .col:first-child {
  width: 60%
}

.compound-blocks-modules.C2 .col:last-child {
  width: 40%
}

.compound-blocks-modules.C2 .col:last-child .content-block {
  max-width: 430px
}

.compound-blocks-modules.P1 .content-block {
  height: 380px;
  font-size: 18px;
  line-height: 1.2em
}

.compound-blocks-modules.parallax {
  position: relative
}

.compound-blocks-modules.parallax:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  margin: 0;
  visibility: visible;
  background: rgba(15, 20, 25, .5)
}

.compound-blocks-modules.parallax .content-block {
  height: 542px
}

.compound-blocks-modules.parallax .col:first-of-type .content-block {
  height: 268px;
  padding: 60px 20px
}

.compound-blocks-modules.parallax .parallax-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  margin: 0
}

.compound-blocks-modules .full-col {
  display: block;
  width: 100%
}

.compound-blocks-modules .full-col .content-block {
  max-width: 1240px;
  margin: 0 auto;
  height: 650px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.compound-blocks-modules.embed-video {

  .cloack {
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .full-col .content-block {
    height: 500px;
    padding: 0;

    @media screen and (max-width: 768px) {
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      height: inherit;
    }

    @media screen and (max-width: 480px) {
      min-height: 0;
    }

    .align-content .icon {
      @media screen and (max-width: 768px) {
        display: block;
        color: white;
      }
    }
    .video.relative {
      height: 500px;
      padding-bottom: 0;

      @media screen and (max-width: 480px) {
        height: 300px;
      }
    }
  }
}

.compound-blocks-modules.full-article .full-col .content-block,
.compound-blocks-modules.small-article .full-col .content-block {
  @media screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    min-height: inherit;
  }
}
.imagen_panel_s5{
  @media screen and (max-width: 768px) {
    height:0!important;
  }
  height:290px!important;
}
.compound-blocks-modules .full-col .content-block .align-content {
  max-width: 1020px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: flex-start;
  align-self: flex-start
}

.compound-blocks-modules .full-col .content-block .align-content.align-middle {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

.compound-blocks-modules .full-col.main-gallery .content-block .align-content {
  max-width: 100%
}

.compound-blocks-modules .main-gallery .content-block {
  height: auto;
  min-height: 75vh;
  padding-top: 30px;
  padding-bottom: 65px;

  @media screen and (max-width: 780px) {
    min-height: initial !important;
    display: block;
    padding: 20px;
    color: #000;
  }
}

.compound-blocks-modules .main-gallery .content-block.half-height {
  min-height: 420px
}

.compound-blocks-modules .main-gallery .content-block.small-height {
  min-height: 300px
}

.compound-blocks-modules .main-gallery .content-block.auto-height {
  min-height: initial;
  padding: 55px 20px
}

.compound-blocks-modules .main-gallery .social {
  color: #ddd;
  position: absolute;
  right: 0
}

.compound-blocks-modules .main-gallery .social ul {
  margin: 0;
  padding: 0;
  display: block
}

.compound-blocks-modules .main-gallery .social li {
  list-style: none;
  display: inline-block;
  margin: 0 8px
}

.compound-blocks-modules .main-gallery .social .icon {
  font-size: 19px
}

.compound-blocks-modules .col {
  width: 50%;
  float: left;
  display: block
}

.compound-blocks-modules .col:first-child .content-block, .compound-blocks-modules .col:first-of-type .content-block {
  margin-left: auto;
  padding-right: 50px
}

.compound-blocks-modules .col:last-child .content-block, .compound-blocks-modules .col:last-of-type .content-block {
  margin-right: auto;
  padding-left: 50px
}

.compound-blocks-modules .block {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  color: #fff;
  overflow: hidden
}

.compound-blocks-modules .block .container {
  position: relative;
  z-index: 1
}

.compound-blocks-modules .block.c_black {
  color: #000
}

.compound-blocks-modules .block[data-background]:before {
  content: "";
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: 1
}

.compound-blocks-modules .block[class*=back] {
  padding: 0
}

.compound-blocks-modules .block.infographic {
  overflow: hidden;
  position: relative;
  cursor: pointer
}

.compound-blocks-modules .block.infographic .cover-infographic {
  position: relative;
  overflow: hidden;
  height: 650px
}

.compound-blocks-modules .block.infographic .cloack {
  display: none
}

.compound-blocks-modules .block.infographic:hover:before {
  transition: all .3s ease;
  opacity: 1
}

.compound-blocks-modules .block.infographic:before {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  content: '\E838';
  font-family: fontello;
  display: block;
  width: 96px;
  height: 96px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 96px;
  line-height: 1em;
  z-index: 1;
  opacity: .3
}

.compound-blocks-modules .block.infographic img {
  max-width: initial;
  width: auto;
  pointer-events: none
}

.compound-blocks-modules .block.infographic.on-action:before {
  display: none
}

.compound-blocks-modules .cloack {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .45);
  z-index: 1;

  @media screen and (max-width: 768px) {
    display: none;
  }
}
.compound-blocks-modules.full-article .cloack,
.compound-blocks-modules.small-article .cloack{
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.compound-blocks-modules .cover-media {
  display: block;
  position: absolute;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  z-index: 0;
  margin: 0;
  overflow: hidden
}

.compound-blocks-modules .cover-media>img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: initial;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  transform: translate(-50%, -50%)
}

.compound-blocks-modules .cover-media>video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.compound-blocks-modules {
  .col {
    .cover-media {
      position:inherit;
      top:0;
      left:0;

      &>img, &>video {
        width: 100%;
        height: auto;
        transform: none;
        position: static;
      }
    }
  }
}

.compound-blocks-modules .content-block {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 620px;
  padding: 50px 20px;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.53em;
  height: 570px;
  overflow: hidden;

  @media screen and (max-width: 780px) {
    display: block;
    height: initial;
    color: #000;
    padding: 30px 20px;
    max-width: 100%;
  }
}

.compound-blocks-modules .content-block.height2 {
  height: 880px
}

.compound-blocks-modules .content-block .align-content {
  display: block;
  width: 100%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

.compound-blocks-modules .content-block .align-content .icon {
  line-height: 18px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.compound-blocks-modules.full-article .content-block .align-content .icon,
.compound-blocks-modules.small-article .content-block .align-content .icon {
  @media screen and (max-width: 768px) {
    display: block;
    color: #fff;
  }
}

.compound-blocks-modules .content-block .align-content .fix-align-icon {
  position: relative;
  padding-left: 35px
}

.compound-blocks-modules .content-block .align-content .fix-align-icon .icon {
  position: absolute;
  left: -8px;
  top: -2px
}

.compound-blocks-modules .content-block .align-content.align-bottom {
  margin-top: auto
}

.compound-blocks-modules .content-block .align-content.align-center, .compound-blocks-modules .content-block .align-content.align-middle {
  margin-top: auto;
  margin-bottom: auto
}

.compound-blocks-modules p {
  margin-bottom: 6px
}

.compound-blocks-modules p>.inline-block {
  vertical-align: middle
}

.compound-blocks-modules .title {
  font-family: "bentonsansRegular", sans-serif;
  font-size: 35px;
  line-height: 1.1em;
  font-weight: 500;
  margin: 0 0 10px;
  letter-spacing: 0
}

.compound-blocks-modules .title small {
  font-size: 30px;
  line-height: 1em;
  display: block
}


.compound-blocks-modules .title2 {
  font-family: Prata, sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.36em;
  letter-spacing: 1px;
  margin: 0 0 10px
}
.compound-blocks-modules {
  .title, 
  .title2 {
    line-height: 1em;

    $class-slug: size !default;

    @for $i from 30 through 80 {
      &.#{$class-slug}#{$i} {
        font-size: 0px + $i;
      }
    }
  }
}

.compound-blocks-modules .title-rounded-img {
  font-size: 28px;
  line-height: 1.2em;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto
}

.compound-blocks-modules .small-title {
  font-size: 18px;
  line-height: 1em;
  margin-bottom: 6px;
  letter-spacing: 1px;
  font-weight: 300
}

.compound-blocks-modules .extrabigtitle {
  font-size: 104px;
  line-height: .9em;
  font-family: Oswald;
  font-weight: 500;
  margin: 0 0 10px;
  letter-spacing: -4px
}

.compound-blocks-modules .extrabigtitle.size2 {
  font-size: 96px;
  line-height: .9em
}

.compound-blocks-modules .extrabigtitle+p {
  font-size: 15px;
  line-height: 1.2em
}

.compound-blocks-modules .extrabigtitle small {
  font-size: .7em;
  line-height: 1em;
  display: inline-block;
  vertical-align: middle
}

.compound-blocks-modules .bigtitle {
  margin: 0 0 20px;
  display: block;
  font-family: Oswald, sans-serif;
  font-weight: 500;
  font-size: 80px;
  line-height: .9em;
  color: #fff;
  text-transform: uppercase;

  b, strong {
    font-family: Oswald, sans-serif;
  }

  + .epigraph {
    color: #fff;
  }
}

.compound-blocks-modules .bigtitle small {
  display: block;
  font-size: .63em;
  line-height: .8em;
  margin-bottom: 4px;
  font-weight: 200
}

.compound-blocks-modules .premios-fronteras .bigtitle {
  font-family: stagsansMedium, sans-serif;
  text-transform: none;
}

.compound-blocks-modules .bigtitle.t1 {
  font-size: 70px;
  margin-left: -3px;
}

.compound-blocks-modules .bigtitle.t2 {
  font-size: 120px;
  line-height: 1.2em;
  font-weight: 200;
  margin: 15px 0 0;
  letter-spacing: -3px;
  margin-left: -6px;
}

.compound-blocks-modules .bigtitle.t2 b, .compound-blocks-modules .bigtitle.t2 strong, .compound-blocks-modules .bigtitle.t3 b, .compound-blocks-modules .bigtitle.t3 strong {
  font-weight: 500
}

.compound-blocks-modules .bigtitle.t3 {
  font-size: 100px;
  line-height: 1.2em;
  margin: 20px 0 0;
  letter-spacing: -3px;
  margin-left: -5px;
}

.compound-blocks-modules .bigtitle.t4 {
  font-size: 140px;
  line-height: 1.2em;
  letter-spacing: -4px;
  margin: 0;
  margin-left: -7px;
}

.compound-blocks-modules .bigtitle.t4 small {
  display: block;
  font-weight: 200;
  font-size: 60px;
  line-height: .9em;
  letter-spacing: -1px
}

.compound-blocks-modules .bigtitle.t5 {
  font-size: 134px;
  line-height: .8em;
  letter-spacing: -4px;
  margin-top: -20px;
  margin-left: -6px;
}

.compound-blocks-modules .bigtitle.t5 small {
  font-size: .67em;
  line-height: .9em;
  display: block;
  font-weight: 200;
  letter-spacing: -2px
}

.compound-blocks-modules .bigtitle.t6 {
  font-size: 99px;
  line-height: .8em;
  letter-spacing: -3px
}

.compound-blocks-modules .bigtitle.t6 small {
  font-size: .6em;
  line-height: .8em;
  letter-spacing: -1px
}

.compound-blocks-modules .subtitle {
  font-size: 13px;
  line-height: 20px;
  font-family: bentonsansRegular, sans-serif;
  font-weight: 300
}

.compound-blocks-modules .edition, .compound-blocks-modules .tag {
  font-size: 25px;
  line-height: 1.2em;
  @media screen and (max-width: 700px) {
    font-size: 20px !important;
  }
}

.compound-blocks-modules .tag {
  display: block;
  margin: 0 0 1em;
  max-width: 620px;
  letter-spacing: 1px;
  font-weight: 400
}

.compound-blocks-modules .author, .compound-blocks-modules .edition {
  margin-bottom: 0;
  font-weight: 300;
  display: block;
  font-family: bentonsansRegular, sans-serif
}

.compound-blocks-modules .edition {
  margin-bottom: 10px;
  color: white;
}

.compound-blocks-modules .premios-fronteras .edition {
  font-family: stagsansLight, sans-serif;
}

.compound-blocks-modules .author {
  font-size: 14px;
  line-height: 1.9em;
  letter-spacing: 1px
}

.compound-blocks-modules .author span {
  color: #009ed6
}

.compound-blocks-modules .rounded-img {
  display: block;
  margin: 10px auto 0;
  width: 158px;
  height: 158px
}

.compound-blocks-modules .rounded-img img {
  min-width: 150px;
  min-height: 150px;
  display: block;
  max-width: initial;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0)
}

.compound-blocks-modules .info-block {
  background: #000
}

.compound-blocks-modules .info-block:after, .compound-blocks-modules .info-block:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.compound-blocks-modules .info-block p {
  margin-bottom: 1em
}

.compound-blocks-modules .info-block .title {
  margin-bottom: 0;
  padding-right: 60px;
  font-family: bentonsans;
  font-size: 23px;
  line-height: 1.2em;
  font-weight: 400
}

.compound-blocks-modules .picture-block {
  padding: 0;
  font-size: 0
}

.compound-blocks-modules .picture-block .picture {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 228px;
  height: 300px;
  overflow: hidden;
  position: relative;
  margin: 0
}

.compound-blocks-modules .picture-block .picture img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: initial;
  width: auto;
  min-height: 100%
}

.compound-blocks-modules .picture-block .picture+.picture-caption {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: -webkit-calc(100% - 228px);
  width: -moz-calc(100% - 228px);
  width: calc(100% - 228px);
  padding-left: 26px;
  overflow: hidden;
  font-size: 17px;
  line-height: 1.4em;
  font-weight: 300
}

.compound-blocks-modules .picture-block .picture .epigraph {
  font-size: 15px;
  line-height: 1.2em
}

.compound-blocks-modules .epigraph {
  letter-spacing: 1px;
  display: block;
  margin-bottom: 13px;
  font-size: 18px;
  line-height: 1.2em;
}

.compound-blocks-modules .copy {
  font-size: 12px;
  line-height: 1.2em
}

.compound-blocks-modules .lead {
  font-size: 28px;
  line-height: 27px;
  font-family: Oswald
}

.compound-blocks-modules h5 {
  margin: 0 0 1em
}

.compound-blocks-modules .icon {
  font-size: 24px;
  line-height: 1em;
  display: inline-block;
  vertical-align: middle
}

.compound-blocks-modules .icon-audio, .panel-info .icon-play,.compound-blocks-modules .icon-play, .compound-blocks-modules .icon-scroll-hand, .compound-blocks-modules .icon-single-audio {
  display: block;
  width: 96px;
  height: 96px;
  margin: 0 auto 40px;
  font-size: 96px;
  line-height: 1em;
  opacity: .3;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.compound-blocks-modules .icon-audio:before, .compound-blocks-modules .icon-play:before, .compound-blocks-modules .icon-scroll-hand:before, .compound-blocks-modules .icon-single-audio:before {
  margin: 0
}

.compound-blocks-modules .icon-audio:hover, .compound-blocks-modules .icon-play:hover, .compound-blocks-modules .icon-scroll-hand:hover, .compound-blocks-modules .icon-single-audio:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 1
}

.compound-blocks-modules .btn {
  margin-top: 18px
}

.compound-blocks-modules.island .size1 .content-block {
  height: 300px
}

.compound-blocks-modules.island .size2 .content-block {
  height: 668px
}

.compound-blocks-modules.island.A1 .content-block {
  height: 300px
}

.compound-blocks-modules.island-premios.A1 .content-block {
  height: 300px
}

.compound-blocks-modules.island-premios.A1 .col:first-child .content-block {
  padding: 35px 20px
}

.compound-blocks-modules.island-premios.type1 .size1 .content-block {
  height: 303px;
  padding: 35px 20px
}

.compound-blocks-modules.island-premios.type1 .size2 .content-block {
  height: 633px
}

.generic-article .related-info .item-video .compound-blocks-modules .full-col .content-block {
  height: 510px
}

.exposition-block .exposition-nav .desplegable input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size:14px;
  color:#BEBEBE;
}
.exposition-block .exposition-nav .desplegable input::-moz-placeholder { /* Firefox 19+ */
  font-size:14px;
  color:#BEBEBE;
}
.exposition-block .exposition-nav .desplegable input:-ms-input-placeholder { /* IE 10+ */
  font-size:14px;
  color:#BEBEBE;
}
.exposition-block .exposition-nav .desplegable input:-moz-placeholder { /* Firefox 18- */
  font-size:14px;
  color:#BEBEBE;
}
.exposition-block .exposition-nav .desplegable .error {
  display: none;
  padding: 10px;
  color:#E25063;
}


.confirmation-block {
  padding: 80px 0;
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 300;
  letter-spacing: 1px
}

.confirmation-block .info {
  display: block;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  margin-bottom: 50px
}

.confirmation-block .number-reference {
  background: #f5f5f5;
  padding: 30px 10px;
  margin-bottom: 50px
}

.confirmation-block .number-reference p {
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 10px
}

.confirmation-block .number-reference .number {
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2em;
  margin: 0
}

.confirmation-block .btn {
  margin-top: 30px
}

.block-audio {
  display: block;
  background: #f7f7f7;
  min-height: 40px;
  position: relative;
  z-index: 5
}

.block-audio audio {
  display: none;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px
}

.block-audio.desktop .audio-ctrls, .block-audio.mobile audio {
  display: block
}

.block-audio .audio-ctrls {
  display: none;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px
}

.block-audio .audio-ctrls:after, .block-audio .audio-ctrls:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.block-audio .audio-ctrls .play-block .icon.pause:before, .block-audio .audio-ctrls .play-block .icon.play:before, .block-audio .audio-ctrls .sound-lvl .icon.muted:before, .block-audio .audio-ctrls .sound-lvl .icon.sound:before {
  font-size: 15px;
  line-height: 40px;
  font-family: fontello
}

.block-audio .audio-ctrls .play-block, .block-audio .audio-ctrls .sound-lvl {
  width: 60px;
  height: 40px;
  position: relative;
  z-index: 1;
  float: left;
  display: block;
  text-align: center;
  cursor: pointer
}

.block-audio .audio-ctrls .play-block .icon {
  display: block;
  margin: 0 auto
}

.block-audio .audio-ctrls .play-block .icon.play:before {
  content: '\e835'
}

.block-audio .audio-ctrls .play-block .icon.pause {
  animation: delayOpacity 2s ease-in-out infinite;
  -webkit-animation: delayOpacity 2s ease-in-out infinite;
  -moz-animation: delayOpacity 2s ease-in-out infinite;
  -o-animation: delayOpacity 2s ease-in-out infinite;
  -ms-animation: delayOpacity 2s ease-in-out infinite
}

.block-audio .audio-ctrls .play-block .icon.pause:before {
  content: '\e801'
}

.block-audio .audio-ctrls .sound-lvl:hover .sound-cover-bar {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  height: 120px
}

.block-audio .audio-ctrls .sound-lvl .icon {
  display: block;
  margin: 0 auto
}

.block-audio .audio-ctrls .sound-lvl .icon.sound:before {
  content: '\e836'
}

.block-audio .audio-ctrls .sound-lvl .icon.muted:before {
  content: '\e837'
}

.advanced-search .title, .block-panels .title, .cicle-timeline .full-cicle .number {
  font-family: Oswald
}

.block-audio .audio-ctrls .sound-lvl .sound-cover-bar {
  display: block;
  height: 0;
  overflow: hidden;
  width: 30px;
  background: #f7f7f7;
  bottom: 100%;
  position: absolute;
  z-index: 10;
  margin-left: -17px;
  left: 50%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.block-audio .audio-ctrls .sound-lvl .sound-bar {
  background: rgba(0, 0, 0, .2);
  width: 4px;
  height: 100px;
  display: block;
  margin: 20px auto 0;
  border-radius: 3px;
  position: relative
}

.block-audio .audio-ctrls .sound-lvl .sound-bar span {
  display: block;
  width: 4px;
  height: 0;
  position: absolute;
  bottom: 0;
  border-radius: 3px;
  left: 0;
  background: #4b4b4b
}

.block-audio .audio-ctrls .block-bar {
  float: left;
  padding-left: 60px;
  margin-left: -60px;
  padding-right: 60px;
  margin-right: -60px;
  width: 100%
}

.block-audio .audio-ctrls .block-bar .cover-bar {
  width: 100%;
  display: block;
  position: relative;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  padding: 15px 20px
}

.block-audio .audio-ctrls .block-bar .bar {
  display: block;
  position: relative;
  width: 100%;
  height: 10px;
  background: #e7e7e7;
  cursor: pointer
}

.block-audio .audio-ctrls .block-bar .bar span {
  display: block;
  width: 0;
  height: 10px;
  background: #fff
}

.block-panels {
  display: block;
  margin-left: -10px;
  margin-right: -10px;
  font-size: 0;
  overflow: hidden
}

.block-panels.type2 .cover {
  color: #000;
  background: #fff
}

.block-panels .animation-frame {
  float: right;
  max-width: 180px
}

.block-panels .dynamic-background {
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  height: 100%;
  width: auto
}

.block-panels .cover-img img, .block-panels .header-img img {
  left: 50%;
  max-width: initial;
  min-width: 100%;
  min-height: 100%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%)
}

.block-panels .dynamic-background+.cover {
  background: 0 0
}

.block-panels .panel-info {
  padding: 0 10px;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin: 10px 0
}

.block-panels .panel-info[data-position=BottomRight] .cover, .block-panels .panel-info[data-position=MiddleRight] .cover, .block-panels .panel-info[data-position=TopRight] .cover {
  padding-right: 180px
}

.block-panels .panel-info.full {
  width: 100%
}

.block-panels .panel-info.full .title {
  font-size: 34px
}

.block-panels .panel-info.small {
  width: 25%
}

.block-panels .panel-info.c_white .cover {
  color: #fff
}

.block-panels .cover {
  background: #000;
  padding: 35px 30px 30px;
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 300;
  /*color: #fff;*/
  position: relative
}

.block-panels .tag, .block-panels .tag.blue {
  background: #009ed6
}

.block-panels .cover .bottom {
  margin-top: auto
}

.block-panels .cover .bottom, .block-panels .cover .top {
  width: 100%
}

.block-panels .header-img {
  position: relative;
  display: block;
  height: 330px;
  overflow: hidden;
  margin: -35px -30px 55px
}

.block-panels .header-img img {
  position: absolute;
  top: 50%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.block-panels .cover-img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 330px;
  height: 100%;
  z-index: 0;
  overflow: hidden
}

.block-panels .cover-img img {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.block-panels .cover-img+.cover-text {
  padding-right: 330px
}

.block-panels .cover-text {
  min-height: 265px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative
}

.block-panels .tag {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  width: 62px;
  height: 62px;
  text-align: center;
  font-size: 24px;
  line-height: 62px;
  z-index: 2
}

.block-panels .tag.sin_flecha {
  display: none
}

.block-panels .tag.red {
  background: $red-leonardo
}



.block-panels .tag.green, .block-panels .tag.greenl {
  background: #00d1c7
}

.block-panels .tag.yellow {
  background: #efbd2d
}

.block-panels .tag.orange {
  background: #f7893b
}

.block-panels .title {
  font-size: 29px;
  line-height: 1.2em;
  font-weight: 500;
  margin: 0 0 10px;
  letter-spacing: 0
}

.block-panels .title.padd-tag {
  padding-right: 50px
}

.block-panels .date {
  color: #878787;
  font-size: 13px;
  line-height: 1.2em;
  font-weight: 300;
  letter-spacing: 1px
}

.block-panels p {
  margin-bottom: 10px
}

.block-panels .copy {
  font-size: 12px;
  line-height: 1.2em
}

.block-panels .btn {
  margin-top: 10px
}

.block-panels .column-fix {
  display: block;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
  column-gap: 0;
  padding-top: 10px
}

.block-panels .column-fix .panel-info {
  width: 100%;
  display: block;
  margin-top: 0;
  margin-bottom: 20px
}

.profile-blocks {
  display: block;
  margin-left: -10px;
  margin-right: -10px;
  font-size: 0
}

.profile-blocks .block {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 10px;
  margin: 10px 0
}

.profile-blocks .info {
  display: block;
  background: #fff;
  position: relative;
  min-height: 216px
}

.profile-blocks .info:after, .profile-blocks .info:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.profile-blocks .cover-img {
  display: block;
  width: 220px;
  height: 220px;
  overflow: hidden;
  position: relative;
  float: left
}

.profile-blocks .cover-img img {
  max-width: initial;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0)
}

.profile-blocks .cover-img+.cover-text {
  float: left;
  width: -webkit-calc(100% - 220px);
  width: -moz-calc(100% - 220px);
  width: calc(100% - 220px)
}

.profile-blocks .cover-text {
  padding: 20px;
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 300
}

.profile-blocks .title {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2em;
  margin-bottom: 6px
}

.exposition-description .info-description .title b, .exposition-description .info-description .title strong, .exposition-description .location-description .title b, .exposition-description .location-description .title strong {
  font-weight: 700
}

.exposition-block {
  display: block;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .15);
  padding: 20px 0;
  position: relative;
  z-index: 1
}

.exposition-block .exposition-nav {
  display: block
}

.exposition-block .exposition-nav:after, .exposition-block .exposition-nav:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.exposition-block .exposition-nav .left {
  font-size: 25px;
  line-height: 1em
}

.exposition-block .exposition-nav .left .icon {
  font-size: 36px;
  line-height: 1em
}

.exposition-block .exposition-nav .left a {
  display: inline-block;
  vertical-align: middle
}

.exposition-block .exposition-nav .right nav {
  display: block
}

.exposition-block .exposition-nav .right ul {
  margin: 0;
  padding: 0;
  font-size: 0
}

.exposition-block .exposition-nav .right li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #000;
  background: #fff;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.exposition-block .exposition-nav .right li+li {
  border-left: 0
}

.exposition-block .exposition-nav .right li.active, .exposition-block .exposition-nav .right li:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  background: #f6f6f6
}

.exposition-block .exposition-nav .right span {
  display: block;
  padding: 15px 25px;
  font-size: 14px;
  line-height: 1.2em
}

.exposition-block .btn-dropdown.type2 .btn {
  padding-left: 20px;
  min-width: 220px
}

.exposition-block .btn-dropdown .btn-dropdwon-items li {
  text-align: left
}

.exposition-description {
  display: block;
  width: 100%;
  overflow: hidden
}

.exposition-description:after, .exposition-description:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.exposition-description .container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex
}

.exposition-description .location-description {
  background: #f1f1f1;
  min-height: 100%;
  width: 220px;
  position: relative;
  padding: 50px 20px 50px 0;
  font-size: 13px;
  line-height: 1.6em
}

.exposition-description .location-description:before {
  content: "";
  display: block;
  width: 2000px;
  right: 0;
  position: absolute;
  height: 100%;
  background: #f1f1f1;
  z-index: -1;
  top: 0
}

.exposition-description .location-description p {
  margin-bottom: 30px
}

.exposition-description .location-description .title,
.exposition-description .location-description h3 a {
  font-size: 14px;
  line-height: 1.2em;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.exposition-description .location-description h3 {
  margin-top: 0px;
}

.exposition-description .location-description h3 span {
  font-size: 13px;
  line-height: 1.6em;
  letter-spacing: 0px;
  margin-bottom: 30px;
  display: block;
}

.exposition-description .location-description .btn {
  display: block;
  width: 100%
}

.exposition-description .location-description .icon {
  font-size: 20px
}

.exposition-description .location-description .icon.border-left {
  padding-left: 5px;
  border-left: 1px solid #000;
  opacity: .3;
  min-height: 40px;
  line-height: 40px
}

.exposition-description .location-description .icon-place {
  margin-left: -3px
}

.exposition-description .location-description .icon-place:before {
  margin-left: 0
}

.exposition-description .location-description a {
  display: block;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.exposition-description .info-description .social li, .exposition-description .location-description a:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.exposition-description .location-description a:hover {
  color: #b2b2b2;
  transition: all .3s ease
}

.exposition-description .location-description a:hover .icon {
  color: #000
}

.exposition-description .location-description a:hover .icon.border-left {
  opacity: 1
}

.exposition-description .location-description .description-block {
  margin-bottom: 50px;
  white-space: nowrap;

}

.exposition-description .location-description .description-block p {
  margin-bottom: 10px;
  padding-left: 15px;
  position: relative
}

.exposition-description .location-description .description-block p img {
  margin-left: -15px
}

.exposition-description .location-description .description-block p .icon-place {
  position: absolute;
  left: -3px;
  top: 0
}

.exposition-description .location-description .description-block a {
  font-size: 11px;
  line-height: 1.2em
}

.exposition-description .location-description+.info-description {
  padding-left: 20px
}

.exposition-description .info-description {
  background: #fbfbfb;
  min-height: 100%;
  width: -webkit-calc(100% - 220px);
  width: -moz-calc(100% - 220px);
  width: calc(100% - 220px);
  position: relative;
  padding: 50px 0;

  a {
    color: $blue-awards;

    &:hover {
      text-decoration: underline;
    }
  }

  .social {
    li {
      a {
        color: inherit;
      }
    }
  }
}

.exposition-description .info-description.full {
  width: 100%
}

.exposition-description .info-description.full:before {
  left: -1000px;
  width: 3000px
}

.exposition-description .info-description.full .cover-text {
  max-width: 940px
}

.exposition-description .info-description.has-aside-right {
  padding-right: 140px;

  @media screen and (max-width: $desktop-small) {
    padding-right: 280px;
  }

  @media screen and (max-width: $tablet-landscape) {
    padding-right: 0;
  }
}

.exposition-description .info-description:before {
  content: "";
  display: block;
  width: 2000px;
  left: 0;
  position: absolute;
  height: 100%;
  background: #fbfbfb;
  z-index: -1;
  top: 0
}

.exposition-description .info-description .social {
  position: absolute;
  right: 0
}

.exposition-description .info-description .social ul {
  margin: 0;
  padding: 0;
  font-size: 0
}

.exposition-description .info-description .social li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  color: #dedede;
  font-size: 20px;
  line-height: 1em;
  transition: all .3s ease
}

.aside-cicle-block, .exposition-description .info-description .social li:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.exposition-description .info-description .social li:hover {
  transition: all .3s ease;
  color: #000
}

.exposition-description .info-description .social li+li {
  margin-left: 20px
}

.exposition-description .info-description .title {
  font-size: 19px;
  line-height: 1.2em;
  letter-spacing: 1px;
  margin-bottom: 10px
}

.exposition-description .info-description h2 span {
  font-size: 19px;
  line-height: 1.2em;
  letter-spacing: 1px;
  margin-bottom: 10px;
  display: block;
}

.exposition-description .info-description  {
  p, ul li {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.6em;
    font-weight: 400
  }

  ul li {
    margin-bottom: 10px;
  }
}

.exposition-description .info-description p img {
  max-width: 110px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px
}

.exposition-description .info-description p:empty {
  display: block;
  margin: 0
}

.exposition-description .info-description .cover-text {
  position: relative;
  max-width: 700px;
  width: 100%;
}

.exposition-description .info-description .cover-text h2 {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1.6em;
  font-weight: bold;
}

.exposition-description .info-description .cover-text h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.6em;
  font-weight: bold;
}

body#islaMultiverso .exposition-description .info-description .aside-right {
  position: absolute;
  right: -280px;
  top: 50px !important;
  width: 280px;

  @media screen and (max-width: $tablet-landscape) {
    position: static;
    right: 0;
    top: 0;
    width: 50%;
  }

  @media screen and (max-width: $mobile-landscape) {
    width: 100%;
  }

  .aside-info {
    display: grid;
    grid-template-areas: 'icon tag tag' 'icon title title';
    grid-gap: 10px;
    .icon {
      grid-area: icon;
      font-size: 44px;
    }
    .tag {
      grid-area: tag;
      margin-bottom: 0;
    }
    .title {
      grid-area: title;
      font-family: Oswald, sans-serif;
    }
  }
}
.exposition-description .info-description .aside-right {
  position: absolute;
  right: -280px;
  top: 50px;
  width: 280px;

  @media screen and (max-width: $tablet-landscape) {
    position: static;
    right: 0;
    top: 0;
    width: 50%;
  }

  @media screen and (max-width: $mobile-landscape) {
    width: 100%;
  }

  .aside-info {
    display: grid;
    grid-template-areas: 'icon tag tag' 'icon title title';
    grid-gap: 10px;
    .icon {
      grid-area: icon;
      font-size: 44px;
    }
    .tag {
      grid-area: tag;
      margin-bottom: 0;
    }
    .title {
      grid-area: title;
      font-family: Oswald, sans-serif;
    }
  }
}

.exposition-description .info-description .aside-right .btn {
  display: block
}

.aside-cicle-block {
  position: fixed;
  overflow: auto;
  width: 100%;
  max-width: 500px;
  right: -500px;
  top: 69px;
  background: #000;
  color: #fff;
  bottom: 0;
  z-index: 21;
  transition: all .3s;

  &.show {
    right: 0;
  }

  .content {
    padding: 20px;
    position: relative;

    .icon-cancel {
      position: absolute;
      display: block;
      top: 20px;
      right: 20px;
      cursor: pointer
    }

    .small {
      font-size: 14px;
      line-height: 1.2em;
      margin-bottom: 10px
    }

    .title {
      font-size: 20px;
      line-height: 1.2em;
      margin: 0 0 17px
    }

    .subtitle {
      font-size: 16px;
      line-height: 1.2em;
      margin: 0 0 18px;
      font-weight: 700;
      letter-spacing: 1px
    }

    .list-links {
      display: block;
      list-style: none;
      margin-left: -20px;
      margin-right: -20px;
      padding: 0;

      li {
        list-style: none;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        padding: 10px;

        &:nth-child(odd) {
          background: #1b1b1b;
        }

        &:nth-child(even), .dynamic-block {
          background: #000;
        }

        .cover-img {
          width: 92px;
          height: 92px;

          a img {
            transition: opacity .3s;

            &:hover {
              opacity: .5;
            }
          }
        }

        .cover-text {
          width: calc(100% - 92px);
          font-size: 14px;
          line-height: 1.4em;
          padding: 0 14px;

          a {
            &:hover {
              text-decoration: none;
            }
          }

          .event-title {
            margin: 0;
            font-size: 16px;

            &:hover {
              transition: all .3s;
              opacity: .5;
            }

            span {
              font-family: bentonsansBold, sans-serif;
              font-weight: bold;
              display: block;
            }
          }

        }
      }
    }
  }
}


.dynamic-block {
  display: block;
  position: relative;
  min-height: 600px;
  z-index: 0;
  color: #fff;
  padding: 30px 0;
  overflow: hidden
}

.dynamic-block .container {
  position: relative;
  z-index: 10
}

.dynamic-block .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.dynamic-block .color-block, .dynamic-block .image-block, .dynamic-block .interactive-block, .dynamic-block .video-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0
}

.dynamic-block .video-block video {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.dynamic-block .BotLeft {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -620px
}

.dynamic-block .TopLeft {
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -620px
}

.dynamic-block .BotRight, .dynamic-block .TopRight {
  position: absolute;
  bottom: 30px;
  right: 50%;
  margin-right: -620px
}

.dynamic-block .animated-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto
}

.dynamic-block .animated-block li {
  display: block;
  width: 180px;
  height: 180px;
  border: 1px solid #fff;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: opacity .3s ease
}

.dynamic-block .animated-block li:hover {
  opacity: .5;
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  -ms-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  transition: opacity .3s ease
}

.dynamic-block .animated-block li:nth-child(1) {
  bottom: 60px;
  left: 0
}

.dynamic-block .animated-block li:nth-child(2) {
  bottom: 160px;
  left: 31.8%
}

.dynamic-block .animated-block li:nth-child(3) {
  top: 120px;
  right: 21%;
}

.dynamic-block .animated-block li:nth-child(4) {
  bottom: 85px;
  right: 0;
}

.dynamic-block .animated-block span {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2em;
  text-align: center;
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: opacity .3s linear;
  -moz-transition: opacity .3s linear;
  -ms-transition: opacity .3s linear;
  -o-transition: opacity .3s linear;
  transition: opacity .3s linear
}


.content-block .logo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 40px;
  margin-top: 30px;
  width: 100%;
  max-width: 200px;
  float: left;
}

.content-block .logo:after {
  content: "";
  width: 1px;
  height: 178px;
  background: #000;
  opacity: .4;
  -webkit-transform: matrix(.9613, .2756, -.2756, .9613, 25, 3);
  -moz-transform: matrix(.9613, .2756, -.2756, .9613, 25, 3);
  -ms-transform: matrix(.9613, .2756, -.2756, .9613, 25, 3);
  -o-transform: matrix(.9613, .2756, -.2756, .9613, 25, 3);
  transform: matrix(.9613, .2756, -.2756, .9613, 25, 3);
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -89px;
  left: 180px
}

.content-block .nav-block-section {
  display: block;
  font-size: 0;
  color: #000;
  position: relative;
  float: left;
  width: 100%
}

.nav-block-section .col {
  float: left;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  width: 275px;
  font-size: 15px;
  line-height: 1.5em;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0 30px
}

.nav-block-section #menuDcha {
  float: right;
  font-weight: 400;
  margin-right: 0;
  margin-left: 15px;
  max-width: 166px;
}

.nav-block-section #menuDcha+#menuIzq {
  float: left;
  width: 260px;
}

.nav-block-section #menuIzq {
  float: left;
  width: 100%;
}

.nav-block-section li {
  list-style: none
}

.nav-block-section li a {
  transition: all .3s ease
}

.nav-block-section li a.active, .nav-block-section li a:hover {
  transition: all .3s ease;
  color: #b2b2b2
}

.contact-block {
  display: block
}

.contact-block .block {
  display: block;
  margin-bottom: 25px
}

.contact-block .block p {
  margin-bottom: 6px
}

.contact-block .block .job {
  font-size: 19px;
  line-height: 1.2em;
  font-weight: 600;
  margin-bottom: 6px
}

.contact-block .block .name-number {
  font-size: 21px;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 6px
}

.contact-block .block a {
  color: #009ed6
}

.newsletter-block {
  display: block;
  padding: 50px 0
}

.newsletter-block .title {
  font-size: 19px;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 1px
}

.newsletter-block .block-title {
  display: block;
  float: left;
  width: 290px;
  padding-right: 20px
}

.newsletter-block .block-info {
  display: block;
  float: left;
  width: -webkit-calc(100% - 290px);
  width: -moz-calc(100% - 290px);
  width: calc(100% - 290px);
  font-size: 18px;
  line-height: 1.6em
}

.newsletter-block .form-section:after, .newsletter-block .form-section:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.newsletter-block .form-ctrl {
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 500;
  outline: 0;
  padding: 14px 15px;
  border: 1px solid #dedede
}

.newsletter-block .label-input, .newsletter-block .small {
  font-size: 12px;
  line-height: 1.2em
}

.newsletter-block .label-input a, .newsletter-block .small a {
  color: #009ed6;
  font-weight: 600
}

.newsletter-block .input-block {
  display: block;
  float: left;
  width: -webkit-calc(100% - 160px);
  width: -moz-calc(100% - 160px);
  width: calc(100% - 160px);
  padding-right: 20px;
  margin-bottom: 20px
}

.newsletter-block .submit-block {
  display: block;
  float: right;
  margin-bottom: 20px
}

.newsletter-block .label-input, .newsletter-block .label-input input, .newsletter-block .label-input p {
  display: inline-block;
  vertical-align: middle
}

.newsletter-block .label-input p {
  margin: 0 0 0 5px
}

.double-block {
  display: block;
  padding: 25px 0;
  background: #e0e0e0;
  font-size: 0
}

.double-block .row {
  margin-left: -10px;
  margin-right: -10px;
  display: block
}

.double-block .block {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  margin: 20px 0;
  padding: 0 10px
}

.double-block .block .cover {
  background: #fff
}

.double-block .cover-img img {
  width: 100%
}

.double-block .cover-info {
  min-height: 304px;
  overflow: hidden;
  font-size: 15px;
  line-height: 1.5em;
  padding: 30px 20px;
  font-weight: 300
}

.double-block .cover-info .epigraph {
  font-size: 18px;
  line-height: 1.2em
}

.double-block .cover-info .title {
  margin-bottom: 6px;
  font-size: 29px;
  line-height: 1.2em
}

.block-animated {
  display: block;
  width: 100%
}

.block-animated .block-ranking {
  display: block;
  width: 100%;
  font-size: 0
}

.block-animated .block-ranking:after, .block-animated .block-ranking:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.block-animated .block-ranking .column {
  display: inline-block;
  vertical-align: bottom;
  width: 33.33%;
  padding: 0 10px;
  text-align: center
}

.block-animated .block-ranking .column:nth-child(2) .rect {
  height: 120px
}

.block-animated .block-ranking .column:nth-child(1) .rect {
  height: 82px
}

.block-animated .block-ranking .column:nth-child(3) .rect {
  height: 70px
}

.block-animated .block-ranking .number {
  font-size: 18px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  opacity: 0
}

.block-animated .block-ranking .rect {
  display: block;
  width: 100%;
  height: 10px;
  border: 2px solid #000;
  border-radius: 4px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0)
}

.block-animated .block-ranking.animated .column:nth-child(2) .number {
  animation: fadeIn 1s linear 1.1s forwards;
  -webkit-animation: fadeIn 1s linear 1.1s forwards;
  -moz-animation: fadeIn 1s linear 1.1s forwards;
  -o-animation: fadeIn 1s linear 1.1s forwards;
  -ms-animation: fadeIn 1s linear 1.1s forwards
}

.block-animated .block-ranking.animated .column:nth-child(2) .rect {
  animation: ranking 1s linear 0s forwards;
  -webkit-animation: ranking 1s linear 0s forwards;
  -moz-animation: ranking 1s linear 0s forwards;
  -o-animation: ranking 1s linear 0s forwards;
  -ms-animation: ranking 1s linear 0s forwards
}

.block-animated .block-ranking.animated .column:nth-child(1) .number {
  animation: fadeIn 1s linear 2.1s forwards;
  -webkit-animation: fadeIn 1s linear 2.1s forwards;
  -moz-animation: fadeIn 1s linear 2.1s forwards;
  -o-animation: fadeIn 1s linear 2.1s forwards;
  -ms-animation: fadeIn 1s linear 2.1s forwards
}

.block-animated .block-ranking.animated .column:nth-child(1) .rect {
  animation: ranking 1s linear 1s forwards;
  -webkit-animation: ranking 1s linear 1s forwards;
  -moz-animation: ranking 1s linear 1s forwards;
  -o-animation: ranking 1s linear 1s forwards;
  -ms-animation: ranking 1s linear 1s forwards
}

.block-animated .block-ranking.animated .column:nth-child(3) .number {
  animation: fadeIn 1s linear 3.1s forwards;
  -webkit-animation: fadeIn 1s linear 3.1s forwards;
  -moz-animation: fadeIn 1s linear 3.1s forwards;
  -o-animation: fadeIn 1s linear 3.1s forwards;
  -ms-animation: fadeIn 1s linear 3.1s forwards
}

.block-animated .block-ranking.animated .column:nth-child(3) .rect {
  animation: ranking 1s linear 2s forwards;
  -webkit-animation: ranking 1s linear 2s forwards;
  -moz-animation: ranking 1s linear 2s forwards;
  -o-animation: ranking 1s linear 2s forwards;
  -ms-animation: ranking 1s linear 2s forwards
}

.block-animated .block-calendar {
  display: block;
  width: 100%;
  max-width: 132px;
  margin: 0 auto;
  position: relative
}

.block-animated .block-calendar:after, .block-animated .block-calendar:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.block-animated .block-calendar .back {
  position: relative
}

.block-animated .block-calendar .hand {
  position: absolute;
  bottom: -25px;
  right: 10px;
  width: 52px
}

.block-animated .block-calendar .months {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  height: 22px;
  overflow: hidden
}

.block-animated .block-calendar .month {
  position: absolute;
  width: 100%;
  font-size: 24px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #000;
  text-align: center;
  display: block;
  opacity: 0
}

.block-animated .block-calendar.animated .month:nth-child(1) {
  animation: calendarMonths 16.8s ease-in-out 1.4s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 1.4s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 1.4s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 1.4s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 1.4s infinite
}

.block-animated .block-calendar.animated .month:nth-child(2) {
  animation: calendarMonths 16.8s ease-in-out 2.8s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 2.8s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 2.8s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 2.8s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 2.8s infinite
}

.block-animated .block-calendar.animated .month:nth-child(3) {
  animation: calendarMonths 16.8s ease-in-out 4.199999999999999s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 4.199999999999999s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 4.199999999999999s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 4.199999999999999s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 4.199999999999999s infinite
}

.block-animated .block-calendar.animated .month:nth-child(4) {
  animation: calendarMonths 16.8s ease-in-out 5.6s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 5.6s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 5.6s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 5.6s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 5.6s infinite
}

.block-animated .block-calendar.animated .month:nth-child(5) {
  animation: calendarMonths 16.8s ease-in-out 7s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 7s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 7s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 7s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 7s infinite
}

.block-animated .block-calendar.animated .month:nth-child(6) {
  animation: calendarMonths 16.8s ease-in-out 8.399999999999999s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 8.399999999999999s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 8.399999999999999s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 8.399999999999999s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 8.399999999999999s infinite
}

.block-animated .block-calendar.animated .month:nth-child(7) {
  animation: calendarMonths 16.8s ease-in-out 9.799999999999999s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 9.799999999999999s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 9.799999999999999s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 9.799999999999999s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 9.799999999999999s infinite
}

.block-animated .block-calendar.animated .month:nth-child(8) {
  animation: calendarMonths 16.8s ease-in-out 11.2s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 11.2s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 11.2s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 11.2s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 11.2s infinite
}

.block-animated .block-calendar.animated .month:nth-child(9) {
  animation: calendarMonths 16.8s ease-in-out 12.6s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 12.6s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 12.6s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 12.6s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 12.6s infinite
}

.block-animated .block-calendar.animated .month:nth-child(10) {
  animation: calendarMonths 16.8s ease-in-out 14s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 14s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 14s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 14s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 14s infinite
}

.block-animated .block-calendar.animated .month:nth-child(11) {
  animation: calendarMonths 16.8s ease-in-out 15.399999999999999s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 15.399999999999999s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 15.399999999999999s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 15.399999999999999s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 15.399999999999999s infinite
}

.block-animated .block-calendar.animated .month:nth-child(12) {
  animation: calendarMonths 16.8s ease-in-out 16.799999999999997s infinite;
  -webkit-animation: calendarMonths 16.8s ease-in-out 16.799999999999997s infinite;
  -moz-animation: calendarMonths 16.8s ease-in-out 16.799999999999997s infinite;
  -o-animation: calendarMonths 16.8s ease-in-out 16.799999999999997s infinite;
  -ms-animation: calendarMonths 16.8s ease-in-out 16.799999999999997s infinite
}

.block-animated .block-calendar.animated .hand {
  animation: calendarHand .7s ease-in-out 1.4s infinite alternate;
  -webkit-animation: calendarHand .7s ease-in-out 1.4s infinite alternate;
  -moz-animation: calendarHand .7s ease-in-out 1.4s infinite alternate;
  -o-animation: calendarHand .7s ease-in-out 1.4s infinite alternate;
  -ms-animation: calendarHand .7s ease-in-out 1.4s infinite alternate
}

.multiple-block {
  display: block;
  position: relative;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 500
}

.multiple-block .multiple-cloack-options {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer
}

.multiple-block .multiple-options {
  position: absolute;
  width: 100%;
  padding: 0;
  overflow: auto;
  max-height: 150px;
  background: #fff;
  color: #000;
  display: none;
  z-index: 10
}

.multiple-block .multiple-options>div {
  transition: all .3s ease
}

.multiple-block .multiple-options>div:first-of-type {
  padding-top: 10px
}

.multiple-block .multiple-options>div:last-of-type {
  padding-bottom: 10px
}

.multiple-block .multiple-options>div.active, .multiple-block .multiple-options>div:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  background: #009ed6;
  color: #fff
}

.multiple-block .optionGroup {
  font-weight: 600;
  padding: 5px 10px
}

.multiple-block .optionChild {
  padding: 5px 10px 5px 25px;
  color: #7f7f7f
}

.background-block {
  display: block;
  position: relative;
  overflow: hidden;
  background: #000;
  color: #fff
}

.background-block .background {
  position: absolute;
  top: 50%;
  left: 0;
  width: auto;
  max-width: initial;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  opacity: .4;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%)
}

.background-block .content-block, .background-block .title-block {
  display: block;
  position: relative;
  z-index: 1
}

.background-block .title-block {
  min-height: 370px;
  padding: 58px 0
}

.background-block .title-block .title {
  font-size: 35px;
  line-height: 1.2em;
  letter-spacing: 1px;
  max-width: 780px;
  margin: 0;
  font-weight: 100
}

.background-block .content-block {
  background: #f5f5f5;
  opacity: .9;
  padding-top: 50px;
  padding-bottom: 30px;
  color: #000;
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 300
}

.background-block .content-block .column_2 {
  -moz-column-gap: 120px;
  -webkit-column-gap: 120px;
  column-gap: 120px
}



@media all and (max-width:1200px) {

  .compound-blocks-modules .bigtitle {
	font-size: 6.6vw
  }
  .compound-blocks-modules .bigtitle.t2 {
	font-size: 110px;
	margin: 20px 0
  }
  .compound-blocks-modules .bigtitle.t3 {
	font-size: 8.34vw
  }
  .compound-blocks-modules .bigtitle.t4 {
	font-size: 11.7vw
  }
  .compound-blocks-modules .bigtitle.t4 small {
	font-size: .66em
  }
  .compound-blocks-modules .bigtitle.t5 {
	font-size: 114px
  }
  .dynamic-block .BotLeft, .dynamic-block .TopLeft, .general-title-fixed.holdtitle {
    left: 0;
    margin-left: 0
  }
  .dynamic-block .BotRight, .dynamic-block .TopRight {
    margin-right: 0;
    right: 0
  }
  .nav-block .logo {
    margin-right: 30px;
    max-width: 160px
  }
  .nav-block .col {
    margin: 0
  }
  .nav-block:after {
    left: 140px
  }
  .nav-block-section:after {
    left: 160px
  }
  .nav-block-section .logo {
    margin-right: 20px
  }
  .nav-block-section .col {
    width: 100px;
    margin: 0 10px
  }
  .nav-block-section .col:last-of-type {
    width: 80px
  }
}




@media all and (max-width:1023px) {
  .compound-blocks-modules .col, .compound-blocks-modules.C1 .col:first-child, .compound-blocks-modules.C1 .col:last-child, .compound-blocks-modules.C2 .col:first-child, .compound-blocks-modules.C2 .col:last-child {
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 50px;
    border-bottom: solid 1px #ccc;
  }

  .compound-blocks-modules.A1 .col {
    padding-bottom: inherit;
    margin-bottom: inherit;
    border-bottom: none;
  }

  .compound-blocks-modules.C1 .col:last-child .content-block, .compound-blocks-modules.C2 .col:first-child .content-block, .compound-blocks-modules.island.A1 .col:first-child .content-block, .compound-blocks-modules.type1 .size1 .content-block {
    height: auto
  }

  .compound-blocks-modules .col:first-child .content-block, .compound-blocks-modules .col:first-of-type .content-block {
    margin-right: auto;
    padding-right: 20px
  }
  .compound-blocks-modules .col:last-child .content-block, .compound-blocks-modules .col:last-of-type .content-block {
    margin-left: auto;
    padding-left: 20px
  }
  .compound-blocks-modules .bigtitle {
    font-size: 12.6vw;
    font-size: 74px
  }
  .compound-blocks-modules .bigtitle.t2 {
    margin: 0
  }
  .compound-blocks-modules .bigtitle.t3 {
    font-size: 100px
  }
  .compound-blocks-modules .bigtitle.t4 {
    font-size: 140px
  }
  .compound-blocks-modules .title.size50, .compound-blocks-modules .title2.size50 {
    font-size: 35px
  }
  .compound-blocks-modules .title.size60, .compound-blocks-modules .title2.size60 {
    font-size: 45px
  }
  .compound-blocks-modules .title.size70, .compound-blocks-modules .title2.size70 {
    font-size: 52px
  }
  .compound-blocks-modules .title.size80, .compound-blocks-modules .title2.size80 {
    font-size: 60px
  }

  .title-block-fixed {
    top: 69px;
    padding: 18px 0
  }
  .title-block-fixed+.module {
    margin-top: 65px
  }
  .title-block-fixed .nav-cover>.section-selector {
    margin-top: -6px
  }
  .background-block .title-block {
    min-height: auto
  }
  .background-block .content-block .column_2 {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
    -moz-column-gap: 0;
    -webkit-column-gap: 0;
    column-gap: 0
  }
  .information-block .item {
    padding-left: 20px;
    text-align: center
  }
  .information-block .item .icon {
    position: relative;
    margin-bottom: 15px;
    display: block
  }
  .calendar-block.static-calendar .generic-timeline .info {
    padding: 20px 15px
  }
  .block-panels .panel-info.small {
    width: 50%
  }
  .block-panels .column-fix {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2
  }
  .block-panels .column-fix .panel-info.small, .nomination-formulary .details .info>.label-description, .nomination-formulary .form-group.step2, .nomination-formulary .form-group.step3, .nomination-formulary .form-section.step2, .nomination-formulary .form-section.step3, .standar-formulary .details .info>.label-description, .standar-formulary .form-group.step2, .standar-formulary .form-group.step3, .standar-formulary .form-section.step2, .standar-formulary .form-section.step3 {
    width: 100%
  }
  .profile-blocks .block {
    width: 100%
  }
  .exposition-description .container {
    display: block
  }
  .exposition-description .location-description {
    display: block;
    width: 100%;
    min-height: initial;
    padding: 30px 0
  }
  .exposition-description .location-description:before {
    right: -20px
  }
  .exposition-description .location-description .btn {
    display: inline-block;
    vertical-align: middle;
    width: auto
  }
  .exposition-description .location-description+.info-description {
    padding-left: 0
  }
  .exposition-description .info-description {
    padding: 30px 0;
    margin: 0;
    width: 100%
  }
  .exposition-description .info-description:before {
    left: -20px
  }
  .nav-block-section .col {
    margin: 0 25px;
    width: auto
  }
  .dynamic-block .TopLeft {
    width: 60%
  }
  .newsletter-block .block-info, .newsletter-block .block-title {
    width: 100%
  }
}

@media all and (max-width:700px) {
  .compound-blocks-modules.A1 .content-block, .compound-blocks-modules.parallax .col:first-of-type .content-block, .main-header .search-field {
    height: auto;
  }
  .compound-blocks-modules.A1 .content-block.picture-block {
    padding-left: 0 !important
  }
  .compound-blocks-modules.type2 .size1 .content-block {
    height: auto;
    min-height: 602px
  }
  .compound-blocks-modules .extrabigtitle {
    font-size: 100px;
    line-height: 80px
  }
  .compound-blocks-modules .extrabigtitle.size2 {
    font-size: 80px;
    line-height: 68px
  }
  .compound-blocks-modules .bigtitle {
    font-size: 13.8vw
  }
  .compound-blocks-modules .bigtitle.t2 {
    font-size: 17.1vw !important
  }
  .compound-blocks-modules .bigtitle.t3 {
    font-size: 14.5vw
  }
  .compound-blocks-modules .bigtitle.t4 {
    font-size: 20vw
  }
  .compound-blocks-modules .bigtitle.t5 {
    font-size: 16.3vw;
    margin: 0
  }
  .compound-blocks-modules .bigtitle.t6 {
    font-size: 14.1vw
  }
  .compound-blocks-modules .title {
    font-size: 30px
  }
  .compound-blocks-modules .title.size40, .compound-blocks-modules .title.size50, .compound-blocks-modules .title.size60, .compound-blocks-modules .title.size70, .compound-blocks-modules .title.size80 {
    font-size: 36px
  }
  .compound-blocks-modules .title2 {
    font-size: 18px;
    line-height: 1.3em
  }
  .compound-blocks-modules .title2.size40, .compound-blocks-modules .title2.size50, .compound-blocks-modules .title2.size60, .compound-blocks-modules .title2.size70, .compound-blocks-modules .title2.size80 {
    font-size: 36px
  }
  .compound-blocks-modules .title2.size2 {
    font-size: 25px
  }
  .compound-blocks-modules .main-gallery .social {
    top: 10px
  }
  .title-block-fixed {
    max-height: none;
    padding: 10px 0
  }
  .title-block-fixed.fixNextModule+.module {
    margin-top: 100px
  }
  .title-block-fixed+.module {
    margin-top: 58px
  }
  .title-block-fixed .title {
    font-size: 18px;
    margin: .5em 0;
    width: 100%
  }
  .title-block-fixed .general-nav, .title-block-fixed .nav-cover.diary-nav .section-selector:first-of-type, .title-block-fixed .section-selector {
    margin-top: 0
  }
  .title-block-fixed .section-selector .selector-name {
    padding: 10px 20px
  }
  .title-block-fixed .section-selector:hover .section-selector {
    padding-bottom: 0;
    max-height: 0
  }
  .title-block-fixed .section-selector.active {
    z-index: 1
  }
  .title-block-fixed .section-selector.active .section-selector {
    padding-bottom: 10px;
    max-height: 1000px
  }
  .title-block-fixed .general-nav span {
    width: 48px;
    height: 40px;
    line-height: 40px;
    font-size: 22px
  }
  .title-block-fixed .nav-cover.diary-nav>.section-selector:after, .title-block-fixed .nav-cover.diary-nav>.section-selector:before, .title-block-fixed .nav-cover:after, .title-block-fixed .nav-cover:before {
    font-size: 0;
    height: 0;
    display: block;
    visibility: hidden;
    content: " "
  }
  .title-block-fixed .nav-cover {
    float: none;
    display: block
  }
  .title-block-fixed .nav-cover>.section-selector.double .section-selector {
    left: 0;
    right: auto
  }
  .title-block-fixed .nav-cover>.section-selector.double li {
    text-align: left
  }
  .title-block-fixed .nav-cover.diary-nav>.section-selector {
    width: 50%
  }
  .title-block-fixed .nav-cover.diary-nav>.section-selector:first-of-type {
    clear: both;
    width: 50%;
    float: none;
    display: block;
    margin: 0 auto;
  }
  .background-block .title-block .title {
    font-size: 25px
  }
  .information-block {
    display: block
  }
  .information-block .item {
    text-align: center;
    width: 100%;
    max-width: 300px;
    margin-bottom: 30px;
    vertical-align: top
  }
  .calendar-block.static-calendar .generic-timeline-indicators {
    display: none
  }
  .calendar-block.static-calendar .generic-timeline .cover-timeline-blocks .item {
    padding: 5px;
    width: 33.3333%
  }
  .block-panels .cover-img {
    position: relative;
    left: 0;
    width: auto;
    display: block;
    margin: -35px -30px 20px
  }
  .block-panels .cover-img img {
    position: relative;
    min-height: initial;
    height: auto;
    width: 100%;
    top: 0;
    left: 0;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none
  }
  .block-panels .cover-img+.cover-text {
    padding-right: 0
  }
  .block-panels .panel-info {
    display: block;
    width: 100%;
    margin-bottom: 20px
  }
  .block-panels .panel-info:last-child {
    margin-bottom: 0
  }
  .panel_s5_phone{
    margin-bottom: 20px!important;
  }
  .block-panels .panel-info.small {
    width: 100%
  }
  .block-panels .column-fix {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1
  }
  .exposition-description .info-description .social {
    position: relative;
    margin-bottom: 10px;
    text-align: right
  }
  .exposition-description .info-description .aside-right {
    position: relative;
    top: auto
  }
  .exposition-description .info-description .aside-right .btn {
    display: inline-block;
    min-width: 130px
  }
  .exposition-block .exposition-nav .left {
    margin-bottom: 10px
  }
  .exposition-block .exposition-nav .right li+li {
    border-left: 1px solid #000
  }
  .exposition-block .exposition-nav .left, .exposition-block .exposition-nav .right {
    float: none !important;
    display: block;
    text-align: center
  }
  .dynamic-block .animated-block li {
    width: 25%;
    height: 0;
    padding-bottom: 24.8%
  }
  .dynamic-block .animated-block span {
    font-size: 2.3vw
  }
  .double-block .block {
    width: 100%
  }
  .newsletter-block .input-block {
    width: 100%;
    padding: 0;
    text-align: left !important
  }
  .newsletter-block .submit-block {
    width: 100%
  }
  .block-panels .cover .bottom, .block-panels .cover .top {
    width: 98%
  }
}

@media all and (max-width:480px) {
  .content-block .logo.home {
    width: 100%;
    max-width: inherit
  }
  .content-block .logo.home:after {
    height: 120px;
    margin-top: -21px;
    left: 143px
  }
  .content-block .nav-block-section {
    width: 100%;
    margin-top: 17px;
    position: relative
  }
  .content-block .nav-block-section #menuDcha {
    width: 100px;
    margin: 0;
    padding: 0
  }
  .content-block .nav-block-section #menuDcha + #menuIzq {
    width: 130px;
    margin: 0;
    padding: 0
  }
  html:lang(en) .content-block .logo.home:after {
    left: 250px
  }
  .content-block .nav-block-section #menuIzq {
    width: 100%;;
    margin: 0;
    padding: 0
  }
  .compound-blocks-modules .title2.size50, .compound-blocks-modules .title2.size60, .compound-blocks-modules .title2.size70, .compound-blocks-modules .title2.size80 {
    font-size: 25px
  }
  .compound-blocks-modules.A1 .bigtitle {
    font-size: 45px;
    line-height: 1.2em
  }
  .compound-blocks-modules.A1 .content-block.picture-block {
    height: auto
  }
  .compound-blocks-modules .picture-block .picture {
    width: 100%;
    height: auto
  }
  .compound-blocks-modules .picture-block .picture img {
    position: relative
  }
  .compound-blocks-modules .picture-block .picture+.picture-caption {
    max-height: initial;
    padding-left: 20px;
    padding-bottom: 40px;
    margin-left: 0;
    width: 100%;
    padding-top: 40px
  }
  .compound-blocks-modules .full-col .content-block, .compound-blocks-modules .full-col .content-block.height2 {
    height: auto;
    min-height: 500px;
  }

  .compound-blocks-modules .main-gallery .content-block {
    min-height: 500px
  }
  .compound-blocks-modules .bigtitle.t4 {
    font-size: 15vw
  }
  .compound-blocks-modules .bigtitle.t4 small {
    font-size: .6em
  }
  .compound-blocks-modules .block.infographic .cover-infographic {
    height: 500px
  }
  .title-block-fixed .title {
    float: none
  }
  .title-block-fixed .nav-cover {
    margin-left: -20px;
    margin-right: -20px
  }
  .title-block-fixed .nav-cover.diary-nav {
    margin: 0
  }
  .title-block-fixed .nav-cover>.section-selector .selector-name {
    min-width: initial
  }
  .title-block-fixed .nav-cover>.section-selector .selector-name:after {
    position: relative;
    right: 0
  }
  .title-block-fixed .nav-cover>.section-selector+.section-selector .section-selector {
    max-width: initial
  }
  .title-block-fixed .nav-cover>.section-selector.double {
    margin-left: 20px;
    margin-top: 0
  }
  .title-block-fixed .nav-cover>.section-selector.double .section-selector {
    width: -webkit-calc(200% - 40px);
    width: -moz-calc(200% - 40px);
    width: calc(200% - 40px)
  }
  .title-block-fixed .section-selector {
    float: left;
    width: 50%;
    text-align: center
  }
  .title-block-fixed .section-selector .selector-name {
    font-size: 12px;
    padding: 10px
  }
  .title-block-fixed .section-selector .selector-name:after {
    right: auto;
    font-size: 10px;
    margin-left: 5px
  }
  .title-block-fixed .section-selector .section-selector {
    width: 200%
  }
  .title-block-fixed .section-selector:hover .section-selector {
    padding-bottom: 0;
    max-height: 0
  }
  .title-block-fixed .section-selector.active {
    z-index: 1
  }
  .title-block-fixed .section-selector.active .section-selector {
    padding-bottom: 10px;
    max-height: 1000px
  }
  .title-block-fixed .section-selector.type2 .section-selector {
    left: 0;
    right: 0
  }
  .title-block-fixed .section-selector+.section-selector .section-selector {
    left: auto;
    right: 0
  }
  .calendar-block.static-calendar .generic-timeline-indicators {
    display: none
  }
  .calendar-block.static-calendar .generic-timeline .cover-timeline-blocks .item {
    width: 50%
  }
  .block-panels .animation-frame {
    float: none;
    display: block;
    margin: 0 auto 20px
  }
  .profile-blocks .cover-img {
    width: 100%
  }
  .profile-blocks .cover-text {
    width: 100% !important
  }
  .nav-block-section .logo {
    display: block;
    margin: 0 auto 20px
  }
  .nav-block-section .col {
    margin: 0;
    padding: 0 10px;
    width: 50%;
    text-align: center
  }
  .nav-block-section .col li {
    text-align: left
  }
  .nav-block-section .col:last-of-type {
    width: 50%;
    margin: 0;
    padding-left: 40px
  }
  .nav-block-section:after {
    left: 43%;
    height: 98px;
    margin-top: -29px
  }
}
.c {
  &_gray {
    color: #b2b2b2 !important;
  }
  &_black {
    color: $black !important;
  }
  &_white {
    color: $white !important;
  }
  &_blue {
    color: #009ed6 !important;
  }
  &_red {
    color: #da3851 !important;
  }
}

.back {
  &-blue {
    background: #009ed6 !important;
    .bigtitle {
      small {
        color: $black !important;
      }
    }
  }
  &-blued {
    background: #002664 !important;
  }
  &-red {
    background: #da3851 !important;
  }
  &-red2 {
    background: #e52f04 !important;
    color: #fff
  }
  &-black {
    background: $black !important;
    padding: 20px 0;
  }
  &-black2 {
    background: #333 !important;
  }
  &-black3 {
    background: #111 !important;
  }
  &-black, &-black2, &-black3 {
    color: $white !important;
  }
  &-green {
    background: #c4d600 !important;
  }
  &-greenl {
    background: #00d1c7 !important;
  }
  &-gray {
    background: #e0e0e0;
    color: #000;
  }
  &-grayd {
    background: #333 !important;
    color: #fff !important;
  }
  &-grayl,&-grayl2,&-grayl3,&-white {
    color: #000 !important;
  }
  &-grayl {
    background: #f5f5f5;
  }
  &-grayl2 {
    background: #f7f7f7;
  }
  &-grayl3 {
    background: #f1f1f1 !important;
  }
  &-white {
    background: #fff !important;
  }
  &-orange {
    background: #f7893b !important;
    color: #fff !important;
  }
  &-yellow {
    background: #efbd2d !important;
    color: #fff !important;
  }
  &-item {
    color:$white;
  }
}
.generic--color--greenl,
.generic--color--orange,
.generic--color--red,
.generic--color--yellow {
  .main-header {
    .blue-bar {
      &.show-bar {
        margin-top: -12px;
        padding: 8px 0;
      }
    }
  }
}
.generic--color--greenl {
  .main-header {
    .blue-bar {
      background: #00d1c7;
      color: $white;
      font-size: 20px;
      width: 100%;
    }
    .lead-text {
      span {
        color: #00d1c7;
      }
    }
  }
  .result-list {
    .title {
      span, &:hover {
        color: #00d1c7;
      }
    }
  }
  .search-results {
    .results-counter {
      &.type2 {
        .header {
          background: #00d1c7;
        }
      }
    }
  }
  .btn {
    &.btn-color {
      background: #00d1c7;
      border-color: #00d1c7;
    }
  }
  .cicle-generic {
    .nav-tabs {
      li {
        &:active, &:hover {
          background: #00d1c7;
        }
      }
    }
  }
  .calendar-block {
    .generic-timeline {
      .info {
        background: #00d1c7 !important;
      }
    }
  }
  .diary-slider {
    .content-slider {
      .content {
        background: #00d1c7 !important;
      }
    }
  }
}
.generic--color--yellow {
  .main-header {
    .blue-bar {
      background: #efbd2d;
      color: $white;
      font-size: 20px;
      width: 100%;
    }
    .lead-text {
      span {
        color: #efbd2d;
      }
    }
  }
  .result-list {
    .title {
      span, &:hover {
        color: #efbd2d;
      }
    }
  }
  .search-results {
    .results-counter {
      &.type2 {
        .header {
          background: #efbd2d;
        }
      }
    }
  }
  .btn {
    &.btn-color {
      background: #efbd2d;
      border-color: #efbd2d
    }
  }
  .cicle-generic {
    .nav-tabs {
      li {
        &.active, &:hover {
          background: #efbd2d;
        }
      }
    }
  }
  .calendar-block {
    .generic-timeline {
      .info {
        background: #efbd2d !important;
      }
    }
  }
  .diary-slider {
    .content-slider {
      .content {
        background: #efbd2d !important;
      }
    }
  }
}
.generic--color--orange {
  .main-header {
    .blue-bar {
      background: #f7893b;
      color: $white;
      font-size: 20px;
      width: 100%;
    }
    .lead-text {
      span {
        color: #f7893b;
      }
    }
  }
  .result-list {
    .title {
      span, &:hover {
        color: #f7893b;
      }
    }
  }
  .search-results {
    .results-counter {
      &.type2 {
        .header {
          background: #f7893b;
        }
      }
    }
  }
  .btn {
    &.btn-color {
      background: #f7893b;
      border-color: #f7893b;
    }
  }
  .cicle-generic {
    .nav-tabs {
      li {
        &.active, &:hover {
          background: #f7893b;
        }
      }
    }
  }
  .calendar-block {
    .generic-timeline {
      .info {
        background: #f7893b !important;
      }
    }
  }
  .diary-slider {
    .content-slider {
      .content {
        background: #f7893b !important;
      }
    }
  }
}
.generic--color--red {
  .btn {
    &.btn-color {
      background: #da3851;
    }
  }
  .cicle-generic {
    .nav-tabs {
      li {
        &.active, &:hover {
          background: #da3851;
        }
      }
    }
  }
  .search-results {
    .results-counter {
      &.type2 {
        .header {
          background: #AF9A69;
        }
      }
    }
  }
  .main-header {
    .blue-bar {
      background: #da3851;
      color: $white;
      font-size: 20px;
      width: 100%;
    }
    .lead-text {
      span {
        color: #da3851;
      }
    }
  }
  .result-list {
    .title {
      span,&:hover {
        color: #da3851;
      }
    }
  }
  .calendar-block {
    .generic-timeline {
      .info {
        background: #af9a69!important;
      }
    }
  }
  .diary-slider {
    .content-slider {
      .content {
        background: #da3851 !important;
      }
    }
  }
}

.hover-blue:hover {
  color: #009ed6 !important; }
//Cabecera de apertura cuando el blog es individual
.blog-individual {
  background-color: $black;
  margin-top:36px;
  @include tablet {
    background-color: $white;
    margin-top:0px;
  }

}

.blog-header--solo {
  .blog-header__data {
    display: flex;
    flex-wrap: wrap;
    padding:5%;
    @include tablet{
      font-size: 45px;
      padding:0%;
    }

    .author__info {
      display: inline-block;
      width: calc(100% - 260px);
      margin-top: -12px;

      .blog-header__title {
        font-size:55px;
        @include tablet-big {
          @include absolute($t: 128px, $l: 0);
          width: 100%;
        }

        @include desktop-small {
          font-size: 45px;
        }

        @include tablet {
          font-size: 30px;
        }

        @include mobile {
          font-size: 25px;
        }
      }

      @include tablet-big {
        width: 100%;
      }
    }

    .blog-header__text {
      font-size: 15px;
      margin-bottom: 20px;

      @include tablet-big {
        text-align: center;
        border-bottom: 0;
      }

      @include tablet {
        margin-bottom: 20px;
      }

      @include mobile {
        border-bottom: 1px solid $grey-light;
      }
    }
    .blog-header__puesto {
      font-size: 14px;
    }

    .author__picture {
      @include size(220px, 220px);
      margin: 0 40px 0 0;

      @include tablet-big {
        @include size(94px, 94px);
        margin: 0 0 16px 0;
      }

      img {
        @include size(100%, 100%);
        @include border-radius(50%);
      }
    }

    @include tablet-big {
      position: unset;
      flex-direction: column;
      align-items: center;
      padding: 32px;
    }

    @include tablet {
      padding: 32px;
      margin-top: 25px;
    }

    @include tablet-portrait {
      padding: 32px 15px;
    }

    @include mobile {
      padding: 15px 15px 0px;
    }
  }

  .blog-header__info {
    padding: 3%;
    @include tablet-big {
      padding: 0;
    }
    .row {
      @include tablet-big {
        margin: 0;
        width: 100%;
      }

      @include tablet-portrait {
        padding: 0;
      }
    }

    @include tablet-big {
      padding: 32px;
    }

    @include tablet {
      padding: 32px;
      margin-top: 25px;
    }

    @include tablet-portrait {
      padding: 32px 15px 32px 0;
    }

    @include mobile {
      font-size: 15px;
      padding: 0 20px;
    }
  }

  .blog-header__author {
    margin-top: 0;
    margin-bottom: 8px;
    font-family: Oswald;
    font-size: 19px;
    font-weight: 500;

    @include tablet-big {
      text-align: center;
    }
  }

  .blog-header__text {
    font-family: $font_bentonsansRegular;
    font-size: 15px;

    @include tablet-big {
      width: 100%;
      margin: 0;
    }

    @include tablet {
      margin-bottom: 20px;
    }

    margin-bottom: 20px;

    @include tablet-portrait {
      padding: 0 0 24px;
    }

    @include mobile {
      font-size: 15px;
      padding: 0 0 24px 0;
    }
  }

  .blog-header__social {
    @include tablet-big {
      padding: 0;
      align-items: start;
    }

    .social-list {
      flex-wrap: wrap;
      width: initial;

      & .icon {
        &:before {
          @include desktop-small {
            font-size: 20px;
          }
        }
      }

      @include tablet {
        justify-content: flex-end;
      }
    }
  }

  .blog-header__tags {
    @include tablet-big {
      margin-bottom: 20px;
      align-items: start;
    }
  }

  @include tablet-big {
    padding-top: 192px;
  }
}

//Listado de blogs. Por defecto el item es el de colectivos

.blog-list {
  .blog-list__title {
    font-family: $font_bentonsansBold;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 24px 0;
    @include tablet-big {
      width: 100%;
      max-width: 636px;
      margin: 24px auto;
    }
  }
  .blog-list__container {
    background: $white;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey-light-blog;
    .blog-item {
      @include size(48%,auto);
      max-width: 588px;
      padding-bottom: 0px;
      margin-bottom: 40px;
      background:#F8F8F8;
      .main-image {
        @include size(100%,170px);
        padding: 0;
        margin: 0;
        background-size: cover;
        @include tablet-big {
          height: 68px;
        }
      }
      .blog-item__title {
        margin: 0;
        padding: 18px 15px;
        background: $black;
        color: $white;
        font-family: Oswald;
        font-size: 24px;
        font-weight: $bold;
        text-transform: uppercase;
        text-align: center;
      }
      .blog__info {
        padding: 24px 12px;
        border-bottom: 1px solid $grey-light-blog;
        margin-bottom: 0;
        @include tablet-big {
          padding: 24px 51px;
        }
        @include tablet-portrait {
          padding: 24px 12px;
        }
      }
      .last-post {
        padding: 24px 12px;
        font-family: bentonsans, Helvetica, Arial, Sans-serif;
        .post {
          &__date {
            display: inline-block;
            font-size: 16px;
            color: $grey-mid-blog;
            padding-bottom: 6px;
          }
          &__title {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.33;
            margin: 0;
          }
          &__author {
            a {
              color:$blue;
            }
          }
        }
        @include tablet-big {
          padding: 24px 51px;
        }
        @include tablet-portrait {
          padding: 24px 12px;
        }
      }
      @include tablet-big {
        width: 100%;
        max-width: 636px;
        height: auto;
      }
    }
    @include tablet-big {
      flex-direction: column;
      align-items: center;
    }
  }
}


@import "blog-list-item-solo";
@import "blog-list-mixed";

  
  /** Custom Select **/
  .custom-select-wrapper {
    position: relative;
    display: inline-block;
    user-select: none;
  }
    .custom-select-wrapper select {
      display: none;
    }
    .custom-select {
      position: relative;
      display: inline-block;
      min-width:280px;
      max-width: 100%;
      text-align: left;
      font-family:$font_bentonsansRegular;
      z-index:1;
    }
      .custom-select-trigger {
        position: relative;
        display: block;
        width: 100%;
        padding: 0 84px 0 22px;
        font-size: 22px;
        font-weight: 300;
        color: $grey-dark;
        line-height: 60px;
        background: $white;
        border-radius: 0px;
        cursor: pointer;
        border:1px solid $grey-dark;
        font-family:$font_bentonsansRegular;
        font-size: 20px;
        @include tablet{
          font-size:18px;
        }

      }
        .custom-select-trigger:after {
          position: absolute;
          display: block;
          content: '';
          width: 10px; height: 10px;
          top: 50%; right: 25px;
          margin-top: -3px;
          border-bottom: 1px solid $grey-dark;
          border-right: 1px solid $grey-dark;
          transform: rotate(45deg) translateY(-50%);
          transition: all .4s ease-in-out;
          transform-origin: 50% 0;
        }
        .custom-select.opened .custom-select-trigger:after {
          margin-top: 3px;
          transform: rotate(-135deg) translateY(-50%);
        }
    .custom-options {
      position: absolute;
      display: block;
      top: 100%; left: 0; right: 0;
      min-width: 100%;
      margin: -1px 0 0 0;
      border-left: 1px solid $grey-dark;
      border-bottom: 1px solid $grey-dark;
      border-right: 1px solid $grey-dark;
      border-radius: 0px;
      box-sizing: border-box;
      box-shadow: 0 2px 1px rgba(0,0,0,.07);
      background: #fff;
      transition: all .4s ease-in-out;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translateY(-15px);
    }
    .custom-select.opened .custom-options {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: translateY(0);
    }
      .custom-options:before {
        position: absolute;
        display: none;
        content: '';
        bottom: 100%; right: 25px;
        width: 7px; height: 7px;
        margin-bottom: -4px;
        border-top: 1px solid $grey-dark;
        border-left: 1px solid $grey-dark;
        background: #fff;
        transform: rotate(45deg);
        transition: all .4s ease-in-out;
      }
      
      .option-hover:before {
        background: #f9f9f9;
      }
      .custom-option {
        position: relative;
        display: block;
        padding: 0 22px;
        border-bottom: 1px solid $grey-light-blog;
        font-size: 20px;
        color: $grey-dark;
        line-height: 55px;
        cursor: pointer;
        transition: all .4s ease-in-out;
        @include tablet{
          font-size:18px;
        }
      }
      .custom-option:first-of-type {
        border-radius:0px;
      }
      .custom-option:last-of-type {
        border-bottom: 0;
        border-radius: 0 0 4px 4px;
      }
      .custom-option:hover,
      .custom-option.selection {
        background: #f9f9f9;
      }
.main-content {
  padding-top: 72px;

  @include tablet {
    padding-top: 58px;
  }

  &.no-padding {
    padding: 0;
  }
}


/*------------------- LEGACY ------------------*/

// $$ Containers & Wrappers

.main-wrapper {
  display: block;
  width: 100%;
  min-height: 100%;
  margin-top: 0;
  overflow-x:hidden;
}

.container {
  padding: 0 20px;
  display: block;
  max-width: 1240px;
  margin: 0 auto
}

.container:after, .container:before {
}

.wrapper:before {
}

.wrapper.show-menu {
  left: -500px;
  -webkit-transition: left .3s linear;
  -moz-transition: left .3s linear;
  -ms-transition: left .3s linear;
  -o-transition: left .3s linear;
  transition: left .3s linear
}

.wrapper.show-menu:before {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.wrapper.show-menu:before, .wrapper.show-menu-top:before {
  opacity: 1;
  z-index: 99;
  transition: all .3s ease
}

.wrapper.show-menu .main-header, .wrapper.show-menu .title-block-fixed {
  left: -500px;
  -webkit-transition: left .3s linear;
  -moz-transition: left .3s linear;
  -ms-transition: left .3s linear;
  -o-transition: left .3s linear;
  transition: left .3s linear
}

.wrapper.show-menu .diary-generic thead.fixed {
  left: -500px
}

.wrapper.header-type2 {
  padding-top: 0
}

.wrapper.header-type2.padd {
  padding-top: 72px
}

.wrapper.header-type2 .main-header {
  top: -150px;
  -webkit-transition: all .3s linear;
  -moz-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear
}

.wrapper.header-type2 .main-header.block, .wrapper.header-type2 .main-header.show {
  top: 0;
}

.wrapper.has--bar .title-block-fixed {
  top: 72px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}
.wrapper.has--bar .title-block-fixed.show-bar {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}
.wrapper.has--bar .title-block-fixed.show-bar {
  top: 138px;
  transition: all .3s ease
}
.wrapper.has--bar .main-header>.container {

  @include tablet {
    margin-bottom: 0;
  }
  @include mobile {
    margin-bottom: 10px;
  }

}

// $$ Buttons

.btn, .btn:before {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.btn, .btn:before {
  transition: all .3s ease
}

.btn, .btn .icon {
  display: inline-block;
  vertical-align: middle
}

.btn-dropdown .btn:after, .btn.load:after {
  font-family: "fontello", sans-serif;
}

.btn, .btn>span {
  position: relative
}

.btn {
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-size: 14px;
  line-height: 1.2em;
  padding: 7px 15px;
  outline: 0;
  cursor: pointer;
  text-align: center;

  &.btn-white {
    background: white;
    color: #2b2b2b;
    border: solid 1px #ccc;
  }
}

.btn .icon {
  font-size: 24px;
  line-height: 1em;
  margin-left: 10px
}

.btn>span {
  z-index: 2;
  display: block
}

.btn:before {
  content: "";
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background: #fff;
  -webkit-transform: translate(-50%, 0) skew(30deg);
  -moz-transform: translate(-50%, 0) skew(30deg);
  -ms-transform: translate(-50%, 0) skew(30deg);
  -o-transform: translate(-50%, 0) skew(30deg);
  transform: translate(-50%, 0) skew(30deg)
}

.btn:hover, .btn:hover:before {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.btn:hover {
  color: #fff;
  background: rgba(0, 0, 0, .7);
  transition: all .3s ease
}

.btn:hover:before {
  transition: all .3s ease;
  width: 150%
}

.btn.btn-type5 .icon, .btn.btn-type5:hover .icon {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.btn.btn-big {
  padding: 17px 15px;
}

.btn.btn-medium {
  padding: 15px 10px;
  font-size: 13px;
  line-height: 1.2em
}

.btn.btn-rounded {
  border-radius: 100px;
  overflow: hidden
}

.btn.btn-type2 {
  border: 1px solid rgba(255, 255, 255, .3)
}

.btn.btn-type2:hover {
  color: #fff;
  background: rgba(80, 80, 80, .7)
}

.btn.btn-type3 {
  border: 1px solid #dedede;
  background: #fff;
  color: #000
}

.btn.btn-type3:before {
  background: #000
}

.btn.btn-type3:hover {
  color: #000;
  background: #dedede
}

.btn.btn-type4 {
  border-color: #009ed6;
  color: #fff;
  background: #009ed6
}

.btn.btn-type4:before {
  background: #fff
}

.btn.btn-type4:hover {
  color: #fff;
  background: rgba(0, 158, 214, .7)
}

.btn.btn-type5 {
  border-color: #dedede;
  background: #dedede;
  color: #000
}

.btn.btn-type5 .icon {
  color: #fff;
  transition: all .3s ease
}

.btn.btn-type5:hover {
  background: rgba(255, 255, 255, .7)
}

.btn.btn-type5:hover .icon {
  color: #000;
  transition: all .3s ease
}

.btn.btn-type6 .icon, .btn.btn-type6:hover .icon {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.btn.btn-type6 {
  border-color: #000;
  background: #fff;
  color: #000
}

.btn.btn-type6 .icon {
  color: #fff;
  transition: all .3s ease
}

.btn.btn-type6:hover {
  background: #dedede
}

.btn.btn-type6:hover .icon {
  color: #000;
  transition: all .3s ease
}

.btn-dropdown .btn-dropdwon-items, .btn-dropdown .btn-dropdwon-items li {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  width: 100%
}

.btn.btn-color {
  background: #009ed6;
  color: #fff;
  border-color: #009ed6;
  opacity: 1
}

.btn.btn-color:hover {
  opacity: .7
}

.btn.btn-color-now {
  background: #f21400;
  color: #fff;
  border-color: #f21400;
  opacity: 1
}

.btn.btn-color-now:hover {
  opacity: .7
}

.btn.load {
  background: #fff;
  color: transparent
}

.btn.load:after {
  content: '\e834';
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  font-size: 30px;
  margin-top: -8px;
  margin-left: -15px;
  color: #000;
  animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -ms-animation: spin 2s infinite linear;
}

.btn.right {
  margin-top: -15px;
  margin-left: 20px
}

.btn.btn-icon-left .icon, .btn.btn-icon-right .icon {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  display: block;
  font-size: 1.5em;
  margin: 0;
  top: 50%
}

.btn.btn-icon-right {
  padding-right: 3em;
  position: relative
}

.btn.btn-icon-right .icon, .btn.btn-icon-right .fab {
  position: absolute;
  left: auto;
  right: .4em;
  transform: translate(0, -50%);
  top: 50%;
  font-size: 20px;
}

.btn.btn-icon-left {
  padding-left: 3em;
  position: relative
}

.btn.btn-icon-left .icon {
  position: absolute;
  left: .4em;
  transform: translate(0, -50%)
}

.btn.btn-icon {
  padding-right: 45px;
  padding-left: 20px
}

.btn.btn-icon .icon {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -.5em
}

.btn.btn-icon>span {
  position: static
}

.btn.disabled {
  background: #dedede
}

.btn.margin {
  margin-right: .1em;
  margin-bottom: .3em
}

.btn-dropdown {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 10
}

.btn-dropdown .btn {
  background: #009ed6;
  border-color: #009ed6;
  position: relative;
  padding-right: 3em
}

.btn-dropdown .btn:after {
  content: '\e81e';
  font-weight: 400;
  position: absolute;
  right: 1.4em;
  font-size: 1em;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%)
}

.btn-dropdown .btn-dropdwon-items {
  display: block;
  margin: 0;
  padding: 0;
  transition: all .3s ease;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 16px;
  line-height: 1.2em
}

.btn-dropdown .btn-dropdwon-items li {
  display: block;
  padding: 6px 20px;
  background: #009ed6;
  text-align: center;
  color: #fff;
  font-weight: 300;
  cursor: pointer;
  border: 1px solid #009ed6;
  transition: all .3s ease
}

.btn-dropdown .btn-dropdwon-items li.active, .btn-dropdown .btn-dropdwon-items li:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  background: #fff;
  color: #009ed6
}

.btn-dropdown:hover .btn-dropdwon-items {
  max-height: 1000px
}

.btn-dropdown.mobile-action:hover .btn-dropdwon-items {
  padding-bottom: 0;
  max-height: 0
}

.btn-dropdown.mobile-action.show-dropdown .btn-dropdwon-items {
  padding-bottom: 10px;
  max-height: 500px
}

.btn-dropdown.type2 .btn {
  background: #0059a3;
  border-color: #0059a3
}

.btn-dropdown.type2 .btn-dropdwon-items {
  padding-bottom: 0;
  background: #0059a3
}

.btn-dropdown.type2 .btn-dropdwon-items li {
  background: #0059a3;
  border-color: #0059a3
}

.btn-dropdown.type2 .btn-dropdwon-items li:hover {
  background: #0059a3;
  color: #000
}

.btn-dropdown.type2:hover .btn-dropdwon-items {
  padding-bottom: 10px
}

.btn-dropdown.type3 .btn {
  background: #FFFFFF;
  border-color: #0059a3;
  color: #0059a3;
}

.btn-dropdown.type3 .btn-dropdwon-items {
  padding-bottom: 0;
  background: #FFFFFF;
}

.btn-dropdown.type3 .btn-dropdwon-items li {
  background: #FFFFFF;
  border-color: #0059a3;
  color: #0059a3;
  padding: 0;
  a {
    display: block;
    padding: 17px 20px;
  }
}


.btn-dropdown.type3 .btn-dropdwon-items li:hover {
  background: #0059a3;
  color: #FFFFFF;
}

.btn-dropdown.type2 + .btn-dropdown.type3{
  margin-left: 40px;
}

.wrapper {
  -webkit-transition: left .3s linear;
  -moz-transition: left .3s linear;
  -ms-transition: left .3s linear;
  -o-transition: left .3s linear;
  transition: left .3s linear;
  position: relative;
  left: 0;
  width: 100%;
}

//$$ Tabs

.nav-tabs ul {
  margin: 0;
  padding: 0;
  font-size: 0
}

.nav-tabs li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 20px;
  font-size: 15px;
  line-height: 1.2em;
  border: 1px solid #009ed6;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.nav-tabs li.active, .nav-tabs li:hover, .search-results .interested-block.slider .slide {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.nav-tabs li.active, .nav-tabs li:hover {
  background: #009ed6;
  color: #fff;
  transition: all .3s ease
}

.nav-tabs.especial-type {
  text-align: right;
  padding: 0;
  height: 0;
  letter-spacing: 1px
}

.nav-tabs.especial-type li {
  position: relative;
  z-index: 1;
  background: #000;
  border: 0;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 1em;
  padding: 20px 30px;
  -webkit-transform: translate(0, -54px);
  -moz-transform: translate(0, -54px);
  -ms-transform: translate(0, -54px);
  -o-transform: translate(0, -54px);
  transform: translate(0, -54px)
}

.nav-tabs.especial-type li.active, .nav-tabs.especial-type li:hover {
  background: #fff;
  color: #000
}

.nav-tabs.private-area {
  text-align: left;
  padding-top: 40px;
  padding-bottom: 0;
  border-bottom: 1px solid #dedede
}

.nav-tabs.private-area li {
  border: 0
}

.nav-tabs.private-area li.active, .nav-tabs.private-area li:hover {
  background: #000
}


.block-tabs {
  display: block;
  width: 100%
}

.block-tabs .tab {
  display: none
}

.block-tabs .tab.active {
  display: block
}



.tabs {
  display: block;
  border-top: 1px solid #dedede
}

.tabs .tab {
  display: block;
  border-bottom: 1px solid #dedede
}

.tabs .tab-selector {
  display: block;
  margin: 0;
  padding: 40px 120px 40px 0;
  font-size: 18px;
  line-height: 1.3em;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  letter-spacing: 1px
}

.tabs .tab-selector:before {
  content: '\e81e';
  font-family: fontello, sans-serif;
  font-size: 18px;
  display: block;
  width: 100px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -9px;
  text-align: center;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.tabs .tab-selector.active:before, .tabs .tab-selector:after, .tabs .tab-selector:hover:before {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.tabs .tab-selector:after {
  content: '';
  width: 100px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  background: #f3f3f3;
  z-index: -1;
  transition: all .3s ease
}

.tabs .tab-selector.active:before, .tabs .tab-selector:hover:before {
  color: #fff;
  transition: all .3s ease
}

.tabs .tab-selector.active:after, .tabs .tab-selector:hover:after {
  background: #009ed6;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.tabs .tab-selector.active:before {
  content: '\e81f'
}

.tabs .tab-content {
  display: none;
  padding: 30px;
}

.tabs .tab-content.pt {
  padding-top: 25px
}

.tabs+.tabs {
  border: 0
}

//$$ Tooltip

.tooltip-inner {
  text-decoration: none
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #545454;
  border: 1px solid #fff;
  border-radius: 4px
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid
}

.tooltip.top .tooltip-arrow, .tooltip.top-left .tooltip-arrow, .tooltip.top-right .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #fff;
  bottom: 0
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 12px;
  line-height: 1.4;
  visibility: visible;
  opacity: 0
}

.tooltip.in {
  opacity: .9
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px
}

.tooltip.top .tooltip-arrow {
  left: 50%;
  margin-left: -5px
}

.tooltip.top-left .tooltip-arrow {
  left: 5px
}

.tooltip.top-right .tooltip-arrow {
  right: 5px
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #fff
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #fff
}

.tooltip.bottom .tooltip-arrow, .tooltip.bottom-left .tooltip-arrow, .tooltip.bottom-right .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
  top: 0
}

.tooltip.bottom .tooltip-arrow {
  left: 50%;
  margin-left: -5px
}

.tooltip.bottom-left .tooltip-arrow {
  left: 5px
}

.tooltip.bottom-right .tooltip-arrow {
  right: 5px
}

//$$ Gallery

.cover-slider {
  position: relative;
  display: block;

  .embed-container {
    background-color: #000;
    iframe {
      background-color: #000;
      margin: 0 auto;
      width: 100%;
      height: 56vw;
      display: block;

      .container {
        background-color: #000 !important;
        max-width: 100%;
        width: 100%;
        height: 56vw;
      }
    }
  }
}

.main-gallery {
  display: block;
  overflow: hidden;
  position: relative
}

.main-gallery h1, .main-gallery h2, .main-gallery h3, .main-gallery h4, .main-gallery h5, .main-gallery h6 {
  margin: 0 0 6px
}

.main-gallery.back-on-controls+.ctrls-gallery {
  background: #000;
  padding: 15px 25px;
  top: 0
}

.main-gallery.slick-dotted {
  margin-bottom: 0
}

.main-gallery+.ctrls-gallery {
  display: block;
  position: absolute;
  top: 25px;
  right: 50%;
  margin-right: -600px;
  z-index: 5;
  color: #fff
}

.main-gallery+.ctrls-gallery .slick-dots li.slick-active button, .main-gallery+.ctrls-gallery .slick-dots li:hover button {
  background: #fff;

  @media screen and (max-width: 780px) {
    background: #000;
  }

}

.main-gallery+.ctrls-gallery .slick-next:before, .main-gallery+.ctrls-gallery .slick-prev:before {
  color: #fff;
  opacity: 1;

  @media screen and (max-width: 780px) {
    color: #000;
  }
}

.main-gallery+.ctrls-gallery .slick-next:hover:before, .main-gallery+.ctrls-gallery .slick-prev:hover:before {
  opacity: .5
}

.main-gallery .slide {
  position: relative
}

.main-gallery .slide:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 0
}

.main-gallery .slide+.slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1
}

#coverHome .main-gallery .slide.slick-slide:not(.archive) {
  position: relative;
  top: auto;
  left: auto;
  z-index: auto;
  overflow: hidden;

  @media screen and (max-width: 480px) {
    min-height: 350px;
  }
}

.main-gallery .slide.c_black .date-info:after, .main-gallery .slide.c_black .date-info:before, .main-gallery .slide.c_black .middle-line:before {
  background: #000
}

.main-gallery .container {
  position: relative;
  z-index: 1;
  height: 700px;
  padding-top: 45px;
  color: #fff
}

.main-gallery .back-img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  max-width: initial;
  top: 50%;
  left: 50%;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.main-gallery .tag {
  font-size: 21px;
  line-height: 1.2em;
  letter-spacing: 1px;
  max-width: 100%
}

.main-gallery .tag b, .main-gallery .tag strong {
  font-weight: 600
}

.main-gallery .bottom-info {
  position: absolute;
  bottom: 40px;
  left: 20px;
  right: 20px;
  font-size: 0
}

.main-gallery .bottom-info .text-info {
  display: inline-block;
  vertical-align: middle;
  width: 65%;
  font-size: 15px;
  line-height: 1.6em;
  font-weight: 300
}

.main-gallery .date-info .middle-line:before, .main-gallery .date-info:after, .main-gallery .date-info:before,
.ayudas .date-info .middle-line:before, .ayudas .date-info:after, .ayudas .date-info:before {
  content: "";
  width: 2px;
  height: 230px;
  background: #fff;
  opacity: .4;
  vertical-align: middle;

  @media screen and (max-width: 480px) {
    background-color: #000;
  }
}


.main-gallery .date-info, .ayudas .date-info{
  display: block;
  text-align: right;
  position: relative;
  margin-bottom: -12px;
  padding-right: 6px
}

.main-gallery .date-info:after, .main-gallery .date-info:before,.ayudas .date-info:after, .ayudas .date-info:before {
  display: inline-block;
  -webkit-transform: rotate(13deg);
  -moz-transform: rotate(13deg);
  -ms-transform: rotate(13deg);
  -o-transform: rotate(13deg);
  transform: rotate(13deg);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

.main-gallery .date-info .date,.ayudas .date-info .date {
  font-size: 90px !important;
  line-height: .9em;
  font-family: Oswald;
  font-weight: 500;
  margin: 0
}

.main-gallery .date-info .date small,.ayudas .date-info .date small  {
  font-size: 18px!important;
  line-height: 22px;
  display: block;
  font-family: bentonsans;
  font-weight: 200
}

.main-gallery .date-info .hour, .main-gallery .date-info .month, .main-gallery .date-info .year, .main-gallery .title,
.ayudas .date-info .hour, .ayudas .date-info .month, .ayudas .date-info .year, .ayudas .title {
  font-family: Oswald
}

.main-gallery .date-info .month,.ayudas .date-info .month {
  font-size: 80px!important;
  line-height: .9em;
  font-weight: 600;
  margin: 0
}

.main-gallery .date-info .hour,.ayudas .date-info .hour {
  line-height: .9em;
  font-weight: 200;
  font-size: 73px!important;
  margin: 0
}

.main-gallery .date-info .year,.ayudas .date-info .year {
  font-size: 20px!important;
  line-height: 1.2em;
  font-weight: 300;
  padding-right: 5px;
  margin: 0
}

.main-gallery .date-info .inline-block, .ayudas .date-info .inline-block{
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  padding: 0 30px 0 15px;
  margin: 0
}

.main-gallery .date-info .inline-block~.inline-block, .ayudas .date-info .inline-block~.inline-block {
  margin-left: 5px
}

.main-gallery .date-info .middle-line,.ayudas .date-info .middle-line {
  margin: 0 0 0 10px;
  position: relative;
  top: 0
}

.main-gallery .date-info .middle-line:before, .ayudas .date-info .middle-line:before{
  display: inline-block;
  -webkit-transform: rotate(13deg);
  -moz-transform: rotate(13deg);
  -ms-transform: rotate(13deg);
  -o-transform: rotate(13deg);
  transform: rotate(13deg);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

.main-gallery .date-info.now .inline-block,.ayudas .date-info.now .inline-block {
  text-align: right;
  padding: 0 20px 0 15px
}

.main-gallery .date-info.now .date,.ayudas .date-info.now .date {
  font-size: 70px!important;
  line-height: .86em
}

.main-gallery .date-info.now .hour ,.ayudas .date-info.now .hour {
  font-size: 45px!important;
  line-height: 1.5em;
  font-weight: 200
}

.main-gallery .epigraph {
  font-size: 18px;
  line-height: 1em;
  margin-bottom: 13px
}

.main-gallery .status {
  font-size: 18px;
  line-height: 1.2em;
  letter-spacing: 1px
}

.main-gallery .title {
  font-family: bentonsans;
  font-weight: 500;
  font-size: 54px;
  line-height: 1.1em;
  margin: 0 0 13px;
  text-indent: -3px;
}

.main-gallery .title.size40 {
  font-size: 40px
}

.main-gallery .title.size50 {
  font-size: 50px
}

.main-gallery .title.size60 {
  font-size: 60px
}

.main-gallery .title.size70 {
  font-size: 70px;
  line-height: .95em
}

.main-gallery .title.size80 {
  font-size: 80px
}

.main-gallery .title.type2 {
  font-family: Prata;
  font-weight: 400;
  font-size: 48px;
  line-height: 1.1em;
  padding-left: 18px
}

.main-gallery .title.type2:before {
  content: '«';
  display: inline-block;
  vertical-align: middle;
  margin-left: -.4em;
  line-height: 1em
}

.main-gallery .title.type2:after {
  content: '»';
  display: inline-block;
  vertical-align: middle;
  line-height: 1em
}

.main-gallery .title.type2~p {
  padding-left: 18px
}

.main-gallery .title.type3 {
  font-family: Oswald;
  font-weight: 200;
  font-size: 65px;
  line-height: 1.2em;
  line-height: .9em
}

.main-gallery .title.type3 .block-tag {
  display: block;
  margin-bottom: 12px
}

.main-gallery .title.type3 .block-tag:last-of-type {
  margin-bottom: 0
}

.main-gallery .title.type3 b, .main-gallery .title.type3 strong {
  font-weight: 500
}

.main-gallery .ponent {
  font-size: 19px;
  line-height: 1.1em;
  font-weight: 500;
  margin-bottom: 13px
}

.main-gallery .btn {
  margin-top: 1em
}

.main-gallery .cover-info {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.main-gallery .cover-info.align-bottom {
  vertical-align: bottom
}

.main-gallery .cover-info.full-size {
  width: 100%
}

.main-gallery .cover-info.big-size {
  width: 85%
}

.main-gallery .cover-info.middle-size {
  width: 65%;
  padding-right: 90px;

  @media screen and (max-width: 480px) {
    padding-right: 0;
  }
}

.main-gallery .cover-info.middle-size-fill {
  width: 35%
}

.main-gallery .cover-info.short-size {
  width: 38%
}

.main-gallery .cover-info.short-size-fill {
  width: 62%
}

.main-gallery .cover-info+.cover-info {
  margin-left: -4px;
  padding-left: 4px
}

.main-gallery .optional {
  margin-bottom: 6px !important;
  font-size: 16px;
  line-height: 1.2em;
  letter-spacing: 2px
}

.main-gallery a {
  outline: 0
}

.main-gallery .icon-play {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.main-gallery .caption {
  position: relative;
  background: rgba(0, 0, 0, .6);
  padding: 8px 20px
}

.main-gallery .caption .title {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  line-height: 1.5em
}

.main-gallery .caption .description {
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  line-height: 1.5em
}

.ctrls-gallery {
  text-align: right;
  display: block
}

.ctrls-gallery .slick-dots {
  position: relative;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  bottom: auto;
  margin: 0 20px;
  font-size: 0;
  line-height: 0;

  @media screen and (max-width: 480px) {
    display: none;
  }
}

.ctrls-gallery .slick-dots li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  width: auto;
  height: auto
}

.ctrls-gallery .slick-dots li.slick-active button, .ctrls-gallery .slick-dots li:hover button {
  background: #000;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.ctrls-gallery .slick-dots li button, .ctrls-gallery .slick-next:before, .ctrls-gallery .slick-prev:before {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.ctrls-gallery .slick-dots li button {
  display: block;
  width: 8px;
  height: 8px;
  padding: 0;
  border: 1px solid #000;
  background: 0 0;
  border-radius: 50%;
  transition: all .3s ease
}

.ctrls-gallery .slick-dots li button:before {
  display: none
}

.ctrls-gallery .slick-next {
  right: auto
}

.ctrls-gallery .slick-next:before {
  content: '\e81c'
}

.ctrls-gallery .slick-prev {
  left: auto
}

.ctrls-gallery .slick-prev:before {
  content: '\e81d'
}

.ctrls-gallery .slick-next, .ctrls-gallery .slick-prev {
  position: relative;
  top: auto;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  width: auto;
  height: auto
}

.ctrls-gallery .slick-next:before, .ctrls-gallery .slick-prev:before {
  font-family: fontello;
  font-size: 35px;
  line-height: 1em;
  opacity: .3;
  color: #000;
  transition: all .3s ease
}

.ctrls-gallery .slick-next:hover:before, .ctrls-gallery .slick-prev:hover:before, .editions-slider .slide a {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.ctrls-gallery .slick-next:hover:before, .ctrls-gallery .slick-prev:hover:before {
  opacity: 1;
  transition: all .3s ease
}

.ctrls-gallery .slick-next.slick-disabled:before, .ctrls-gallery .slick-prev.slick-disabled:before {
  opacity: .3 !important
}

.ctrls-gallery .slick-next.slick-disabled:hover:before, .ctrls-gallery .slick-prev.slick-disabled:hover:before {
  opacity: .3
}

.ctrls-gallery.c_black .slick-next:before, .ctrls-gallery.c_black .slick-prev:before {
  color: #000 !important
}

.ctrls-gallery.c_black .slick-dots li.slick-active button, .ctrls-gallery.c_black .slick-dots li:hover button {
  background: #000 !important
}

.ctrls-gallery.c_black .slick-dots li button {
  border-color: #000 !important
}

.editions-slider+.ctrls-gallery .slick-dots li button, .main-gallery+.ctrls-gallery .slick-dots li button {
  border-color: #fff;

  @media screen and (max-width: 780px) {
    border-color: #000;
  }
}


//$$ General title

.general-title-fixed {
  background: #000;
  color: #fff;
  font-size: 17px;
  line-height: 1.2em;
  padding: 15px 0;
  position: fixed;
  z-index: 19;
  left: 0;
  width: 100%;
  -webkit-transition: top .3s ease-in-out;
  -moz-transition: top .3s ease-in-out;
  -ms-transition: top .3s ease-in-out;
  -o-transition: top .3s ease-in-out;
  transition: top .3s ease-in-out;
  display: none;
}

.general-title-fixed b, .general-title-fixed strong {
  font-weight: 600
}

.general-title-fixed.holdtitle {
  background: 0 0;
  left: 50%;
  margin-left: -620px;
  line-height: 1em;
  padding: 17px 0;
  width: 100%;
  max-width: 1240px
}

.general-title-fixed .icon-cancel {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  float: right;
  font-size: 14px;
  line-height: 1.2em;
  cursor: pointer
}

#islaRedLeonardo .general-title-fixed{
  @include mobile{
    padding-top: 37px;
  }
}

//$$ Sliders

.generic-slider {
  display: block;
  margin-left: -10px;
  margin-right: -10px;
  position: relative;
  &.slick-dotted {
    margin-bottom: 0
  }
  .slide {
    outline: 0;
    padding: 0 10px;
    font-size: 15px;
    line-height: 1.6em;
    font-weight: 300;
    position: relative;
    max-width: 250px;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease
  }
  .slide+.slide {
    position: absolute;
    left: 0;
    top: 0
  }
  .slide.slick-slide {
    position: relative;
    max-width: initial;
    opacity: 1;
    transition: opacity .3s ease
  }
  .cover-img {
    display: block;
    margin: 0 0 1em;
    img {
      width: 100%
    }
  }
  .name {
    font-weight: 400;
    font-size: 21px;
    margin-bottom: 6px
  }
  .area{
    font-weight: 500;
    margin-bottom: 5px;
  }
}


.editions-slider .slide,
.generic-slider .name {
  line-height: 1.2em;
  font-family: 'Oswald';
}

.dynamic-block .animated-block li,
.generic-slider .slide.slick-slide {
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  -ms-transition: opacity .3s ease;
  -o-transition: opacity .3s ease
}

.colaborators p,
.editions-slider.slick-dotted {
  margin-bottom: 0
}

.editions-slider {
  color: #fff;
  .slide {
    font-weight: 500;
    font-size: 80px;
    padding: 0 10px;
    outline: 0;
    text-align: center;
    p {
      margin: 0;
      &.description {
        font-size: 16pt;
        line-height: 20pt;
      }
    }
    span {
      display: block;
      font-size: .35em;
      line-height: 1em;
      font-family: bentonsansRegular, sans-serif;
      font-weight: 400;
    }
    a {
      outline: 0;
      color: #333;
      transition: all .3s ease;
      &:hover {
        color: #fff;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        text-decoration: none;
      }
    }
    +.slide {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1
    }
    &.slick-slide {
      position: relative;
      top: auto;
      left: auto;
      opacity: 1;
      z-index: auto
    }
    &.active a {
      color: #fff
    }
  }
}

.editions-slider+.ctrls-gallery {
  color: #fff;
  margin-top: 30px;
  .slick-dots li.slick-active button,
  .slick-dots li:hover button {
    background: #fff
  }
  .slick-next:before,
  .slick-prev:before {
    color: #fff
  }
}





//$$ Blocks

//Code block

.cover-code-block {
  display: none;
  width: 100%;
  overflow: hidden
}

.cover-code-block code {
  background: #fff;
  padding: 10px 20px;
  margin-top: 10px;
  color: #000;
  display: block;
  width: 100%
}

//Calendar blcok

.calendar-block {
  display: block;
  width: 100%;
  background: #000;
  position: relative;
  padding: 50px 0;
  color: #fff
}

.calendar-block:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/simbolofronteras.jpg) no-repeat;
  background-size: cover;
  opacity: .14
}

.calendar-block .container {
  position: relative;
  z-index: 1
}

.calendar-block .generic-timeline-indicators {
  display: block;
  margin-left: -10px;
  margin-right: -10px;
  overflow: hidden
}

.calendar-block .generic-timeline-indicators .cover-timeline-indicators {
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  position: relative;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out
}

.calendar-block .generic-timeline-indicators .cover-timeline-indicators:after, .calendar-block .generic-timeline-indicators .cover-timeline-indicators:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.calendar-block .generic-timeline-indicators .item {
  display: block;
  float: left;
  width: 80%;
  padding: 0 10px;
  font-size: 0
}

.calendar-block .generic-timeline-indicators .year {
  font-size: 13px;
  line-height: 1.2em;
  letter-spacing: 1px;
  display: inline-block;
  vertical-align: middle;
  width: 54px
}

.calendar-block .generic-timeline-indicators .bar {
  display: inline-block;
  vertical-align: middle;
  width: -webkit-calc(100% - 54px);
  width: -moz-calc(100% - 54px);
  width: calc(100% - 54px);
  height: 1px;
  background: #fff;
  overflow: hidden
}

.calendar-block .generic-timeline-indicators .bar span {
  display: block;
  width: 0;
  height: 1px;
  background: #009ed6;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -ms-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear
}

.calendar-block .generic-timeline {
  display: block;
  margin-left: -10px;
  margin-right: -10px;
  overflow: hidden
}

.calendar-block .generic-timeline .cover-timeline-blocks {
  display: block;
  overflow: hidden;
  width: 10000000px;
  position: relative;

  @media screen and (max-width: 780px){
    width: 100%;
  }
}

.calendar-block .generic-timeline .cover-timeline-blocks:after, .calendar-block .generic-timeline .cover-timeline-blocks:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.calendar-block .generic-timeline .item {
  display: block;
  float: left;
  padding: 10px;
  width: 244px;

  @media screen and (max-width: 780px) {
    &:nth-child(3n+1) {
      clear:both;
    }
  }
  @media screen and (max-width: 480px) {
    float: none;
    width: 100%;
  }
}

.calendar-block .generic-timeline .info {
  background: #009ed6;
  height: 175px;
  overflow: hidden;
  padding: 25px 20px;
  font-size: 14px;
  line-height: 1.2em;
  letter-spacing: 1px;
  position: relative
}

.calendar-block .generic-timeline .info time {
  font-size: 12px;
  line-height: 1.2em;
  letter-spacing: 1px;
  color: #000;
  font-weight: 300;
  display: block;
  margin-bottom: 10px
}

.calendar-block.static-calendar .generic-timeline-indicators .bar span {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none
}

.data-sheet-header .social li, .data-sheet-header .social li:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.calendar-block.static-calendar .generic-timeline-indicators .item {
  display: none
}

.calendar-block.static-calendar .generic-timeline-indicators .item:first-child {
  display: block
}

.calendar-block.static-calendar .generic-timeline-indicators .item:nth-child(2) {
  display: block;
  width: 20%
}

.calendar-block.static-calendar .generic-timeline .cover-timeline-blocks {
  width: 100%
}

.calendar-block.static-calendar .generic-timeline .cover-timeline-blocks .item {
  width: 20%
}

//Data sheet

.data-sheet-header .subtitle, .data-sheet-header .title {
  font-weight: 400;
  font-family: Oswald
}


.data-sheet-header {
  display: block;
  position: relative;
  height: 570px;
  overflow: hidden
}

.data-sheet-header.fix-height {
  height: auto
}

.data-sheet-header:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .08;
  background-size: cover
}

.data-sheet-header.artist,
.data-sheet-header.jury,
.data-sheet-header.beneficiary {
  background: #f5f5f5;
  height: 100%;
}

.data-sheet-header .container {
  position: relative;
  z-index: 1
}

.data-sheet-header .cover-img {
  display: block;
  margin: 0;
  padding: 0;
  width: 38%;
  float: left;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 768px){
    width: 50%;
  }
  @media screen and (max-width: 600px){
    width: 100%;
  }

}
.data-sheet-header .cover-img+.cover-info {
  width: 62%;

  @media screen and (max-width: 768px){
    width: 50%;
  }
  @media screen and (max-width: 600px){
    width: 100%;
  }

}

.data-sheet-header .cover-img.full {
  width: 100%;
  max-height: 600px
}

.data-sheet-header .cover-img img {
  width: 100%;
}

.data-sheet-header {
  .cover-video {
    display: block;
    margin: 0;
    padding: 0;
    width: 66%;
    float: left;
    position: relative;
    overflow: hidden;

    & + .cover-info {
      width: 33%;
    }
  }

}
.data-sheet-header .cover-info {
  float: left;
  width: 100%;
  padding: 50px 20px;
  position: relative;
  color: #000
}

.data-sheet-header .cover-info .data-info {
  display: block;
  padding-right: 145px
}

.data-sheet-header .cover-info .data-description {
  margin-top: 80px;
  font-size: 18px;
  line-height: 1.6em;

  &.equipo {
    margin-top: 10px;
    width: 70%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  a {
    color: #009ed6;
    &:hover {
      text-decoration: underline;
    }
  }
}

.data-sheet-header .cover-info.full {
  width: 100%
}

.data-sheet-header .social {
  position: absolute;
  right: 0;
  top: 50px
}

.data-sheet-header .social ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block
}

.data-sheet-header .social li {
  display: inline-block;
  vertical-align: middle;
  color: #dedede;
  font-size: 20px;
  line-height: 1em;
  transition: all .3s ease;
  margin: 0 5px
}

.data-sheet-header.artist:before, .data-sheet-header.jury:before, .data-sheet-header.team:before {
  display: none
}

.data-sheet-header .social li:hover {
  transition: all .3s ease;
  color: #000
}

.generic-article .generic-title .social li, .generic-article .generic-title .social li:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.data-sheet-header .title {
  font-size: 40px;
  line-height: 1.2em;
  margin-bottom: 22px
}

.data-sheet-header .subtitle {
  font-size: 16px;
  line-height: 1.5em;
  margin-top: 0;
  margin-bottom: 6px;
  text-transform: uppercase
}

.data-sheet-header .category {
  font-size: 14px;
  line-height: 1.2em;
  color: #009ed6;
  margin-bottom: 5px
}

.data-sheet-header .edition {
  font-weight: 300;
  margin-bottom: 6px
}

.data-sheet-header.team {
  background: #f5f5f5;
  height: auto
}

.data-sheet-header.auto {
  height: auto
}

.data-sheet-header.beneficiary{
  .subtitle,
  .category,
  .edition{
    color: #616161;
  }
  .category{
    text-transform: uppercase;
    + .category{
      text-transform: none;
    }
  }
  .cover-linkedin{
    position: absolute;
    bottom: 60px;
    left: 40%;
    @include desktop-small{
      bottom: 20px;
      left: 0;
      position: relative;
      float: left;
      padding-left: 20px;
    }
    a{
      color: #009ed6;
    }
    .icon{
      font-size: 20px;
      color: #333333;
      position: relative;
      top: -2px;
    }
  }
  &:before {
    background-image: none !important;
  }
}

// Information block

.information-block {
  font-size: 0;
  display: block;
  text-align: left
}

.information-block .item {
  display: inline-block;
  vertical-align: top;
  width: 33.3333%;
  padding: 0 20px 0 90px;
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 300;
  text-align: left;
  margin: 0 auto;
  position: relative
}

.information-block .item p {
  margin-bottom: 0
}

.information-block .item .title {
  font-size: 21px;
  font-family: Oswald;
  font-weight: 400;
  line-height: 1.2em;
  margin-bottom: 6px
}

.information-block .item .icon {
  font-size: 70px;
  line-height: 1em;
  position: absolute;
  top: 0;
  left: 0
}

.information-block .item .icon:before {
  margin: 0
}

.information-block .item .tag {
  font-family: Oswald;
  font-weight: 500;
  font-size: 19px;
  line-height: 1em;
  margin-bottom: 12px
}

// Title block

.title-block-fixed {
  display: block;
  position: fixed;
  top: 71px;
  left: 0;
  width: 100%;
  z-index: 11;
  background: #000;
  color: #fff;
  -webkit-transition: left .3s linear;
  -moz-transition: left .3s linear;
  -ms-transition: left .3s linear;
  -o-transition: left .3s linear;
  transition: left .3s linear;
  padding: 25px 0;
  max-height: 80px
}

.title-block-fixed .title {
  margin: 0;
  font-size: 28px;
  letter-spacing: 2px;
  line-height: 1.1em;
  float: left;
  text-transform: uppercase
}

.title-block-fixed+.module {
  margin-top: 150px;
}

.title-block-fixed .general-nav {
  display: block;
  float: right;
  margin-top: -12px
}

.title-block-fixed .general-nav ul {
  margin: 0 auto;
  display: block;
  list-style: none;
  font-size: 0;
  padding: 0
}

.title-block-fixed .general-nav li {
  display: inline-block;
  vertical-align: middle
}

.title-block-fixed .general-nav li.active span {
  background: #009ed6
}

.title-block-fixed .general-nav span {
  font-size: 30px;
  display: block;
  width: 68px;
  height: 54px;
  line-height: 54px;
  text-align: center
}

.title-block-fixed .nav-cover {
  display: block;
  float: right
}

.title-block-fixed .nav-cover>.section-selector {
  float: left
}

.title-block-fixed .nav-cover>.section-selector.double .section-selector {
  width: 200%;
  max-width: 413px;
  right: 0;
  left: auto
}

.title-block-fixed .nav-cover>.section-selector+.section-selector .section-selector {
  width: 200%;
  max-width: 440px;
  right: 0;
  left: auto
}

.title-block-fixed .nav-cover.diary-nav, .title-block-fixed .nav-cover.diary-nav>.section-selector .section-selector {
  width: 100%
}

.title-block-fixed .nav-cover.diary-nav>.section-selector:first-of-type {
  float: left;
  margin-top: -16px
}

.title-block-fixed .nav-cover.diary-nav>.section-selector:first-of-type~.section-selector {
  float: right
}

.title-block-fixed .nav-cover.diary-nav>.section-selector .selector-name {
  background: #000
}

.title-block-fixed .section-selector {
  display: block;
  float: right;
  position: relative;
  font-size: 14px;
  line-height: 1.2em;
  letter-spacing: 1px;
  margin-top: -13.5px
}

.title-block-fixed .section-selector.active, .title-block-fixed .section-selector:hover {
  z-index: 1
}

.title-block-fixed .section-selector.active .section-selector, .title-block-fixed .section-selector:hover .section-selector {
  padding: 20px 0 25px;
  max-height: 420px;
  overflow: auto;
  -webkit-transition: all .3s linear;
  -moz-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear
}

.title-block-fixed .section-selector.active+.section-selector, .title-block-fixed .section-selector:hover+.section-selector {
  z-index: 0
}

.title-block-fixed .section-selector .selector-name {
  display: block;
  background: #009ed6;
  padding: 20px 55px 20px 25px;
  cursor: pointer;
  min-width: 220px
}

.title-block-fixed .section-selector .selector-name:after {
  content: '\e81e';
  font-family: fontello;
  font-size: 19px;
  margin-left: 15px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  position: absolute;
  right: 25px
}

.title-block-fixed .section-selector .section-selector {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #009ed6;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all .3s linear;
  -moz-transition: all .3s linear;
  -ms-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear
}

.title-block-fixed .section-selector .section-selector li {
  display: block;
  color: #fff;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.title-block-fixed .section-selector .section-selector li.active, .title-block-fixed .section-selector .section-selector li:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  color: #000
}

.title-block-fixed .section-selector .section-selector li ul {
  padding-left: 20px
}

.title-block-fixed .section-selector .section-selector li ul li {
  position: relative
}

.title-block-fixed .section-selector .section-selector li ul li:before {
  content: "•";
  color: #fff;
  position: absolute;
  left: 10px;
  top: 50%;
  line-height: 1em;
  margin-top: -.5em
}

.title-block-fixed .section-selector .section-selector span {
  display: block;
  padding: 0 25px 10px;
  font-size: 16px;
  line-height: 1.68em
}

.title-block-fixed .section-selector.type2 .section-selector, .title-block-fixed .section-selector.type2 .selector-name {
  background: #000;
  color: #fff
}

.title-block-fixed .section-selector.type2 li.active, .title-block-fixed .section-selector.type2 li:hover {
  color: #fff;
  opacity: .6
}

.title-block-fixed .section-selector.fix-submenu .section-selector {
  width: 200%;
  max-width: 394px;
  right: 0;
  left: auto
}

//$$ Generic article -- ¿Gestor de contenido?

.generic-article {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5em;

  & > img {
    width: 100%;
  }
}

.generic-article:after, .generic-article:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.generic-article .responsive-table {
  display: block;
  width: 100%;
  overflow: auto;
  margin-bottom: 1.8em
}

.generic-article .responsive-table table {
  margin-bottom: 0
}

.generic-article table {
  width: 100%;
  margin-bottom: 1.8em
}

.generic-article table th {
  font-weight: 600;
  padding: 0 10px;
  text-align: left
}

.generic-article table td {
  font-size: 14px;
  padding: 10px;
  max-width: 200px;
  vertical-align: top
}

.generic-article .title3 {
  font-family: Oswald;
  color: #009ed6;
  font-weight: 500;
  font-size: 18px;
  line-height: 1em;
  letter-spacing: 1px;
  margin-top: 80px;
  margin-bottom: 1.8em
}

.generic-article .title3:first-child {
  margin-top: 0
}

.generic-article br {
  content: "";
  display: block;
  height: 6px
}

.generic-article .small-article .full-col .content-block, .generic-article .small-article .full-col .content-block.height2 {
  height: auto;
  min-height: 500px;

  @media screen and (max-width: 480px){
    min-height: 300px;
  }
}

.generic-article b, .generic-article strong {
  font-weight: 600
}

.generic-article b.s, .generic-article strong.s {
  font-weight: 700
}

.generic-article hr {
  margin: 55px 0;
  border: 0;
  border-top: 1px solid #dedede
}

.generic-article hr.separator {
  margin-left: -240px;
  margin-right: -290px
}

.generic-article .full-article, .generic-article .half-article, .generic-article .small-article {
  opacity: 0;
  width: auto;
  display: block;
  margin-bottom: 1.8em
}

.generic-article .full-article .generic-caption, .generic-article .half-article .generic-caption, .generic-article .small-article .generic-caption {
  max-width: 1240px;
  padding: 0 20px
}

.generic-article .generic-content .full-article, .generic-article .generic-content .half-article {
  position: relative;
  left: 25px
}

.generic-article .generic-content span.ver-mas~*, .generic-article .generic-content span.view-more~* {
  display: none
}

.generic-article .generic-content span.ver-mas~.animated, .generic-article .generic-content span.view-more~.animated {
  display: block
}

.generic-article .generic-content span.ver-mas~.btn-show-view-more, .generic-article .generic-content span.view-more~.btn-show-view-more {
  display: inline-block
}

.generic-article .small-article .full-col .content-block .video.relative {
  position: initial !important
}

.generic-article .small-article .main-gallery .content-block, .generic-article .small-article .main-gallery .content-block.height2 {
  min-height: 480px
}

.generic-article .small-article .main-gallery+.ctrls-gallery {
  margin-right: 0;
  right: 0
}

.generic-article .small-article .block.infographic .cover-infographic {
  height: 480px
}

.generic-article .small-article .generic-caption {
  padding: 0
}

.generic-article .generic-title {
  display: block;
  position: relative;
  padding-right: 220px;
  margin: 40px 0
}

.generic-article .generic-title:after, .generic-article .generic-title:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.generic-article .generic-title .cover-img-left {
  float: left;
  margin-right: 50px;
  margin-bottom: 30px;
}

.generic-article .generic-title .block-img {
  float: left;
  max-width: 300px;
  margin-right: 20px
}

.generic-article .generic-title .block-img .cover-img {
  display: block;
  position: relative;
  width: 300px;
  height: 300px;
  overflow: hidden
}

.generic-article .generic-title .block-img .cover-img img {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  width: auto;
  max-width: initial;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.generic-article .generic-title .block-img .capiton-img {
  display: block;
  margin: 10px 0;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 300;
  color: #7f7f7f
}

.generic-article .generic-title .block-img .capiton-img p {
  margin: 0
}

.generic-article .generic-title .social {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  right: 0;
  max-width: 200px;
  font-size: 20px;
  z-index: 1
}

.generic-article .generic-title .social li {
  display: inline-block;
  vertical-align: middle;
  color: #dedede;
  margin: 0 5px;
  transition: all .3s ease
}

.generic-article .generic-title .social li:hover {
  transition: all .3s ease;
  color: #000
}

.generic-article .generic-title .epigraph {
  font-size: 13px;
  line-height: 1.2em;
  margin-bottom: 10px
}

.generic-article .generic-title .title {
  font-size: 40px;
  line-height: 1.2em;
  margin: 0 0 20px;
  font-weight: 600;

  @include mobile {
    font-size: 26px;
  }

  &.publication {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.generic-article .generic-title .title2 {
  font-size: 50px;
  line-height: 1.2em;
  margin: 0 0 20px;
  font-family: Prata
}

.generic-article .generic-title .title2:before {
  content: "«";
  display: inline-block;
  vertical-align: top;
  margin-left: -.4em
}

.generic-article .generic-title .title2:after {
  content: "»";
  display: inline-block;
  vertical-align: top
}

.generic-article .generic-title .title.size40, .generic-article .generic-title .title2.size40 {
  font-size: 40px;
  line-height: 1em
}

.generic-article .generic-title .title.size50, .generic-article .generic-title .title2.size50 {
  font-size: 50px;
  line-height: 1em
}

.generic-article .generic-title .title.size60, .generic-article .generic-title .title2.size60 {
  font-size: 60px;
  line-height: 1em
}

.generic-article .generic-title .title.size70, .generic-article .generic-title .title2.size70 {
  font-size: 70px;
  line-height: 1em
}

.generic-article .generic-title .title.size80, .generic-article .generic-title .title2.size80 {
  font-size: 80px;
  line-height: 1em
}

.generic-article .generic-title .author-info .image {
  float: left;
  width: 54px;
  height: 54px;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
  border-radius: 50%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex
}

.generic-article .generic-title .author-info .image img {
  display: block;
  object-fit: cover
}

.generic-article .generic-title .author-info .tag {
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 600;
  margin-top: 40px;
  color: #000;
}

.generic-article .generic-title .author-info .info {
  font-size: 16px;
  line-height: 1.2em
}

.generic-article .generic-title .author-info .info p {
  margin-bottom: 10px;

  &.author-name {
    margin-bottom: 20px;
    font-size: 16px;
    text-transform: uppercase;
  }

  b {
    color: #666;
    font-weight: bold;
  }
}

.generic-article .generic-title .author-info .social {
  position: relative;
  margin-bottom: 1em;
  font-size: 15px;
  line-height: 1.2em
}

.generic-article .generic-title .author-info .social li {
  margin: 0
}

.generic-article .generic-title.back-block-img {
  margin-top: 0;
  padding-top: 60px;
  margin-bottom: 60px;
  min-height: 500px;
  font-size: 14px
}

.generic-article .generic-title.back-block-img .block {
  position: absolute !important;
  top: 0;
  left: 0;
  width: auto;
  right: 0;
  height: 500px;
  display: block
}

.generic-article .generic-title.back-block-img .block+.social {
  top: 450px
}

.generic-article .generic-title.back-block-img .block.back-contain .dynamic-background {
  background-size: contain !important
}

.generic-article .generic-title.back-block-img .relative {
  max-width: 710px
}

.generic-article .generic-title .interview-info {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 1.2em;
  margin-bottom: 50px
}

.generic-article .generic-title .interview-info p {
  margin-bottom: 6px
}

.generic-article .generic-title .interview-info .interview-person {
  font-size: 19px;
  line-height: 1em;
  font-family: Oswald;
  font-weight: 500;
  margin: 0 0 6px
}

.generic-article .generic-title .interview-info .lead {
  font-size: 14px;
  line-height: 1.2em;
  font-family: Oswald;
  font-weight: 500;
  margin-bottom: 6px
}

.generic-article .generic-title.type2 {
  margin: 0 0 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative
}

.generic-article .generic-title.type2:before {
  content: "";
  position: absolute;
  top: 0;
  left: -1000px;
  width: 3000px;
  background: #f5f5f5;
  height: 100%;
  z-index: -1;
  visibility: visible
}

.generic-article .primal-info {
  margin-top: 30px;
  margin-bottom: 40px;
  display: block;
  padding: 30px 0;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  font-size: 18px;
  line-height: 1.6em;
  font-weight: 600
}

.generic-article .primal-info p {
  margin: 0;

  a {
    color: $blue;

    &:hover {
      text-decoration: underline;
    }
  }
}

.generic-article .generic-content {

  &.user-profile {

    padding-top:50px;

    &:after {
      clear:both;
      content: '';
      display: block;
    }

    .aside-left {
      float: left;
      clear: both;

      @media screen and (max-width: 768px){
        float: none;
        margin-bottom: 0;
      }

      .title {
        @media screen and (max-width: 768px){
          margin-bottom: 10px;
        }
      }
    }
    .content-center {
      float: left;
      width: calc(100% - 500px);
      position: relative;

      iframe {
        width: 100%;
        height: 500px;
        @media screen and (max-width: 480px) {
          height: 300px;
        }
      }
      h3, h4 {
        font-size: inherit;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 10px;
      }

      .wp-caption {
        width: 100% !important;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .aside-right {
        right: calc(0% - 300px);
        @media screen and (max-width: 768px) {
          position: static;
          margin-bottom: 0;
        }
      }
    }

    &.extra-left {
      @media screen and (max-width: 480px) {
        padding-top: 30px !important;
      }
      .content-center {
        padding-left: 30px;
        box-sizing: border-box;

        @media screen and (max-width: 768px) {
          padding-left: 0;
          margin-bottom: 40px;
        }
      }
    }
  }

}

.generic-article .generic-content p:empty+p {
  min-height: 70px
}

.generic-article .generic-content p {
  min-height: 0 !important;
  margin-bottom: 1.8em;
  a {
    color: $blue;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.generic-article .generic-content .content-full-right {

}

.generic-article .generic-content .content-full-left {
  margin-left: -240px
}

.generic-article .generic-content a {
  color: #009ed6
}
.generic-article .generic-content {
  .content-center {
    .aside-right {
      a:hover {
        text-decoration: none;
      }
    }
  }
}

.generic-article .generic-content .generic-body {
  width: 90%;
  max-width: 1200px;
}

.generic-article .generic-content .open-modal, .generic-article .generic-content .open-video {
  color: inherit
}

.generic-article .generic-content blockquote {
  max-width: 450px;
  background: #000;
  color: #fff;
  font-weight: 300;
  border: 0;
  margin: 0;
  padding: 20px 30px;
  font-size: 16px;
  line-height: 1.2em;
  letter-spacing: 2px
}

.generic-article .generic-content blockquote.grayl {
  background: #f3f3f3;
  color: #000
}

.generic-article .generic-content blockquote.grayl a {
  color: #000
}

.generic-article .generic-content blockquote.grayl .icon {
  color: #dedede
}

.generic-article .generic-content blockquote.left {
  margin-left: -240px;
  margin-right: 20px;
  margin-bottom: 20px
}

.generic-article .generic-content blockquote.right {
  margin-right: -290px;
  margin-left: 20px;
  margin-bottom: 20px
}

.generic-article .generic-content blockquote .title {
  font-size: 37px;
  line-height: 1.2em;
  font-family: Oswald;
  font-weight: 200;
  letter-spacing: 0;
  margin-bottom: 15px
}

.generic-article .generic-content blockquote .title2 {
  font-size: 40px;
  line-height: 1.2em;
  font-family: Prata;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 15px
}

.generic-article .generic-content blockquote .title2:before {
  content: "«";
  display: inline-block;
  vertical-align: top;
  margin-left: -.4em
}

.generic-article .generic-content blockquote .title2:after {
  content: "»";
  display: inline-block;
  vertical-align: top
}

.generic-article .generic-content blockquote p {
  margin: 0 0 6px
}

.generic-article .generic-content blockquote a {
  color: #fff;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.generic-article .generic-content blockquote a:hover, .generic-article .generic-content blockquote a:hover .icon {
  color: #009ed6;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.generic-article .generic-content .rounded-block .icon, .generic-article .generic-content .rounded-block:hover .icon {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.generic-article .generic-content blockquote .small-font {
  font-size: 12px;
  line-height: 1.2em
}

.generic-article .generic-content blockquote .small-font .icon {
  font-size: 20px;
  line-height: 1.2em;
  display: inline-block;
  vertical-align: middle
}

.generic-article .generic-content blockquote.single-block {
  padding: 0;
  background: 0 0;
  color: #000;
  max-width: initial;
  margin: 4.3em 0;
  font-size: 16px;
  line-height: 1.2em;
  font-family: bentonsans;
  font-weight: 300
}

.generic-article .generic-content blockquote.single-block .title {
  font-size: 45px;
  line-height: 1.2em;
  font-family: Prata;
  font-weight: 400;
  margin: 0 0 20px
}

.generic-article .generic-content blockquote.single-block .title:before {
  content: "«";
  display: inline-block;
  vertical-align: top;
  margin-left: -.4em
}

.generic-article .generic-content blockquote.single-block .title:after {
  content: "»";
  display: inline-block;
  vertical-align: top
}

.generic-article .generic-content blockquote.single-block p {
  margin-bottom: 5px
}

.generic-article .generic-content .aside-left {
  width: 200px;
  font-size: 14px;
  line-height: 17px;

  p {
    font-size: 18px;
    line-height: 1.5em;
  }
  &.full-width {
    width: 100%;
    p {
      a {
        display: inline-block;
      }
    }
  }
  &.width-66 {
    width: 66%;
    position: relative;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .aside-right {
      right: calc(0% - 300px);
      @media screen and (max-width: 768px) {
        right: 0;
      }
    }
  }
}

.generic-article .generic-content .aside-left .title {
  text-transform: uppercase
}

.generic-article .generic-content .aside-left.relative {

}

.generic-article .generic-content .aside-left p {
  margin-bottom: 1em
}

.generic-article .generic-content .aside-left p.time {
  font-size: 14px;
  line-height: 1.2em;
  color: #7f7f7f
}

.generic-article .generic-content .aside-left .btn {
  display: block;
  width: 100%;
  margin-bottom: 10px
}

.generic-article .generic-content .aside-left .rounded-block {
  margin-right: -38px
}

.generic-article .generic-content .aside-right {
  position: absolute;
  right: 0;
  width: 200px;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 40px
}

.generic-article .generic-content .aside-right.relative {
  margin-left: auto;
  margin-right: -290px
}

.generic-article .generic-content .aside-right p {
  margin-bottom: 1em
}

.generic-article .generic-content .aside-right .btn {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 17px 10px;
}

.generic-article .generic-content .aside-right .rounded-block {
  margin-left: -38px
}

.generic-article .generic-content .aside-info {
  display: block;
  position: relative;
  font-size: 15px;
  line-height: 1.2em;
  margin-bottom: 10px
}

.generic-article .generic-content .aside-info .title {
  font-size: 21px;
  line-height: 1.2em;
  font-family: Oswald;
  font-weight: 400;
  margin-bottom: 6px
}

.generic-article .generic-content .aside-info .tag {
  display: block;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 1em
}

.generic-article .generic-content .aside-info .tag small {
  font-size: .7em
}

.generic-article .generic-content .aside-info .icon {
  position: absolute;
  font-size: 44px;
  line-height: 1em;
  left: -60px;
  top: 0
}

.generic-article .generic-content .aside-info .icon:before {
  margin: 0
}

.generic-article .generic-content .rounded-block {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 238px;
  height: 238px;
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  padding: 20px 40px;
  position: relative;
  font-size: 13px;
  line-height: 1.2em;
  font-weight: 300;
  margin-bottom: 30px
}

.generic-article .generic-content .rounded-block:hover .icon {
  transition: all .3s ease;
  opacity: 1
}

.generic-article .generic-content .rounded-block .align-items {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%
}

.generic-article .related-info .related-data.artist .text-right, .generic-article .related-info .related-data.play .text-right {
  margin-top: 30px
}

.generic-article .generic-content .rounded-block .icon {
  font-size: 77px;
  line-height: 1em;
  margin-bottom: 10px;
  opacity: .5;
  display: block;
  transition: all .3s ease
}

.generic-article .related-info .item a, .generic-article .related-info .item-generic a:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.generic-article .generic-content .rounded-block .tag {
  font-size: 20px;
  line-height: 1em;
  font-family: Oswald;
  font-weight: 500;
  margin-bottom: 10px
}

.generic-article .generic-content .rounded-block p {
  margin-bottom: 0
}

.generic-article .generic-content .contact-block {
  display: block;
  margin-bottom: 25px
}

.generic-article .generic-content .contact-block p {
  margin-bottom: 6px
}

.generic-article .generic-content .contact-block .job {
  font-size: 19px;
  line-height: 1.2em;
  font-weight: 600;
  margin-bottom: 6px
}

.generic-article .generic-content .contact-block .name-number {
  font-family: Oswald;
  font-size: 21px;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 6px
}

.generic-article .generic-content .contact-block a {
  color: #009ed6
}

.generic-article .part {
  font-size: 14px;
  line-height: 1.5em;
  margin-bottom: 30px;
  letter-spacing: 1px
}

.generic-article .part .title {
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 20px;
  letter-spacing: 0
}

.generic-article .part .title b, .generic-article .part .title strong {
  font-weight: 700
}

.generic-article .part .name {
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 400;
  letter-spacing: 0
}

.generic-article .part p {
  margin-bottom: 15px
}

.generic-article .related-info {
  display: block;
  position: relative;
  margin-bottom: 30px
}

.generic-article .related-info .block {
  display: block;
  border-top: 1px solid #dedede;
  padding: 10px 0
}

.generic-article .related-info .block:after, .generic-article .related-info .block:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.generic-article .related-info .block:last-child {
  border-bottom: 1px solid #dedede
}

.generic-article .related-info .block.no-border {
  border: 0 !important
}

.generic-article .related-info .block.border-top {
  border-top: 1px solid #dedede !important;
  border-bottom: 0 !important
}

.generic-article .related-info .block.border-bottom {
  border-top: 0 !important;
  border-bottom: 1px solid #dedede !important
}

.generic-article .related-info .related-name {
  float: left;
  width: 240px;
  padding-right: 20px;
  padding-top: 30px;
  font-size: 22px;
  line-height: 1.2em;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0
}

.generic-article .related-info .related-name br {
  height: 0
}

.generic-article .related-info .related-name.full {
  width: 100%
}

.generic-article .related-info .related-data {
  float: left;
  width: -webkit-calc(100% - 240px);
  width: -moz-calc(100% - 240px);
  width: calc(100% - 240px)
}

.generic-article .related-info .related-data.artist {
  padding-top: 0;
  padding-bottom: 40px
}

.generic-article .related-info .related-data.play {
  padding-top: 30px;
  padding-bottom: 40px;
  font-size: 0
}

.generic-article .related-info .related-data.video {
  padding-bottom: 40px
}

.generic-article .related-info .related-data.full {
  width: 100%
}

.generic-article .related-info .related-data.awarded {
  padding-top: 30px
}

.generic-article .related-info .related-data.awarded p {
  margin: 0;
  font-family: Oswald;
  font-weight: 300;
  font-size: 21px;
  line-height: 1.7em
}

.generic-article .related-info .related-data.awarded p b, .generic-article .related-info .related-data.awarded p strong {
  font-weight: 400
}

.generic-article .related-info .related-data a {
  color: #000
}

.generic-article .related-info .related-data a .icon-play {
  color: #fff
}

.generic-article .related-info .related-data .section-name {
  margin-top: 60px !important
}

.generic-article .related-info .related-data .section-name:first-of-type {
  margin-top: 0 !important
}

.generic-article .related-info .related-data .info-card {
  margin-bottom: 25px;
  padding-right: 20px
}

.generic-article .related-info .item-generic {
  padding: 36px 0 20px;
  border-bottom: 1px solid #dedede;
  position: relative
}

.generic-article .related-info .item-generic:after, .generic-article .related-info .item-generic:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.generic-article .related-info .item-generic:last-of-type {
  border-bottom: 0
}

.generic-article .related-info .item-generic .cover-img {
  float: left;
  width: 220px;
  position: relative;
  z-index: 1
}

.generic-article .related-info .item-generic .cover-img+.cover-text {
  float: left;
  padding-left: 240px;
  width: 100%;
  margin-left: -220px
}

.generic-article .related-info .item-generic .epigraph {
  font-size: 13px;
  line-height: 1.2em;
  font-weight: 300;
  color: #616161;
  margin-bottom: 10px
}

.generic-article .related-info .item-generic .epigraph b, .generic-article .related-info .item-generic .epigraph strong {
  font-weight: 600
}

.generic-article .related-info .item-generic .title {
  font-size: 18px;
  line-height: 1.2em;
  margin-bottom: 10px;
  font-weight: 600
}

.generic-article .related-info .item-generic .date {
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 300;
  color: #878787
}

.generic-article .related-info .item-generic a:hover {
  transition: all .3s ease;
  color: #009ed6
}

.generic-article .related-info .item {
  padding: 30px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2em
}

.generic-article .related-info .item .epigraph {
  font-size: 13px;
  line-height: 1.2em;
  color: #616161;
  margin-bottom: 10px;
  font-weight: 300
}

.generic-article .related-info .item a {
  transition: all .3s ease
}

.generic-article .related-info .item a:hover, .generic-article .related-info .item-artist .cover-text .tag {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.generic-article .related-info .item a:hover {
  color: #009ed6;
  transition: all .3s ease
}

.generic-article .related-info .item .date {
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 300;
  color: #878787;
  margin-top: 11px
}

.generic-article .related-info .item+.item {
  border-top: 1px solid #dedede
}

.generic-article .related-info .item p {
  margin: 0
}

.generic-article .related-info {

  .item-artist {
    background: #f5f5f5;
    border: 1px solid #dedede;
    margin-top: 30px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    position: relative
  }

  .projects {
    .item-artist {
      .cover-img {
        max-width: 400px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position:0;

        @media screen and (max-width: 480px){
          max-width: 100%;
          background-position:0 0;
          min-height: 160px;
        }

        &.square {
          max-width: 220px;
          background-size: cover;
          background-position: 0 0;

          @media screen and (max-width: 480px){
            max-width: 100%;
            min-height: 260px;
          }
        }
      }
    }
  }
}

.generic-article .related-info .item-artist .cover-img {
  min-height: 220px;
  width: 100%;
  max-width: 220px;
  overflow: hidden;
  position: relative
}

.generic-article .related-info .item-artist .cover-img img {
  position: absolute;
  max-width: initial;
  min-width: 100%;
  width: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0)
}

.generic-article .related-info .item-artist .cover-text {
  width: 100%;
  padding: 25px 170px 25px 20px;
  min-height: 220px;
  position: relative;
  font-size: 15px;
  line-height: 1.2em;
  font-weight: 300
}

.generic-article .related-info .item-artist .cover-text .title {
  font-family: Oswald;
  font-size: 21px;
  line-height: 1.2em;
  margin-bottom: 12px
}

.generic-article .related-info .item-artist .cover-text .tag {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #dedede;
  padding: 20px 25px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0;
  transition: all .3s ease
}

.generic-article .related-info .item-artist .cover-text .tag:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  background: #333;
  color: #fff
}

.generic-article .related-info .item-play {
  display: inline-block;
  vertical-align: middle;
  width: -webkit-calc(50% - 10px);
  width: -moz-calc(50% - 10px);
  width: calc(50% - 10px);
  position: relative;
  min-height: 440px;
  padding: 30px 50px;
  margin-bottom: 20px
}

.generic-article .related-info .item-play:nth-child(odd) {
  margin-right: 10px
}

.generic-article .related-info .item-play:nth-child(even) {
  margin-left: 10px
}

.generic-article .related-info .item-play .flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  min-height: 380px;
  position: relative;
  z-index: 1
}

.generic-article .related-info .item-play .info {
  display: block;
  width: 100%;
  margin-top: auto;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.2em
}

.generic-article .related-info .item-play .title {
  font-size: 35px;
  line-height: 1.2em;
  margin-bottom: 10px
}

.generic-article .related-info .item-play .author {
  font-size: 15px;
  line-height: 1.2em;
  letter-spacing: 1px
}

.generic-article .related-info .item-video {
  display: block;
  margin-top: 30px
}

.generic-article .related-info .item-video+.item-video {
  padding-top: 30px;
  border-top: 1px solid #dedede
}

.generic-article .related-info .item-video .epigraph {
  font-size: 13px;
  line-height: 1.2em;
  color: #616161;
  margin-bottom: 10px
}

.generic-article .related-info .item-video .title {
  font-size: 18px;
  line-height: 1.2em;
  margin-bottom: 10px;
  font-weight: 600
}

.generic-article .related-info .item-video .date {
  font-size: 14px;
  line-height: 1.2em;
  margin-bottom: 20px;
  color: #878787;
  font-weight: 300
}

.generic-article .related-info .item-video .compound-blocks-modules .full-col .content-block {
  height: 510px
}

.generic-article .related-info .item-image:after, .generic-article .related-info .item-image:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.generic-article .related-info .item-image .cover-img {
  float: left;
  width: 300px;
  height: 300px;
  overflow: hidden
}

.generic-article .related-info .item-image .cover-info {
  float: left;
  width: 100%;
  padding-left: 320px;
  margin-left: -300px;
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 300
}

.generic-article .related-info .item-image .cover-info p {
  font-weight: 300
}

.generic-article .related-info .item-image .title {
  font-size: 22px;
  line-height: 1.5em;
  margin-bottom: 10px
}

.generic-article .related-info .block-info {
  display: block;
  padding-bottom: 30px;
  border-bottom: 1px solid #dedede
}

.generic-article .related-info .block-info img {
  margin-bottom: 1em
}

.generic-article .related-info .block-info img.right {
  margin-left: 25px
}

.generic-article .related-info .block-info img.left {
  margin-right: 25px
}

.generic-article .cover-media {
  display: block;
  margin-bottom: 1.8em
}

.generic-article .cover-media .media-video {
  display: block;
  margin: 0 auto 10px;
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  padding-bottom: 52.96%
}

.generic-article .cover-media .media-video .video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%
}

.generic-article .data-sheet-block br, .generic-article .info-card br {
  height: 0
}

.generic-article .cover-media .media-image {
  margin-bottom: 10px
}

.generic-article .tabs {
  font-size: 15px
}

.generic-article .tabs .tab-content {
  padding-top: 30px
}

.generic-article .section-name {
  font-size: 19px;
  line-height: 1.2em;
  font-weight: 600;
  margin: 0 !important;
  padding-bottom: 6px
}

.generic-article .info-card {
  display: inline-block;
  vertical-align: middle;
  width: 48%;
  font-size: 15px;
  line-height: 1.5em
}

.generic-article .info-card .name {
  font-size: 21px;
  line-height: 1.2em;
  font-family: Oswald;
  font-weight: 400;
  margin: 0;
  padding-bottom: 6px
}

.generic-article .info-card .name p {
  margin: 0
}

.generic-article .data-sheet-block {
  background: #f1f1f1;
  padding: 20px;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 300
}

.generic-article .data-sheet-block b, .generic-article .data-sheet-block strong {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5em
}

.generic-caption {
  display: block;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 300;
  color: #7f7f7f;
  margin: 10px auto
}

//$$ Paginador y lista de categorias

.category-list a, .pager a, .pager li.active a, .pager li.next:before, .pager li.prev:before, .pager li:hover a {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
}

.pager {
  list-style: none;
  font-size: 0;
  float: right;
}

.pager li {
  margin: 0 5px 10px;
  font-size: 14px;
  text-align: center
}

.pager li.active a, .pager li:hover a {
  background: #000;
  color: #fff;
  transition: all .3s ease
}

.pager li.next, .pager li.prev {
  position: relative;
  height: 50px
}

.pager li.next:before, .pager li.prev:before {
  font-family: fontello;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  font-size: 35px;
  line-height: 50px;
  color: #000;
  opacity: .1;
  transition: all .3s ease
}

.category-list a, .pager a {
  transition: all .3s ease
}

.pager li.next a, .pager li.prev a {
  border-color: transparent;
  background: 0 0 !important;
  color: #000 !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0
}

.pager li.next:hover:before, .pager li.prev:hover:before {
  opacity: 1
}

.pager li.next:before {
  content: '\e81c'
}

.pager a {
  display: block;
  width: 50px;
  height: 50px;
  border: 1px solid #464646;
  background: #fff;
  color: #000;
  line-height: 50px
}

.category-list {
  display: block;
  margin: 0 auto
}

.category-list a:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  color: #009ed6
}

.category-list .item {
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid #dedede
}

.category-list .item:after, .category-list .item:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.category-list .cover-img {
  float: left;
  display: block;
  width: 220px;
  overflow: hidden;
  position: relative
}

.category-list .cover-img a, .category-list .cover-img img {
  display: block
}

.category-list .cover-img+.cover-text {
  display: block;
  float: left;
  width: -webkit-calc(100% - 220px);
  width: -moz-calc(100% - 220px);
  width: calc(100% - 220px);
  padding-left: 20px
}

.category-list .cover-img.audio:before, .category-list .cover-img.gallery:before, .category-list .cover-img.video:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .15)
}

.category-list .cover-img.audio:after, .category-list .cover-img.gallery:after, .category-list .cover-img.video:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: fontello;
  font-size: 95px;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  opacity: .5;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.contact-block .block .name-number, .diary-generic .hour, .dynamic-block .animated-block span {
  font-family: Oswald
}

.category-list .cover-img.audio:hover:after, .category-list .cover-img.gallery:hover:after, .category-list .cover-img.video:hover:after {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 1
}

.category-list .title {
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 600;
  margin: 0 0 12px
}

.category-list .date, .category-list .epigraph {
  line-height: 1.2em;
  margin-bottom: 12px;
  display: block
}

.category-list .title span {
  color: #009ed6
}

.category-list .date {
  font-size: 14px;
  color: #878787;
  font-weight: 300
}

.category-list .epigraph {
  font-size: 13px;
  color: #616161
}

.category-list .epigraph:empty {
  display: none
}

//$$ Cicle

.cicle-generic {
  background: #131313;
  color: #fff
}

.cicle-generic .container {
  position: relative
}

.cicle-generic .nav-tabs {
  position: absolute;
  right: 20px;
  width: auto;
  padding: 0;
  margin-top: -10px
}

.cicle-generic .nav-tabs li {
  letter-spacing: 1px;
  border: 0;
  padding: 15px 25px;
  background: #000
}

.cicle-generic .nav-tabs li.active, .cicle-generic .nav-tabs li:hover {
  background: #009ed6
}

//$$ Audio media

.audio-media-gallery, .photo-media-gallery {
  display: block;
  position: relative
}

.audio-media-gallery:after, .audio-media-gallery:before, .photo-media-gallery:after, .photo-media-gallery:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.audio-media-gallery.onlyOne .gallery-slider, .photo-media-gallery.onlyOne .gallery-slider {
  width: 100%
}

.audio-media-gallery.onlyOne .right-nav, .photo-media-gallery.onlyOne .right-nav {
  display: block;
  width: 100%;
  padding: 0
}

.audio-media-gallery.onlyOne .galery-nav, .photo-media-gallery.onlyOne .galery-nav {
  display: none
}

.audio-media-gallery.onlyOne .info-photo, .photo-media-gallery.onlyOne .info-photo {
  padding: 10px
}

.audio-media-gallery .top-block, .photo-media-gallery .top-block {
  display: block;
  padding-right: 250px;
  margin-bottom: 25px
}

.audio-media-gallery .social, .photo-media-gallery .social {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  line-height: 1em;
  text-align: right
}

.audio-media-gallery .social ul, .photo-media-gallery .social ul {
  margin: 0;
  padding: 0
}

.audio-media-gallery .social li, .photo-media-gallery .social li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  color: #dedede;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.audio-media-gallery .social li:hover, .photo-media-gallery .social li:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  color: #009ed6
}

.audio-media-gallery .social li:last-of-type, .photo-media-gallery .social li:last-of-type {
  margin-right: 0
}

.audio-media-gallery .epigraph, .photo-media-gallery .epigraph {
  font-size: 13px;
  line-height: 1.2em;
  color: #009ed6;
  margin-bottom: 10px
}

.audio-media-gallery .title, .photo-media-gallery .title {
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 600;
  margin-bottom: 20px
}

.audio-media-gallery .author, .photo-media-gallery .author {
  font-size: 14px;
  font-weight: 300
}

.audio-media-gallery .bot-block, .photo-media-gallery .bot-block {
  display: block
}

.audio-media-gallery .bot-block:after, .audio-media-gallery .bot-block:before, .photo-media-gallery .bot-block:after, .photo-media-gallery .bot-block:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.audio-media-gallery .caption>p, .photo-media-gallery .caption>p {
  margin: 0;
  color: #7f7f7f;
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 300
}

.audio-media-gallery .gallery-slider, .photo-media-gallery .gallery-slider {
  float: left;
  width: -webkit-calc(100% - 180px);
  width: -moz-calc(100% - 180px);
  width: calc(100% - 180px);
  position: relative
}

.audio-media-gallery .gallery-slider.video-slider, .photo-media-gallery .gallery-slider.video-slider {
  width: 100%;
  float: none
}

.audio-media-gallery .gallery-slider.video-slider .icon-play, .photo-media-gallery .gallery-slider.video-slider .icon-play {
  font-size: 95px;
  line-height: 1em;
  display: block;
  width: 1em;
  height: 1em;
  margin: 0 auto;
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: .5
}

.audio-media-gallery .gallery-slider.video-slider .icon-play:hover, .photo-media-gallery .gallery-slider.video-slider .icon-play:hover {
  opacity: 1
}

.audio-media-gallery .gallery-slider.video-slider .icon-play:before, .photo-media-gallery .gallery-slider.video-slider .icon-play:before {
  margin: 0 auto
}

.audio-media-gallery .gallery-slider.video-slider .slide, .photo-media-gallery .gallery-slider.video-slider .slide {
  height: 664px;
  position: relative;
  overflow: hidden
}

.audio-media-gallery .gallery-slider.video-slider .slide:before, .photo-media-gallery .gallery-slider.video-slider .slide:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .45);
  z-index: 2
}

.audio-media-gallery .gallery-slider.video-slider .slide iframe, .photo-media-gallery .gallery-slider.video-slider .slide iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: none;
}

.audio-media-gallery .gallery-slider.video-slider .slide img, .photo-media-gallery .gallery-slider.video-slider .slide img {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  max-width: initial;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.audio-media-gallery .gallery-slider .caption, .photo-media-gallery .gallery-slider .caption {
  display: none
}

.audio-media-gallery .gallery-slider img, .photo-media-gallery .gallery-slider img {
  display: block
}

.audio-media-gallery .gallery-slider .slide, .photo-media-gallery .gallery-slider .slide {
  position: relative;
  opacity: 1;
  outline: 0
}

.audio-media-gallery .gallery-slider .slide+.slide, .photo-media-gallery .gallery-slider .slide+.slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0
}

.audio-media-gallery .gallery-slider .slide.slick-slide, .photo-media-gallery .gallery-slider .slide.slick-slide {
  position: relative;
  opacity: 1;
  z-index: auto
}

.audio-media-gallery .bottom-nav, .photo-media-gallery .bottom-nav {
  display: block;
  margin-top: 15px
}

.audio-media-gallery .bottom-nav:after, .audio-media-gallery .bottom-nav:before, .photo-media-gallery .bottom-nav:after, .photo-media-gallery .bottom-nav:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.audio-media-gallery .bottom-nav .galery-nav, .photo-media-gallery .bottom-nav .galery-nav {
  float: right;
  margin-bottom: 20px
}

.audio-media-gallery .bottom-nav .caption .title, .photo-media-gallery .bottom-nav .caption .title {
  font-size: 17px;
  line-height: 1.5em;
  color: #fff;
  font-weight: 400
}

.audio-media-gallery .right-nav, .photo-media-gallery .right-nav {
  float: right;
  width: 180px;
  padding-left: 20px
}

.audio-media-gallery .galery-nav, .photo-media-gallery .galery-nav {
  display: block;
  margin-bottom: 60px;
  font-size: 14px;
  color: #fff;
  line-height: 1.2em;
  font-weight: 300
}

.audio-media-gallery .galery-nav .icon, .photo-media-gallery .galery-nav .icon {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  color: #fff;
  font-size: 35px;
  opacity: .3;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.audio-media-gallery .galery-nav .icon:before, .photo-media-gallery .galery-nav .icon:before {
  margin: 0
}

.audio-media-gallery .galery-nav .icon:hover, .photo-media-gallery .galery-nav .icon:hover {
  opacity: 1;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.modalbox .form-control, .modalbox .form-control:focus, .modalbox .form-ctrl, .modalbox .form-ctrl:focus {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.audio-media-gallery .galery-nav .count-slides, .photo-media-gallery .galery-nav .count-slides {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px
}

.audio-media-gallery .gallery-slider {
  width: 100%
}

.audio-media-gallery .gallery-slider .caption {
  display: block;
  margin-top: 25px;
  min-height: 40px
}

.audio-media-gallery .bot-block {
  position: relative
}

.audio-media-gallery .block-audio {
  color: #000
}

.audio-media-gallery .galery-nav {
  position: absolute;
  bottom: 0;
  right: 0;
  float: none;
  margin: 0
}

//$$ Diary generic

.diary-generic {
  position: relative;
  display: block;
  border: 1px solid #dedede;
  margin-bottom: 30px;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 1180px
}

.diary-generic .diary-table {
  min-width: 800px;
  position: relative;
  display: block
}
@media screen and (max-width: 700px) {
  figure.pictureNone{
    height: 0 !important;
  }
}
@media screen and (max-width: 700px) {
  section.separadorHome{
    height: 1px;
    background-color: lightgray;
  }
}
@media screen and (max-width: 700px) {
  tr.no_pc{
    visibility: hidden;
    display: none;
  }
}
@media screen and (max-width: 700px) {
  td.no_pc{
    visibility: hidden;
    display: none;
  }
}
@media screen and (max-width: 700px) {
  img.no_pc{
    visibility: hidden;
    display: none;
  }
}
.display_newsletter{
  @media screen and (max-width: 700px) {
    display: block;
  }
  display: flex;
}
.width_newsletter{
  @media screen and (max-width: 700px) {
    width: auto;
  }
  width: 145%;
}

.footer_form{
  margin-top: inherit;
  color: #004481;
}
.footer_form .nav-footer{
  max-width: 200px;
}
@media screen and (max-width: 700px) {
  .footer_form .nav-footer{
    display: contents;
  }
}
.diary-generic tbody, .diary-generic thead, .diary-generic thead.fixed+tbody {
  display: table;
  table-layout: fixed;
  width: 100%
}

.diary-generic thead {
  background: #fff;
  left: 0;
  right: 0;
  z-index: 1;
  max-width: 1180px;
  margin: 0 auto
}

.diary-generic thead.fixed {
  position: fixed
}

.diary-generic thead.fixed+tbody {
  padding-top: 60px
}

.diary-generic th {
  font-size: 15px;
  line-height: 1.2em;
  font-weight: 600;
  padding: 20px;
  width: 14.285%;
  border: 1px solid #dedede;
  border-left: 0;
  text-align: left
}

.diary-generic th.otherMonth {
  color: #afafaf
}

.diary-generic .item-info.greenl .title, .diary-generic .item-info.yellow .title, .diary-generic .tag {
  color: #fff
}

.diary-generic th.actualDay {
  color: #fff;
  background: #009ed6
}

.diary-generic th:last-of-type {
  border-right: 0
}

.diary-generic td {
  width: 14.285%;
  padding: 0;
  border-right: 1px solid #dedede;
  background: #f6f6f6;
  vertical-align: top
}

.diary-generic td .cover-info {
  width: 100%
}

.diary-generic td[colspan="2"] {
  width: 28.57%
}

.diary-generic td[colspan="2"]+td {
  width: 0;
  display: none
}

.diary-generic td[colspan="2"] .cover-img, .diary-generic td[colspan="2"] .cover-img+.cover-info {
  width: 50%
}

.diary-generic td[colspan="3"] {
  width: 42.855000000000004%
}

.diary-generic td[colspan="3"]+td, .diary-generic td[colspan="3"]+td+td {
  width: 0;
  display: none
}

.diary-generic td[colspan="3"] .cover-img {
  width: 33.333%
}

.diary-generic td[colspan="3"] .cover-img+.cover-info {
  width: 66.666%
}

.diary-generic td[colspan="4"] {
  width: 57.14%
}

.diary-generic td[colspan="4"]+td, .diary-generic td[colspan="4"]+td+td, .diary-generic td[colspan="4"]+td+td+td {
  width: 0;
  display: none
}

.diary-generic td[colspan="4"] .cover-img {
  width: 25%
}

.diary-generic td[colspan="4"] .cover-img+.cover-info {
  width: 75%
}

.diary-generic td[colspan="5"] {
  width: 71.425%
}

.diary-generic td[colspan="5"]+td, .diary-generic td[colspan="5"]+td+td, .diary-generic td[colspan="5"]+td+td+td, .diary-generic td[colspan="5"]+td+td+td+td {
  width: 0;
  display: none
}

.diary-generic td[colspan="5"] .cover-img {
  width: 20%
}

.diary-generic td[colspan="5"] .cover-img+.cover-info {
  width: 80%
}

.diary-generic td[colspan="6"] {
  width: 85.71000000000001%
}

.diary-generic td[colspan="6"]+td, .diary-generic td[colspan="6"]+td+td, .diary-generic td[colspan="6"]+td+td+td, .diary-generic td[colspan="6"]+td+td+td+td, .diary-generic td[colspan="6"]+td+td+td+td+td {
  width: 0;
  display: none
}

.diary-generic td[colspan="6"] .cover-img {
  width: 16.666%
}

.diary-generic td[colspan="6"] .cover-img+.cover-info {
  width: 83.334%
}

.diary-generic td[colspan="7"] {
  width: 99.995%
}

.diary-generic td[colspan="7"]+td, .diary-generic td[colspan="7"]+td+td, .diary-generic td[colspan="7"]+td+td+td, .diary-generic td[colspan="7"]+td+td+td+td, .diary-generic td[colspan="7"]+td+td+td+td+td, .diary-generic td[colspan="7"]+td+td+td+td+td+td {
  width: 0;
  display: none
}

.diary-generic td[colspan="7"] .cover-img {
  width: 14.285%
}

.diary-generic td[colspan="7"] .cover-img+.cover-info {
  width: 85.715%
}

.diary-generic td:last-of-type {
  border-right: 0
}

.diary-generic .item-info {
  display: block;
  background: #fff
}

.diary-generic .item-info.greenl {
  background: #00d1c7
}

.diary-generic .item-info.yellow {
  background: #efbd2d
}

.diary-generic .tag {
  display: block;
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.2em;
  padding: 10px 20px;
  background: #000;
 /* text-transform: capitalize*/
}

.diary-generic .cover-block {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex
}

.diary-generic .cover-img {
  width: 172px;
  min-height: 233px;
  position: relative;
  overflow: hidden
}

.diary-generic .cover-img img {
  position: absolute;
  min-width: 100%;
  max-width: initial;
  min-height: 100%;
  max-height: 233px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0)
}

.diary-generic .cover-info {
  width: 100%;
  padding: 20px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 400;
  min-height: 233px
}

.diary-generic .cover-info p {
  margin-bottom: 6px
}

.diary-generic .cover-info .top {
  display: block;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2em
}

.diary-generic .cover-info .bottom {
  display: block;
  width: 100%;
  margin-top: auto;
  font-size: 12px;
  line-height: 1.3em
}

.diary-generic .cover-info .icon-paragraph {
  position: relative;
  padding-left: 15px
}

.diary-generic .cover-info .icon-paragraph .icon-place {
  position: absolute;
  top: 0;
  left: 0
}

.diary-generic .cover-info .icon-paragraph .icon-place:before {
  margin: 0
}

.diary-generic .title {
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 600;
  margin: 0 0 1em
}

.diary-generic .hour {
  font-size: 21px;
  line-height: 1.2em;
  font-weight: 400;
  margin-bottom: 6px
}

.home-galery {
  position: relative;
    padding: 0 20px;
  .icon-nav {
    position: absolute;
    top: 43%;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    color: #fff;
    font-size: 35px;
    opacity: .3;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    font-weight: 300;
    &.icon-left {
      left: -20px;
    }
    &.icon-right {
      right: -20px;
    }
    &:before {
      margin: 0;
    }
    &:hover {
      opacity: 1;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -ms-transition: all .3s ease;
      -o-transition: all .3s ease;
      transition: all .3s ease;
    }
  }
}

.embed-popup-container {
  header {
    padding: 10px;
    text-align: right;
  }

  .body {
    height: calc( 100% - 84px );
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
      width: 75%;
      height: 95%;

      @include tablet {
        width: 100%;
      }
    }
  }
}

@media all and (max-width:1240px) {
  .main-gallery .date-info .date, .ayudas .date-info .date {
    font-size: 80px!important;
  }
  .main-gallery .date-info .month,.ayudas .date-info .month  {
    font-size: 70px!important;
  }
  .diary-generic {
    max-width: initial;
    margin-left: -20px;
    margin-right: -20px;
  }
  .diary-generic thead {
    max-width: 100%;
  }
}


@media all and (max-width:1200px) {
  .main-gallery+.ctrls-gallery {
    margin-right: 0;
    right: 20px
  }
  .main-gallery .cover-info {
    padding-right: 50px
  }
  .main-gallery .cover-info.middle-size-fill {
    padding-right: 0
  }
  .main-gallery .cover-info.short-size, .main-gallery .cover-info.short-size-fill {
    width: 50%
  }
  .main-gallery .date-info .middle-line,.ayudas .date-info .middle-line  {
    margin-right: 0;
    position: relative;
    right: 0;
    margin-left: 13px
  }
  .main-gallery .date-info .inline-block,.ayudas .date-info .inline-block {
    padding: 0 10px
  }
  .main-gallery .date-info .inline-block~.inline-block,.ayudas .date-info .inline-block~.inline-block  {
    margin-left: 0;
    padding-right: 25px
  }
}
@media all and (max-width:1023px) {
  .generic-article .generic-title .cover-img-left {
    margin-bottom: 20px;
    float: none;
    display: block;
    margin-right: 0
  }
  .generic-article .generic-title .cover-img-left img {
    margin: 0 auto;
    display: block
  }
  .generic-article .generic-content .content-full-right, .generic-article .generic-content blockquote.right {
    margin-right: 0
  }
  .generic-article .generic-title .social {
    position: relative;
    margin-bottom: 15px
  }
  .generic-article .generic-title.back-block-img .block+.social {
    top: 0
  }
  .generic-article hr.separator {
    margin-left: 0;
    margin-right: 0
  }
  .generic-article .generic-content {
    padding: 0 !important
  }
  .generic-article .generic-content .content-full-left, .generic-article .generic-content blockquote.left {
    margin-left: 0
  }
  .generic-article .generic-content blockquote.single-block {
    padding: 0 .5em;
    margin: 3em 0
  }
  .generic-article .generic-content blockquote.single-block .title {
    font-size: 30px
  }
  .generic-article .generic-content .aside-left {
    position: relative;
    width: 100%;
    margin-bottom: 1.8em
  }
  .generic-article .generic-content .aside-left.relative {
    margin: 0 0 1.8em
  }
  .generic-article .generic-content .aside-left .rounded-block {
    margin: 0 auto 1.8em
  }
  .generic-article .generic-content .aside-right {
    position: relative;
    width: 100%;
    margin-bottom: 1.8em;
    top: auto !important
  }
  .generic-article .generic-content .aside-right.relative {
    margin: 0 0 1.8em
  }
  .generic-article .generic-content .aside-right .rounded-block {
    margin: 0 auto 1.8em
  }
  .generic-article .generic-content .aside-right .btn {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px
  }
  .generic-article .generic-content .aside-info {
    padding-left: 60px
  }
  .generic-article .generic-content .aside-info .icon {
    left: 0
  }
  .generic-article .related-info .related-data, .generic-article .related-info .related-name {
    width: 100%
  }
  .generic-article .generic-content .full-article, .generic-article .generic-content .half-article {
    left: auto
  }
  .diary-generic {
    margin-top: 78px
  }
  .diary-generic thead.fixed {
    position: relative
  }
}

@media all and (max-width:900px) {
  .general-title-fixed.holdtitle {
    width: 100%;
    background: #000
  }
}
@media all and (max-width:700px) {
  .wrapper.has--bar {
  }
  .wrapper.has--bar .title-block-fixed {
    top: 58px;
  }
  .wrapper.has--bar .title-block-fixed.show-bar {
    top: 129px;
  }
  .audio-media-gallery .right-nav:after, .photo-media-gallery .right-nav:after, .title-block-fixed .nav-cover.diary-nav>.section-selector:after, .title-block-fixed .nav-cover:after {
    clear: both
  }
  .ctrls-gallery {
    text-align: center
  }
  .ctrls-gallery .slick-dots {
    max-width: 60%;
    margin: 0 5px
  }
  .tabs .tab-selector {
    padding: 30px 60px 30px 0
  }
  .tabs .tab-selector:after, .tabs .tab-selector:before {
    width: 60px
  }
  .main-gallery+.ctrls-gallery {
    top: 15px
  }
  .main-gallery .date-info {
    float: none;
    width: 100%;
    text-align: center
  }
  .main-gallery .cover-info {
    padding-right: 0;
    margin-bottom: 20px;
  }
  .main-gallery .cover-info.big-size, .main-gallery .cover-info.middle-size, .main-gallery .cover-info.middle-size-fill, .main-gallery .cover-info.short-size, .main-gallery .cover-info.short-size-fill {
    width: 100%
  }
  .main-gallery .title, .main-gallery .title.size50, .main-gallery .title.size60, .main-gallery .title.size70, .main-gallery .title.size80 {
    font-size: 36px
  }
  .main-gallery .title.type2 {
    font-size: 25px;
    padding-left: 0
  }
  .main-gallery .title.type2:before {
    margin-left: 0
  }
  .main-gallery .title.type2~p {
    padding-left: 0
  }
  .main-gallery .title.type3 {
    font-size: 36px
  }
  .nav-tabs.especial-type li {
    border: 1px solid #000;
    padding: 15px 20px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none
  }
  .generic-article .generic-title .block-img {
    float: none;
    margin: 0 auto 30px
  }
  .generic-article .generic-title .title.size40, .generic-article .generic-title .title.size50, .generic-article .generic-title .title.size60, .generic-article .generic-title .title.size70, .generic-article .generic-title .title.size80, .generic-article .generic-title .title2.size40, .generic-article .generic-title .title2.size50, .generic-article .generic-title .title2.size60, .generic-article .generic-title .title2.size70, .generic-article .generic-title .title2.size80 {
    font-size: 30px;
    line-height: 1.2em
  }
  .generic-article .related-info .item-play {
    width: 100%;
    margin: 0 0 20px;
    padding: 20px
  }
  .generic-article .related-info .item-play:nth-child(even), .generic-article .related-info .item-play:nth-child(odd) {
    margin-left: 0;
    margin-right: 0
  }
  .generic-article .related-info .item-artist {
    display: block
  }
  .generic-article .related-info .item-artist .cover-text {
    display: block;
    width: 100%;
    min-height: initial;
    padding-right: 100px
  }
  .generic-article .related-info .item-artist .cover-img {
    margin: 0 auto;
    max-width: initial
  }
  .generic-article .related-info .item-artist .cover-img img {
    position: relative
  }
  .generic-article .related-info .item-image .cover-img {
    width: 100%;
    height: auto
  }
  .generic-article .related-info .item-image .cover-img img {
    width: 100%
  }
  .generic-article .related-info .item-image .cover-info {
    padding-left: 0;
    margin: 20px 0 0
  }
  .generic-article .related-info .item-generic .cover-img {
    float: none;
    margin-right: auto;
    margin-bottom: 20px
  }
  .generic-article .related-info .item-generic .cover-img+.cover-text {
    float: none;
    margin-left: 0;
    padding-left: 0
  }
  .audio-media-gallery .top-block, .exposition-description .info-description.has-aside-right, .photo-media-gallery .top-block {
    padding-right: 0
  }
  .audio-media-gallery .gallery-slider, .photo-media-gallery .gallery-slider {
    width: 100%;
    margin-bottom: 20px
  }
  .audio-media-gallery .right-nav, .photo-media-gallery .right-nav {
    padding-left: 0;
    width: 100%
  }
  .audio-media-gallery .right-nav:after, .audio-media-gallery .right-nav:before, .photo-media-gallery .right-nav:after, .photo-media-gallery .right-nav:before {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    height: 0
  }
  .audio-media-gallery .galery-nav, .photo-media-gallery .galery-nav {
    float: left;
    margin-right: 10px;
    margin-bottom: 20px
  }
  .audio-media-gallery .social, .photo-media-gallery .social {
    position: relative;
    margin-bottom: 20px
  }
  .audio-media-gallery .gallery-slider .caption {
    min-height: 0
  }
  .audio-media-gallery .galery-nav {
    float: none !important;
    width: 100%;
    text-align: right;
    display: block;
    position: relative
  }
  .diary-generic thead.fixed {
    position: relative;
    top: auto !important
  }
  .diary-generic thead.fixed+tbody {
    padding-top: 0;
    display: table
  }
}

@media all and (max-width:480px) {
  .nav-tabs.especial-type {
    margin-left: -20px;
    margin-right: -20px;
    width: auto;
    text-align: center
  }
  .nav-tabs.especial-type li {
    padding: 15px 10px
  }
  .generic-article .generic-content .aside-right .btn {
    width: 100%;
    margin-right: 0
  }
  .generic-article .generic-content blockquote.left, .generic-article .generic-content blockquote.right {
    float: none;
    max-width: initial;
    display: block;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px
  }
  .generic-article .generic-content blockquote .title, .generic-article .generic-content blockquote .title2 {
    font-size: 25px
  }
  .generic-article .info-card {
    width: 100%
  }
  .audio-media-gallery .gallery-slider.video-slider .slide, .photo-media-gallery .gallery-slider.video-slider .slide {
    height: 400px
  }
  .audio-media-gallery .bottom-nav .galery-nav, .photo-media-gallery .bottom-nav .galery-nav {
    float: none;
    width: 100%;
    text-align: right
  }
  .main-gallery .date-info .date,.ayudas .date-info .date {
    font-size: 50px!important;
  }
  .main-gallery .date-info .hour, .ayudas .date-info .month {
    font-size: 52px!important;
  }
  .main-gallery .cover-info {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 700px) {
  .convocatorias_movil{
    margin-top:40%!important;
  } 
  .paragraphBoldTabs .tab-content .name {
    font-size:20px!important;
  } 
  .fok-adapt{
    font-size:8px!important;margin-right:0px!important;
  } 
  .fok-less-text{
    margin-right:0px!important;
  } 
  .fok-text{
    font-size:15px!important;text-align:left!important;margin-left:1%;
  }
} 
.main-header .header-container .island-title .fok-text{
  text-align:left!important;
}
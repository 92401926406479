.awarded-anouncement {
  display: block;
  width: 100%;
  background: #000;
  position: relative;
  padding: 50px 0;
  color: #fff
}

.awarded-anouncement:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/simbolofronteras.jpg) no-repeat;
  background-size: cover;
  opacity: .14
}

.awarded-anouncement .container {
  position: relative;
  z-index: 1
}

.awarded-anouncement .cover-awarded {
  margin-left: -10px;
  margin-right: -10px;
  font-size: 0
}

.awarded-anouncement .awarded {
  display: inline-block;
  vertical-align: top;
  width: -webkit-calc(33.3333% - 20px);
  width: -moz-calc(33.3333% - 20px);
  width: calc(33.3333% - 20px);
  font-size: 14px;
  line-height: 1.2em;
  color: #009ed6;
  letter-spacing: 1px;
  border-top: 1px solid #333;
  padding: 15px 0;
  margin: 0 10px
}

.awarded-anouncement .awarded .date {
  font-weight: 300;
  color: #7f7f7f;
  font-size: 13px;
  line-height: 1em;
  letter-spacing: 1px;
  margin-bottom: 10px
}

.awarded-grid {
  font-size: 0;
  margin-left: -10px;
  margin-right: -10px
}

.awarded-grid:after, .awarded-grid:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.awarded-grid {
  .awarded {
    display: inline-block;
    vertical-align: top;
    width: 33.3333%;
    padding: 0 10px 20px;
    position: relative
  }
  .cover-img {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 81%;
    position: relative;
    overflow: hidden;
    margin: 0
  }
  .img-cloack {
    background: #000;

    img {
      opacity: 0.5;
    }
  }
  &.becarios {
    h3 {
      padding: 0 10px;
      margin-top: 30px;

      @include tablet {
        padding: 0 3px;
        font-size: 22px;
      }
    }
    .awarded {
      width: 25%;
      @include tablet {
        width: 33.33%;
        padding: 0 3px 7px;

        .info-cloack {
          padding: 8px 5px;
          text-align: center;

          .name {
            font-size: 18px;
          }
        }
      }

      @include mobile {
        width: 50%;

        .info-cloack {
          padding: 0;
          bottom: 10px;

          .name {
            font-size: 17px;
          }
        }
      }
    }
    .cover-img {
      img { 
        position: relative;
      }
      height: inherit;
      padding-bottom: 0;
    }
  }
} 

.awarded-grid .awarded:hover .cover-img img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease
}

.awarded-grid .awarded .link {
  width: auto;
  height: auto;
  left: 10px;
  top: 0;
  bottom: 10px;
  right: 10px
}


.awarded-grid .cover-img:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .45)
}

.awarded-grid .cover-img img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  max-width: none;
  left: 0;
  top: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease
}

.awarded-grid .info-cloack {
  position: absolute;
  left: 10px;
  top: auto;
  right: 10px;
  bottom: 20px;
  padding: 25px 20px;
  font-size: 14px;
  line-height: 1.2em
}

.awarded-grid .info-cloack .name {
  font-size: 28px;
  line-height: 1.2em;
  font-family: Oswald;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 6px;
  color: #fff
}

.awarded-grid .info-cloack .theme {
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 0
}


.awarded-anouncement-slider {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 1.5em;
  padding-top: 60px;
  margin-left: -20px;
  margin-right: -20px;
  max-height: 217
}

.awarded-anouncement-slider .slide {
  position: relative;
  padding: 0 20px;
  height: 157px;
  border-right: 1px solid #333;
  overflow: hidden;
  outline: 0
}

.awarded-anouncement-slider .slide+.slide {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0
}

.awarded-anouncement-slider.slick-slider .slide {
  top: auto;
  left: auto;
  opacity: 1;
  position: relative
}

.awarded-anouncement-slider .date {
  font-size: 13px;
  line-height: 1.7em;
  font-weight: 300;
  color: #7f7f7f
}

.awarded-anouncement-slider .theme {
  color: #009ed6;
  font-weight: 600;
  letter-spacing: 1px
}

.awarded-anouncement-slider .theme.done {
  color: #fff;
  padding-right: 20px;
  position: relative
}

.awarded-anouncement-slider .theme.done:after {
  content: '\e826';
  font-family: fontello;
  font-weight: 400;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px
}

.awarded-anouncement-slider .slick-next {
  right: 0;
  left: auto
}

.awarded-anouncement-slider .slick-next:before {
  content: '\e81c'
}

.awarded-anouncement-slider .slick-prev {
  right: 40px;
  left: auto
}

.awarded-anouncement-slider .slick-prev:before {
  content: '\e81d'
}

.awarded-anouncement-slider .slick-next, .awarded-anouncement-slider .slick-prev {
  position: absolute;
  z-index: 1;
  top: -35px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  width: auto;
  height: auto
}

.awarded-anouncement-slider .slick-next:before, .awarded-anouncement-slider .slick-prev:before {
  font-family: fontello;
  font-size: 35px;
  line-height: 1em;
  opacity: .3;
  color: #fff;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.awarded-anouncement-slider .slick-next:hover:before, .awarded-anouncement-slider .slick-prev:hover:before {
  opacity: 1;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.colaborators {
  display: block;
  padding: 22px 0;
  border-bottom: 1px solid #dedede;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1px
}

.colaborators img {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px
}

@media all and (max-width:1023px) {
  .awarded-anouncement .awarded {
	width: -webkit-calc(50% - 20px);
	width: -moz-calc(50% - 20px);
	width: calc(50% - 20px)
  }
  .awarded-grid .awarded {
	width: 50%
  }
}

@media all and (max-width:700px) {
  .awarded-anouncement .cover-awarded {
    margin: 0
  }
  .awarded-anouncement .awarded {
    width: 100%;
    margin: 0
  }
  .awarded-grid .awarded {
    width: 100%
  }
  .awarded-anouncement-slider .slide, .nav-tabs.especial-type {
    height: auto;
  }
}

@media all and (max-width: 480px) {
  .colaborators {
    text-align: center
  }
}
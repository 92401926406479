.header-page {
    height: 40vh;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;

    .header-content {
        width: 100%;
        max-width: 90%;
        margin: 0 auto;
        z-index: 10;
        color: white;

        .page-title {
            font-family: 'Oswald', sans-serif;
            font-weight: bold;
            padding: 0;
            margin: 0 0 5px;
        }

        .page-subtitle {
            font-family: 'Oswald', sans-serif;
            font-size: 25px;
            text-transform: uppercase;
        }
    }
}
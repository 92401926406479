.main-footer {
  display: block;
  padding: 30px 0 40px;
  font-weight: 300;

  .container {
    display: flex;

    @include tablet-big {
      flex-wrap: wrap;
      justify-content: center;
    }

    .nav-block {
      margin-top: 26px;
    }

  }

}

.main-footer .contact-block {
  font-size: 12px;
  line-height: 1.6em;
  min-width: 250px;
  position: relative;
  z-index: 1;
}

.main-footer .contact-block .clearfix {
  margin-left: -10px;
  margin-right: -10px
}

.main-footer .contact-block .logo {
  display: block;
  max-width: 128px;
  margin-bottom: 22px
}

.main-footer .contact-block .lg_6 {
  padding: 0 10px
}

.main-footer .contact-block p {
  margin-bottom: 0;
  letter-spacing: 1px
}

.main-footer .contact-block b {
  font-weight: 600
}

.main-footer .nav-block {
  font-size: 13px;
  width: 100%;
  margin-top: 0;
  text-align: center
}

.main-footer .nav-block:after, .main-footer .nav-block:before {
  content: "";
  width: 1px;
  height: 150px;
  opacity: .4;
  vertical-align: middle;
  display: inline-block;
  background: #000
}

.main-footer .nav-block:before {
  -webkit-transform: skew(-15deg);
  -moz-transform: skew(-15deg);
  -ms-transform: skew(-15deg);
  -o-transform: skew(-15deg);
  transform: skew(-15deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0
}

.main-footer .nav-block:after {
  -webkit-transform: skew(-15deg);
  -moz-transform: skew(-15deg);
  -ms-transform: skew(-15deg);
  -o-transform: skew(-15deg);
  transform: skew(-15deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%
}

.main-footer .nav-block .nav-footer {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 100%;
  max-width: 560px;
  font-size: 0;
  text-align: left;
  padding: 0 10px
}

.main-footer .nav-block .nav-footer li {
  list-style: none;
  display: block;
  float: left;
  width: 33.3333%;
  font-size: 13px;
  line-height: 1.7em;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0 10px
}

.main-footer .nav-block .nav-footer span {
  display: inline-block;
  vertical-align: middle
}

.main-footer .social-block {
  position: relative;
  z-index: 1;
  font-size: 13px;
  min-width: 190px;
  padding-left: 20px;
}

.main-footer .social-block .language {
  display: block;
  margin-bottom: 25px
}

.main-footer .social-block .language ul {
  margin: 0;
  padding: 0
}

.main-footer .social-block .language li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  color: #b2b2b2;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.main-footer .social-block .language li.active, .main-footer .social-block .language li:hover {
  color: #000;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.main-footer .social-block .social {
  display: block
}

.main-footer .social-block .social ul {
  margin: 0;
  padding: 0
}

.main-footer .social-block .social li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  color: #b2b2b2;
  font-size: 19px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.main-footer .social-block .social li.active, .main-footer .social-block .social li:hover {
  color: #000;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.main-footer.type2 .contact-block {
  max-width: 480px
}

.main-footer.type2 .contact-block .small {
  font-size: 11px;
  letter-spacing: 0
}

.main-footer.type2 .nav-block {
  margin-left: -480px;
  padding-left: 480px
}

.main-footer.type2 .nav-block .nav-footer {
  max-width: 380px
}

.main-footer.type2 .nav-block .nav-footer li:nth-child(odd) {
  width: 70%
}

.main-footer.type2 .nav-block .nav-footer li:nth-child(even) {
  width: 30%
}

.main-footer.type3 .contact-block {
  width: 45%;
  padding: 0 10px
}

.main-footer.type3 .contact-block .col-info-small {
  float: left;
  padding: 0 10px;
  width: 30%
}

.main-footer.type3 .contact-block .col-info-medium {
  float: left;
  padding: 0 10px;
  width: 40%
}

.main-footer.type3 .contact-block .col-info-medium .small {
  letter-spacing: 0;
}

.main-footer.type3 .contact-block .logo.inline-block {
  vertical-align: bottom;
  display: inline-block;
  margin: 0;
  width:50px;
}

.main-footer.type3 .contact-block .inline-block {
  vertical-align: bottom;
  display: inline-block;
  margin-bottom: -3px;
  margin-left: 5px
}

.main-footer.type3 .nav-block {
  width: 39%;
  margin-left: 0;
  margin-right: 0;
  padding: 0 0 0 20px;
  position: relative
}

.main-footer.type3 .nav-block .nav-footer {
  width: -webkit-calc(100% - 100px);
  width: -moz-calc(100% - 100px);
  width: calc(100% - 100px);
  text-align: center;
  vertical-align: top;
  margin-top: 36px
}

.main-footer.type3 .nav-block .nav-footer li {
  text-align: left;
  width: 65%;
  font-weight: 400
}

.main-footer.type3 .nav-block .nav-footer li:nth-child(2n) {
  width: 35%
}

.main-footer.type3 .social-block {
  margin-top: 48px;
}

.main-footer.type3 .social-block .language {
  margin-bottom: 0
}

.main-footer.type3 .social-block .language li {
  margin-bottom: 20px
}

.main-footer.type3 .social-block .social li {
  font-size: 23px;
  color: #ddd
}

.main-footer.type3 .social-block .social li:hover {
  color: #000
}

.main-footer.type3 .social-block .social li .icon:before {
  margin: 0
}

.main-footer.type3 .social-block .social li .icon-youtube {
  //font-size: 2.2em
}

.nav-block-section li a, .nav-block-section li a.active, .nav-block-section li a:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}




@media all and (max-width:1200px) {
  .main-footer .nav-block .nav-footer {
    max-width: 480px;
    padding: 0
  }
  .main-footer.type2 .contact-block {
    max-width: 440px
  }
  .main-footer.type2 .nav-block {
    padding-left: 440px;
    margin-left: -440px
  }
  .main-footer.type2 .nav-block .nav-footer {
    max-width: 240px
  }
  .main-footer.type2 .nav-block .nav-footer li {
    font-size: 11px
  }
  .main-footer.type3 .nav-block {
    width: 35%
  }
}

@media all and (max-width:1023px) {
  .main-footer .contact-block, .main-footer .social-block {
    display: inline-block;
    vertical-align: top;
    text-align: left
  }
  .main-footer .nav-block:after, .main-footer .nav-block:before {
    display: none
  }
  .main-footer .nav-block .nav-footer {
    max-width: 100%
  }
  .main-footer .nav-block .nav-footer li {
    font-size: 12px
  }
  .main-footer.type2 .contact-block {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #dedede;
    max-width: 460px
  }
  .main-footer.type2 .contact-block .clearfix {
    margin-left: 0;
    margin-right: 0
  }
  .main-footer.type2 .nav-block {
    padding-left: 0;
    margin-left: 0;
    display: inline-block;
    vertical-align: middle;
    max-width: 300px;
    border-top: 0
  }
  .main-footer.type3 .contact-block {
    width: 100%;
    max-width: 480px;
    float: none;
    display: block;
    margin: 0 auto;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(178, 178, 178, .4)
  }
  .main-footer.type3 .social-block {
    margin-top: 30px;
    display: block;
    text-align: center;
  }
  .main-footer.type3 .nav-block {
    width: 100%;
    max-width: 480px;
    float: none;
    display: block;
    margin: 20px auto 0;
    padding-top: 20px;
    padding-left: 0
  }
  .main-footer.type3 .nav-block .nav-footer {
    margin-top: 0
  }
}

@media all and (max-width:480px) {

  .main-footer .contact-block {
    width: 100%;
    text-align: center
  }
  .main-footer .contact-block .logo {
    margin-left: auto;
    margin-right: auto
  }
  .main-footer .social-block {
    width: 100%;
    padding: 0;
    margin-top: 20px;
    text-align: center
  }
  .main-footer .nav-block .nav-footer li {
    width: 100%;
    text-align: center
  }
  .main-footer.type2 .contact-block .logo img {
    display: block;
    margin: 0 auto
  }
  .main-footer.type2 .contact-block .clearfix > .lg_8 {
    margin-bottom: 20px
  }
  .main-footer.type3 .contact-block .col-info-medium, .main-footer.type3 .contact-block .col-info-small {
    margin-top: 15px;
    width: 100%
  }
  .main-footer.type3 .nav-block .nav-footer li {
    width: 100%;
    text-align: center
  }
  .main-footer.type3 .nav-block .nav-footer li:nth-child(2n) {
    width: 100%
  }
}
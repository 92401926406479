
.search-results {
  margin-left: -20px;
  margin-right: -20px;
  position: relative;
  display: flow-root;
  @include tablet-big{
    display: block;
    flex-flow: column;
  }
  &:after,
  &:before {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
  }
  [class^=lg_] {
    padding: 0 20px
  }
  .left-col {
    float: left;
    width: 25%;
    min-height: 1px;
    padding-left: 0px;
    left: 0;
    height: 100%;
    z-index: 1;
    @include tablet-big{
      order: 2;
      position: relative;
      width: 100%;
      height: auto;
      max-width: initial;
      padding-right: 20px;
    }
  }
  .right-col {
    float: left;
    width: 75%;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 600px;
    @include tablet-big{
      order: 1;
      min-height: auto;
      width: 100%;
    }

    @include mobile {
      float: none;
      padding: 0 15px;
    }
  }
  .results-counter {
    font-size: 15px;
    line-height: 1em;
    .counter {
      font-size: 34px;
      line-height: 1em;
      font-family: Oswald;
      font-weight: 500;
      color: #009ed6;
      margin-bottom: 15px
    }
    &.type2 {
      max-width: 300px;
      width: 100%;
      padding-left: 20px;
      .header {
        display: block;
        width: 100%;
        padding: 25px 20px;
        background: #009ed6;
        color: #fff;
        font-family: Oswald;
        font-size: 35px;
        line-height: 1.2em;
        font-weight: 200;
        position: relative;
        .title {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 40px;
          .icon {
            position: absolute;
            top: 20px;
            right: 10px;
          }
        }
        b,
        strong {
          font-weight: 500
        }
      }
      .content {
        display: block;
        background: #111;
        color: #fff;
        padding: 25px 20px;
        h3.title {
          font-size: 16px;
          font-weight: 600;
          color: #7f7f7f;
        }
      }
      label {
        line-height: 1.4em;
        margin-bottom: 10px;
        display: block;
      }
      .form-section {
        display: block;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0
        }
      }
      .form-ctrl {
        display: block;
        width: 100%;
        appearance: none;
        -none-appearance: none;
        -webkit-appearance: none;
        background: #2b2b2b;
        color: #fff;
        font-size: 14px;
        line-height: 1.2em;
        border: 0;
        outline: 0;
        border-radius: 0;
        font-weight: 500;
        padding: 18px 10px;
        &:focus {
          outline: rgba(255, 255, 255, .5) solid 1px
        }
      }
      select.form-ctrl {
        background-image:url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im0xMjEuMywzNC42Yy0xLjYtMS42LTQuMi0xLjYtNS44LDBsLTUxLDUxLjEtNTEuMS01MS4xYy0xLjYtMS42LTQuMi0xLjYtNS44LDAtMS42LDEuNi0xLjYsNC4yIDAsNS44bDUzLjksNTMuOWMwLjgsMC44IDEuOCwxLjIgMi45LDEuMiAxLDAgMi4xLTAuNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIgMC4xLTUuOHoiIGZpbGw9IiNGRkZGRkYiLz4KICA8L2c+Cjwvc3ZnPgo=);
        background-repeat: no-repeat;
        background-position: right 16px top 50%;
        background-size: 18px
      }
      .selection-label {
        display: block;
        font-size: 13px;
        line-height: 1.2em;
        cursor: pointer;
        .icon {
          font-size: 34px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
        input {
          display: none;
          &:checked+.icon {
            color: #fff
          }
          &:checked~span {
            color: #fff;
            font-family: sans-serif;
            font-weight: bold;
            border-bottom: solid 2px #fff;
          }
        }
      }
    }
  }
  .field-search {
    display: block;
    margin-bottom: 15px;
    &.nomination-formulary {
      margin-top: 0
    }
    .form-section {
      display: flex;
      width: 100%;
      &.include-btn{
        &:after,
        &:before {
          visibility: hidden;
          display: block;
          font-size: 0;
          content: " ";
          height: 0
        }
        .form-ctrl {
          width: -webkit-calc(100% - 66px);
          width: -moz-calc(100% - 66px);
          width: calc(100% - 66px);
          margin: 0;
          float: left;
          height: 60px;
          outline: 0;
          padding: 0 15px;
          font-size: 18px;
          font-weight: 500;
          border: 1px solid #dedede
        }
        .btn {
          float: left;
          width: 62px;
          height: 60px;
          display: block;
          text-align: center;
          padding: 0;
          .icon {
            margin: 0;
            line-height: 60px
          }
        }
      }
      &.include-btn-2{
        &:after,
        &:before {
          visibility: hidden;
          display: block;
          font-size: 0;
          content: " ";
          height: 0
        }
        .form-ctrl {
          flex-grow: 1;
          margin-right: 10px;
          height: 60px;
          outline: 0;
          padding: 0 15px;
          box-sizing: border-box;
          font-size: 18px;
          border: 1px solid #dedede;
        }
        .btn {
          width: 120px;
          height: 60px;
          text-align: center;
          padding: 0;
          .icon {
            margin: 0;
            line-height: 60px
          }
        }
      }
      +.order-by {
        margin-top: 10px
      }
    }
    .cover-select {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 1em;
        height: 1em;
        background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im0xMjEuMywzNC42Yy0xLjYtMS42LTQuMi0xLjYtNS44LDBsLTUxLDUxLjEtNTEuMS01MS4xYy0xLjYtMS42LTQuMi0xLjYtNS44LDAtMS42LDEuNi0xLjYsNC4yIDAsNS44bDUzLjksNTMuOWMwLjgsMC44IDEuOCwxLjIgMi45LDEuMiAxLDAgMi4xLTAuNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIgMC4xLTUuOHoiIGZpbGw9IiM3ZjdmN2YiLz4KICA8L2c+Cjwvc3ZnPgo=) no-repeat;
        background-size: cover;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -.5em
      }
    }
    select.form-ctrl {
      color: #7f7f7f;
      font-size: 12px;
      line-height: 1.2em;
      padding: 0
    }
  }

  .interested-block {

    display: block;
    padding-top: 30px;

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      color: #7f7f7f;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;

      li {
        padding-right: 10px;
        margin-right: 10px;
        border-right: solid 1px #ccc;

        &:last-child {
          border: 0;
        }

        input[type="radio"] {
          display: none;

          &:checked + label {
            color: $blue;
          }
        }

        label {
          cursor: pointer;
          font-size: 13px;
          font-family: bentonsansBold, sans-serif;
        }
      }
    }


    &.slider {
      background: #fafafa;
      padding: 30px;
      .slide {
        font-size: 13px;
        line-height: 1.7em;
        padding: 10px 0;
        font-weight: 700;
        color: #000;
        text-align: center;
        border: 1px solid #bebebe;
        outline: 0;
        cursor: pointer;
        position: relative;
        transition: all .3s ease;
        +.slide {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0
        }
        &.slick-slide {
          opacity: 1;
          position: relative
        }
        label {
          cursor: pointer
        }
        input {
          display: none
        }
        &.active,
        &:hover {
          background: #000;
          color: #fff;
          border-color: #000;
          -webkit-transition: all .3s ease;
          -moz-transition: all .3s ease;
          -ms-transition: all .3s ease;
          -o-transition: all .3s ease;
          transition: all .3s ease
        }
      }
      .slick-prev:before {
        content: '\e81d';
        font-family: fontello;
        color: #000;
        opacity: .4
      }
      .slick-next:before {
        content: '\e81c';
        font-family: fontello;
        color: #000;
        opacity: .4
      }
    }
  }
}


.searchBBVA .inputCol, .searchBBVA .searchWrapper, body, html {
  margin: 0 !important
}


.searchBBVA {
  padding: 0 !important
}

.searchBBVA .buttonCol {
  display: none !important
}

.searchBBVA input.term {
  display: block !important;
  width: 100% !important;
  border: 0 !important;
  padding: 8px 5px !important;
  border-bottom: 1px solid #dedede !important;
  outline: 0 !important;
  font-size: 15px !important;
  line-height: 1.2em !important
}

.searchBBVA .inputCol {
  padding: 0 !important;
  width: 100% !important;
  border-bottom: 0 !important
}

.search-results .searchBBVA .inputCol {
  width: -webkit-calc(100% - 62px) !important;
  width: -moz-calc(100% - 62px) !important;
  width: calc(100% - 62px) !important
}

.search-results .searchBBVA input.term {
  margin: 0 !important;
  float: left !important;
  height: 60px !important;
  outline: 0 !important;
  padding: 0 15px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  border: 1px solid #dedede !important
}

.search-results .searchBBVA .buttonCol {
  float: left !important;
  width: 62px !important;
  height: 60px !important;
  display: block !important;
  text-align: center !important;
  padding: 0 !important;
  border-color: 1px solid #009ed6 !important;
  color: #fff !important;
  background: #009ed6 !important;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.search-results .searchBBVA .buttonCol:hover {
  background: rgba(0, 158, 214, .7) !important;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

.search-results .searchBBVA .but {
  background: 0 0 !important;
  font-size: 24px !important;
  line-height: 60px !important
}

.search-results .searchBBVA .but:hover {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important
}

.search-results .searchBBVA .but:before {
  content: '\e820';
  font-family: fontello;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.result-list {

  .cover-img {
    flex-basis: 220px;

    img {
      width: 100%;
      max-width: none;
    }

    &.video {
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5)
      }
      &:after {
        content: '\e821';
        font-family: fontello;
        font-weight: 400;
        font-size: 90px;
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        text-align: center;
        color: #fff;
        opacity: .3;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease
      }
      &:hover:after {
        opacity: 1;
        transition: all .3s ease
      }
    }
  }
  .cover-img.video:hover:after {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
  }
  .block-tag {
    margin: -20px 0 0;
    background: #000;
    color: #fff;
    padding: 20px 25px;
    font-size: 14px;
  }
  .info {
    padding-left: 15px;
    flex-basis: calc(100% - 220px);
  }
  .name {
    font-size: 19px;
    line-height: 1.3em;
    font-family: Oswald, sans-serif;
    font-weight: 500;
    margin-bottom: 6px;

    a {
      transition: all .25s;

      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }
  }
  .subtitulo {
    font-family: Oswald, sans-serif;
    text-transform: uppercase;
    margin-bottom: 6px;
    font-size: 13px;
  }
  .status {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 2;
    vertical-align: text-bottom;
    position: absolute;
    right: 0;
    top: -2px;
    border: 1px solid #dedede;
    i[class^="icon-"]{
      color: #FFFFFF;
      font-size: 24px;
      @include mobile{
        font-size: 14px;
      }
    }
    &.open {
      background-color: #029ED6;
    }
    &.close {
      background-color: #DC3545;
    }
    @include mobile{
      top: 11px;
      width: 26px;
      height: 26px;
      right: -5px;
    }
  }
  .status~.status {
    right: 80px
  }
  .item {
    display: flex;
    padding: 35px 0;
    border-bottom: 1px solid #dedede;
    position: relative;

    &:first-of-type {
      border-top: 1px solid #dedede;
    }

    &.profile {
      border: 1px solid #dedede;
      padding: 0;
      background: #f5f5f5;
      margin: 35px 0;
      flex-wrap: wrap;
      justify-content: flex-end;

      .cover-img {
        padding: 20px 0 20px 20px;

        @include mobile {
          padding: 20px;
          flex-basis: 100%;
        }

        .icon-user {
          font-size: 128px;

          @include mobile {
            display: block;
            text-align: center;
          }
        }
      }
      .info {
        padding: 20px;

        @include mobile {
          flex-basis: 100%;
          padding: 0 20px 20px;
        }
      }

      .link-block-tag {
        .block-tag {
          background: #dedede;
          color: #333;
          &:hover {
            background: #333;
            color: #fff
          }
        }

        @include mobile {
          margin-top: 20px;
        }
      }

    }
    >:empty {
      display: none
    }
  }
  .date,
  .tag,
  .title {
    margin-bottom: 10px;
    line-height: 1.2em
  }
  .tag {
    font-size: 13px;
    font-weight: 300;
    color: #616161
  }

  .title {
    font-size: 18px;
    font-weight: 600;

    a {
      transition: all .25s;
      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }

    span {
      color: #009ed6;
    }

  }

  .date {
    font-size: 14px;
    font-weight: 300;
    color: #878787
  }
  .area,
  .common2,
  .epigraph,
  .year {
    font-weight: 600;
    color: #616161;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 1.2em
  }
  .kind-of-island,
  .kind-of-island.premios {
    background: #f5f5f5;
    border: 1px solid #dedede
  }
  .kind-of-island {
    color: #000;
    padding: 10px;
    border-bottom: 0;

    .date {
      color: #000
    }
    &.biophilia,
    &.biophilia .date,
    &.contrapunto,
    &.contrapunto .date,
    &.fronteras,
    &.fronteras .date,
    &.multiverso,
    &.multiverso .date,
    &.red-leonardo,
    &.red-leonardo .date {
      color: #fff
    }
    .info {
      padding-top: 24px
    }
    .island-name {
      font-size: 19px;
      font-family: Oswald;
      font-weight: 500
    }
    &.multiverso {
      background: #00d1c7;
      .island-name {
        font-size: 40px;
        line-height: 1.2em;
        margin: 0;
        padding-bottom: 6px;
        font-weight: 300;
        b,
        strong {
          font-weight: 500
        }
      }
    }
    &.contrapunto {
      background: #efbd2d;
      .island-name {
        font-size: 40px;
        line-height: 1.2em;
        margin: 0;
        padding-bottom: 6px;
        font-weight: 300;
        b,
        strong {
          font-weight: 500
        }
      }
    }
    &.biophilia {
      background: #f7893b;
      .island-name {
        font-size: 40px;
        line-height: 1.2em;
        margin: 0;
        padding-bottom: 6px;
        font-weight: 300;
        b,
        strong {
          font-weight: 500;
        }
      }
    }
    &.red-leonardo {
      background: #da3851;
      .island-name {
        font-size: 40px;
        line-height: 1.2em;
        margin: 0;
        padding-bottom: 6px;
        font-weight: 300;
        b,
        strong {
          font-weight: 500;
        }
      }
    }
    &.fronteras {
      background: #242234;
      .island-name {
        font-size: 40px;
        line-height: 1.2em;
        margin: 0;
        padding-bottom: 6px;
        font-weight: 300;
        b,
        strong {
          font-weight: 500
        }
      }
    }
  }
}

.general-grid .grid-item:hover .cover-media img {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.category-list .cover-img.video:after,
.general-grid .cover-media.video:before {
  content: '\e821'
}

.category-list .cover-img.audio:after,
.general-grid .cover-media.audio:before {
  content: '\e829'
}

.category-list .cover-img.gallery:after,
.general-grid .cover-media.gallery:before {
  content: '\e832'
}



.advanced-search {
  display: block
}

.advanced-search:after, .advanced-search:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.advanced-search .title {
  width: 220px;
  font-size: 50px;
  line-height: 1em;
  font-weight: 200;
  float: left;
  margin: 0
}

.advanced-search .form-block {
  float: left;
  width: 100%;
  padding-left: 240px;
  margin-left: -220px;
  color: #7f7f7f;
  font-size: 13px;
  line-height: 1.2em;
  letter-spacing: 1px
}

.advanced-search .form-section {
  margin-bottom: 25px
}

.advanced-search .form-section:after, .advanced-search .form-section:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.advanced-search .form-section label {
  display: block;
  margin-bottom: 10px
}

.advanced-search .form-section:last-child {
  margin-bottom: 0
}

.advanced-search .form-section.inline-items label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px
}

.advanced-search .form-section.inline-items .input-selector {
  margin: 0 12px 10px
}

.advanced-search .input-selector {
  margin: 0 12px;
  cursor: pointer;
  font-weight: 500;
  min-width: 68px;
  text-align: center
}

.advanced-search .input-selector>input {
  display: none;
  visibility: hidden
}

.advanced-search .input-selector>input:checked~* {
  color: #fff
}

.advanced-search .input-selector .icon {
  display: block;
  text-align: center;
  font-size: 34px
}

.advanced-search .form-ctrl {
  background: #2b2b2b;
  width: 100%;
  display: block;
  border: 0;
  padding: 18px 25px;
  font-size: 13px;
  color: #fff;
  font-weight: 300;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  min-height: 53px
}

.advanced-search .form-ctrl:focus {
  outline: rgba(255, 255, 255, .4) solid 1px
}

.advanced-search select.form-ctrl {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im0xMjEuMywzNC42Yy0xLjYtMS42LTQuMi0xLjYtNS44LDBsLTUxLDUxLjEtNTEuMS01MS4xYy0xLjYtMS42LTQuMi0xLjYtNS44LDAtMS42LDEuNi0xLjYsNC4yIDAsNS44bDUzLjksNTMuOWMwLjgsMC44IDEuOCwxLjIgMi45LDEuMiAxLDAgMi4xLTAuNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIgMC4xLTUuOHoiIGZpbGw9IiNGRkZGRkYiLz4KICA8L2c+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
  background-size: 18px
}

.advanced-search .inline-forms {
  display: block
}

.advanced-search .inline-forms:after, .advanced-search .inline-forms:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.areas-slider .area, .areas-slider p {
  font-size: 14px;
  line-height: 1.2em
}

.advanced-search .inline-forms .block-form {
  display: inline-block;
  vertical-align: top;
  width: -webki-calc(-20%);
  width: -moz-calc(100% - 120px);
  width: calc(100% - 120px)
}

.advanced-search .inline-forms .form-section {
  display: inline-block;
  vertical-align: middle;
  width: 48%;
  margin-bottom: 10px
}

.advanced-search .inline-forms label {
  display: inline-block;
  vertical-align: middle;
  width: 170px;
  margin-right: 10px
}

.advanced-search .inline-forms .form-ctrl {
  display: inline-block;
  vertical-align: middle;
  width: -webkit-calc(100% - 185px);
  width: -moz-calc(100% - 185px);
  width: calc(100% - 185px)
}

.advanced-search .inline-forms .multiple-block {
  display: inline-block;
  width: -webkit-calc(100% - 185px);
  width: -moz-calc(100% - 185px);
  width: calc(100% - 185px)
}

.advanced-search .inline-forms .multiple-block .form-ctrl {
  width: 100%
}

@media all and (max-width:1023px) {
  .advanced-search .title {
    width: 100%;
    margin-bottom: 20px;
    font-size: 25px
  }
  .advanced-search .form-block {
    margin-left: 0;
    padding-left: 0;
    width: 100%
  }
  .advanced-search .form-section.inline-items label {
    margin-right: 0
  }
  .advanced-search .form-section.inline-items .input-selector {
    margin: 0 10px 10px
  }
  .advanced-search .clearfix .form-section:last-child {
    margin-bottom: 25px
  }
  .advanced-search .inline-forms {
    text-align: center
  }
  .advanced-search .inline-forms .block-form {
    width: 100%
  }
  .advanced-search .inline-forms .form-section {
    margin-bottom: 25px;
    width: 49.5%;
    text-align: left
  }
}

@media all and (max-width:970px) {
  .search-results .results-counter.type2 {
    margin: 0 auto 20px;
    max-width: initial
  }
}

@media all and (max-width:700px) {
  .result-list .item.profile {
    border-bottom: 1px solid #dedede
  }
  .result-list .block-tag {
    width: 220px;
    text-align: center;
    padding: 10px;
    font-size: 13px
  }

  .result-list .kind-of-island .cloak {
    padding: 10px;
  }
  .result-list .kind-of-island .cover-img img {
    width: 100%;
  }
  .result-list .kind-of-island .island-name {
    font-size: 30px;
  }
  .advanced-search [class*=pl], .advanced-search [class*=pr] {
    padding: 0 !important
  }
  .advanced-search .form-section.inline-items label {
    display: block;
    width: 100%;
    margin-bottom: 20px
  }
  .advanced-search .form-section.inline-items .input-selector {
    display: inline-block;
    vertical-align: middle;
    width: auto
  }
  .advanced-search .left {
    margin-bottom: 20px
  }
  .advanced-search .left, .advanced-search .right {
    float: none;
    display: block;
    width: 100%;
    text-align: center
  }
  .advanced-search .inline-forms .form-ctrl, .advanced-search .inline-forms .form-section, .advanced-search .inline-forms label {
    width: 100%
  }
  .advanced-search .inline-forms .multiple-block {
    width: 100%;
    display: block
  }
  .advanced-search .inline-forms .multiple-block .form-ctrl {
    padding: 18px 25px !important
  }
  .category-list .cover-img {
    float: none;
    display: block;
    margin: 0 auto 12px
  }
  .category-list .cover-img+.cover-text {
    float: none;
    display: block;
    width: 100%;
    padding-left: 0
  }
}

@media all and (max-width: 480px) {

  .result-list .name {
    font-size: 17px
  }

  .result-list .block-tag, .result-list .cover-img {
    width: 100px
  }
  .result-list .status .icon {
    font-size: 20px !important;
    margin-bottom: 5px !important
  }
  .result-list .title {
    font-size: 14px;
  }
  .result-list .info {
    font-size: 14px;
    flex-basis: 60%;
  }
  .result-list .info.status-info {
    padding-right: 70px !important
  }
  .result-list .info>:empty {
    display: none
  }
  .result-list .info p:last-child {
    margin-bottom: 0
  }
  .result-list .item {
    padding: 20px 0;

    &.kind-of-island {
      padding: 20px;
      flex-wrap: wrap;

      .cover-img, .info {
        flex-basis: 100%;
      }

      .info {
        padding-left: 0;
      }
    }
  }
  .result-list .item.profile {
    margin: 20px 0
  }
  .advanced-search .form-section.inline-items {
    margin-left: -20px;
    margin-right: -20px
  }
  .advanced-search .form-section.inline-items .input-selector {
    margin: 0
  }
  .advanced-search .form-section.inline-items .input-selector .selector-name {
    font-size: 11px
  }

  .search-results .field-search {
    margin-top: 15px;
  }
  .search-results .field-search .form-section.include-btn-2 .easy-autocomplete {
    width: -webkit-calc(100% - 100px) !important;
    width: -moz-calc(100% - 100px) !important;
    width: calc(100% - 100px) !important
  }
  .search-results .field-search .form-section.include-btn-2 .form-ctrl {
    width: -webkit-calc(100% - 100px);
    width: -moz-calc(100% - 100px);
    width: calc(100% - 100px)
  }
  .search-results .field-search .form-section.include-btn-2 .btn {
    width: 90px
  }
  .search-results .interested-block nav li+li {
    margin-left: 0;
    padding-left: 0;
    display: block;
    border-left: 0
  }
  .order-by {
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px;
  }
}

/* Maquetación filtros buscador */

.check-label {
  display: block;
  position: relative;
  padding-left: 2em;
  margin-bottom: 1.2em !important;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #FFF;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkmark {
      background-color: #009ed6;
      &:after {
        display: block;
        left: 4px;
        top: 1px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  .icon {
    font-size: 30px;
    position: absolute;
    top: -5px;
    right: 0;
  }

}
.content-type .check-label {
  max-width: 200px;
}
.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  margin-bottom: 0px !important;
  transition: .25s ease-in-out;
}
.read-more-wrap {
  margin-bottom: 0px !important;
}
.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  margin-bottom: 1.2em !important;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger {
  display: block;
  &.minus {
    display: none;
  }
}

.read-more-state:checked ~ .read-more-trigger{
  display: none;
  &.minus {
    display: block;
  }
}

.read-more-trigger {
  cursor: pointer;
  color: #009ed6;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px !important;
  margin-top: 20px;
  .icon{
    display: inline-block;
    color: #FFF;
    background-color: #009ed6;
    width: 18px;
    height: 18px;
    font-size: 14px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%;
    margin-right: 1em;
    text-align: center;
    font-weight: bold;
  }
}

@media screen and (max-width: 700px)   {
  .pager-buscador{
    float:none!important;
  }
}

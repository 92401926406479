@charset 'UTF-8';

a {
  text-decoration: none;
  &:hover {
  	text-decoration: underline;
  }
}


/*base todas las plantillas*/
@import 'comun/_variables.scss';
@import 'comun/_mixins.scss';
@import 'comun/_queries.scss';
@import 'comun/fonts/font-face.scss';
@import 'comun/fonts/fontello.scss';
@import 'comun/_utilities.scss';

/*Heredity*/
@import 'heredity/modules/admin_main';
@import 'heredity/components/form';
@import 'heredity/components/islas';
@import 'heredity/generic/reset';
@import 'heredity/elements/utilities';
@import 'heredity/modules/header';
@import 'heredity/modules/footer';
//@import 'heredity/modules/footer2';
@import 'heredity/components/components';
@import 'heredity/components/page-header';
@import 'heredity/modules/modules';
@import 'heredity/components/awards';
@import 'heredity/components/sliders';
@import 'heredity/modules/admin_main';
@import 'heredity/components/select-menu-ul';
@import 'heredity/components/filters';
@import 'heredity/components/publications';
@import "heredity/components/single-profile";
@import 'heredity/components/news';
@import 'heredity/components/ayudas';
@import 'heredity/components/eventos';
@import 'heredity/components/ciclos';
@import 'heredity/components/generic-components';
//@import 'heredity/components/list-results';
@import 'heredity/elements/colors';
@import 'heredity/generic/grid';
@import 'heredity/components/modal-box';
@import 'heredity/others/other-styles';
@import 'heredity/components/islas';
@import 'heredity/components/buscador';
@import 'heredity/others/unknown';
@import 'heredity/generic/animations';
@import 'heredity/responsive.scss';



/* New components */
@import 'organisms/home-nav.scss';
@import 'organisms/top-nav.scss';
@import 'organisms/webmap-nav.scss';
@import 'organisms/islands-nav.scss';
@import 'organisms/_share-bar.scss';

/* Partials */
@import 'heredity/partials/capas-filtros-checkbox';


/* New pages */
@import 'pages/webmap.scss';
@import 'pages/convocatorias.scss';
@import 'pages/catalogo.scss';
@import 'pages/indice-de-autores.scss';


/* Blog */

@import 'blog/blog-fbbva.scss';

$unidad-contexto: 15;

// Colors
$white: #FFFFFF;
$black: #000000;
$blue:#009ed6;
$blue-dark: #0059a3;
$blue-awards:#009ed6;
$blue-awards-dark:#242234;
$blue-multiverso: #00d1c7;
$red-leonardo: #AF9A69;
$orange:#f7893b;
$gold: #efbd2d;
$grey: #b2b2b2;
$grey-light: #dedede;
$grey-mid: #878787;
$grey-mid-2: #7a7a7a;
$grey-mid-3: #999999;
$grey-dark: #333333;
$grey-webmap:#f1f1f1;
$grey-blog-light:#f2f2f2;
$grey-light-blog: #e0e0e0;
$grey-light-blog-2: #f8f8f8;
$grey-mid-blog: #7f7f7f;
$dark-grey:#2B2B2B;
$link-color:#000;

// Vars Grid
$desktop: 1240px;
$desktop-small: 1024px;
$tablet-big: 991px;
$tablet-landscape: 768px;
$tablet-portrait: 576px;
$mobile-landscape: 480px;
$mobile-portrait: 100%;

//Fonts
$bold: 700;
$regular: 400;
$normal: 400;
$small: 200;

$font_bebasneueBold: "bebasneueBold", sans-serif;
$font_bebasneueRegular: "bebasneueRegular", sans-serif;
$font_bebasneueBook: "bebasneueBook", sans-serif;
$font_bebasneueLight: "bebasneueLight", sans-serif;
$font_bentonsansBold: "bentonsansBold", sans-serif;
$font_bentonsansSemibold: "bentonsansSemibold", sans-serif;
$font_bentonsansMedium: "bentonsansMedium", sans-serif;
$font_bentonsansRegular: "bentonsansRegular", sans-serif;
$font_bentonsansLight: "bentonsansLight", sans-serif;
$font_bentonsansThin: "bentonsansThin", sans-serif;
$font_playfairBold: "playfairBold", sans-serif;
$font_playfairRegular: "playfairRegular", sans-serif;


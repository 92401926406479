#islaContrapunto {
	.diary-slider {
	  .content-slider {
		.content {
		  background: $gold;
		}
	  }
	}
  }
  
  #islaRedLeonardo {
	.diary-slider {
	  .content-slider {
		.content {
		  background: $red-leonardo;
		}
	  }
	}
  }
  
  #islaMultiverso {
	.bigtitle{
	  white-space: nowrap;
	}
	.diary-slider {
	  .content-slider {
		.content {
		  background: $blue-multiverso;
		}
	  }
	}
  }
  
  #islaBiophilia {
	.diary-slider {
	  .content-slider {
		.content {
		  background: $orange;
		}
	  }
	}
  }
  
  
  .main-header {
  
	.leonardo {
	  color: $red-leonardo;
	}
	.multiverso {
	  color:$blue-multiverso;
	}
	.biophilia {
	  color: $orange;
	}
	.contrapunto {
	  color: $gold;
	}
	.mapa-web {
	  color: $black;
	}
	.blue-bar {
	  font-size: 20px;
	  &.show-bar {
		padding: 8px 0;
	  }
	  &.leonardo {
		background: $red-leonardo;
		color: $white;
	  }
	  &.multiverso {
		background-color: $blue-multiverso;
		color: $white;
	  }
	  &.biophilia {
		background-color:$orange;
		color: $white;
	  }
	  &.contrapunto {
		background-color: $gold;
		color: $white;
	  }
	  &.mapa-web {
		background-color: $black;
		color: $white;
	  }
	  small {
		font-weight: $small;
	  }
	}
  }
  
  
  //$$ Query suelta de 1230px
  
  @media all and (max-width: 1230px) {
  
	//$$ Isla Premios
  
	.main-header {
	  .lead-text {
		width: 40%;
		padding-left: 0;
	  }
	  .blue-bar {
		&.show-bar {
		  margin-top:0;
		}
	  }
	}
	.compound-blocks-modules {
	  .bigtitle {
		&.t2 {
		  white-space: nowrap;
		}
		&.t6 {
		  font-size: 90px;
		}
	  }
	}
  
	//$$ Agenda
	.diary-generic {
		.dates {
			display: none;
	  }
	  .diary-table {
		min-width: 0;
		width: 100%;
		overflow: hidden;
		th {
		  text-align: center;
		}
	  }
	}
  
  }
  
  
  
  //$$ HASTA 1023px
  
  @include screen-desktop-small {
  
	//$$ HOME DESKTOP SMALL
  
	.advanced-search {
	  .form-section {
		&.inline-items {
		  label {
			&:first-child{
			  max-width: 150px;
			  line-height: 18px;
			}
		  }
		}
	  }
	}
  
	//$$ Isla Premios
  
	#islaPremios {
	  .main-header {
		.btn-big {
		  margin-top: 0;
		}
	  }
	}
  
	.compound-blocks-modules.parallax {
	  background-size: cover!important;
	  background-position: 0 0!important;
	}
  
  }
  
  //$$ Hasta 768px
  
  @include tablet {
  
	//$$ HOME TABLET
  
	  .compound-blocks-modules {
		  .main-gallery {
			  & + .ctrls-gallery {
				  top: 90%;
				  width: 100%;
				  left: 0;
			  }
		  }
	  }
	.content-block {
	  .logo {
		margin-right: 75px;
		&:after {
		  left: 215px;
		}
	  }
	}
	.nav-block-section {
	  #menuDcha, #menuIzq {
		line-height:1.6em;
		width: auto;
		padding-left: 20px;
	  }
	  #menuIzq {
		margin-right: 0;
		margin-left: 35px;
	  }
	}
	.advanced-search {
	  .form-section {
		&.inline-items {
		  .left {
			height: 120px;
			label {
			  &:first-child{
				max-width: 100%;
				display: block;
				margin-bottom: 24px;
			  }
			}
		  }
		  .right {
			position: relative;
			height: 103px;
			button {
			  @include absolute($r:0, $b:0);
			}
		  }
		}
	  }
	}
	.dynamic-block {
	  .animated-block {
		li {
		  &:nth-child(1) {
			bottom: 140px;
			left: 5%;
		  }
		  &:nth-child(2) {
			bottom: 40px;
			left: 34%;
		  }
		  &:nth-child(3) {
			top: 120px;
			right: 38%;
		  }
		  &:nth-child(4) {
			bottom: 15%;
			right: 8%;
		  }
		}
	  }
	}
	.main-footer {
	  &.type3 {
		.social-block {
		  .social {
			li {
			  color: $black;
			}
		  }
		}
	  }
	  .nav-block {
		.nav-footer {
		  span {
			font-size: 15px;
			margin-bottom: 12px;
		  }
		}
	  }
	}
  
	//$$ Sobre fundación
	.exposition-block  {
	  .exposition-nav {
		.left {
		  display: none;
  
		  &.beca {
			display: block;
			width: 100%;
  
			.conDesplegable {
			  float: none;
			  width: 50%;
			  margin: 10px auto;
  
			  .btn {
				width: 100%;
				text-align: center;
			  }
			}
		  }
		}
		.right {
		  width: 100%;
		  float: none;
		  ul {
			width: 50%;
			margin: 0 auto;
			text-align: center;
			border: 1px solid $black;
		  }
		  li {
			display: block;
			border:none;
			border-bottom: 1px solid #000;
			a {
			  span {
				font-size: 16px;
			  }
			}
			&:last-child {
			  border:none;
			}
			&+ li {
			  border-left: 0;
			}
		  }
		}
	  }
	}
	#resultadosBusqueda {
	  .result-list {
		.kind-of-island {
		  .info {
			padding-right: 0;
			width: 100%;
		  }
		}
	  }
	  .subArea {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	  }
	}
  }
  
  //$$ Query suelta de 700px
  
  @media all and (max-width:700px) {
	.main-gallery + .ctrls-gallery {
	  min-width: 250px;
	  top:30px;
	}
	.advanced-search {
	  .form-section {
		&.inline-items {
		  max-height: 200px;
		  .right {
			button {
			  position: relative;
			  width: 410px;
			}
		  }
		}
	  }
	}
	.compound-blocks-modules {
	  .bigtitle {
		&.t5 {
		  margin: 0 0 20px;
		}
		&.t6 {
		  font-size: 88px;
		}
	  }
  
	}.full-col {
	   .content-block {
		 min-height: 0;
	   }
	 }
  
	//$$ Sobre la fundación
  
	.exposition-description {
	  .info-description {
		.social {
		  margin-bottom: 20px;
		}
	  }
	}
	.generic-article {
	  .generic-content {
		blockquote {
		  &.left, &.right {
			margin-left: 0;
			margin-right: 100%;
			width: 100%;
			float: left!important;
		  }
		}
	  }
	}
	//$$ Agenda
	.title-block-fixed .nav-cover.diary-nav>.section-selector {
	  width: 36%;
	  margin-left: 7%;
	  margin-right: 7%;
	}
	 .diary-generic {    
	  border: none;
	  .diary-table {  
		 thead {
		  display: none;
		}
		td {
		  display: block;           
		  border: none;
		  margin: 20px;
		  width: calc(100% - 40px) !important; 
		  -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
		  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
		  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);   
  
		  &:empty {
			display: none;
		  }
		  .cover-block {
			display: block;
			.cover-img {
			  width: 100%;
			  & + .cover-info {
				width: 100%;              
			  }            
			}
		  .cover-info {
				.dates {
				  display: block;
				  width: 100%;
				  margin-top: 20px;
				  border-top: 1px solid;
				  margin-bottom: 20px;
				  border-bottom: 1px solid;
				  border-color: #EEEEEE;
				  .date-start, 
				  .date-end {
					.date-title {
					  font-size: 18px;
					  margin-bottom: 10px;
					}
					width: 50%;
					padding: 20px 0px;
					float: left;
					font-size: 14px;
					font-weight: 600;                  
				  }
				}
			  }
		  }
		}
	  }
	  .yellow {
		.cover-block {
		  .cover-info {
				.dates {
				  border-color: #000 !important;
				}
			  }
		}
	  }    
	}
	//$$ Resultado de Busqueda
	#resultadosBusqueda {
	  .result-list {
		.item {
		  &.profile {
			.cover-img {
			  img {
				top:0;
				bottom: 0;
			  }
			}
		  }
		}
	  }
	}
  }
  
  //$$ Hasta 576px
  
  @include tablet-portrait {
	.content-block {
	  .logo {
		&.home {
		  margin: 0;
		  width: 100%;
		  max-width: inherit;
		  img {
			max-width: 80%;
			margin: 0px;
		  }
		  &:after {
			left: calc(50% - 10px);
			margin-top: -30px;
		  }
		}
	  }
	  .nav-block-section {
		width: 100%;
		margin-top: 17px;
		position: relative;
		#menuDcha {
		  &+#menuIzq {
			font-size: 18px;
			line-height: 1.5em;
			width: 130px;
			margin: 0 0 0 14%;
			padding: 0;
		  }
		  li {
			font-size: 16px;
			text-align: right;
			line-height: 1.7em;
		  }
		}
	  }
	}
	// $$Sobre la fundación
	.generic-article  {
	  font-size: 16px;
	  .related-info {
		.block-info {
		  img {
			width:50%;
		  }
		}
	  }
	  .generic-content {
		blockquote {
		  &.left, &.right {
			margin: 0 50px 15px -35px;
			width: 108%;
			float:none;
		  }
		}
	  }
	}
	.exposition-description {
	  .info-description {
		p {
		  font-size: 16px;
		}
	  }
	}
	.ctrls-gallery {
	  .slick-next, .slick-prev {
		padding: 0px;
	  }
	}
	.slick-dotted {
	  +.ctrls-gallery {
		.slick-next, .slick-prev {
		  padding: 0;
		}
	  }
	}
	//$$ Resultados de búsqueda
  
	#resultadosBusqueda {
	  .result-list {
		.kind-of-island {
		  .island-name {
			font-size: 24px !important;
		  }
		}
	  }
	  .cover-img {
		img {
		  top:32px;
		}
	  }
	}
  }
  
  //$$ Hasta 480px
  
  @include mobile {
	//$$ HOME MOBILE
  
	  // Cabeceras
	.compound-blocks-modules {
	  .title {
		font-size: 22px;
		line-height: 1.2em;
	  }
	  &.island {
		&.A1 {
		  .col {
			&:first-child {
			  .content-block {
				padding: 18px 12px 12px;
			  }
			}
		  }
		}
	  }
	  &.parallax {
  
		  .col {
			  max-height: 200px;
			  &:first-of-type {
				  .content-block {
					  height: 122px;
					  padding: 18px 12px 12px;
					  display: flex;
					  justify-content: center;
				  }
			  }
		  }
		  &.about {
			  height: 500px;
			  .col {
				  &:nth-child(2) {
					  display: none;
				  }
			  }
		  }
	  }
	  &.island-premios {
		&.A1 {
		  .col {
			&:first-child {
			  .content-block {
				padding: 18px 12px 12px;
				.bigtitle {
				  &.t1 {
					font-size: 26px;
				  }
				}
				.edition {
				  font-size: 20px;
				  margin-bottom: 6px;
				}
			  }
			}
		  }
		  .picture-block {
			.picture {
			  height: 240px;
			}
		  }
		  .picture-caption {
			.btn-big {
			  width: 100%;
			  text-align: center;
			}
			.epigraph {
			  margin-bottom: 0;
			}
		  }
		}
	  }
  
	  .block[class*="back"] {
		.content-block {
		  padding: 18px 12px 12px;
		  .bigtitle {
			margin: 0;
			font-size: 36px;
			letter-spacing: -1px;
			padding-bottom: 6px;
			&.t5 {
			  font-size: 36px;
			  small {
				display: inline-block;
				font-size: 28px;
				letter-spacing: -1px;
			  }
			}
			&.t2 {
			  font-size: 10.5vw !important;
			}
		  }
		  .epigraph {
			font-size: 16px;
			margin-bottom: 0;
		  }
		}
	  }
  
	  // Modulitos
  
	  
  
	  &.type4 {
		.size1 {
		  .content-block {
			height: auto;
		  }
		}
	  }
	  .size3 {
		.content-block {
		  height: 420px;
		}
	  }
	  &.type1 {
		.size2, .size4 {
		  .content-block {
			height: 340px;
		  }
		}
		.size3 {
		  .content-block {
			height: 360px;
		  }
		}
	  }
	  &.type5 {
		.size1, .size2 {
		  .content-block {
			height: 420px;
		  }
		}
	  }
	  .main-gallery {
		.content-block {
			/* Restamos altura del menú fijo arriba */
		  min-height: initial;
		}
		& + .ctrls-gallery {
			top: 87%;
		}
		.title[class*="size"] {
		  font-size: 30px;
		}
	  }
	  .picture-block {
		.picture {
		  height: 320px;
		  @include ie {
			height: auto;
			max-height: 320px;
		  }
		  img {
			position: absolute;
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
			@include ie {
			  position: relative;
			  margin-top: -20%;
			}
		  }
		  &+.picture-caption {
			  padding: 20px;
			  color: #000;
		  }
		}
	  }
	}
  
	.diary-slider {
	  .content-slider {
		.tag {
		  font-size: 18px;
		}
		.info  {
		  .top {
			.title {
			  font-size: 16px;
			}
		  }
		}
		.bottom {
		  font-size: 14px;
		  line-height: 1.4em;
		}
	  }
	}
  
	.advanced-search {
	  padding: 50px 0;
	}
  
	//$$ Isla Premios
	#islaPremios {
	  .compound-blocks-modules {
		.main-gallery {
		  &+.ctrls-gallery {
			top:93%;
		  }
		}
	  }
	}
	.dynamic-block {
	  min-height: 500px;
	  .animated-block {
		li {
		  @include size(110px,100px);
		  &:nth-child(1) {
			bottom: 6%;
			left: 13%;
		  }
		  &:nth-child(2) {
			bottom: 37%;
			left: 25%;
		  }
		  &:nth-child(3) {
			top: 60px;
			right: 23%;
		  }
		  &:nth-child(4) {
			bottom: 30%;
			right: 10%;
		  }
		}
		span {
		  font-size: 11px;
		}
	  }
	}
	.advanced-search {
	  .form-section {
		&.inline-items {
		  max-height: 275px;
		  .left {
			height: 200px;
			margin-top: 12px;
			.input-selector {
			  width: 50%;
			  float: left	;
			  padding: 0 0 24px;
			  .selector-name {
				font-size: 12px;
			  }
			}
		  }
		  .right {
			height: 100px;
			margin-top: 30px;
			button {
			  width: 80%;
			}
		  }
		}
	  }
	}
	.main-footer {
	  .contact-block {
		.logo {
		  max-width: 66%;
		  margin-bottom: 16px;
		}
	  }
	  .col-info-small, .col-info-medium {
		font-size: 15px;
		line-height: 24px;
	  }
	  .contact-block {
		p>a {
		  font-size: 15px;
		}
	  }
	}
  
	//$$ Sobre la fundación
  
	.exposition-block {
	  .exposition-nav {
		.left {
		  &.beca {
			.conDesplegable {
			  width: 100%;
			}
		  }
		}
		.right {
		  ul {
			width: 98%;
		  }
		}
	  }
	}
	.exposition-description {
	  .location-description  {
		.description-block {
			white-space: nowrap;
		  p img {
			width: 100%;
			padding-bottom: 12px;
		  }
		}
	  }
	  .info-description {
		.social {
		  text-align: center;
		  li {
			font-size: 24px;
		  }
		}
	  }
	}
  
	.generic-article {
	  .related-info {
		.related-data  {
		  .section-name {
			margin-top: 18px!important;
		  }
		}
		.related-name {
		  padding-bottom: 16px;
		  padding-left: 12px;
		}
	  }
	  hr {
		margin: 33px 0 0;
	  }
	}
  
	.pager {
	  padding-left: 0;
	  padding-top: 16px;
	}
  
	#sobrelaFundacion {
	  .module {
		&.padding {
		  padding: 0;
		}
	  }
	}
  
  
	.editions-slider + .ctrls-gallery {
	  margin-top: 0;
	  padding: 18px 0;
	}
  
	#memoria {
	  &.title {
		padding-top: 12px;
	  }
	}
  
  
	//$$ Agenda
  
	.title-block-fixed {
	  .nav-cover {
		&.diary-nav {
		  &>.section-selector {
			.selector-name {
			  text-align: left;
			  &:after {
				position: absolute;
				right: 0;
			  }
			}
		  }
		}
	  }
	  .section-selector {
		&.active {
		  .section-selector {
			max-height: 370px;
		  }
		}
		.section-selector {
		  span {
			padding:0 12px 10px ;
		  }
		}
	  }
	}
  
	//$$ Prensa
  
	#prensa {
	  .text-righ.mt50 {
		pager {
		  width: 100%;
		  padding: 0;
		  text-align: center;
		}
	  }
  
	  .title-block-fixed + .module {
		margin-top: 0;
	  }
  
	}
  
	.result-list {
	  .item {
		border:none;
	  }
	  .cover-img  {
		flex-basis: 40%;
  
		img {
		  width: 100%;
		}
	  }
	}
	//$$ Resultados búsqueda
  
	#resultadosBusqueda {
	  .title-block-fixed {
		&+ .module {
		  margin-top: 16px;
		}
	  }
	}
  
	.btn {
	  &.btn-type3,
	  &.btn-type2 {
		width: 100%;
		text-align:center ;
	  }
	}
  }
  
  //$$ Hasta 320px
  
  @include mobile-small {
  
	//$$ HOME MOBILE SMALL
  
	//Cabecera
  
	.compound-blocks-modules {
	  &.island-premios {
		&.A1 {
		  .col {
			&:first-child {
			  .content-block {
				height: 152px;
				.bigtitle {
				  &.t1 {
					font-size: 24px;
				  }
				}
				.edition {
				  font-size: 19px;
				}
			  }
			}
		  }
		}
	  }
	}
  
	.dynamic-block {
	  .animated-block {
		li {
		  &:nth-child(1) {
			bottom: 8%;
			left: 11%;
		  }
		  &:nth-child(3) {
			top: 185px;
			right: 16%;
		  }
		  &:nth-child(5) {
			top: 40px;
			right: 4%;
		  }
		}
		span {
		  font-size: 11px;
		  line-height: 16px;
		}
	  }
	}
  
	//$$ Sobre la fundacion
	.generic-article {
	  .generic-content {
		blockquote {
		  &.left, &.right {
			width: 115%;
		  }
		}
	  }
	}
  }
  
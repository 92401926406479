

@font-face {
  font-family: "bebasneueBold";
  src: url(../fonts/bebasneue_bold.woff2) format('woff2'), url(../fonts/bebasneue_bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bebasneueRegular";
  src: url(../fonts/bebasneue_regular.woff2) format('woff2'), url(../fonts/bebasneue_regular.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "bebasneueBook";
  src: url(../fonts/bebasneue_book.woff2) format('woff2'), url(../fonts/bebasneue_book.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bebasneueLight";
  src: url(../fonts/bebasneue_light.woff2) format('woff2'), url(../fonts/bebasneue_light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bentonsansBold";
  src: url(../fonts/BentonSansBBVA-Bold.woff2) format('woff2'), url(../fonts/BentonSansBBVA-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bentonsansSemibold";
  src: url(../fonts/BentonSansBBVA-Bold.woff2) format('woff2'), url(../fonts/BentonSansBBVA-Bold.wofff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bentonsansMedium";
  src: url(../fonts/BentonSansBBVA-Medium.woff2) format('woff2'), url(../fonts/BentonSansBBVA-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bentonsansRegular";
  src: url(../fonts/BentonSansBBVA-Book.woff2) format('woff2'), url(../fonts/BentonSansBBVA-Book.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bentonsansLight";
  src: url(../fonts/BentonSansBBVA-Light.woff2) format('woff2'), url(../fonts/BentonSansBBVA-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bentonsansThin";
  src: url(../fonts/BentonSansBBVA-Light.woff2) format('woff2'), url(../fonts/BentonSansBBVA-Light.woff) format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;;
}

@font-face {
  font-family: "playfairBold";
  src: url(../fonts/playfairdisplay-bold.woff2) format('woff2'), url(../fonts/playfairdisplay-bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "playfairRegular";
  src: url(../fonts/playfairdisplay-regular.woff2) format('woff2'), url(../fonts/playfairdisplay-regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "stagsansLight";
  src: url(../fonts/StagSans-Light.otf) format('otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "stagsansMedium";
  src: url(../fonts/StagSans-Medium.otf) format('otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/*Nombre del becario*/
.pNameBecario {
  color: #000000;
  font-family: '__Oswald_5';
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  padding: 0px 0px 6px;
}
/*Química / Subárea     2016*/
.subArea {
  font-size: 13px;
  text-transform: none;
  line-height: 16px;
  letter-spacing: 0px;
  padding-bottom: 10px;
  color: #616161;
  font-family: bentonsansRegular, sans-serif;
  font-weight: 600;
}
/*fecha*/
.pDate {
  font-size: 14px;
  line-height: 17px;
  color: #878787;
  font-family: bentonsansRegular, sans-serif;
  font-weight: 300;
}
/*nombre del conferencista*/
.pNameConf {
  font-size: 13px;
  text-transform: uppercase;
  line-height: 16px;
  /*padding-bottom: 10px;*/
  color: #616161;
  font-family: bentonsansRegular, sans-serif;
  font-weight: 600;
}
/*Sala de Conciertos de la fundaciión BBVA, c/ Paseo de Recoletos 23, Madrid*/
.pPlace {
  font-size: 13px;
  text-transform: none;
  line-height: 16px;
 /* padding-bottom: 20px;*/
  color: #616161;
  font-family: bentonsansRegular, sans-serif;
  font-weight: 600;
}
/*convocatiora*/
.pConvocatoria {
  font-size: 14px;
  text-transform: none;
  line-height: 17px;
  padding-bottom: 10px;
  color: #878787;
  font-family: bentonsansRegular, sans-serif;
  font-weight: 300;
}
/*convocatoria span nevrita*/
.spanConvocatoria {
  color: #000000;
  font-family: bentonsansRegular, sans-serif;
  font-weight: 600;
}

/*ajustar padding de botón de descargar*/
.downloadBtnFix {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

/** a¡padding texto buscador **/
.select-varios-search {
  margin-top: 9px;
}


.exposition-block .exposition-nav .conDesplegable {
	position: relative;
}
.exposition-block .exposition-nav .beca .conDesplegable {
  float: right;
}
.exposition-block .exposition-nav .desplegable {
	position: absolute;
	border: 1px solid #D6D6D6;
	background:#fff;
	padding: 30px 40px;
	top: 70px;
	display: none;
}
.exposition-block .exposition-nav .desplegable:before {
	content: "";
	position: absolute;
	top: -10px;
	left: 20%;
	width: 20px;
	height: 20px;
	background: #fff;
	border: 1px solid #D6D6D6;
	z-index: 0;
	transform: rotate(45deg);
}
.exposition-block .exposition-nav .desplegable:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 20px;
	z-index: 1;
	display: block;
	background: #fff
}
.exposition-block .exposition-nav .desplegable ul {
	padding: 0;
	margin: 0
}
.exposition-block .exposition-nav .desplegable li {
	display: block;;
	font-size: 14px;
	color:#414141;
	margin-bottom: 10px
}
.exposition-block .exposition-nav .desplegable li label {
	display: inline-block;
	padding-left: 10px
}
.exposition-block .exposition-nav .desplegable input[type=text] {
	height: 48px;
	border:1px solid #D7D7D7;
	text-align: center;
	width: 180px;
}
.exposition-block .exposition-nav .desplegable input[type=text]:disabled {
	color:#BEBEBE;
}
.desplegable_boton {
	height: 62px;
	border:1px solid #008DCD;
	background: #008DCD;
	text-align: center;
	width: 100%;
	font-size: 14px;
	color:#fff;
	letter-spacing: 1px;
	line-height: 60px;
	border-radius: 0;
	text-transform: uppercase;
}
.desplegable_boton:hover {
	opacity: 0.4
}

.error-page {
  display: block;
  margin: 0 auto;
  max-width: 700px;
  padding: 50px 0;
  text-align: center;
  font-size: 22px;
  line-height: 1em
}

.error-page .title {
  font-size: 300px;
  line-height: 1em;
  font-weight: 600;
  margin-bottom: -40px
}

.error-page.type2 {
  background: url(../img/telescopio1.jpg) 50% 50% no-repeat;
  background-size: cover;
  max-width: 100%;
  padding: 130px 0;
  min-height: 660px;
  color: #fff
}

.error-page.type2 .p1 {
  font-size: 20px;
  line-height: 1.2em;
  margin-bottom: 10px
}

.error-page.type2 .p2 {
  font-size: 18px;
  line-height: 1.5em;
  margin-bottom: 5px
}

.error-page.type2 .p3 {
  font-size: 15px;
  line-height: 1.5em;
  font-weight: 300
}

.error-page.type2 form {
  display: block;
  margin: 40px auto 0;
  max-width: 600px;
  font-size: 0
}

.error-page.type2 .form-ctrl {
  background: #fff;
  width: -webkit-calc(100% - 140px);
  width: -moz-calc(100% - 140px);
  width: calc(100% - 140px);
  display: inline-block;
  vertical-align: middle;
  border: 0;
  padding: 15px 25px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  margin-right: 20px
}

.cicle-timeline.yellow .content, .cicle-timeline.yellow .head-indicators .bar span {
  background: #efbd2d
}

.cicle-timeline.yellow .tag span {
  color: #efbd2d
}

.cicle-timeline .cover-timeline-blocks {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px
}

.cicle-timeline .item {
  padding: 0 10px;
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.cicle-timeline .tag {
  display: block;
  background: #000;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.2em
}

.cicle-timeline .tag span {
  color: #009ed6
}

.cicle-timeline .content {
  background: #009ed6;
  padding: 15px 20px;
  height: 100%;
}

.cicle-timeline .title {
  font-size: 17px;
  line-height: 1.2em;
  font-weight: 600;
  color: #fff;
  margin: 0 0 6px
}

.cicle-timeline .info {
  color: #000;
  font-size: 12px;
  line-height: 1.2em;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%
}

.cicle-timeline .bottom, .cicle-timeline .top {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: flex-start;
  align-self: flex-start;
  width: 100%
}

.cicle-timeline .bottom {
  margin-top: auto
}

.cicle-timeline p {
  margin: 0
}

.cicle-timeline .place {
  margin-top: 8px
}

.cicle-timeline .icon {
  font-size: 22px;
  display: inline-block;
  vertical-align: top
}

.cicle-timeline .icon+span {
  display: inline-block;
  vertical-align: middle;
  width: -webkit-calc(100% - 35px);
  width: -moz-calc(100% - 35px);
  width: calc(100% - 35px)
}

.cicle-timeline .icon-place {
  margin-left: -4px
}

.cicle-timeline .icon-place:before {
  margin-left: 0
}

.cicle-timeline .head-indicators {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  font-size: 0
}

.cicle-timeline .head-indicators .bar-block {
  display: inline-block;
  vertical-align: middle;
  width: 100%
}

.cicle-timeline .head-indicators .name {
  display: inline-block;
  vertical-align: middle;
  width: 95px;
  padding: 0 10px;
  color: #fff;
  font-size: 13px;
  line-height: 1.2em;
  letter-spacing: 1px
}

.cicle-timeline .head-indicators .bar {
  display: inline-block;
  vertical-align: middle;
  width: -webkit-calc(100% - 105px);
  width: -moz-calc(100% - 105px);
  width: calc(100% - 105px);
  position: relative;
  height: 15px
}

.cicle-timeline .head-indicators .bar:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  background: #fff
}

.cicle-timeline .head-indicators .bar span {
  display: block;
  position: absolute;
  top: 50%;
  width: 0;
  height: 1px;
  background: #009ed6
}

.cicle-timeline .cover-media {
  display: block;
  width: 100%;
  overflow: hidden;
  height: 130px;
  position: relative
}

.cicle-timeline .cover-media img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%
}

.cicle-timeline .icon-right-direction {
  float: right;
  border-left: 1px solid #000;
  font-size: 21px;
  padding-left: 5px;
  line-height: 1.3em;
  opacity: .4
}

.cicle-timeline .full-cicle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-bottom: 25px
}

.cicle-timeline .full-cicle .title {
  font-size: 20px
}

.cicle-timeline .full-cicle .number {
  width: 170px;
  min-height: 234px;
  background: #000;
  font-size: 90px;
  line-height: 234px;
  font-weight: 500;
  text-align: center
}

.cicle-timeline .full-cicle .information {
  width: 100%
}

.cicle-timeline.type2 .content {
  height: 370px
}

.cicle-timeline.type2 .content:hover .icon-right-direction, .cicle-timeline.type2 .content:hover .icon-right-direction+p {
  color: #616161;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.areas-slider .area, .areas-slider .area:hover {
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.cicle-timeline.type2 .cover-media+.content {
  height: 240px
}

//$$ Datas

[data-animated] {
  opacity: 0
}

[data-background]:hover .hover-effect {
  -webkit-transition: all 4s ease-in-out;
  -moz-transition: all 4s ease-in-out;
  -ms-transition: all 4s ease-in-out;
  -o-transition: all 4s ease-in-out;
  transition: all 4s ease-in-out;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2)
}

[data-component-bbva=form] form {
  display: none
}

[data-component-bbva=form] form.nomination-formulary, [data-component-bbva=form] form.standar-formulary {
  display: block
}

//$$ Label cicle

.label-cicle-action {
  display: block;
  width: 100%;
  background: #323944;
  color: #727c8d;
  text-align: right;
  font-size: 17px;
  line-height: 1.2em;
  padding: 17px 0;
  letter-spacing: 1px;

  @include mobile {
    text-align: center;
  }
}

.label-cicle-action .control {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding-right: 25px;
  position: relative
}

.label-cicle-action b, .label-cicle-action strong {
  color: #fff
}

.label-cicle-action .icon {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  vertical-align: middle;
  color: #fff;
  display: block;
  position: absolute;
  right: 0;
  top: 0
}

@media all and (max-width:1200px) {
  .data-sheet-header .container {
    padding: 0;
  }
}

@media all and (max-width:1023px) {
  .data-sheet-header .cover-info .data-info, .generic-article .generic-title {
    padding-right: 0;
  }
  .data-sheet-header {
    height: auto
  }
  .data-sheet-header .cover-img.full {
    max-height: initial;
    height: auto
  }
  .data-sheet-header .cover-img img {
    position: relative;
    min-height: initial;
    width: 100%
  }
  .data-sheet-header .cover-info {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 60px
  }
  .data-sheet-header .cover-info .data-description {
    margin-top: 30px
  }
  .data-sheet-header .social {
    top: 10px
  }
  .cicle-timeline .item {
    width: 33.3333%
  }
}

@media only screen and (max-width : 700px) {
  .error-page .title {
    font-size: 43vw;
    margin-bottom: -.1em
  }
}

@media only screen and (max-width : 576px) {
  .desplegable form {
    text-align: left;
  }
}

@media all and (max-width: 700px) {
  .cicle-timeline .item {
    width: 50%
  }
}

@media all and (max-width:480px) {
  .data-sheet-header .title {
    font-size: 30px
  }
  .cicle-timeline .full-cicle {
    display: block
  }
  .cicle-timeline .full-cicle .number {
    width: 100%;
    line-height: 1.2em;
    min-height: initial;
    padding: 20px 0
  }
  .cicle-timeline .full-cicle .information, .cicle-timeline .item {
    width: 100%
  }
  .cicle-timeline .cover-media {
    height: 0;
    padding-bottom: 54%
  }
  .cicle-generic .nav-tabs {
    position: relative;
    margin: 0 0 20px
  }
  .error-page.type2 .form-ctrl {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0
  }
}

.noticiaFotoAutor{
    p{
      @media screen and (max-width: 700px) {
        margin-left: 3%;
      }
      margin-left: 1%;
      margin-bottom:0;
    }
    div.image{
      place-items: baseline; 
      display: flex; 
      margin-bottom:1em;
    }
}
.webmap__container {

  .webmap__row {
	&.nav {
	  @include transition(left,.3s,linear);
	  position: fixed;
	  z-index: 8;
	  top: 70px;
	  left: 0;
	  width: 100%;
	  background: $white;
	  .contenedor {
		@include webmap-bg;
		display: flex;
		max-width: 1200px;
		width: calc(100% - 40px);
		margin: 0 auto;
		padding-bottom: 30px;
	  }
	  .main {
		width: 75%!important;
		.webmap {
		  &__nav {
			color: $grey-mid-3;
			font-family: bentonsans, Helvetica, Arial, Sans-serif;
			font-size: 16px;
			text-transform: uppercase;
			padding-right: 24px;
			height: 25px;
			.webmap__nav__list {
			  position: relative;
			  display: flex;
			  justify-content: space-between;
			  align-items: flex-end;
			  flex-wrap: nowrap;
			  padding: 0;
			  margin: 0;
			  list-style: none;
			  .webmap__nav__item {
				@include transition(all, .3s, ease);
				width: auto;
				cursor:pointer;
				&:nth-last-child(2) {
				  margin-right: 40px;
				}
			  }
			  .webmap__nav__line {
				@include absolute($b:-3px, $l:0);
				@include transition(all, .3s, linear);
				height: 3px;
				background-color: $black;
				width: 60px;
				-webkit-transform-origin: 0 0;
				transform-origin: 0 0;
			  }
			}
		  }
		}
	  }
	  .aside {
		width: 25%;
		padding: 45px 0 0 25px;
		font-family: Oswald;
		color: $black;
		position: relative;
		.aside--title {
		  font-weight:600;
		  font-size: 20px;
		  margin: 0;
		}
	  }
	  @include tablet-big {
		display: none!important;
	  }
	  & + .webmap__row {
		padding-top: 70px;
		@include tablet-big {
		  padding-top: 16px;
		}
	  }
	}
  }
}
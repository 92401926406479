// Container de listado, title y select

.all-entries {
  .infoPost{
    display:flex;
    flex-direction: column;

    &.column_12{
      width:90%;
     
      @include mobile-medium{
        width:100%;
      }

    }
    &.column_8{
      width:75%;
      @include tablet{
        width:70%;
      }
      @include mobile{
        width:60%;
      }

    }
  }
  .title-all-entries{

     @include tablet{
        padding-left:20px;
      }
    @include tablet-plus{
        padding-left:5px;
      }
     @include mobile-medium{
        padding-left:5px;
      }
  }
  .select-menu{
    text-align: right;
    margin-top:20px;
    padding-right: 50px;
     @include tablet{
        text-align: left;
        padding-left:20px;
      }
      @include tablet-plus{
        padding-left:5px;
      }
     @include mobile-medium{
        text-align: left;
        padding-left:5px;
      }

  }

  .all-entries__title {
    font-family: $font_bentonsansBold;
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    padding: 32px 0;
    display: inline-block;
    @include tablet {
      display: block;
      padding-bottom: 0;

    }
  }
  .list-entries {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    justify-content: center;
    @include tablet {
      flex-direction: column;
      margin-left: -15px;
      padding:20px;
    }
  }
  .form-section {
    position: relative;
    display: inline-block;
    padding-top: 15px;
    text-align: right;
    float: right;
    background-color: $white;
    z-index: 0;
    &:after {
      @include absolute($t:22px,$r:16px);
      @include size(20px,30px);
      font-family: fontello;
      content: '\e81e';
      color: $black;
      display: inline-block;
      padding: 12px 8px;
      z-index: -1;
    }
    .form-ctrl {
      @include appearance-none;
      @include border-radius(0);
      background:transparent;
      width: 300px;
      display: inline-block;
      padding: 18px 0 16px 16px;
      font-size: 13px;
      color: $black;
      font-weight: 300;
      min-height: 53px;
      border: 1px solid $grey-light-blog;
      z-index: 1;
      &:focus{
        outline: rgba(255, 255, 255, 0.4) solid 1px;
      }
      @include tablet {
        width: calc(100% - 90px);
        text-align: right;
      }
    }
    label {
      font-size: 18px;
      font-weight: 600;
      padding-right: 12px;
    }
    @include tablet {
      display: block;
      float: none;
      text-align: left;
    }
  }
  .read-more {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 0;
  }
  .more-blogs-block {
    padding-top: 24px;
    .list-entries {
      margin: 0;
    }
  }
}



@import "list-entry-default";




//Cabecera de apertura cuando es la HOME general

.blog-header--home {
  /*@include center;*/
  background: $white;
  color: $black;
  text-align: center;
  border-bottom:2px solid $grey-webmap;
  margin-top: 72px;
  @include tablet-big {
	padding-top: 0;
  }
  .blog-header__info {
    padding: 40px 20px;
    .row, .blog-header__text {
      padding: 0 20px;
      .social-list, .blog-header__tags {
        padding: 0;
      }
      @include tablet-big {
        display: none;
      }
    }
    .blog-header__text {
      padding:0px;
      margin-bottom: 30px;
    }
    @include tablet-big {
      padding: 0;
    }
  }
  .blog-header__tags_rrss{
    margin-bottom: 20px;
    @include tablet-big {
      display: none;
    }
   @include mobile{
      display: none;
    }
    @include mobile-small{
      display: none;
    }
  }
  .blog-header__tablet{
    font-family: $font_bentonsansBold;
    padding-left: 30px;
    h2{
      font-size:24px;

    }
  }
  .blog-header__mobil{
    @include tablet-big {
      margin-left:-30px;
      margin-right:-30px;
      margin-top:35px;
    }
   
  }

}

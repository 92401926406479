//Item de listado de blogs cuando es blog individual

.blog-list {
  &.blog-list__solo {
	.blog-list__container {
	  .blog-item {
		@include size(32%,auto);
		max-width: 375px;
		.author__info {
		  padding: 24px 12px;
		  border-bottom: 1px solid $grey-light-blog;
		  .author__picture {
			@include size(72px,72px);
			display: inline-block;
			margin: 0 24px 0 0;
			img {
			  @include size(100%,100%);
			  @include border-radius(50%);
			}
		  }
		  p {
			width: calc(100% - 96px);
			display: inline-block;
			float: right;
			margin: 0;
		  }
		  @include tablet-big {
			padding: 24px 51px;
		  }
		  @include tablet-portrait {
			padding: 24px 12px;
		  }
		}
		@include tablet-big {
		  width: 100%;
		  max-width: 636px;
		  height: auto;
		}
	  }
	}
  }
}

@font-face {
  font-family: fontello;
  src: url(../fonts/fontello.eot);
  src: url(../fonts/fontello.eot#iefix) format('embedded-opentype'), url(../fonts/fontello.woff2) format('woff2'), url(../fonts/fontello.woff) format('woff'), url(../fonts/fontello.ttf) format('truetype'), url(../fonts/fontello.svg#fontello) format('svg');
  font-weight: 400;
  font-style: normal;  
  font-display: swap;
}

[class*=" icon-"]:before, [class^=icon-]:before {
  font-family: fontello;
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility
}


 
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-scroll-hand:before { content: '\e800'; } /* '' */
.icon-pause:before { content: '\e801'; } /* '' */
.icon-lock:before { content: '\e802'; } /* '' */
.icon-unlock:before { content: '\e803'; } /* '' */
.icon-impresora:before { content: '\e804'; } /* '' */
.icon-warning:before { content: '\e805'; } /* '' */
.icon-export:before { content: '\e806'; } /* '' */
.icon-descargar:before { content: '\e807'; } /* '' */
.icon-informacion:before { content: '\e808'; } /* '' */
.icon-download:before { content: '\e81b'; } /* '' */
.icon-right:before { content: '\e81c'; } /* '' */
.icon-left:before { content: '\e81d'; } /* '' */
.icon-down:before { content: '\e81e'; } /* '' */
.icon-up:before { content: '\e81f'; } /* '' */
.icon-search:before { content: '\e820'; } /* '' */
.icon-play:before { content: '\e821'; } /* '' */
.icon-linkedin:before { content: '\e822'; } /* '' */
.icon-twitter:before { content: '\e823'; } /* '' */
.icon-facebook:before { content: '\e824'; } /* '' */
.icon-whatsapp:before { content: '\e825'; } /* '' */
.icon-ok:before { content: '\e826'; } /* '' */
.icon-clock:before { content: '\e827'; } /* '' */
.icon-open:before { content: '\e828'; } /* '' */
.icon-audio:before { content: '\e829'; } /* '' */
.icon-interview:before { content: '\e82a'; } /* '' */
.icon-single-play:before { content: '\e82b'; } /* '' */
.icon-single-audio:before { content: '\e82c'; } /* '' */
.icon-attach:before { content: '\e82d'; } /* '' */
.icon-delete:before { content: '\e82e'; } /* '' */
.icon-bars:before { content: '\e830'; } /* '' */
.icon-download2:before { content: '\e831'; } /* '' */
.icon-gallery:before { content: '\e832'; } /* '' */
.icon-user:before { content: '\e833'; } /* '' */
.icon-spin:before { content: '\e834'; } /* '' */
.icon-play-fill:before { content: '\e835'; } /* '' */
.icon-sound:before { content: '\e836'; } /* '' */
.icon-muted:before { content: '\e837'; } /* '' */
.icon-blogs:before { content: '\e838'; } /* '' */
.icon-place:before { content: '\e839'; } /* '' */
.icon-open2:before { content: '\e83a'; } /* '' */
.icon-activity:before { content: '\e83b'; } /* '' */
.icon-news:before { content: '\e83c'; } /* '' */
.icon-publications:before { content: '\e83d'; } /* '' */
.icon-list:before { content: '\e83e'; } /* '' */
.icon-mosaic:before { content: '\e83f'; } /* '' */
.icon-cancel:before { content: '\e840'; } /* '' */
.icon-benefit:before { content: '\e841'; } /* '' */
.icon-calls:before { content: '\e842'; } /* '' */
.icon-calendar:before { content: '\e843'; } /* '' */
.icon-right-direction:before { content: '\e844'; } /* '' */
.icon-left-direction:before { content: '\e845'; } /* '' */
.icon-top-direction:before { content: '\e846'; } /* '' */
.icon-bottom-direction:before { content: '\e847'; } /* '' */
.icon-user-2:before { content: '\e848'; } /* '' */
.icon-event:before { content: '\e849'; } /* '' */
.icon-youtube:before { content: '\e84a'; } /* '' */
.icon-soundcloud:before { content: '\f089'; } /* '' */
/*
   Animation example, for spinners
*/
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

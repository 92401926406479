$media-desktop: 1240px;
$media-desktop-small: 1024px;
$media-tablet-big: 991px;
$media-tablet: 768px;
$media-tablet-portrait: 576px;
$media-mobile-landscape: 480px;
$media-tablet-plus: 414px;
$media-mobile-landscape2: 375px;
$media-mobile-portrait: 320px;

@mixin retina {
    @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}

@mixin mobile-small {
    @media all and (max-width: $media-mobile-portrait) {
        @content;
    }
}

@mixin mobile {
    @media all and (max-width: $media-mobile-landscape) {
        @content;
    }
}
@mixin mobile-medium {
    @media all and (max-width: $media-mobile-landscape2) {
        @content;
    }
}

@mixin tablet-portrait {
    @media all and (max-width: $media-tablet-portrait) {
        @content;
    }
}

@mixin tablet-plus {
    @media all and (max-width: $media-tablet-plus) {
        @content;
    }
}

@mixin tablet {
    @media all and (max-width: $media-tablet) {
        @content;
    }
}


@mixin tablet-big {
    @media all and (max-width: $media-tablet-big) {
        @content;
    }
}

@mixin desktop-small {
    @media all and (max-width: $media-desktop-small) {
        @content;
    }
}

@mixin desktop {
    @media all and (min-width: $media-desktop) {
        @content;
    }
}

//---------------
//$$ Screen
//---------------

@mixin screen-desktop {
    @media all and (max-width: $desktop - 1px) {
        @content;
    }
}
// @include screen-desktop { ... }

@mixin screen-desktop-small {
    @media all and (max-width: $desktop-small - 1px) {
        @content;
    }
}
// @include screen-desktop-small { ... }

@mixin screen-tablet {
    @media all and (max-width: $tablet-landscape - 1px) {
        @content;
    }
}
// @include screen-tablet { ... }

@mixin screen-mobile {
    @media all and (max-width: $mobile-landscape - 1px) {
        @content;
    }
}

// @include screen-mobile { ... }

//
//@mixin tablet {
//    @media screen and (min-width: $media-tablet) and (max-width: $media-desktop - 1px) {
//        @content;
//    }
//}
//
//@mixin mobile {
//    @media screen and (max-width: $media-mobile-portrait - 1px) {
//        @content;
//    }
//}
//
//@mixin mobile-landscape {
//    @media screen and (min-width: $media-mobile-portrait) and (max-width: $media-mobile-landscape - 1px) {
//        @content;
//    }
//}

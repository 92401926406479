//Entrada principal o destacada de la home
.entrada-destacada {
  margin-top: 3%;
  margin-bottom: 3%;
}
.resto-entradas {
  padding-top: 2%;
  border-top: 1px solid $grey-light-blog-2;
   @include tablet{
     border-top:none;

   }
}

.main-entry {
  text-align: center;
  font-family: $font_bentonsansMedium;
  font-size: 18px;
  @include desktop-small {
    font-size: 20px;
  }
  @include tablet-big {
    font-size: 20px;
    background-color: $grey-light-blog-2;
    padding: 32px 10% 40px;
  }

  @include tablet-portrait {
    padding: 32px 15px 40px;
    font-size: 18px;
  }
  .main-entry {
    &__date {
      color: $grey-mid-blog;
      padding-bottom: 10px;
      font-size: 18px;
    }
    &__title {
      font-size: 30px;
      font-family: $font_bentonsansBold;
      font-weight: 700;
      line-height: 1.33;
      margin: 0;
      padding-bottom: 18px;
      @include tablet-big {
        font-size: 32px;
      }
    }
    &__image {
      width: 100%;
    }
    &__info {
      line-height: 1.5;
      padding-top: 3%;
    }
    &__author {
      a {
        color: $blue;
        font-weight: 600;
      }
    }
  }
}

.language--select {
	width: 50px;
	margin-right: 24px;
	position: relative;

	ul {
		@include appearance-none;
		width: 100%;
		position: absolute;
		border: none;
		cursor: pointer;
		margin: 0;
		padding: 0;
		list-style: none;
		outline: none !important;
		li {
			display: none;
			padding: 0 5px;
			&.active {
				display: block;
				color: $black !important;
				background-color: $white !important;
				&:after {
					@include absolute($t:7px,$r:0);
					@include size(15px,10px);
					font-family: fontello, sans-serif;
					content: '\e81e';
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					outline: none !important;
				}
			}
			&:hover {
				color: $white;
				background-color: $grey-mid-2;
			}
		}
		&:focus {
			outline: none !important;
		}
		&:hover {
			color: $black;
			li {
				display: block;
			}
		}
	}

	&.fixed {
		display: inline-block;
		width: 60px;
		ul {
			margin-top: -10px;
			li {
				font-size: 16px;
				margin-bottom: 6px;
				text-align: left;

				&.active:after {
					top: 4px;
				}

				a {
					padding-right: 18px;
				}

			}
		}
	}
}

.navigation-block {
  .nav {
	&--menu, &--link {
	  display: block;
	}
	&--menu {
	  position: relative;
	  display: flex;
	  align-items:center;
	  justify-content: space-between;
	  height: 50px;
	  margin-bottom: 24px;
	  color: $grey-mid-2;
	  .logo {
			margin: 0px 0px 0px 0px;
			img {
				width:73%;
			}
			&:after {
				display:none;
			}
	  }
	  .nav--menu--container {
		font-size:13px;
		width: calc(100% - 177px);
		display: flex;
		justify-content: flex-end;

		.private-area {
		  margin-right: 24px;
		  display: none;
		  &:hover{
			color:$black;
		  }
		  &:before {
			display: inline-block;
			font-family: fontello;
			content: '\e833';
			padding-right: 8px;
			font-size: 16px;
			font-weight: $bold;
			color:$black;
		  }
		}
		.icon-search, .icon {
		  @include size(20px,20px);
		  overflow: hidden;
		  position: relative;
		  display: inline-block;
		  vertical-align: middle;
		  margin: 0 .2em;
		  color:$black;
		  &:before {
			@include translate(-50%, -50%);
			@include absolute($t:50%, $l:50%);
			margin: 0;
			font-size: 1em

		  }
		}
		.search-field {
		  @include absolute($t:0,$l:auto,$r:45px);
		  display: none;
		  background: $white;
		  height: 100%;
		  z-index: 2;
		  padding: 7px 20px 7px 0;

			@include mobile {
				width: 250px;
				padding: 7px 20px;
				left: 0;
			}

		  &.show {
			display: block !important;
			right: 80px;
			left: auto;
			min-width: 200px;
			&[style] {
			  @include opacity(0);
			}
			&.no-ctrl-nav {
			  right: 30px
			}
		  }
		  .form-ctrl {
			display: block;
			width: 100%;
			border: 0;
			padding: 8px 5px;
			border-bottom: 1px solid $grey-light;
			outline: 0;
			min-width: 200px
		  }
		}
		.crtl-nav, .ctrl-search {
		  display: inline-block;
		  vertical-align: middle;
		  cursor: pointer;
		  &:hover {
			color:$black;
		  }
		}
		.ctrl-search {
		  font-size: 18px;
		}
		.ctrl-nav-top {
		  @include size(27px, 19px);
		  position: relative;
		  margin-left: 25px;
		  span {
			@include size(27px, 3px);
			display: block;
			background: $black;
			margin-bottom: 5px;
			&:first-child {
			  //@include transition(all, .3s, ease, 8s);
			}
			&:nth-child(2) {
			  //@include transition(all, .3s, ease, 6s);
			}
			&:last-child {
			  //@include transition(all, .3s, ease, 4s);
			  margin-bottom: 0;
			}
		  }
		  &:after, &:before {
				@include size(0, 3px);
				@include absolute();
				content: "";
				display: block;
				background: $black;
		  }
		  &:before {
				@include transform(rotate 45deg);
				@include transform-origin(0 0);
				@include transition(all, .3s, ease, 2s);
				top: -1px;
				left: 5px;
		  }
		  &:after {
				@include transform(rotate -45deg);
				@include transform-origin(0 100%);
				@include transition(all, .3s, ease);
				left: 5px;
				bottom: -1px;
		  }
		  &.active {
				span {
					width: 0;
					&:first-child {
						@include transition(all, .3s, ease);
					}
					&:nth-child(2) {
						@include transition(all, .3s, ease, .2s);
					}
					&:last-child {
						@include transition(all, .3s, ease, .4s);
					}
					&:before {
						width: 27px;
						@include transition(all, .3s, ease, .6s);
					}
					&:after {
						width: 27px;
						@include transition(all, .3s, ease, .8s);
					}
				}
		  }
		  &:hover {
			cursor: pointer;
		  }
		}
		@include tablet-portrait {
		  margin-bottom: 0;
		}
		@include desktop-small {
		  width: 100%;
		}
	  }
	  @include tablet-portrait {
		margin: 0;
	  }
	}
	&--links {
	  #menuIzq {
			display: inline-block;
			width: 100%;
			margin-left: 0;
			font-weight: $regular;
			&:after {
				@include tablet-portrait {
				margin-top: 0;
				margin-bottom: 14px;
				left:50%;
				}
			}
	  }
	  @include screen-desktop {
		width: 57%;
	  }
	  @include tablet {
		width: 100%;
	  }
	}
  }
}

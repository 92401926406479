//Hay que activar los js de bootstrap en el layout para que esto funcione

.modal-dialog {
  &.modal-dialog-gallery {
	max-width: 800px;
	@include tablet-big {
	  max-width: inherit;
	}
	.slick-list {
	  max-height:657px;
	  @include tablet-big {
		max-height: inherit;
	  }
	}
	.modal-header {
	  background: none;
	  border-bottom: none!important;
	  justify-content: flex-end;
	  margin-bottom: 8px;
	  .close {
		position: relative;
		text-align: right;
		margin: -12px;
		box-shadow: none;
		outline: none;
		background: transparent;
		border: none;
		.icon-close {
		  @include absolute($t:9px,$r:5px);
		  @include size(27px,19px);
		  display: inline-block;
		  vertical-align: middle;
		  margin-left: 0;
		  cursor: pointer;
		  &:before, &:after {
			content: "";
			display: block;
			@include size(27px,3px);
			background: $black;
			position: absolute;
		  }
		  &:before {
			@include transform(rotate(45deg));
			@include transform-origin(0 0);
			@include transition(all,.3s,ease, .2s);
			top: -1px;
			left: 2px;
		  }
		  &:after {
			@include transform(rotate(-45deg));
			@include transform-origin(0 100%);
			@include transition(all,.3s,ease);
			left: 5px;
			bottom: -1px;
		  }
		}
	  }
	}
	.modal-body {
	  padding: 0 1rem;
	  .modal-gallery__container {
		mmargin-bottom: 16px;
	  }
	  .modal-gallery {
		position: relative;
		.ctrls-gallery {
		  @include absolute($t:16px,$r:16px);
		  padding: 12px 8px;
		  background-color: $black;
		  .slick-next, .slick-prev {
			&:before {
			  color:$white;
			}
		  }
		  .slick-dots {
			li {
			  button {
				border-color: $white;
				background-color: $white;

			  }
			  &.slick-active {
				button {
				  background-color: $black;
				}
			  }
			}
		  }
		}
		.main-image {
		  margin: 0;
		  img {
			padding-bottom: 8px;
		  }
		}
	  }
	}
  }
}




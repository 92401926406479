.filtro {
  background-color: $black;

  & p {
    font-size: 14px;
    line-height: 2;
    color: $grey-mid-blog;
    pointer-events: none;
    text-transform: uppercase;
    margin-bottom: 0.2rem;
  }

  & .rowFiler1 {
    z-index: 1;
  }

  & .rowFiler2 {
    display: flex;
    z-index: 20;
    .closeCombo{
      text-align: right;
      cursor: pointer;
      margin: 10px 20px;
    }
  }
  & .rowFiler3 {
    margin-top: 10px;
    align-content: flex-start;
  }

  .advancedSearch {
    text-align: right;
    padding: 20px 5px;
    color: $grey-mid-blog;
    font-family: $font_bentonsansBold;
    font-size: 13px;

    a {
      color: $grey-mid-blog !important;
    }
  }

  & .form-inline {
    align-items: flex-start;
  }

  & .formGroup_text {
    position: relative;
    margin-bottom: 6px;

    & .formTextLabel {
      font-size: 14px;
      line-height: 2;
      color: $grey-mid-blog;
      pointer-events: none;
      text-transform: uppercase;
    }

    & .placehold {
      position: absolute;
      left: 15px;
      top: 10px;
      line-height: 1;

      & #titulo {
        width: 45%;
      }

      & input.formText {
        color: $grey-mid-blog;

        &:focus {
          outline: none;
          color: $grey-mid-blog;
          outline: 0;
          //box-shadow: 0px 0px 1px 1px $black;
        }

        &:focus~.placehold
        /*, &:valid ~ label*/
        {
          top: 5px;
          font-size: 12px;
          line-height: 1.08;
          color: $grey-mid-blog;
          transition: top 0.35s ease-in-out;
        }
      }
    }

    & .formText {
      width: 100%;
      height: 55px;
      background: $dark-grey;
      border: none;
      border-radius: 1px;
      border-bottom: 1px solid $grey-mid-blog;
      font-size: 18px;
      padding: 0 15px;
      color: $grey-mid-blog;
    }
  }
}

.formGroup_text:nth-child(2) {
  flex: 1 0 250px;
}

.title_busqueda {
  font-size: 35px;
  font-family: Oswald, sans-serif;
  color: white;
  font-weight: bolder;
  padding: 20px 0;
}

/* FILTRO 2 : criterio + texto_busqueda + operador + delete-filter */
.filtro2 {
  padding-left: 0;
  z-index: 3;
  margin-top: 28px;

  .operador {
    flex: 0 1 100px;
  }

  .texto_busqueda {
    flex-grow: 1;
    margin-right: 10px;
    align-self: flex-end;
  }
}

.criterio,
.idioma,
.fechas {
  flex: 0 190px;
  margin-right: 10px;
}

.delete-filter {
  align-self: flex-end;
  padding: 22px 30px;
  opacity: 0;
  &:hover {
    cursor: pointer;
  }
  &--visible{
    opacity: 1;
  }
  .icon-cancel{
    color: white;
  }
}

.label-left {
  align-self: center;
  padding-right: 4px;
  color: $grey-mid-blog;
  margin-top: 13px;
}

/* FILTRO 3 . formato + fechas + anyos + area + subarea */
.filtro3 {
  margin-top: 0px;
  .formato,
  .anyos,
  .fechas,
  .area,
  .subarea{
    z-index: 2;
    flex: 0 187px;
    position:relative;
    margin-right: 10px;
    padding-top:31px;
    &.lastitem {
      margin-right:0px;
    }
  }
 
  .anyos1-2 {
    padding-top:0px;
    margin-right:10px;
  }
  .selectBtn {
    margin-bottom:5px;
    padding: 16px 0px 20px 20px;
    &:after {
      margin-top:-3px;
      right: 20px;
      left:inherit;
    }
    &.arrowRotate {
      &:after {
        margin-top:0px;
      }
    }
  }
}

#formato.capaId {
  left:8px;
  .contentId {
    &:before {
      left:11px;
    }
    &:after {
      left:10px;
    }
  }
}
.area {
  #area.capaId {
    left:-208px;
    .contentId {
      &:before {left:261px;}
      &:after {left:260px;}
    }
  }
  &.openrango {
    #area.capaId {
      left:-411px;
      .contentId {
        &:before {left:3421px;}
        &:after {left:420px;}
      }
    }
  }
}
#subarea.capaId {
  left:-410px;
  .contentId {
    &:before {left:429px;}
    &:after {left:428px;}
  }
}
#anyo1-2 {
  flex-grow: 1;

  .anyos1-2 {
    display: flex;
    flex-grow: 1;

    .anyo-init {
      flex-grow: 1;
      margin-right: 10px;
    }

    .anyo-fin {
      flex-grow: 1;
    }
  }
}
.anyos {
  flex-grow: 1;
}

/* FILTRO 4 . coleccion + idioma */
.filtro4 {
  margin-top: 28px;
  z-index: 1;
}
.idioma {
  flex: 0 225px;
  margin-right: 10px;
}

.coleccion {
  flex: 0 460px;
  margin-right: 10px;
}

/* comunes filtro */
.formatosCheck {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 10px;
  border-bottom: 1px solid $grey-mid-blog;

  &.catalogo {
    display: block;
    column-count: 3;

    .area-division, .area-subdivision {
      column-span: all;
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
    }

    .checkContainer {
      min-height: 20px;
      font-size: 13px;
      line-height: 1.2em;
      break-inside: avoid;
    }
  }

  p {
    flex: 0 100%;
    margin-bottom: 10px;
  }

  .checkContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    padding-right:5px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    line-height: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex: 0 1 33.33%;
  }

  /* Hide the browser's default checkbox */
  .checkContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .checkContainer:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .checkContainer input:checked ~ .checkmark {
    background-color: $dark-grey;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkContainer input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkContainer .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.btnAplicar {
  text-align: right;
  & a.btnCheck {
    background-color: $white;
    color: $dark-grey !important;
    margin: 20px;
    border-radius: 0px;
    text-transform: uppercase;
  }
}
.filterSearch {
  text-align: right;

  & .btnBuscar {
    background-color: $white;
    color: $dark-grey !important;
    margin: 20px;
    border-radius: 0px;
    padding: 15px 60px;
    text-transform: uppercase;
  }
  & .restablecer {
    color: $grey-mid-blog;
    cursor: pointer;
    &:hover {
      color: $white;
    }
  }
  img.restartIcon {
    width: 15px;
    display: inline-block;
    margin-right: 5px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

/* ********** switch button ************* */

.switchAll {
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 1px;
    background-color: $grey-mid-blog;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: white;
  }

  input:focus + .slider {
    //box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

/*  *************** custom select negro filtro************ */

$select-color: #fff;
$select-width: 100%;
$select-height: 55px;

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: $select-color;
  width: $select-width;
  height: $select-height;
}
.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $dark-grey;
  padding: 15px;
  height: 55px;
  border-bottom: 1px solid #7f7f7f;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $font_bentonsansRegular;
  &:after {
    position: absolute;
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    right: 20px;
    margin-top: -3px;
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    -webkit-transform: rotate(45deg) translateY(-50%);
    transform: rotate(45deg) translateY(-50%);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    z-index: 11;
  }
  &:hover {
    background-color: $black;
  }
  &.active {
    background-color: $black;
    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 10px;
      height: 10px;
      top: 50%;
      right: 20px;
      margin-top: -3px;
      border-bottom: 1px solid #ffffff;
      border-right: 1px solid #ffffff;
      transform: rotate(-135deg) translateY(-50%);
      transition: all 0.4s ease-in-out;
      transform-origin: 50% 0;
    }
  }
}
.select-options {
  display: none;
  position: absolute;
  top: 55px;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $dark-grey;
  max-height: 300px;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width: 12px;
    background-color: #F5F5F5;
  }
    &::-webkit-scrollbar-track{    
      background-color: #CCCCCC;
  }
    &::-webkit-scrollbar-thumb{
      background-color: #FFF;    
  }
  li {
    margin: 0;
    padding: 12px 0 12px 12px;
    border-top: 1px solid $grey-blog-light;
    text-transform: uppercase;
    font-size: 14px;
    font-family: $font_bentonsansRegular;

    &:hover {
      color: $white;
      background: $black;
    }
    &[rel="hide"] {
      display: none;
    }
    &.seleccionado {
      background: $black;
      color: white;
      &:hover {
        cursor: default;
      }
    }
  }
}

#operadoridul li[rel="noOperador"] {
  display:none;
}
/*  *************** custom select blanco movil ************ */
.contListResult {
  .ordenFiltro {
    display: none;
  }
  .ordenarSelect {
    display: none;
  }

  .select-styled {
    background-color: $white;
    color: $black;
    border-bottom: none;
    &:before {
      content: "\2193";
      position: absolute;
      right: 44px;
      bottom: 17px;
    }
    &:after {
      border-bottom: 1px solid $black;
      border-right: 1px solid $black;
    }
    &:hover {
      background-color: $white;
    }
    &:active,
    &.active {
      background-color: $white;
      &:after {
        border-bottom: 1px solid $black;
        border-right: 1px solid $black;
      }
    }
  }
  .select-options {
    background-color: $white;
    color: $black;
  }
}

.lista-resultados {
  & .lst-titulo {
    font-family: Oswald, sans-serif;
    font-weight: bolder;
    font-size: 16px;
    text-transform: uppercase;
    padding: 20px 0;
    border-bottom: 2px solid $grey-blog-light;

    & .orderArrowDown {
      opacity: 0.3;
      &:hover,
      &.active {
        opacity: 1;
      }
      &:after {
        content: "\25bc";
        width: 20px;
        height: 10px;
        color: $black;
        font-size: 10px;
        position: absolute;
        right: 0;
        bottom: 4px;
        cursor: pointer;
      }
      &:hover {
        opacity: 1;
      }
    }

    & .orderArrowUP {
      opacity: 0.3;
      &:hover,
      &.active {
        opacity: 1;
      }
      &:after {
        content: "\25b2";
        width: 20px;
        height: 10px;
        color: $black;
        font-size: 10px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }

  & .item-lst-results {
    & .title-mobil {
      display: none;
    }

    & .lst-results {
      &__img {
        img {
          width: 100%;
        }
      }

      &__title {
        font-weight: bolder;
      }
      &__autor {
      }
      &__fecha {
        text-transform: capitalize;
      }

      &__area {
      }
      &__subarea {
      }
      &__formato {
      }
      &__coleccion {
      }
      &__descarga {
        display: flex;
        flex-direction: column;
        &__link{
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 21px;
          .icon-descargar{
            margin-top: 5px;
          }
        }
        img {
          width: 20px;
        }
      }
    }
  }
}

/****************************************************
                   responsive  
*****************************************************/

@media all and (max-width: $desktop-small) {
  .header-catalogo {
    border-bottom: 1px solid #000;
  }
  .filtro {
    .filtro3 {
      .formato,
      .fechas,
      .anyos,
      .area,
      .subarea{
        z-index: 2;
        flex: 0 136px;
        margin-right: 10px;
        position:relative;
      }
      #formato.capaId {
        left:8px;
        .contentId {
          &:before {left:11px;}
          &:after {left:10px;}
        }
      }
      #area.capaId {
        left:-315px;
        .contentId {
          &:before {left:361px;}
          &:after {left:360px;}
        }
      }
      #subarea.capaId {
        left:-454px;
        .contentId {
          &:before {left:461px;}
          &:after {left:460px;}
        }
      }
    }
  }
}

@media all and (max-width: $media-tablet) {
  .filtro {
    .label-left {
      display: none;
    }
    .filtro3 {
      margin-top: 0px;
      .formato,
      .area,
      .subarea{
        flex: 0 100%;
        margin-right: 0px;
        padding-top:12px;
      }
      .anyos,
      .fechas{
        flex: 0 48%;
        margin-right: 5px;
        padding-top:12px;
        z-index: 10;
      }
      #anyo1-2 {
        .anyos1-2 {
          padding-top:12px;
          .anyo-init {
            margin-right: 5px;
          }
        }
      }
      #formato.capaId,
      #area.capaId,
      #subarea.capaId {
        position: relative;
        left:0px;
        width: 100%;
        border:0px;
        .contentId {
          &:before {display:none;}
          &:after {display:none;}
        }
        .formatosCheck  {
          .checkContainer {
            flex: 0 1 50%;
          }
        }
      }
    }
  }
}

@media all and (max-width: $media-tablet-plus) {
  .filtro {
    .criterio,
    .coleccion,
    .idioma{
      flex: 1 0 100%;
    }
    .filtro3 {
      margin-top: 0px;
      .formato,
      .anyos,
      .fechas,
      .area,
      .subarea{
        flex: 0 100%;
        margin-right: 0px;
      }
    }
    p.title_busqueda {
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 1px;
    }
  }
}
//Carrusel de los posts más leidos.

.most-readed {
  background: $grey-light-blog-2;
	padding: 40px 20px;

		@include mobile{
			background-color:$white;
			border-bottom:1px solid $grey-webmap;
		}

	& .ctrls-gallery{
		text-align: right !important;
		margin-top:50px;

		@include mobile{
			text-align:center !important;
			margin-right: 0px;
		}

		@include mobile{
			text-align:center !important;
			margin-right: 0px;
		}
		@include mobile-small{
			text-align:center !important;
			margin-right: 0px;
		}
	}
  .most-readed {
	&__title {
	  font-family: $font_bentonsansBold;
	  font-size: 21px;
	  font-weight: 700;
	  text-transform: uppercase;
	  margin: 0;
	  padding: 0 0 24px;
	  @include tablet-big {
		width: 100%;
		max-width: 636px;
	  }
	}
	&__container {
	  //display: flex;
	  //justify-content: space-between;
	  //border-bottom: 1px solid $grey-light-blog;
	}
	&__item {
	  width: 282px;
	  @include transition(all,.3s,ease);
	  background-color: $white;
	  display: inline-block;
	  margin-right: 20px;
		background: $white;
		@include mobile-medium{
			width: 278px !important;
		}
		@include mobile-small{
			width: 230px !important;
		
		}

	  .main-image {
		@include size(100%,140px);
		padding: 0;
		margin: 0;
		background-size: cover;

	  }
	  .last-post {
		padding: 16px;
		font-family: bentonsans, Helvetica, Arial, Sans-serif;
		.post {
		  &__date {
			display: block;
			font-size: 14px;
			color: $grey-mid-blog;
			padding-bottom: 6px;
		  }
		  &__title {
			font-size: 18px;
			font-weight: 600;
			line-height: 1.33;
			margin: 0;
		  }
		  &__author {
			a {
			  color:$blue;
			  font-weight: 600;
			}
		  }
		}
		@include tablet-big {
		  padding: 24px 51px;
		}
		@include tablet-portrait {
		  padding: 24px 12px;
		}
	  }
	  &.slick-current {
		background: white;
	  }
	  @include tablet-big {
		background-color: rgba(0, 0, 0, 0.2);
	  }
	}
  }
}



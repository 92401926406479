.ayudas {

  header {

    .banner {

      .overlay {
        justify-content: flex-start;
        color: #fff;

        .overlay-content {

          width: 95%;
          max-width: 1200px;
          margin: 0 auto;
          padding-top: 50px;

          p {
            font-size: 20px;
            margin-bottom: 10px;
          }

          .title {
            font-size: 32px;
            font-family: bentonsansBold, sans-serif;
            font-weight: bold;
            margin-top: 0;
            width: 80%;

            @media screen and (max-width: 600px ) {
              font-size: 28px;
              width: 100%;
            }

            .subtitle {
              display: block;
              margin-top: 0px;

              &.bold {
                font-family: bentonsansBold, sans-serif;
              }
            }
          }

          .title-container {
            flex-basis: 50%;

            h1 {
              width: 100%;
            }
          }

          .date-container {
            display: flex;
          }

        }
      }

    }

    .navigation {

      padding: 20px 0;

      nav {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1200px;
        width: 95%;

        @media screen and ( max-width: 600px ) {
          flex-wrap: wrap;
        }

        .btn, .btn-dropdown {
          @media screen and ( max-width: 600px ) {
            display: block;
            width: 100%;
            margin-bottom: 8px;
          }
        }

        .nav-left {

          @media screen and ( max-width: 600px ) {
            width: 100%;
            margin-bottom: 10px;
            text-align: center;
          }

          & > button, button {
            min-width: 250px;
            text-align: left;
          }

          .btn-dropdwon-items {

            @media screen and ( max-width: 600px ) {
              top: calc( 100% - 8px );
            }

            li {
              text-align: left;

              &:hover {
                background-color: #fff;

                a {
                  color: $blue-dark;
                  width: 100%;
                  display: block;
                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }
          }

          .desplegable {
            .btn {
              min-width: auto;
            }
          }

        }

        .nav-right {

          @media screen and ( max-width: 600px ) {
            width: 100%;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;

            @media screen and ( max-width: 600px ) {
              flex-wrap: wrap;
            }

            li {
              border: solid 1px #999;
              font-size: 14px;

              @media screen and ( max-width: 600px ) {
                width: 100%;
                text-align: center;
                margin-bottom: 5px;
              }

              a {
                display: block;
                padding: 10px;
                text-decoration: none;
                transition: all .25s;

                &:hover {
                  background-color: #f3f3f3;
                }
              }
            }
          }

        }

      }

    }

    .ayuda-status {
      padding: 10px;

      &.open {
        background-color: #3C9FD6;
      }

      &.closed {
        background-color: #ff0000;
      }

      p {
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        margin: 0;
        font-size: 20px;
      }
    }

  }

  article {

    display: flex;

    @media screen and ( max-width: 600px ) {
      flex-wrap: wrap;
    }

    aside {

      width: 34%;
      display: flex;
      justify-content: flex-end;
      background-color: #f5f5f5;
      padding: 30px 20px;

      @media screen and ( max-width: 1600px ) {
        width: 26%;
      }
      @media screen and ( max-width: 768px ) {
        width: 30%;
      }
      @media screen and ( max-width: 600px ) {
        justify-content: flex-start;
        padding: 20px;
        width: 100%;
      }

      .title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 5px;
      }

      .subtitle {
        font-size: 14px;
      }

      a {
        color: $blue;
      }

      .aside-content {

        width: 250px;

        @media screen and ( max-width: 768px ) {
          width: 100%;
        }

        .calendar {

          margin-bottom: 15px;

          p {
            margin: 0 0 5px;

            &.title {
              margin-bottom: 15px;
            }
          }

          .subtitle {

            position: relative;
            padding-left: 30px;

            &:before {
              content: "";
              border: solid 1px #aaa;
              border-radius: 100%;
              background-color: #ccc;
              width: 10px;
              height: 10px;
              position: absolute;
              top: 4px;
              left: 0;
            }

            &.on {
              font-family: bentonsansBold, sans-serif;

              &:before {
                background-color: $blue;
              }
            }
          }

          .calendar-date {
            padding: 0 0 10px 25px;
            border-left: solid 1px #ccc;
            font-size: 14px;
            line-height: 1.3em;
            margin-left: 5px;

            &:last-child {
              border: 0;
            }
          }
        }
      }

    }

    .body {

      width: 75%;
      background-color: #fafafa;
      display: flex;
      flex-wrap: wrap;
      padding: 30px 20px;

      @media screen and ( max-width: 600px ) {
        width: 100%;
      }

      header {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media screen and ( max-width: 1600px ) {
          max-width: 1000px;
        }
        @media screen and ( max-width: 600px ) {
          flex-wrap: wrap;
        }

        .title {
          margin-top: 0;
          text-transform: uppercase;
          font-size: 22px;

          @media screen and ( max-width: 600px ) {
            width: 100%;
          }
        }
        .social {
          display: flex;
          list-style: none;
          padding: 0;
          margin: 0;

          @media screen and ( max-width: 600px ) {
            margin-bottom: 20px;
          }

          li {
            margin: 0 10px 0 0;
            font-size: 22px;
          }
        }
      }

      .description {

        max-width: 700px;
        font-size: 18px;
        line-height: 1.6em;
        padding-right: 20px;

        @media screen and (max-width: 1400px) {
          max-width: 600px;
        }

        @include mobile {
          padding: 0;
          margin-bottom: 20px;
        }

        img, iframe {
          width: 100%;

          & + .footer-picture {
            font-style: italic;
            font-size: 14px;
          }
        }

        a {
          color: $blue;
        }
        
        h3, h4 {
          font-size: 18px;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 10px;
        }

        .grid-container {
          display: grid;
          grid-column-gap: 30px;
          grid-row-gap: 50px;

          &.three-cols {
            grid-template-columns: repeat(3, 1fr);
          }

          &.four-cols {
            grid-template-columns: repeat(4, 1fr);
          }

        }
      }

      .assets {
        width: 300px;
        background-color: transparent;

        @media screen and (max-width: 1400px) {
          max-width: 250px;
        }

      }

    }

  }

}
#table_body .tab-selector {
  margin: 0;
  font-size: 18px;
  line-height: 1.3em;
  font-weight: 600;
  cursor: pointer;
  display:block;
  margin-left: 3%;
  margin-top: 3%;
  position: relative;
  @media screen and (max-width: 700px) {
    font-size: 14px;
  }

  letter-spacing: 1px
}

.calendar-block .generic-timeline .infoybases {
  background: #009ed6;
  height: 175px;
  overflow: hidden;
  padding: 25px 20px;
  font-size: 14px;
  line-height: 1.2em;
  letter-spacing: 1px;
  position: relative
}

.generic--color--red{
  .calendarioinfo {  
    background-color:#af9a69!important;
    color:#fff;}

  #table_body .selected {
  background-color:#af9a69!important;
  color:#fff;
}}

#table_body .tab-selector {
  margin: 0;
  font-size: 18px;
  line-height: 1.3em;
  font-weight: 600;
  cursor: pointer;
  display:block;
  margin-left: 3%;
  margin-top: 3%;
  position: relative;
  @media screen and (max-width: 700px) {
    font-size: 14px;
  }

  letter-spacing: 1px
}
#table_body .selected {
  background-color:#009ed6;
  color:#fff;
}

li.selected {
  background-color:#009ed6;
  color:#fff;        
  &:hover {
    color:#000;    
  }
}

nav.infoybases{
  margin:0 !important;
  width: 100% !important;
}
table.infoybases{
  border-collapse: collapse;
  width:100%;
}
td.infoybases{
  width:20%;
  height:50px;
  @media screen and (max-width: 700px) {
    width:8%;
  }

}
.nav-left.tableinfo {

  @media screen and ( max-width: 600px ) {
    width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    @media screen and ( max-width: 600px ) {
      flex-wrap: wrap;
    }

    li {
      border: solid 1px #999;
      font-size: 14px;

      @media screen and ( max-width: 600px ) {
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
      }

      a {
        display: block;
        padding: 10px;
        text-decoration: none;
        transition: all .25s;

        &:hover {
          background-color: #f3f3f3;
        }
      }
    }
  }

}
.tab-titler{
  display: block;
  margin: 0;
  padding: 40px 0px 30px 0;
  font-size: 18px;
  line-height: 1.3em;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px
}
.paddingtabber{
  padding: 10px 30px 30px 30px;
}
.alertinfoybases{
  margin-top:10px;
  margin-bottom: 0px!important;
}

.tabulador{
  display: initial;
  justify-content: center;
  font-size: 17px;
  line-height: 21px;

  .main-list{
    justify-content: center;
    border-bottom: 1px solid #1a80c9;
    font-size: 17px;
    line-height: 21px;
    padding-top: 30px;
  }
  .tab-button{
    width: 100%;
    border: none;
    background: 0 0;
  }
  .tab-button.selected{
    border-bottom: 2px solid #1a80c9;
    color: #004481;
    font-weight: 700;
  }
}
.generic--color--red .tab-button.selected{
  border-bottom: 2px solid #af9a69;
  color: #8f7538;
}
.generic--color--red .main-list{
  border-bottom: 1px solid #af9a69;
}
.asideConvo{
  width: 20%;
  display: flex;
  height: fit-content;
  margin-top: 0;
  @media screen and (max-width: 970px) {
    width: 100%;        
    padding: 20px;
  }
  .aside-content{
    margin-top:7%;
  }
  .title{
    font-weight: 700!important;
    margin-top: 10%!important;
    font-size: 22px!important;
    margin-bottom: 15px!important;
    font-family: Oswald!important;
  }
  .content{
    padding-right: 20px!important;
    padding-left: 20px!important;
    width:80%;
    background-color: #f5f5f5;
    @media screen and (max-width: 700px) {
      width: 100%;        
    }
  }
}

.generic--color--red .btn-solicitar {
  background-color: #af9a69 !important;
  color: #fff; 
}

.generic--color--red .btn-solicitar:hover {
  background-color: #d3ad56 !important;
  color: #fff; 
}

.module-solicitar{
  padding: 20px;
}

.btn-solicitar {
  background-color: #009ed6 !important;
  color: #fff; 
  margin-top: -15px;
  @media only screen and (min-width: 700px) {
    margin-left: 20px;
  }
}
.btn-solicitar:hover {
  background-color: #007fff !important;
  color: #fff; 
}
.generic--color--red .subtitle.on:before{
  background-color: #af9a69 !important;
  color: #fff; 
}


.desplegableconv {
  text-align: center;
  border-radius: 5px;
  border: 1px solid #004481;
  font-size: 17px;
  height: auto;
  white-space: nowrap;
  background: #f6f6f6;
  text-decoration: none;    
  font-family: Arial, Helvetica, sans-serif;
  padding: .4em 1em;
  position: relative;
  user-select: none;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;    
  box-sizing: border-box;    line-height: 25px;
  color: #004481;
  font-weight: 700;display: block;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
  width: 100%;
  @media screen and (max-width: 700px) {
    display: block;
  }
}
.main-list{
  @media screen and (max-width: 700px) {
    display: none!important
  }
}
.dropdownConv {
  width: 100%;
  position: relative;
  display: inline-block;
}

.dropdown-contentConv {
  background-color: white;
  display: none;
  width: 100%;
  position: absolute;
  text-align: center;
  z-index: 1;
  text-align: center;
  color: #004481;
  font-family: Arial;
  margin-top: -5px !important;
  border: 1px solid #004481 !important;
  border-top: none !important;
}
.dropdown-contentConv{
a{
  padding: .4em 1em;
  background-color: white;
}}
.dropdown-contentConv a {
  display: block;
}

.dropdown-contentConv a:hover {background-color: #ddd;}

.dropdownConv:hover .dropdown-contentConv {display: block;}

.dropdownConv:hover .desplegableconv {background-color: #007fff;color: #fff;}

.generic--color--red .dropdownConv:hover .desplegableconv {background-color: #d3ad56!important;color: #fff!important;}

.generic--color--red .dropdown-contentConv{  
  color:#8f7538!important;
}

.generic--color--red .desplegableconv {
  color:#8f7538!important;
  border: 1px solid #8f7538!important;
}

.infoybasesBody{
  overflow-wrap: break-word;
  width: 80%;
  flex-wrap: wrap;
  padding-left: 20px;
  @media screen and (max-width: 970px) {
    width: 100%;
  }
}
.articleConv{
  @media only screen and (min-width: 970px) {
    display:flex;
  }
  @media screen and (max-width: 700px) {
    display: block;
  }
  .description{
    width: 100%!important;
  }
}

.page-title-info-bases{
  @media only screen and (max-width: 1100px) {
    margin-bottom: 0px!important;
    padding: 15px 0 !important;
  }
}

.conv-float{
  @media only screen and (min-width: 970px) {
  z-index: 2;
  width: 100%;
  margin-left: 80%;
  position: fixed;
  .content{
    width: 16%;
  }}
}
.generic--color--red .tabs .tab-selector.active:after, .generic--color--red .tabs .tab-selector:hover:after {
  background: #af9a69!important;
}

.listConvo{
  a{
    color:rgb(5,99,193);
  }
  font-family: Oswald!important;
  list-style-type: none;    
  font-weight: 400;
  font-size: 15px;
  /*font-size: 17px;*/
}

.conDesplegableNew{
  width: 18%;
  @media screen and (max-width: 700px) {
    width:70%;
  }
}
.desplegableNew{
    margin-top:-50px;
}
.separadorBecas{
  @media screen and (max-width: 700px) {
    height: 100px;
  }
}

.cd-top {
  z-index: 10;
  cursor: pointer;
  text-decoration: none !important;
  padding-top: 5px;
  font-weight: 700;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 40px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  background-color: #d4edfc;
  font-family: bbva_icons;
  color: #2a86ca;
  font-size: 34px;
  line-height: 40px;
  @media screen and (min-width: 700px) {
    right: 18%;
  }
  @media screen and (max-width: 700px) {
    right: 10px;
  }
}

.generic--color--red .cd-top {
  color:#d3ad56!important;
  background-color: #af9a69!important;
}
.sobre-los{
  @media screen and (min-width: 700px) {
    width: 25%;
       
  }}

.sobre-los.conv-float{
  @media screen and (min-width: 700px) {
  margin-left: 69%!important;
  .content{
    width: 55%!important;
  }
}}
//$$ Forms

.general-grid {
  display: block;
  position: relative
}

.general-grid .grid-gutter {
  width: .5%
}

.general-grid .grid-sizer {
  width: 33%
}

.general-grid .grid-item {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  border: 1px solid #dedede;
  padding: 0 20px;
  max-width: 378px;
  width: 33%;
  margin-bottom: 20px;
  -webkit-transition: all 0s ease-in-out;
  -moz-transition: all 0s ease-in-out;
  -ms-transition: all 0s ease-in-out;
  -o-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
  background: #fff;
  opacity: 0
}

.general-grid .grid-item[style] {
  opacity: 1
}

.general-grid .grid-item:first-child {
  position: relative;
  z-index: 1
}

.general-grid .grid-item:hover .cover-media img {
  -webkit-transform: translate3d(-50%, -50%, 0) scale(1.1);
  -moz-transform: translate3d(-50%, -50%, 0) scale(1.1);
  -ms-transform: translate3d(-50%, -50%, 0) scale(1.1);
  -o-transform: translate3d(-50%, -50%, 0) scale(1.1);
  transform: translate3d(-50%, -50%, 0) scale(1.1);
  transition: all .3s ease
}

.general-grid .cover-media img, .general-grid .grid-item:hover .cover-media:before {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.general-grid .grid-item:hover .cover-media:before {
  transition: all .3s ease;
  opacity: 1
}

.general-grid .cover-media {
  display: block;
  margin: 0 -20px;
  position: relative;
  height: 286px;
  overflow: hidden;
  z-index: 0
}

.general-grid .cover-media img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0) scale(1);
  -moz-transform: translate3d(-50%, -50%, 0) scale(1);
  -ms-transform: translate3d(-50%, -50%, 0) scale(1);
  -o-transform: translate3d(-50%, -50%, 0) scale(1);
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition: all .3s ease;
  max-width: initial;
  max-height: 100%
}

.general-grid .cover-media.audio:after, .general-grid .cover-media.gallery:after, .general-grid .cover-media.video:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .4);
  z-index: 0
}

.general-grid .cover-media.audio:before, .general-grid .cover-media.gallery:before, .general-grid .cover-media.video:before {
  font-family: fontello;
  font-weight: 400;
  font-size: 95px;
  line-height: 1em;
  width: 95px;
  height: 95px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  opacity: .6;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.general-grid .cover-text {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 15px;
  height: 286px;
  overflow: hidden
}

.general-grid .cover-text .text-info {
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 600
}

.general-grid .cover-text .text-info p {
  margin-bottom: 10px
}

.general-grid .cover-text .text-info .date {
  color: #878787;
  font-size: 13px;
  line-height: 1.2em;
  font-weight: 300
}

.general-grid .cover-text .text-info .epigraph {
  font-size: 13px;
  line-height: 1.2em;
  font-weight: 300;
  color: #616161
}

.general-grid .cover-text .text-info .epigraph b {
  color: #009ed6
}

.general-grid .cover-text .text-info>:empty {
  display: none
}

.general-grid .cover-text .text-info blockquote {
  font-family: Prata;
  font-weight: 400;
  font-size: 23px;
  line-height: 1.2em;
  padding: 0 0 0 18px;
  border: 0;
  margin-bottom: 10px
}

.general-grid .cover-text .text-info blockquote:before {
  content: "«";
  display: inline-block;
  vertical-align: middle;
  margin-left: -.4em;
  line-height: 1em
}

.general-grid .cover-text .text-info blockquote:after {
  content: "»";
  display: inline-block;
  vertical-align: middle;
  line-height: 1em
}

.general-grid .cover-text .text-info blockquote~* {
  padding-left: 18px
}

.general-grid .cover-text .tags-info {
  display: block;
  width: 100%;
  margin-top: auto
}

.general-grid .cover-text .tags-info .tags-category {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0
}

.general-grid .cover-text .tags-info .tags-category li {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1em;
  color: #009ed6
}

.general-grid .cover-text .tags-info .tags-category li+li:before {
  content: "|";
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px
}

.general-grid .cover-text .tags-info .tags-section {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0
}

.general-grid .cover-text .tags-info .tags-section li {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1em;
  font-weight: 300
}

.general-grid.grid-beneficiarios .cover-text {
  height: auto
}

@media all and (max-width:1200px) {
  .general-grid .cover-text .text-info blockquote {
    font-size: 18px
  }
}

@media all and (max-width:1023px) {
  .general-grid .cover-text .text-info blockquote {
    font-size: 18px;
  }
  .general-grid {
    text-align: center
  }
  .general-grid .grid-sizer {
    width: 49%
  }
  .general-grid .grid-gutter {
    width: 1%
  }
  .general-grid .grid-item {
    text-align: left;
    width: 49%
  }
}

@media all and (max-width: 700px){
  .general-grid .cover-text {
    height: 356px
  }
  .general-grid .grid-sizer {
    width: 100%
  }
  .general-grid .grid-gutter {
    width: 0
  }
  .general-grid .grid-item {
    text-align: left;
    width: 100%
  }
  .general-grid.grid-beneficiarios .cover-text {
    height: auto
  }
}

//$$ Isla premios

#islaPremios {
  .nominations {
    position: relative;
    .conDesplegable {
      position: relative;
    }
    .desplegable {
      @include absolute($t:70px);
      border: 1px solid #D6D6D6;
      background: $white;
      padding: 30px 40px;
      display: none;
      z-index: 9;
      &:before {
        @include absolute($t:-10px, $l:20%);
        @include size(20px,20px);
        content: "";
        background: $white;
        border: 1px solid #D6D6D6;
        z-index: 0;
        transform: rotate(45deg);
      }
      &:after {
        @include absolute($t:0,$l:0);
        @include size(100%,20px);
        content: "";
        z-index: 1;
        display: block;
        background: $white;
      }
      ul {
        padding: 0;
        margin: 0
      }
      li {
        display: block;
        font-size: 14px;
        color:#414141;
        margin-bottom: 10px;
        label {
          display: inline-block;
          padding-left: 10px
        }
        input[type=text] {
          @include size(180px,48px);
          border:1px solid #D7D7D7;
          text-align: center;
          &:disabled {
            color:#BEBEBE;
          }
        }
      }
      .desplegable_boton {
        @include size (100%, 62px);
        @include border-radius(0);
        border:1px solid #009ed6;
        background: #009ed6;
        text-align: center;
        font-size: 14px;
        color:$white;
        letter-spacing: 1px;
        line-height: 60px;
        text-transform: uppercase;
      }
      input[type=submit] {
        &:hover {
          @include opacity(0.4);
        }
      }
      input {
        &::-webkit-input-placeholder /* Chrome/Opera/Safari */,
        &::-moz-placeholder /* Firefox 19+ */,
        &:-ms-input-placeholder  /* IE 10+ */,
        &:-moz-placeholder /* Firefox 18- */ {
          font-size:14px;
          color:#BEBEBE;
        }
      }
      .error {
        display: none;
        padding: 10px;
        color:#E25063;
      }
    }
  }
  .main-header {

    .btn-big {
      padding: 7px 14px;

    }
  }
  .title-block-fixed {
    top: 72px;
  }
  .main-gallery + .ctrls-gallery {
    top: 55px;
  }
}

#islaRedLeonardo {
  .nominations {
    position: relative;
    .conDesplegable {
      position: relative;
    }
    .desplegable {
      @include absolute($t:70px);
      border: 1px solid #D6D6D6;
      background: $white;
      padding: 30px 40px;
      display: none;
      z-index: 9;
      &:before {
        @include absolute($t:-10px, $l:20%);
        @include size(20px,20px);
        content: "";
        background: $white;
        border: 1px solid #D6D6D6;
        z-index: 0;
        transform: rotate(45deg);
      }
      &:after {
        @include absolute($t:0,$l:0);
        @include size(100%,20px);
        content: "";
        z-index: 1;
        display: block;
        background: $white;
      }
      ul {
        padding: 0;
        margin: 0
      }
      li {
        display: block;
        font-size: 14px;
        color:#414141;
        margin-bottom: 10px;
        label {
          display: inline-block;
          padding-left: 10px
        }
        input[type=text] {
          @include size(180px,48px);
          border:1px solid #D7D7D7;
          text-align: center;
          &:disabled {
            color:#BEBEBE;
          }
        }
      }
      .desplegable_boton {
        @include size (100%, 62px);
        @include border-radius(0);
        border:1px solid #009ed6;
        background: #009ed6;
        text-align: center;
        font-size: 14px;
        color:$white;
        letter-spacing: 1px;
        line-height: 60px;
        text-transform: uppercase;
      }
      input[type=submit] {
        &:hover {
          @include opacity(0.4);
        }
      }
      input {
        &::-webkit-input-placeholder /* Chrome/Opera/Safari */,
        &::-moz-placeholder /* Firefox 19+ */,
        &:-ms-input-placeholder  /* IE 10+ */,
        &:-moz-placeholder /* Firefox 18- */ {
          font-size:14px;
          color:#BEBEBE;
        }
      }
      .error {
        display: none;
        padding: 10px;
        color:#E25063;
      }
    }
  }
  @at-root {
    .return{
      position: relative;
      margin-bottom: 20px;
      a{
        position: absolute;
        top: -60px;
        color: #009ed6;
        display: block;
        right: 2px;
        text-transform: uppercase;
      }
    }
  } 
}
@media (max-width: 700px) {
  #islaPremios{
    .desplegable form .btn {
      display:block !important;
    }
  }
}




.body1{
  font-weight: 500 !important;
  font-family: bentonsansMedium !important;
  font-size: 15px !important;
}
.body1Book{
  font-weight: 500 !important;
  font-family: bentonsansRegular !important;
  font-size: 15px !important;
}
.body2Bold{
  font-weight: bold !important;
  font-family: bentonsansBold !important;
  font-size: 12px !important;
}

.body2{
  font-weight: 500 !important;
  font-family: bentonsansMedium !important;
  font-size: 12px !important;
}
.body2.edition{
  font-size: 15px !important;
}

.body2.category{
  font-size: 15px !important;
}

.menuArchipelago{
  font-weight: 500 !important;
  font-family: Oswald !important;
  font-size: 16px !important;
}
.paragraph{
  font-weight: 500 !important;
  font-family: bentonsansMedium !important;
  font-size: 18px !important;
  p{
    font-weight: 500 !important;
    font-family: bentonsansMedium !important;
    font-size: 18px !important;
    strong{
      font-weight: 500 !important;
      font-family: bentonsansBold !important;
      font-size: 17px !important;
    }
  }
  strong{
    font-weight: 500 !important;
    font-family: bentonsansBold !important;
    font-size: 17px !important;
  }
}
.paragraphAuto{
  font-weight: 500 !important;
  font-family: bentonsansRegular !important;
  font-size: 18px !important;
  p{
    font-weight: 500 !important;
    font-family: bentonsansRegular !important;
    font-size: 18px !important;
    strong{
      font-weight: 500 !important;
      font-family: bentonsansBold !important;
      font-size: 17px !important;
    }
  }
  strong{
    font-weight: 500 !important;
    font-family: bentonsansBold !important;
    font-size: 17px !important;
  }
  h3{
    font-weight: 500 !important;
    font-family: bentonsansRegular !important;
    font-size: 22px !important;
  }
}
.paragraphBold{
  font-weight: 500 !important;
  font-family: bentonsansBold !important;
  font-size: 17px !important;
  p{
    font-weight: 500 !important;
    font-family: bentonsansBold !important;
    font-size: 17px !important;
  }
  h3{
    font-weight: 500 !important;
  font-family: bentonsansBold !important;
  font-size: 22px !important;
  }
}


.names{
  font-weight: 500 !important;
  font-family: Oswald !important;
  font-size: 23px !important;
}

.names.subtitle{
  font-weight: 400 !important;
  font-size: 16px !important;
}

.namesPubli{
  font-weight: 400 !important;
  font-family: Oswald !important;
  font-size: 29px !important;
}

.namesTitle{
  margin-block-start: 0.83em!important;
  font-weight: 400 !important;
  font-family: Oswald !important;
  font-size: 33px !important;
}
.paragraphAgendaHome{
  .tag{
    font-weight: 300 !important;
    font-family: bentonsansRegular !important;
    font-size: 12px !important;
  }
  .date{
    font-weight: 300 !important;
    font-family: bentonsansMedium !important;
    font-size: 12px !important;
  }
  .top{
    .title{
      font-family: bentonsansRegular !important;
    }
  }
  .place{
    font-weight: 300 !important;
    font-family: bentonsansRegular !important;
    font-size: 12px !important;
  }
}
.paragraphBook{
  font-weight: 300 !important;
  font-family: bentonsansRegular !important;
  font-size: 16px !important;
  
  span:not([class]){
    font-weight: 300 !important;
    font-family: bentonsansRegular !important;
    font-size: 16px !important;
  }

  li{
    span{
      font-size: 16px !important;
    }
  }
  p{
    font-weight: 300 !important;
    font-family: bentonsansRegular !important;
    font-size: 16px !important;
  }
  strong{
    font-weight: 300 !important;
    font-family: bentonsansBold !important;
  }
  h2{
    strong{
      font-weight: 500 !important;
      font-family: bentonsansBold !important;
      font-size: 33px !important;
    }
  }
}
.body2Book{
  font-weight: 300 !important;
  font-family: bentonsansRegular !important;
  font-size: 12px !important;

  strong{
    font-weight: bold !important;
    font-family: bentonsansBold !important;
    font-size: 12px !important;
  }

}

.h3BoldAgenda{
  font-weight: 500 !important;
  font-family: bentonsansBold !important;
  font-size: 22px !important;
  @media screen and (max-width: 700px) {
    font-size: 17px !important;
  }
}
.h3Bold{
  font-weight: 500 !important;
  font-family: bentonsansBold !important;
  font-size: 22px !important;
}
.picture-caption{
  .h3Bold{
    font-size: 20px !important;
  }
}

.h3Book{
  font-weight: 500 !important;
  font-family: bentonsansRegular !important;
  font-size: 22px !important;
}
.h2Bold{
  font-weight: 500 !important;
  font-family: bentonsansBold !important;
  font-size: 33px !important;
  @media screen and (max-width: 700px) {
    font-size: 22px !important;
  }
}
.h2Regular{
  font-weight: 500 !important;
  font-family: bentonsansRegular !important;
  font-size: 33px !important;
  @media screen and (max-width: 700px) {
    font-size: 22px !important;
  }
}

.h1Oswald.title{
  font-weight: 400 !important;
}

.h1Oswald{
  font-weight: 200 !important;
  font-family: Oswald !important;
  font-size: 35px !important;
  
}

.resultadosNew{
  display:inline-flex !important;
  justify-content:center !important;
}

.paragraphSpace{
  line-height: 15pt;
}

.marginBuscador{
  margin-right: 8px !important;
}
.number-results.marginBuscador{
  @media screen and (max-width: 700px) {
    font-size: 35px !important;
  }
}
.paragraphBoldTabsSobre{
  .tab-selector{
    font-weight: 500 !important;
    font-family: bentonsansBold !important;
    font-size: 17px !important;
  }
}

.paragraphBoldTabs{
  .tab-selector{
    font-weight: 500 !important;
    font-family: bentonsansBold !important;
    font-size: 17px !important;
  }
  .tab-content{
    font-weight: 500 !important;
    font-family: bentonsansMedium !important;
    font-size: 18px !important;
    .name{
      font-weight: 500 !important;
      font-family: Oswald !important;
      font-size: 23px !important;
    }
    .slide{
      :not(.name){
        font-weight: 300 !important;
        font-family: bentonsansRegular !important;
        font-size: 12px !important;
        @media screen and (max-width: 700px) {
          font-size: 15px !important;
        }
      }
    }
    p{
      font-weight: 300 !important;
      font-family: bentonsansRegular !important;
      font-size: 16px !important;
      strong{
        font-weight: 500 !important;
        font-family: bentonsansBold !important;
        font-size: 17px !important;
      }
    }
    ul{
      font-weight: 300 !important;
      font-family: bentonsansRegular !important;
      font-size: 16px !important;
    }
    ol{
      font-weight: 300 !important;
      font-family: bentonsansRegular !important;
      font-size: 16px !important;
    }
  }
}

.chart-container{
  text{
    font-weight: 300 !important;
  font-family: bentonsansRegular !important;
  font-size: 12px !important;
  }
  .highcharts-title{
    font-weight: bold !important;
    font-family: bentonsansBold !important;
    font-size: 12px !important;
  }
  .highcharts-subtitle{
    font-weight: bold !important;
    font-family: bentonsansBold !important;
    font-size: 12px !important;
  }
  .highcharts-legend-title{
    font-weight: bold !important;
    font-family: bentonsansBold !important;
    font-size: 12px !important;
  }
}
.chart-tabs{
  .highcharts-legend-title{
    font-weight: bold !important;
    font-family: bentonsansBold !important;
    font-size: 12px !important;
  }
  .highcharts-title{
    font-weight: bold !important;
    font-family: bentonsansBold !important;
    font-size: 12px !important;
  }
  .highcharts-subtitle{
    font-weight: bold !important;
    font-family: bentonsansBold !important;
    font-size: 12px !important;
  }
  text{
    font-weight: 300 !important;
    font-family: bentonsansRegular !important;
    font-size: 12px !important;
  }
  .chart-title{
      font-weight: 500 !important;
      font-family: bentonsansBold !important;
      font-size: 22px !important;
    } 
}

.aside-right{
  .chart-title{
    font-weight: 500 !important;
    font-family: bentonsansBold !important;
    font-size: 22px !important;
  }
  .tag{
    font-weight: 500 !important;
    font-family: bentonsansRegular !important;
    font-size: 18px !important;
  }
  .title{
      font-weight: 500 !important;
      font-family: Oswald !important;
      font-size: 21px !important;
  }
  p{
    font-weight: 500 !important;
    font-family: bentonsansRegular !important;
    font-size: 15px !important;
  }
}
.chart-container{
  .chart-title{
    font-weight: 500 !important;
    font-family: bentonsansBold !important;
    font-size: 22px !important;
  }
}

.search-results{
  .h3Bold{
    font-size: 19px !important;
    @media screen and (max-width: 700px) {
      font-size: 15px !important;
    }
}
}
$this: ".share-bar";

#{$this} {
  position: fixed;
  z-index: 9;
  background: $red-leonardo;
  border: 1px solid #333333;
  border-right: 0;
  top: calc(50% - 160px);
  right: 0;
  @include mobile {
    top :230px;
  }
  ul{
    margin: 0;
    padding-left: 0;
    list-style: none;
    li{
      display: block;
      &:hover{
        background: black;
      }
    }
    a{
      position: relative;
    }
    i{
      display: block;
      padding: 18px;
      font-size: 24px;
      color: #FFFFFF;
      @include mobile {
        padding: 10px;
        font-size: 18px;
      }
      &.icon-youtube{
        //padding: 18px 4px;
        //font-size: 43px;
        @include mobile {
          //padding: 10px 4px;
          //font-size: 28px;
        }
      }
    }
  }
}

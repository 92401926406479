.btn-blue {
  background-color: #009ed6;
  border-color: #009ed6;
  color: #fff;

  &:hover {
    background-color: rgba(0, 158, 214, 0.8);
    border-color: rgba(0, 158, 214, 0.8);
  }
}
.btn-center {
  text-align: center;
}
a.btn {
  &:hover {
    text-decoration: none;
  }
}

/*----------------- Títulos páginas -----------------------*/
.page-title {
  padding: 30px 0;
  margin-bottom: 20px;

  &.black {
    background-color: #000;
    color: #fff;

    h1 {
      text-transform: uppercase;
      font-size: 30px;
    }
  }

  h1 {
    margin: 0;
  }
}

/*-------------------- Selects ------------------------*/
.list-selector {
  position: relative;
  display: inline-block;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    .item-active::after {
      transform: rotate(135deg);
      top: 7px;
    }

    .item-list {
      display: block;
    }
  }

  &.small {
    font-size: 13px;

    &:hover {
      .item-active::after {
        top: 5px;
      }
    }
    .item-active::after {
        width: 7px;
        height: 7px;
        top: 1px;
        right: 4px;
    }

    .item-list {
      top: 25px;
    }
  }

  .item-active {
    position: relative;
    padding: 0 20px 10px 10px;
    margin-right: 15px;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 2px;
      width: 10px;
      height: 10px;
      border-left: solid 1px #333;
      border-bottom: solid 1px #333;
      transition: all .25s;
      transform: rotate(-45deg);
    }
  }
  ul.item-list {
    display: none;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 30px;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      a {
        display: block;
        padding: 5px 10px;
        border-top: solid 1px #ccc;

        &:hover {
          color: #fff;
          background-color: $blue;
          text-decoration: none;
        }
      }
    }
  }
}

/*
**************** Popup SOLICITAR AYUDA, BECA... *************
*/
.conDesplegable {
  position: relative;
  display: inline-block;
}
.desplegable {
  position: absolute;
  border: 1px solid #D6D6D6;
  background: #fff;
  padding: 30px 40px;
  top: 70px;
  z-index: 10;
  display: none;
  min-width: 300px;

  &::before {
    content: "";
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: solid 1px #ccc;
    position: absolute;
    top: -11px;
    transform: rotate(45deg);
    left: 50px;
    border-right: 0;
    border-bottom: 0;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: 10px;
      font-size: 14px;
      color: #414141;

      label {
        padding-left: 10px;
      }

      input[type=text] {
        height: 48px;
        border: 1px solid #D7D7D7;
        text-align: center;
        width: 180px;
      }

      button.btn {
        min-width: auto;
        width: auto;
        display: block;
        margin: 0 auto;
      }

      #codigo {
        display: none;
      }
    }
  }

  button {
    width: 100%;
    text-align: center;
  }
}

/*
******************* BANNER HEADER **********************
 */
.banner {
  position: relative;
  min-height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.top {
    background-position: center top;
  }
}

/********************* PLAY EMBED *************************/

.icon-play-embed {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  color: white;
  width: 90px;
  height: 90px;
  padding: 0;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  transform: translate( -50%, -50% );
  transition: all .25s;

  &:hover {
    text-decoration: none;
    background-color: rgba( 0, 0, 0, 1 );
  }

}

.embed-popup-container {
  display: none;
  position: fixed;
  background-color: black;
  height: 90vh;
  color: white;
  width: 90%;
  z-index: 200;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);

  @include tablet {
    height: 500px;
  }

  @include mobile {
    height: 300px;
  }
}
/*
 ***************** OVERLAY en Imágenes *****************
 */

.img-with-overlay {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    transition: color .25s;
  }

  &.dark {
    background-color: rgba(0, 0, 0, 0.5);
    color: #aaa;

    a {
      color: #aaa;

      &:hover {
        color: #fff;
      }
    }

  }

  &.light {
    background-color: rgba(255, 255, 255, 0.5);
    color: #000;

    a {
      color: #555;

      &:hover {
        color: #000;
      }
    }
  }
}

.overlay-page {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

/*
********************* FECHA EN BANNER CABECERA **********************
 */

.date-in-banner {

  padding: 20px 40px 20px 20px;
  border-left: solid 1px #fff;
  border-right: solid 1px #fff;
  transform: skewX(-10deg);

  div {
    transform: skewX(10deg);
    font-family: Oswald, sans-serif;
    text-align: right;
  }
}

/*
 * ******************* COMPARTIR en redes **************************
 */

.social {

  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  li {
    margin: 0 5px;

    a {
      color: #ccc;
      transition: color .25s;
      &:hover {
        color: #333;
      }
    }
  }
}

/*
 * ********************* SHORTCODES en sidebar derecho **********************
 */
.aside-right {

  margin-bottom: 20px;

  a:hover {
    text-decoration: none;
  }

  .aside-info {
    position: relative;
    cursor: pointer;
    display: grid;
    grid-template-areas: 'icon tag' 'icon title' 'icon description';
    grid-template-columns: 60px;

    .icon {
      grid-area: icon;
      font-size: 40px;
    }

    .tag {
      grid-area: tag;
      margin-bottom: 0;
      font-size: 18px;
    }

    .title {
      grid-area: title;
      font-family: Oswald, sans-serif;
      font-size: 22px;
      line-height: 1.3em;
      margin-bottom: 5px;
      transition: color .25s;
    }

    .description {
      grid-area: description;
      width: 100%;
      padding: 0;
    }

    &:hover {
      .title {
        color: $blue;
      }
    }
  }

  .rounded-block {
    border: solid 1px #ccc;
    border-radius: 100%;
    padding: 5px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 280px;
    background-color: #000;
    color: #fff;

    .open-modal {
      margin-bottom: 10px;
    }
    a {
      color: #fff;
      transition: all .25s;
      .icon {
        font-size: 60px;
      }
      &:hover {
        color: $blue;
      }
    }
    p {
      font-size: 22px;
      font-family: Oswald, sans-serif;
    }
    .tag {
      font-size: 18px;
      font-family: bentonsansRegular, sans-serif;
      margin-bottom: 5px;
    }
  }

}

/*
 * *********************** BLOQUES bajo Post o CPT ************************
 */

.related {

  margin: 30px 0;

  .related-container {

    max-width: 1200px;
    width: 90%;
    margin: 0 auto 30px;
    padding: 30px 0;
    border-top: solid 1px #dedede;

    &.two-columns {
      display: flex;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      &.type-news {
        padding-bottom: 10px;
      }

      .section-name {

        min-width: 250px;

        @media screen and (max-width: 768px) {
          width: 100%;
          padding-bottom: 20px;
        }

      }

      .section-data {

        padding: 0 30px;
        flex-grow: 1;

        @media screen and (max-width: 768px) {
          padding: 0;
          width: 100%;
        }

        .item-video {
          width: 890px;
          margin-bottom: 50px;
          display: flex;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          @media screen and (max-width: 600px) {
            flex-wrap: wrap;
          }

          .video-wrapper {
            position: relative;
            width: 25%;
            margin-right: 2%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 150px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @media screen and (max-width: 600px) {
              width: 100%;
              height: 200px;
              margin: 0;
            }

            a {
              color: #fff;
              font-size: 50px;
              z-index: 10;
              transition: all .25s;

              &:hover {
                color: $blue;
              }
            }
          }

          .meta-data {
            width: 73%;

            @media screen and (max-width: 600px) {
              width: 100%;
            }

            .epigraph, .title, .date {
              margin: 0 0 5px;
            }

            .epigraph {
              font-size: 12px;
              text-transform: uppercase;
              color: #aaa;
              line-height: 1.3em;

              @media screen and (max-width: 600px) {
                margin-top: 10px;
              }
            }

            .title {
              font-size: 20px;
              a {
                transition: all .25s;

                &:hover {
                  color: $blue;
                  text-decoration: none;
                }
              }
            }

            .date {
              font-size: 14px;
              color: #999;
            }
          }

        }
      }
    }

    &.with-dropdown {
      border: 0;
    }

    .section-name {
      font-size: 20px;
      font-weight: normal;
      
    }

    .section-data {
      font-size: 18px;
      line-height: 1.5em;

      &.inline {
        display: flex;
        align-items: center;
      }

      .item {
        padding-bottom: 20px;

        a {
          transition: color .25s;
          &:hover {
            color: $blue;
            text-decoration: none;
          }
        }

        h3 {
          font-size: 20px;
          margin: 0 0 10px;
        }

        .date {
          color: #aaa;
          font-size: 14px;
        }

        img {
          max-width: 200px;
        }
      }
    }

    .assets {
      min-width: 250px;
      margin-left: 20px;

      @media screen and (max-width: 600px) {
        margin-left: 0;
      }

    }
  }

  &.bg-gray {
    background-color: #f4f4f4;

    .related-container {
      border: 0;
    }
  }
}


/*
*************************** BLOQUES TABS + CONTAINER *************************
 */
.tab-content {

  &.info-card {

    display: flex;
    flex-wrap: wrap;

    .section-name {
      width: 100%;
      margin-bottom: 15px;
      padding-bottom: 5px;
      border-bottom: solid 1px #ddd;
    }

    .info-card-jurado {
      width: 50%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

  }

}

/************ BLOQUES GENERICOS ******************/
.info-card-galardonados {

  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #ddd;
  margin: 0 0 30px;
  background-color: #eee;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }

  &--image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200px;
    margin: 10px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

  }

  &--content {
    width: calc( 100% - 300px);
    padding: 30px 20px;
    background-color: #eee;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    h3 {
      font-size: 22px;
      font-family: Oswald, sans-serif;
      margin: 0 0 10px;
    }

    p {
      font-size: 16px;
      margin: 0;
    }
  }

  &--link {
    background-color: #eee;
    width: 100px;
    display: flex;
    align-self: flex-end;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    a {
      display: block;
      width: 100%;
      background-color: #ddd;
      color: #777;
      text-align: center;
      font-size: 18px;
      padding: 20px 0;
      transition: all .25s;

      &:hover {
        text-decoration: none;
        background-color: #ccc;
      }
    }
  }
  > a {
    position: relative;
    width: 220px;
    height: 0;
    padding-top: 289.68px;

    @media screen and (max-width: 600px) {
      width: 100%;
      padding-top: 134.84%;
    }
  }
}

.info-card-jurado {

  margin-bottom: 20px;
  font-size: 16px;

  &--name {
    font-weight: normal;
    font-family: Oswald, sans-serif;
    font-size: 20px;
    margin: 0 0 8px;
  }

  &--role {
    margin-bottom: 5px;
  }

  &--university {
    font-style: italic;
    margin-bottom: 5px;
  }

  &--country {
    font-size: 16px;
  }
}

.navigation-tabs {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.elements-list {
  display: grid;
  grid-template-columns: repeat( 3, 1fr );
  column-gap: 50px;
  grid-row-gap: 50px;

  &.one-element {
    grid-template-columns: 1fr;

    .video-wrapper {
      height: 300px;
    }
  }

  @include tablet {
    grid-template-columns: repeat( 2, 1fr );
  }

  @include mobile {
    grid-template-columns: 1fr;
  }

  .single-element {

    display: flex;
    flex-direction: column;

    .single-element-header {
      img {
        width: 100%;
      }
    }

    .single-element-footer {
      margin-top: auto;
    }

  }

}

.video-element {

  .video-wrapper {
    position: relative;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    .open-modal {
      font-size: 54px;
      color: white;
      opacity: 0.6;
      z-index: 1;
      transition: all .25s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .epigraph {
    text-transform: uppercase;
    color: #aaa;
    font-size: 12px;
    margin: 10px 0 5px;
  }

  .video-title {
    font-family: Oswald, sans-serif;
    font-weight: bold;
    font-size: 28px;
    margin: 20px 0;

    a {
      transition: all .25s;

      &:hover {
        text-decoration: none;
        color: $blue;
      }
    }
  }

  .subtitle {
    font-size: 18px;
  }

  .more {

    margin-top: auto;

    .btn {
      width: 100%;
    }

  }
}

/*------------------ Alerts --------------------*/
.alert {
  position: relative;
  padding: 15px 20px;
  border: solid 1px #ccc;
  background-color: #f4f4f4;
  margin-bottom: 50px;

  h2 {
    font-size: 22px;
    color: $blue;
    margin: 0 0 10px;
  }

  p {
    font-size: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &-info {
    border-color: #D3EDFB;
    background-color: #D3EDFB;
  }

  &.no-margin {
    margin-bottom: 0;
  }

}

/***************** Anchors internos Fundacion e islas **************************/
a.anchor {
  display: block;
  position: relative;
  top: -72px;
  visibility: hidden;
}
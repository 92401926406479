//Item del listado en sus tres variantes: sin título, con título y en aside

.list-entries-item {
  @include size(50%, 349px);
  position: relative;
  z-index: -2;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 40px;

  @include tablet {
    height: auto;
    padding: 20px 5%;
  }


  color: $white;
  background-color: $black;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;

  .entry {
    &__title {
      font-family: $font_bentonsansSemibold;
      z-index: 0;
      margin: 0;
      font-size: 25px;
      line-height: 35px;
      font-weight: bolder;
      color: $white;

      @include tablet-big {
        font-size: 32px;
      }

      @include tablet {
        color: $grey-dark;
        font-size: 18px;
        line-height: 25px;
      }
    }

    &__author {
      z-index: 0;
      font-size: 13px;
      a{color:$white}

      @include tablet {
        a {
          color: $blue;
          font-weight: 600;
        }
      }
    }

    &__date {
      z-index: 0;
      font-size: 13px;
      font-weight: 500;
      color: $grey-mid-blog;
    }

    &__image {
      @include absolute($t: 0, $l: 0);
      @include opacity(0.4);
      height: 100%;
      z-index: -1;
      object-fit: cover;
      width: 100%; // cambio wp

      @include tablet {
        @include opacity(1);
        //height: 102px;
        top: 0;
        right: 16px;
        left: inherit;
        max-width: 180px;
        border-top: 10px solid $white;
        border-bottom: 10px solid $white;
      }

      @include mobile {
        max-width: 100px;
        max-height: 100px;
        border-top: 20px solid $white;
        right: 0px;

      }

      @include tablet-plus {
        max-width: 33%;
        max-height: 85%;

      }

      @include mobile-small {
        max-height: 75%;

      }

      &+.entry__blog+.entry__title {
        @include tablet {
          max-width: calc(100% + 50%);
        }
      }
    }

    &__blog {
      display: none;
    }
  }

  @include tablet {
    @include size(100%, auto);
    justify-content: start;
    //padding: 40px 16px;
    background-color: $white;
    color: $grey-mid-blog;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid $grey-light-blog;
    align-items: flex-start;
  }

  &.show-blog {
    .entry__blog {
      @include tablet {
        display: block;
        font-size: 16px;
        font-family: Oswald;
        font-weight: 500;
        text-transform: uppercase;
        margin: 6px 0;
      }
    }
  }

  &.aside,
  &.main {
    @include size(100%, 125px);
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 300;
    padding: 0;
    background-color: $white;
    color: $black;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid $grey-light-blog;

    .entry {
      &__title {
        font-size: 18px;
        width: calc(100% - 92px);
        font-weight: 600;
        line-height: 1;
        cursor: pointer;
      }

      &__image {
        @include opacity(1);
        @include size(50%, 50%);
        @include absolute($t: 21px, $r: 0);
        left: inherit;
        max-width: 92px;
        max-height: 92px;

        @include mobile {
          max-width: 100px;
        }

        @include tablet {
          @include size(92px, 92px);
        }
      }

      &__author {
        z-index: 0;
        width: calc(100% - 92px);
        line-height: 1;

        @include desktop {
          line-height: 24px;
        }

        @include tablet {
          line-height: 24px;
        }

        a {
          color: $blue;
          font-weight: 600;
        }
      }

      &__date {
        width: calc(100% - 92px);
        color: $grey-mid-blog;
      }
    }

    &.related {
      height: auto;
      padding: 20px 0px;
      width: 100%;

      & .title {
        font-family: $font_bentonsansBold;
      }

      .entry {

        &__subtitle {
          font-family: $font_bentonsansBold;
          margin-bottom: 10px;
          &.mediaW{
            width: 60%;
          }
        }

        &__date,
        &__author {}
      }
    }
  }

  &.main {
    &.show-blog {
      .entry {
        &__blog {
          display: block;
          font-size: 16px;
          font-family: Oswald;
          font-weight: 500;
          text-transform: uppercase;
          margin: 6px 0;
          color: $grey-mid-blog;
        }

        &__image {
          @include opacity(1);
          @include size(190px, 100%);
          top: 16px;
          right: 0px;
          left: inherit;
          max-width: calc(100% - 196px);

          @include tablet-portrait {
            max-width: 92px;
          }
        }

        &__date,
        &__author,
        &__blog,
        &__title {
          max-width: calc(100% - 196px);

          @include tablet-portrait {
            max-width: calc(100% - 92px);
          }
        }
      }
    }
  }
}

.single-profile {
  header {

    border-bottom: solid 1px #ddd;
    margin-bottom: 50px;

    .header-container {
      width: 95%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;

      &.full-width-image {
        img {
          width: 100%;
        }
      }

      img {
        width: 427px;
        height: 100%;

        @media screen and (max-width: 768px){
          width: 50%;
        }

        @media screen and (max-width: 600px){
          width: 100%;
        }
      }

      .header-main-info {
        width: calc( 100% - 427px );
        padding: 40px 20px;

        &.no-thumbnail {
          padding-left: 0;
        }
        
        @media screen and (max-width: 768px){
          width: 50%;
        }

        @media screen and (max-width: 600px){
          width: 100%;
        }

        .title {
          font-family: Oswald, sans-serif;
          font-size: 28px;
          margin: 0 0 15px;
        }

        .subtitle {
          font-family: Oswald, sans-serif;
          text-transform: uppercase;
          font-size: 18px;
          margin: 0 0 10px;

          &.filiacion {
            font-family: 'bentonsansRegular', sans-serif;
            text-transform: none;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 1.5em;
          }

          &.epigraph {
            font-size: 14px;
          }
          
        }

        .publications-list {

          .see-all {
            margin: 10px 0;
          }
          
          .see-more {
            display: none;
          }
        }

        .category {
          color: $blue;
          font-size: 16px;
          margin: 0 0 10px;
        }

        .edition {
          font-size: 16px;
        }

        .description {
          margin-top: 50px;
          font-size: 18px;
          line-height: 1.4em;
        }

        ul li a {
          color: $blue;
        }
      }

      .more-info-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;

        h3 {
          width: 100%;
          font-size: 22px;
        }

        .aside-right {
          width: 30%;
          margin: 1% 1.5%;
        }
      }
    }
  }

  .body {

    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .epigraph {
      width: 200px;
      font-size: 20px;
      margin: 0;

      @media screen and (max-width: 1023px){
        width: 100%;
        margin-bottom: 1.8em;
      }
    }

    .description {
      width: calc( 100% - 500px );
      font-size: 18px;
      line-height: 1.6em;
      padding: 0 30px;
      margin-bottom: 30px;
      
      h3, h4 {
          font-size: inherit;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 10px;
      }

      .su-pullquote {
        @media screen and (max-width: 1023px){
          width: 100%;
        }
      }

      @media screen and (max-width: 1023px){
        padding: 0;
      }

      @media screen and (max-width: 768px){
        width: 100%;
      }
    }

    .assets {
      width: 300px;
      margin-bottom: 30px;

      .aside-right {
        .aside-info {
          .title {
            @media screen and (max-width: 1023px){
              width: 40%;
            }
          }
        }
      }

      @media screen and (max-width: 1023px){
        position: relative;
        right: -300px;
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        position: static;
      }
    }

  }

  footer {

  }
}
.mencion_del_acta.mencion_del_acta_active{
  background-color: #f0f8ff;
}

.close-acta {
  height: fit-content;
  background-color: #f0f8ff;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  @media screen and (max-width: 768px){margin-top: 5%;}
}

.close-acta:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 30px; 
  content: '\d7';
  color: #333;
  text-align: center;
}

.container-galardonado{
  @media screen and (max-width: 768px){
    margin-top:5%;
  }
}

.galardonado-information{
  @media screen and (max-width: 768px){
    display: grid;
  }
}

.texto-mencion-acta{
  @media screen and (max-width: 768px){
  margin-top: 5%!important;}
  width:95%;
  white-space: nowrap;
}
/* HEADER & NAV */

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 22;
  transition: left .3s linear;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .15);
  background: #fff;

  @media print {
    position: static;
  }

  @include desktop-small {
    padding: 0 10px;
  }

  .header-container {
    max-width: 1340px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;

    @include tablet {
      height: 58px;
    }

    .logo {
      flex-basis: 25%;

      @include mobile {
        flex-basis: 75px;
      }
    }

    .island-title {
      text-align: center;
      font-family: Oswald, sans-serif;
      font-weight: 500;
      font-size: 22px;
      line-height: 1.2em;

      @include mobile {
        font-size: 18px;
      }

      a:hover {
        text-decoration: none;
      }
    }

    .options {
      flex-basis: 25%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include mobile {
        flex-basis: 75px;
      }

      .btn {
        border-radius: 100px;
        margin-right: 20px;

        @include tablet {
	         /* display: none;*/
        }
      }

      .language-selector {
        min-width: 80px;

        @include tablet {
          display: none;
        }
      }

    }
  }

}


.main-nav {
  position: fixed;
  top: 0;
  right: -500px;
  width: 100%;
  max-width: 500px;
  height: 100%;
  overflow: auto;
  background: #fff;
  padding: 20px;
  z-index: 100;
  box-shadow: -5px 0 7px -7px #555;
  -webkit-transition: right .3s linear;
  -moz-transition: right .3s linear;
  transition: right .3s linear;

  &.show-menu {
    right: 0;
  }
}


/*--------------------- LEGACY ---------------------------*/

.main-header .logo img {
  height: 38px
}

.main-header .lead-text {
  float: left;
  width: 50%;
  margin: 12px 0;
  text-align: center;
  font-family: Oswald, sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.2em;
  opacity: 1;
  letter-spacing: 0;

  a {
    text-decoration: none;
  }
}

.main-header .lead-text .regular, .main-header .lead-text.type2 {
  font-family: bentonsansRegular, sans-serif;
  font-weight: 400;
  letter-spacing: 1px
}

.main-header .lead-text.show {
  opacity: 1 !important
}

#coverHome{
  .main-header .lead-text {
	margin: 5px 0 0;
	font-size: 18px;
	font-weight: normal;
	.regular{
	  font-size: 20px;
	  font-weight: bold;
	}
	.epigraph{
	  font-weight: normal;
	  font-size: 15px;
	  font-family: bentonsansRegular, sans-serif;
	}
  }
}


.main-header .language, .main-header .language li, .main-header .language li+li:before {
}

.main-header .options .inline-block {
}

.main-header .options .icon-user-2:before {
  text-rendering: auto
}

.main-header .options .icon, .main-header .options .icon-search {
  height: 20px;
  width: 20px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 .2em
}

.main-header .options .icon-search {
  @include mobile {
    margin-left: 15px;
  }
}

.main-header .options .icon-search:before, .main-header .options .icon:before {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.13em
}

.main-header .language {
  margin: 0 -7px 0 0;
}

.main-header .language ul {
  margin: 0;
  padding: 0;
  font-size: 0
}

.main-header .language li {
  color: #b2b2b2;
  font-size: 15px;
  line-height: 1.2em;
  transition: all .3s ease
}

.main-header .crtl-nav:after, .main-header .language li.active, .main-header .language li:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.main-header .language li+li:before {
  content: "|";
  margin: 0 10px;
  color: #b2b2b2
}

.main-header .language li.active, .main-header .language li:hover {
  color: #000;
  transition: all .3s ease
}

.main-header .search-field {
  position: absolute;
  display: none;
  top: 0;
  left: auto;
  right: 50px;
  background: #fff;
  height: 100%;
  z-index: 2;
  padding: 7px 20px 7px 0
}

.main-header .search-field.show {
  display: block !important;
  right: 80px;
  left: auto;
  min-width: 200px
}

.main-header .search-field.show[style] {
  opacity: 0
}

.main-header .search-field.show.no-ctrl-nav {
  right: 30px
}

.main-header .search-field .form-ctrl {
  display: block;
  width: 100%;
  border: 0;
  padding: 8px 5px;
  border-bottom: 1px solid #dedede;
  outline: 0;
  min-width: 260px
}

.main-header .crtl-nav, .main-header .ctrl-search {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer
}

.main-header .ctrl-search {
  font-size: 18px
}

.main-header .crtl-nav {
  position: relative;
  width: 27px;
  height: 22px;
  margin-left: 25px;

  @include mobile {
    margin-left: 15px;
  }
}

.main-header .crtl-nav span {
  display: block;
  width: 27px;
  height: 3px;
  background: #000;
  margin-bottom: 5px
}

.main-header .crtl-nav span:first-child {
  -webkit-transition: all .3s ease .8s;
  -moz-transition: all .3s ease .8s;
  -ms-transition: all .3s ease .8s;
  -o-transition: all .3s ease .8s;
  transition: all .3s ease .8s
}

.main-header .crtl-nav span:nth-child(2) {
  -webkit-transition: all .3s ease .6s;
  -moz-transition: all .3s ease .6s;
  -ms-transition: all .3s ease .6s;
  -o-transition: all .3s ease .6s;
  transition: all .3s ease .6s
}

.main-header .crtl-nav span:last-child {
  margin-bottom: 0;
  -webkit-transition: all .3s ease .4s;
  -moz-transition: all .3s ease .4s;
  -ms-transition: all .3s ease .4s;
  -o-transition: all .3s ease .4s;
  transition: all .3s ease .4s
}

.main-header .crtl-nav:after, .main-header .crtl-nav:before {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #000;
  position: absolute
}

.main-header .crtl-nav:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  top: -1px;
  left: 5px;
  -webkit-transition: all .3s ease .2s;
  -moz-transition: all .3s ease .2s;
  -ms-transition: all .3s ease .2s;
  -o-transition: all .3s ease .2s;
  transition: all .3s ease .2s
}

.main-header .crtl-nav:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
  left: 5px;
  bottom: -1px;
  transition: all .3s ease
}

.compound-blocks-modules .block.infographic:hover:before, .main-header .crtl-nav.active span:first-child {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.main-header .crtl-nav.active span:first-child {
  width: 0;
  transition: all .3s ease
}

.main-header .crtl-nav.active span:nth-child(2) {
  width: 0;
  -webkit-transition: all .3s ease .2s;
  -moz-transition: all .3s ease .2s;
  -ms-transition: all .3s ease .2s;
  -o-transition: all .3s ease .2s;
  transition: all .3s ease .2s
}

.main-header .crtl-nav.active span:last-child {
  width: 0;
  -webkit-transition: all .3s ease .4s;
  -moz-transition: all .3s ease .4s;
  -ms-transition: all .3s ease .4s;
  -o-transition: all .3s ease .4s;
  transition: all .3s ease .4s
}

.main-header .crtl-nav.active:before {
  width: 27px;
  -webkit-transition: all .3s ease .6s;
  -moz-transition: all .3s ease .6s;
  -ms-transition: all .3s ease .6s;
  -o-transition: all .3s ease .6s;
  transition: all .3s ease .6s
}

.main-header .crtl-nav.active:after {
  width: 27px;
  -webkit-transition: all .3s ease .8s;
  -moz-transition: all .3s ease .8s;
  -ms-transition: all .3s ease .8s;
  -o-transition: all .3s ease .8s;
  transition: all .3s ease .8s
}

.main-header .blue-bar {
  display: none;
  margin-top: 10px;
  padding: 20px 0;
  background: #009ed6;
  color: #000;
  text-align: center;
  font-family: Oswald;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.2em
}

.main-header .blue-bar span {
  color: #fff
}

/****************************
    MAIN NAV
  clickas en la hamburguesa del header de arriba 
  y te sale el panel con menu
******************************/


.main-nav .menu-nav {
  display: block;
  padding-top: 50px
}

.main-nav .menu-nav>ul {
  margin: 0;
  padding: 0
}

.main-nav .menu-nav>ul>li {
  list-style: none;
  display: block;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2em;
  color: #333;
  border-bottom: 1px solid #dedede;

  a:hover {
    text-decoration: none;
  }
}

.main-nav .menu-nav>ul>li>a>span {
  padding: 20px 0;
  display: block;
  transition: all .3s ease;
}

.main-nav .menu-nav>ul>li.dropdown>ul span, .main-nav .menu-nav>ul>li>a>span:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.main-nav .menu-nav>ul>li>a>span:hover {
  transition: all .3s ease;
  color: #878787;
}

.main-nav .menu-nav>ul>li.dropdown>a>span {
  position: relative
}

.main-nav .menu-nav>ul>li.dropdown>a>span:after {
  content: '\e81e';
  font-family: fontello;
  font-weight: 400;
  font-size: 18px;
  line-height: 1em;
  display: block;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -.5em;
  color: #333
}

.main-nav .menu-nav>ul>li.dropdown>a>span:hover {
  color: #009ed6
}

.main-nav .menu-nav>ul>li.dropdown>ul {
  padding: 0;
  margin: 0;
  display: none
}

.main-nav .menu-nav>ul>li.dropdown>ul li {
  list-style: none;
  display: block
}

.main-nav .menu-nav>ul>li.dropdown>ul span {
  display: block;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 1.2em;
  transition: all .3s ease
}

.main-nav .menu-nav>ul>li.dropdown>ul span:hover, .main-nav .social-block .language li {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.main-nav .menu-nav>ul>li.dropdown>ul span:hover {
  transition: all .3s ease;
  color: #878787
}

.main-nav .menu-nav>ul>li.dropdown.active>a>span {
  color: #009ed6
}

.main-nav .menu-nav>ul>li.dropdown.active>a>span:after {
  content: '\e81f'
}

.main-nav .nominations {
  display: block;
  color: #333;
  font-size: 14px;
  line-height: 1.2em;
  padding: 30px 0;
  border-bottom: 1px solid #dedede
}

.main-nav .nominations p {
  margin: 0 0 1em
}

.main-nav .nominations .c_gray {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px
}

.main-nav .nominations .no-list {
  font-size: 16px;
  line-height: 1.6em;
  font-family: Oswald;
  font-weight: 500;
  margin: 0;
  padding: 0
}

.main-nav .nominations .no-list li {
  list-style: none;
  display: block
}

.main-nav .social-block {
  display: block;
  padding: 20px 0
}

.main-nav .social-block:after, .main-nav .social-block:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  height: 0
}

.main-nav .social-block .social ul {
  display: block;
  margin: 0;
  padding: 0
}

.main-nav .social-block .social li {
  display: inline-block;
  vertical-align: middle
}

.main-nav .social-block .social li .icon-youtube {
  //Lo vuelvo a comentar, creo que proyectos lo descomentó por error 2023/10/17
  //font-size: 2.5em
}

.main-nav .social-block .social li .icon-youtube:before {
  //Lo vuelvo a comentar, creo que proyectos lo descomentó por error 2023/10/17
  //margin: 0
}

.main-nav .social-block .webmap {
  display: block;
  width:100%;
  clear: both;
  margin-bottom: 15px;
}


.main-nav .social-block .social {
  display: flex;
  width: 100%;
  justify-content: center;
}

.main-nav .social-block .social li {
  color: #b2b2b2;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  margin: 12px 12px 0 0;
}

.main-nav .close-nav:after, .main-nav .social-block .social li:hover {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.main-nav .social-block .social li:hover {
  transition: all .3s ease;
  color: #000;
}

.main-nav .social-block .social li:last-child {
  margin-right: 0
}

.main-nav {
  .social-block {
    .private-area {
      margin-right: 0;
      display: inline-block;
      float: right;
      &:hover{
        color:$black;
      }
      &:before {
        display: inline-block;
        font-family: fontello;
        content: '\e833';
        padding-right: 8px;
        font-size: 16px;
        font-weight: $bold;
      }
    }
  }
}

.main-nav .close-nav {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  width: 27px;
  height: 19px;
  margin-left: 0;
  right: 20px;
  top: 20px;
  cursor: pointer
}

.main-nav .close-nav span {
  display: block;
  width: 27px;
  height: 3px;
  background: #000;
  margin-bottom: 5px
}

.main-nav .close-nav span:first-child {
  -webkit-transition: all .3s ease .8s;
  -moz-transition: all .3s ease .8s;
  -ms-transition: all .3s ease .8s;
  -o-transition: all .3s ease .8s;
  transition: all .3s ease .8s
}

.main-nav .close-nav span:nth-child(2) {
  -webkit-transition: all .3s ease .6s;
  -moz-transition: all .3s ease .6s;
  -ms-transition: all .3s ease .6s;
  -o-transition: all .3s ease .6s;
  transition: all .3s ease .6s
}

.main-nav .close-nav span:last-child {
  margin-bottom: 0;
  -webkit-transition: all .3s ease .4s;
  -moz-transition: all .3s ease .4s;
  -ms-transition: all .3s ease .4s;
  -o-transition: all .3s ease .4s;
  transition: all .3s ease .4s
}

.main-nav .close-nav:after, .main-nav .close-nav:before {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  background: #000;
  position: absolute
}

.main-nav .close-nav:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  top: -1px;
  left: 5px;
  -webkit-transition: all .3s ease .2s;
  -moz-transition: all .3s ease .2s;
  -ms-transition: all .3s ease .2s;
  -o-transition: all .3s ease .2s;
  transition: all .3s ease .2s
}

.main-nav .close-nav:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
  left: 5px;
  bottom: -1px;
  transition: all .3s ease
}

.back-item .cover-media>img, .back-item .cover-media>video, 
.main-header .options .icon-search:before, 
.main-header .options .icon:before {
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%)
}

.main-header .language li, 
.main-nav .close-nav.active span:first-child {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.main-nav .close-nav.active span:first-child {
  width: 0;
  transition: all .3s ease
}

.main-nav .close-nav.active span:nth-child(2) {
  width: 0;
  -webkit-transition: all .3s ease .2s;
  -moz-transition: all .3s ease .2s;
  -ms-transition: all .3s ease .2s;
  -o-transition: all .3s ease .2s;
  transition: all .3s ease .2s
}

.main-nav .close-nav.active span:last-child {
  width: 0;
  -webkit-transition: all .3s ease .4s;
  -moz-transition: all .3s ease .4s;
  -ms-transition: all .3s ease .4s;
  -o-transition: all .3s ease .4s;
  transition: all .3s ease .4s
}

.main-nav .close-nav.active:before {
  width: 27px;
  -webkit-transition: all .3s ease .6s;
  -moz-transition: all .3s ease .6s;
  -ms-transition: all .3s ease .6s;
  -o-transition: all .3s ease .6s;
  transition: all .3s ease .6s
}

.main-nav .close-nav.active:after {
  width: 27px;
  -webkit-transition: all .3s ease .8s;
  -moz-transition: all .3s ease .8s;
  -ms-transition: all .3s ease .8s;
  -o-transition: all .3s ease .8s;
  transition: all .3s ease .8s
}

.main-nav.type2 {
  display: none;
  right: 0;
  left: 0;
  max-width: initial;
  background: url(../img/chairs.jpg) 50% no-repeat #000;
  background-size: cover;
  z-index: 22;
  padding-top: 135px;
  text-align: center
}

.main-nav.type2:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(0, 0, 0, .6)
}

.main-nav.type2 .container {
  max-width: 700px;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 1
}

.main-nav.type2 .date {
  font-size: 18px;
  line-height: 1.2em;
  margin-bottom: 20px;
  color: #009ed6;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px
}

.main-nav.type2 .block-name {
  color: #fff;
  font-size: 22px;
  line-height: 1.2em;
  letter-spacing: 2px;
  margin-bottom: 30px
}

.main-nav.type2 .menu-nav {
  padding-top: 0
}

.main-nav.type2 .menu-nav>ul {
  margin-bottom: 25px
}

.main-nav.type2 .menu-nav>ul>li {
  font-size: 22px;
  line-height: 1.2em;
  border: 0;
  color: #fff
}

.main-nav.type2 .menu-nav>ul>li>a>span {
  padding: 5px 0;
  display: inline-block
}

.main-nav .menu-nav>ul>li>a>span {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}


@media all and (max-width:1200px) {
  .main-header .lead-text {
    padding-left: 250px
  }
}



@media all and (max-width:1023px) {
  .main-header .blue-bar {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
  }
  .main-header .lead-text {
    display: none
  }
  .main-header .blue-bar {
    display: block;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition: all .3s ease
  }
  .main-header .blue-bar.show-bar {
    max-height: 200px;
    padding: 20px 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
  }
}

@media all and (max-width:700px) {
  .main-nav .menu-nav {

    .cover-img {
      position: relative;
      top: 0;

      img {
        position: static;
      }

      & + .info {
        width: 100%;
      }
    }
    .info {
      padding-top: 0
    }
  }
  .main-nav .close-nav {
    top: auto;
    right: auto;
    display: block;
    margin-left: auto;
    position: relative
  }
  .main-header .options .btn {
      /*display: none*/
  }
  .main-header .blue-bar {
    font-size: 17px
  }
}

@media all and (max-width:480px) {
  .main-header .search-field.show .form-ctrl {
    min-width: 170px
  }
  .main-header .search-field .form-ctrl {
    min-width: 210px
  }
  .main-header .logo img {
    max-width: 130px;
    height: auto
  }
}


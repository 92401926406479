.webmap__container {
  .webmap__row {
	display: flex;
	@include webmap-bg;
	.main {
	  padding-top: 45px;
	  width: 100%;
	  .main--title {
		font-size: 32px;
		font-weight: $bold;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 16px;
		@include tablet-big {
		  font-size: 24px;
		}
	  }
	  .dropdown-control { 				//Encabezado que activa el dropdown
		width: 100%;
		position: relative;
		margin-right: 20px;
		margin-bottom: 32px;
		.main--subtitle {
		  font-size: 16px;
		  font-weight: 500;
		}
		&:before {
		  @include absolute($t:50%,$r:0);
		  @include transition(all,.3s,ease);
		  width: 80px;
		  content: '\e81e';
		  font-family: fontello;
		  font-size: 18px;
		  color: $black;
		  text-align: center;
		  margin-top: -9px;
		  display: none;
		  z-index: 1;
		  @include tablet-big {
			display: block;
		  }

		}
		&:after {
		  @include size(80px,100%);
		  @include absolute($t:0,$r:0);
		  @include transition(all,.3s,ease);
		  background: $grey-webmap;
		  content: "";
		  z-index: 0;
		  display: none;
		  @include tablet-big {
			display: block;
		  }
		}
		@include tablet-big {
		  padding: 16px 100px 16px 0;
		  border-bottom: 4px solid $grey-webmap;
		}
		&.open {
		  border-bottom: 0;
		  @include transition(all,.3s,ease);
		  &:after {
			background-color: $blue;
		  }
		  &:before {
			@include transform(rotate(180deg));
			color:$white;
		  }
		}
		@include tablet-big {
		  margin-bottom: -20px;
		}
	  }
	  .webmap__row__content {  			// Contenido general del dropdown
		padding-right: 20px;
		.webmap__row__block {   // Bloque contenedor dentro de cada apartado del scontenido
		  padding-bottom: 45px;
		  @include tablet-big {
			padding-top: 45px;
			padding-bottom: 0;
		  }
		  &:last-of-type {
			padding-bottom: 0;
		  }
		}
		.content--title {
		  display: block;
		  font-size: 16px;
		  font-weight: 600;
		  margin-top: 0;
		  margin-bottom: 12px;
		  text-transform: uppercase;
		}
		a {
		  &.content--title {
			cursor: pointer;
			@include transition(color,.25s,ease-out);
			&:hover, &:focus, &:active {
			  color:$blue;
			}
		  }
		}
		.content__list {
		  list-style: none;
		  padding: 0;
		  line-height: 2;
		  margin: 0;
		  .content__list__item {
			position: relative;
			.webmap__link {
			  @include transition(color,.25s,ease-out);
			  &:hover, &:focus, &:active {
				color:$blue;
			  }
			}
			.island {
			  display: inline-block;
			  width: auto;
			  position: relative;
			  background-color: white;
			  padding-right: 8px;
			  z-index: 2;
			  @include transition(color,.25s,ease-out);
			  &:hover, &:focus, &:active {
				color:$blue;
			  }
			}
			p {
			  position: relative;
			  display: inline-block;
			  margin: 0;
			  width: calc(75% + 4px);
			  vertical-align: top;
			  z-index: 1;
			  &:after {
				content:"";
				width: 100%;
				height:1px;
				display:block;
				position: absolute;
				top:15px;
				background: #cccccc;
				z-index: 1;
				@include tablet-big {
				  display: none;
				}
			  }
			  @include tablet-big {
				width: 100%;
				padding-bottom: 16px;
			  }
			}
			ul {
			  list-style: none;
			  padding-left: 25px;
			  @include tablet-big {
				order: 2;
			  }
			  li {
				p {
				  &:after {
					width: calc(100% - 6px);
				  }
				}
				.island--title {
				  padding: 0 36px 0 30px;
				}
				@include tablet-big {
				  padding-bottom:8px;
				  &:last-of-type {
					padding-bottom: 16px;
				  }
				}
			  }
			}
			.webmap__link {
			  @include tablet-big {
				padding-bottom: 16px;
			  }
			}
			@include tablet-big {
			  padding-bottom: 16px;
			  line-height: 24px;
			  &:last-of-type {
				padding-bottom: 0;
			  }
			}
		  }
		  @include tablet-big {
			display: flex;
			flex-direction: column;
		  }
		}
		@include tablet-big {
		  padding-right: 0;
		  display: none;
		}
	  }
	  @include tablet-big {
		width: 100%;
	  }
	}
	&:last-child{
	  .main, 
	  .aside {
		padding-bottom: 80px;
	  }
	}
	&.webmap__section {

	}
	@include tablet-big {
	  background: $white;
	}
  }
}

.island--title {
  font-family: Oswald;
  color: $black;
  display: inline-block;
  line-height:1.5;
  font-size: 16px;
  font-weight:600;
  text-transform: uppercase;
  width: 23%;
  padding:0 36px;
  position: relative;
  z-index: 2;
  &.leonardo {
	color:$red-leonardo;
	@include tablet-big{
	  color: $white;
	  background-color: $red-leonardo;
	}
  }
  &.biophilia {
	color:$orange;
	@include tablet-big{
	  color: $white;
	  background-color:$orange;
	}
  }
  &.multiverso{
	color: $blue-multiverso;
	@include tablet-big{
	  color: $white;
	  background-color:$blue-multiverso;
	}
  }
  &.contrapunto{
	color: $gold;
	@include tablet-big{
	  color: $white;
	  background-color:$gold;
	}
  }
  &.awards {
	color:$blue-awards-dark;
	&:after {
	  top:33%;
	}
	@include tablet-big {
	  position: relative;
	  color: $white;
	  background-color: $blue-awards-dark;
	  left: 0;
	}
  }
  &:after {
	@include absolute($t:0,$r:0);
	font-family: fontello;
	content: '\E844';
	color:$grey-mid-3;
	@include tablet-big {
	  position: relative;
	  padding-left: 6px;
	  color: $white;
	}
  }
  &:hover {
	&:after {
	  @include transition(color,.25s,ease-out);
	  color:$black;
	  @include tablet-big {
		color: $white;
	  }
	}
  }
  @include tablet-big {
	@include border-radius(3px);
	width: auto;
	display: inline-block;
	order: 3;
	padding: 3px 8px;
	margin-bottom: 12px;
	font-family: bentonsans, Helvetica, Arial, Sans-serif;
	font-weight: $regular;
	font-size: 12px;
  }
}

#mapaWeb {
.main-wrapper {
	margin-top: 72px;
}
  .webmap__footer {
	display: none;
	padding: 48px;
	background-color: $grey-webmap;
	margin-top: 45px;
	.container {
	  display: flex;
	  align-items: flex-start;
	  justify-content: center;
	  flex-direction: column;
	  padding:0;
	}
	.webmap__footer__title {
	  font-family: Oswald;
	  font-weight: 600px;
	  font-size: 20px;
	  color:#959595;
	  text-transform: uppercase;
	  margin: 0 0 24px;
	}
	.webmap__footer__list {
	  list-style: none;
	  padding: 0;
	  margin: 0;
	  .webmap__footer__item {
		padding: 0;
		text-align: left;
		margin-bottom: 16px;
		height: 24px;
		&:last-of-type {
		  margin-bottom: 0;
		}
	  }
	}
  }
  @include tablet-big {
	.main-footer {
	  display: none;
	}
	.webmap__footer {
	  display: block;
	}
  }
}


.wrapper {
  &.show-menu {
	.webmap__row {
	  &.nav {
		@include transition(left,.3s,linear);
		left: -500px;
	  }
	}
  }
  .main-header {
	.blue-bar {
	  &.mapa-web {
		@include tablet {
		  display: block!important;
		  max-height: 40px;
		  padding: 8px 0;
		  margin-top: 5px;
		}
	  }
	}
  }
}
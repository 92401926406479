.publications {

  header {

    background-color: #f8f9fa;

    .header-container {

      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 50px 0 20px;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        width: 90%;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      img {
        max-width: 300px;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 768px) {
          max-width: 200px;
        }
        @media screen and (max-width: 500px) {
          max-width: 100%;
          margin: 0 auto;
        }
      }

      .header-main-info {
        width: calc( 100% - 450px );
        padding: 0 20px;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
          width: calc( 100% - 200px );
          padding-right: 0;
        }
        @media screen and (max-width: 500px) {
          width: 100%;
          margin: 30px 0;
          padding-left: 0;
        }

        .pretitle {
          text-transform: uppercase;
          font-size: 12px;
          margin-bottom: 5px;
          line-height: 1em;
          color: #999;

          span {
            border-right: solid 1px #999;
            padding: 0 10px;

            @media screen and (max-width: 768px) {
              display: block;
              border: 0;
              padding: 0;
              margin-bottom: 5px;
            }

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              border: 0;
            }
          }
        }

        h1 {
          font-family: Oswald, sans-serif;
          font-size: 40px;
          line-height: 1.2em;
          font-weight: normal;
          margin: 0 0 10px;

          @media screen and (max-width: 1024px) {
            font-size: 30px;
          }
        }

        .subtitle {
          font-family: Oswald, sans-serif;
          font-size: 35px;
          font-weight: normal;
          color: #aaa;
          margin-top: 0;
          margin-bottom: 10px;

          @media screen and (max-width: 1024px) {
            font-size: 25px
          }
        }

        h2 {
          font-family: Oswald, sans-serif;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .author-info {
          margin-top: 20px;

          .author-name {
            color: #444;
            font-size: 16px;
            font-family: bentonsansRegular, sans-serif;

            a {
              display: inline-block;
              color: #444;

              &.see-all {
                color: $blue;
              }
            }

            &.featured {
              margin-top: 10px;
              font-size: 30px;
              line-height: 1.3em;
            }

            .role {
              display: block;
            }
          }
        }

        .area-info {
          margin-top: auto;
          text-align: right;
          
          &.background {
            background-color: #f1f1f1;
            border-bottom: solid 2px #ccc;
            padding: 20px;
          }

          .areas-wrapper {
            background-color: #ddd;
            color: black;
            display: inline-block;
            
            .area {
              font-size: 12px;
              text-transform: uppercase;
              margin-right: 10px;
              padding: 5px 10px;
              position: relative;
              border-radius: 3px;
  
              &:first-child {
                //padding-left: 0;
              }
  
              &::after {
                content:"/";
                margin-left: 10px;
                font-size: 20px;
                color: black;
                position: absolute;
                top: 2px;
              }
  
              &:last-child::after {
                content: "";
              }
  
              .subarea, .subarea-2 {
                color: black;
              }
  
              .subarea-2 {
                text-transform: none;
              }
            }
              
          }

        }

      }

      .social {
        list-style: none;
        display: flex;
        padding-top: 5px;

        @media screen and (max-width: 768px) {
          width: 100%;
          justify-content: flex-end;
          padding-left: 0;
        }
        li {
          margin: 0 5px;
          &:last-child {
            margin-right: 0;
          }
          a {
            color: #dee2e6;
            transition: color .25s;
            font-size: 22px;
            &:hover {
              color: $black;
            }
          }
        }
      }
    }

  }

  .body {
    width: 90%;
    max-width: 1200px;
    margin: 30px auto;
    display: flex;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin-top: 0;
    }

    .meta-data {
      padding: 20px;
      background-color: #f1f1f1;
      flex-basis: 200px;

      @media screen and (max-width: 500px) {
        flex-basis: 100%;
      }
      .title {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 0;
        color: #777;
      }
    }

    .description {
      margin: 0 30px;
      flex-basis: calc( 100% - 480px );
      font-size: 18px;
      color: #333;
      line-height: 1.5em;

      @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-left: 0;
      }
      @media screen and (max-width: 500px) {
        margin: 30px 0;
      }
      h3, h4 {
        font-size: 18px;
        color: inherit;
        line-height: inherit;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 10px;
      }
    }

    .assets {
      flex-basis: 280px;

      @media screen and (max-width: 768px) {
        margin: 30px 0;
        flex-basis: 100%;
        flex-wrap: wrap;

        p {
          width: 100%;
        }
      }

      .aside-right {
        margin-bottom: 30px;
      }

      .btn {
        display: block;

        &:hover {
          text-decoration: none;
        }
      }

    }

  }


}
/* estilo plantilla colectivos*/

.blog-colectivos {

  background-color:$black;

  @include tablet{
    background-color:$white;
  }
   & .cabecera_colectivos{
     margin-top: 35px;
     @include tablet{
      margin-top: 0px;
    }
   } 

  .blog-header {
    &__data {
      padding:5%;
      @include tablet-big {
        padding:0;
      }
      & .blog-header__title {
        font-size: 45px;
        @include mobile {
          font-size: 25px;
        }
      }
      & .blog-header__text {
        font-size: 16px;
        padding-right: 20%;
        @include tablet-big {
          padding-right: 0px;
        }
        @include mobile {
          padding:5% 10%;
        }
      }
    }
    &__info {
      padding:3%;
      display: flex;
      @include tablet {
        padding: 0 12%;
      }
      @include mobile {
        padding: 0;
      }
      & .blog-header__tags {
        align-content: flex-end;
        @include mobile {
          padding: 5%;
        }

      }
      & .blog-header__social {
        align-content: flex-end;
        & .social-list {
          align-content: flex-end;
          flex-wrap: wrap;
          justify-content: flex-end;
          & .icon {
            &:before {
              @include desktop-small {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}

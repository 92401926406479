.delay0 {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s
}

.delay10 {
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -ms-animation-delay: .1s;
  -o-animation-delay: .1s;
  animation-delay: .1s
}

.delay20 {
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -ms-animation-delay: .2s;
  -o-animation-delay: .2s;
  animation-delay: .2s
}

.delay30 {
  -webkit-animation-delay: .30000000000000004s;
  -moz-animation-delay: .30000000000000004s;
  -ms-animation-delay: .30000000000000004s;
  -o-animation-delay: .30000000000000004s;
  animation-delay: .30000000000000004s
}

.delay40 {
  -webkit-animation-delay: .4s;
  -moz-animation-delay: .4s;
  -ms-animation-delay: .4s;
  -o-animation-delay: .4s;
  animation-delay: .4s
}

.delay50 {
  -webkit-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -ms-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s
}

.delay60 {
  -webkit-animation-delay: .6000000000000001s;
  -moz-animation-delay: .6000000000000001s;
  -ms-animation-delay: .6000000000000001s;
  -o-animation-delay: .6000000000000001s;
  animation-delay: .6000000000000001s
}

.delay70 {
  -webkit-animation-delay: .7000000000000001s;
  -moz-animation-delay: .7000000000000001s;
  -ms-animation-delay: .7000000000000001s;
  -o-animation-delay: .7000000000000001s;
  animation-delay: .7000000000000001s
}

.delay80 {
  -webkit-animation-delay: .8s;
  -moz-animation-delay: .8s;
  -ms-animation-delay: .8s;
  -o-animation-delay: .8s;
  animation-delay: .8s
}

.delay90 {
  -webkit-animation-delay: .9s;
  -moz-animation-delay: .9s;
  -ms-animation-delay: .9s;
  -o-animation-delay: .9s;
  animation-delay: .9s
}

.delay100 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s
}

.delay110 {
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  animation-delay: 1.1s
}

.delay120 {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  -ms-animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  animation-delay: 1.2s
}

.delay130 {
  -webkit-animation-delay: 1.3s;
  -moz-animation-delay: 1.3s;
  -ms-animation-delay: 1.3s;
  -o-animation-delay: 1.3s;
  animation-delay: 1.3s
}

.delay140 {
  -webkit-animation-delay: 1.4s;
  -moz-animation-delay: 1.4s;
  -ms-animation-delay: 1.4s;
  -o-animation-delay: 1.4s;
  animation-delay: 1.4s
}

.delay150 {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s
}

.delay160 {
  -webkit-animation-delay: 1.6s;
  -moz-animation-delay: 1.6s;
  -ms-animation-delay: 1.6s;
  -o-animation-delay: 1.6s;
  animation-delay: 1.6s
}

.delay170 {
  -webkit-animation-delay: 1.7s;
  -moz-animation-delay: 1.7s;
  -ms-animation-delay: 1.7s;
  -o-animation-delay: 1.7s;
  animation-delay: 1.7s
}

.delay180 {
  -webkit-animation-delay: 1.8s;
  -moz-animation-delay: 1.8s;
  -ms-animation-delay: 1.8s;
  -o-animation-delay: 1.8s;
  animation-delay: 1.8s
}

.delay190 {
  -webkit-animation-delay: 1.9s;
  -moz-animation-delay: 1.9s;
  -ms-animation-delay: 1.9s;
  -o-animation-delay: 1.9s;
  animation-delay: 1.9s
}

.delay200 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -ms-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s
}

.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s
}

.animated.bounceIn, .animated.bounceOut, .animated.flipOutX, .animated.flipOutY {
  -webkit-animation-duration: .75s;
  -moz-animation-duration: .75s;
  -ms-animation-duration: .75s;
  -o-animation-duration: .75s;
  animation-duration: .75s
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -ms-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  -moz-animation-name: bounceOut;
  -ms-animation-name: bounceOut;
  -o-animation-name: bounceOut;
  animation-name: bounceOut
}

.bounce {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -ms-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform-origin: center bottom
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -ms-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  -ms-animation-name: fadeOutDown;
  -o-animation-name: fadeOutDown;
  animation-name: fadeOutDown
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -ms-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  -moz-animation-name: fadeOutRight;
  -ms-animation-name: fadeOutRight;
  -o-animation-name: fadeOutRight;
  animation-name: fadeOutRight
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -ms-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -ms-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -ms-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -ms-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -ms-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  -moz-animation-name: zoomIn;
  -ms-animation-name: zoomIn;
  -o-animation-name: zoomIn;
  animation-name: zoomIn
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  -moz-animation-name: zoomOut;
  -ms-animation-name: zoomOut;
  -o-animation-name: zoomOut;
  animation-name: zoomOut
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  -moz-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  -o-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  -moz-animation-name: flipInX;
  -ms-animation-name: flipInX;
  -o-animation-name: flipInX;
  animation-name: flipInX
}

.spin {
  animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -ms-animation: spin 2s infinite linear
}

@-moz-keyframes bounceIn {
  0% {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  20% {
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	-moz-transform: scale3d(1.1, 1.1, 1.1);
	-ms-transform: scale3d(1.1, 1.1, 1.1);
	-o-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1)
  }
  40% {
	-webkit-transform: scale3d(.9, .9, .9);
	-moz-transform: scale3d(.9, .9, .9);
	-ms-transform: scale3d(.9, .9, .9);
	-o-transform: scale3d(.9, .9, .9);
	transform: scale3d(.9, .9, .9)
  }
  60% {
	opacity: 1;
	-webkit-transform: scale3d(1.03, 1.03, 1.03);
	-moz-transform: scale3d(1.03, 1.03, 1.03);
	-ms-transform: scale3d(1.03, 1.03, 1.03);
	-o-transform: scale3d(1.03, 1.03, 1.03);
	transform: scale3d(1.03, 1.03, 1.03)
  }
  80% {
	-webkit-transform: scale3d(.97, .97, .97);
	-moz-transform: scale3d(.97, .97, .97);
	-ms-transform: scale3d(.97, .97, .97);
	-o-transform: scale3d(.97, .97, .97);
	transform: scale3d(.97, .97, .97)
  }
  to {
	-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-ms-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-o-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	-moz-transform: scale3d(1, 1, 1);
	-ms-transform: scale3d(1, 1, 1);
	-o-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1)
  }
}

@-webkit-keyframes bounceIn {
  0% {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  20% {
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	-moz-transform: scale3d(1.1, 1.1, 1.1);
	-ms-transform: scale3d(1.1, 1.1, 1.1);
	-o-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1)
  }
  40% {
	-webkit-transform: scale3d(.9, .9, .9);
	-moz-transform: scale3d(.9, .9, .9);
	-ms-transform: scale3d(.9, .9, .9);
	-o-transform: scale3d(.9, .9, .9);
	transform: scale3d(.9, .9, .9)
  }
  60% {
	opacity: 1;
	-webkit-transform: scale3d(1.03, 1.03, 1.03);
	-moz-transform: scale3d(1.03, 1.03, 1.03);
	-ms-transform: scale3d(1.03, 1.03, 1.03);
	-o-transform: scale3d(1.03, 1.03, 1.03);
	transform: scale3d(1.03, 1.03, 1.03)
  }
  80% {
	-webkit-transform: scale3d(.97, .97, .97);
	-moz-transform: scale3d(.97, .97, .97);
	-ms-transform: scale3d(.97, .97, .97);
	-o-transform: scale3d(.97, .97, .97);
	transform: scale3d(.97, .97, .97)
  }
  to {
	-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-ms-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-o-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	-moz-transform: scale3d(1, 1, 1);
	-ms-transform: scale3d(1, 1, 1);
	-o-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1)
  }
}

@-o-keyframes bounceIn {
  0% {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  20% {
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	-moz-transform: scale3d(1.1, 1.1, 1.1);
	-ms-transform: scale3d(1.1, 1.1, 1.1);
	-o-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1)
  }
  40% {
	-webkit-transform: scale3d(.9, .9, .9);
	-moz-transform: scale3d(.9, .9, .9);
	-ms-transform: scale3d(.9, .9, .9);
	-o-transform: scale3d(.9, .9, .9);
	transform: scale3d(.9, .9, .9)
  }
  60% {
	opacity: 1;
	-webkit-transform: scale3d(1.03, 1.03, 1.03);
	-moz-transform: scale3d(1.03, 1.03, 1.03);
	-ms-transform: scale3d(1.03, 1.03, 1.03);
	-o-transform: scale3d(1.03, 1.03, 1.03);
	transform: scale3d(1.03, 1.03, 1.03)
  }
  80% {
	-webkit-transform: scale3d(.97, .97, .97);
	-moz-transform: scale3d(.97, .97, .97);
	-ms-transform: scale3d(.97, .97, .97);
	-o-transform: scale3d(.97, .97, .97);
	transform: scale3d(.97, .97, .97)
  }
  to {
	-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-ms-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-o-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	-moz-transform: scale3d(1, 1, 1);
	-ms-transform: scale3d(1, 1, 1);
	-o-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1)
  }
}

@keyframes bounceIn {
  0% {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  20% {
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	-moz-transform: scale3d(1.1, 1.1, 1.1);
	-ms-transform: scale3d(1.1, 1.1, 1.1);
	-o-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1)
  }
  40% {
	-webkit-transform: scale3d(.9, .9, .9);
	-moz-transform: scale3d(.9, .9, .9);
	-ms-transform: scale3d(.9, .9, .9);
	-o-transform: scale3d(.9, .9, .9);
	transform: scale3d(.9, .9, .9)
  }
  60% {
	opacity: 1;
	-webkit-transform: scale3d(1.03, 1.03, 1.03);
	-moz-transform: scale3d(1.03, 1.03, 1.03);
	-ms-transform: scale3d(1.03, 1.03, 1.03);
	-o-transform: scale3d(1.03, 1.03, 1.03);
	transform: scale3d(1.03, 1.03, 1.03)
  }
  80% {
	-webkit-transform: scale3d(.97, .97, .97);
	-moz-transform: scale3d(.97, .97, .97);
	-ms-transform: scale3d(.97, .97, .97);
	-o-transform: scale3d(.97, .97, .97);
	transform: scale3d(.97, .97, .97)
  }
  to {
	-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-ms-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-o-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	-moz-transform: scale3d(1, 1, 1);
	-ms-transform: scale3d(1, 1, 1);
	-o-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1)
  }
}

@-moz-keyframes bounceOut {
  20% {
	-webkit-transform: scale3d(.9, .9, .9);
	-moz-transform: scale3d(.9, .9, .9);
	-ms-transform: scale3d(.9, .9, .9);
	-o-transform: scale3d(.9, .9, .9);
	transform: scale3d(.9, .9, .9)
  }
  50%, 55% {
	opacity: 1;
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	-moz-transform: scale3d(1.1, 1.1, 1.1);
	-ms-transform: scale3d(1.1, 1.1, 1.1);
	-o-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1)
  }
  to {
	opacity: 0
  }
}

@-webkit-keyframes bounceOut {
  20% {
	-webkit-transform: scale3d(.9, .9, .9);
	-moz-transform: scale3d(.9, .9, .9);
	-ms-transform: scale3d(.9, .9, .9);
	-o-transform: scale3d(.9, .9, .9);
	transform: scale3d(.9, .9, .9)
  }
  50%, 55% {
	opacity: 1;
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	-moz-transform: scale3d(1.1, 1.1, 1.1);
	-ms-transform: scale3d(1.1, 1.1, 1.1);
	-o-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1)
  }
  to {
	opacity: 0
  }
}

@-o-keyframes bounceOut {
  20% {
	-webkit-transform: scale3d(.9, .9, .9);
	-moz-transform: scale3d(.9, .9, .9);
	-ms-transform: scale3d(.9, .9, .9);
	-o-transform: scale3d(.9, .9, .9);
	transform: scale3d(.9, .9, .9)
  }
  50%, 55% {
	opacity: 1;
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	-moz-transform: scale3d(1.1, 1.1, 1.1);
	-ms-transform: scale3d(1.1, 1.1, 1.1);
	-o-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1)
  }
  to {
	opacity: 0
  }
}

@keyframes bounceOut {
  20% {
	-webkit-transform: scale3d(.9, .9, .9);
	-moz-transform: scale3d(.9, .9, .9);
	-ms-transform: scale3d(.9, .9, .9);
	-o-transform: scale3d(.9, .9, .9);
	transform: scale3d(.9, .9, .9)
  }
  50%, 55% {
	opacity: 1;
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	-moz-transform: scale3d(1.1, 1.1, 1.1);
	-ms-transform: scale3d(1.1, 1.1, 1.1);
	-o-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1)
  }
  to {
	opacity: 0
  }
}

@-moz-keyframes bounce {
  to {
	-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-ms-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-o-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
  }
  40%, 43% {
	-webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-moz-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-ms-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-o-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-webkit-transform: translate3d(0, -30px, 0);
	-moz-transform: translate3d(0, -30px, 0);
	-ms-transform: translate3d(0, -30px, 0);
	-o-transform: translate3d(0, -30px, 0);
	transform: translate3d(0, -30px, 0)
  }
  70% {
	-webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-moz-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-ms-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-o-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-webkit-transform: translate3d(0, -15px, 0);
	-moz-transform: translate3d(0, -15px, 0);
	-ms-transform: translate3d(0, -15px, 0);
	-o-transform: translate3d(0, -15px, 0);
	transform: translate3d(0, -15px, 0)
  }
  90% {
	-webkit-transform: translate3d(0, -4px, 0);
	-moz-transform: translate3d(0, -4px, 0);
	-ms-transform: translate3d(0, -4px, 0);
	-o-transform: translate3d(0, -4px, 0);
	transform: translate3d(0, -4px, 0)
  }
}

@-webkit-keyframes bounce {
  to {
	-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-ms-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-o-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
  }
  40%, 43% {
	-webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-moz-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-ms-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-o-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-webkit-transform: translate3d(0, -30px, 0);
	-moz-transform: translate3d(0, -30px, 0);
	-ms-transform: translate3d(0, -30px, 0);
	-o-transform: translate3d(0, -30px, 0);
	transform: translate3d(0, -30px, 0)
  }
  70% {
	-webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-moz-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-ms-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-o-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-webkit-transform: translate3d(0, -15px, 0);
	-moz-transform: translate3d(0, -15px, 0);
	-ms-transform: translate3d(0, -15px, 0);
	-o-transform: translate3d(0, -15px, 0);
	transform: translate3d(0, -15px, 0)
  }
  90% {
	-webkit-transform: translate3d(0, -4px, 0);
	-moz-transform: translate3d(0, -4px, 0);
	-ms-transform: translate3d(0, -4px, 0);
	-o-transform: translate3d(0, -4px, 0);
	transform: translate3d(0, -4px, 0)
  }
}

@-o-keyframes bounce {
  to {
	-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-ms-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-o-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
  }
  40%, 43% {
	-webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-moz-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-ms-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-o-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-webkit-transform: translate3d(0, -30px, 0);
	-moz-transform: translate3d(0, -30px, 0);
	-ms-transform: translate3d(0, -30px, 0);
	-o-transform: translate3d(0, -30px, 0);
	transform: translate3d(0, -30px, 0)
  }
  70% {
	-webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-moz-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-ms-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-o-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-webkit-transform: translate3d(0, -15px, 0);
	-moz-transform: translate3d(0, -15px, 0);
	-ms-transform: translate3d(0, -15px, 0);
	-o-transform: translate3d(0, -15px, 0);
	transform: translate3d(0, -15px, 0)
  }
  90% {
	-webkit-transform: translate3d(0, -4px, 0);
	-moz-transform: translate3d(0, -4px, 0);
	-ms-transform: translate3d(0, -4px, 0);
	-o-transform: translate3d(0, -4px, 0);
	transform: translate3d(0, -4px, 0)
  }
}

@keyframes bounce {
  to {
	-webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-moz-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-ms-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-o-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	animation-timing-function: cubic-bezier(.215, .61, .355, 1);
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
  }
  40%, 43% {
	-webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-moz-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-ms-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-o-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-webkit-transform: translate3d(0, -30px, 0);
	-moz-transform: translate3d(0, -30px, 0);
	-ms-transform: translate3d(0, -30px, 0);
	-o-transform: translate3d(0, -30px, 0);
	transform: translate3d(0, -30px, 0)
  }
  70% {
	-webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-moz-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-ms-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-o-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	animation-timing-function: cubic-bezier(.755, .05, .855, .06);
	-webkit-transform: translate3d(0, -15px, 0);
	-moz-transform: translate3d(0, -15px, 0);
	-ms-transform: translate3d(0, -15px, 0);
	-o-transform: translate3d(0, -15px, 0);
	transform: translate3d(0, -15px, 0)
  }
  90% {
	-webkit-transform: translate3d(0, -4px, 0);
	-moz-transform: translate3d(0, -4px, 0);
	-ms-transform: translate3d(0, -4px, 0);
	-o-transform: translate3d(0, -4px, 0);
	transform: translate3d(0, -4px, 0)
  }
}

@-moz-keyframes fadeIn {
  from {
	opacity: 0
  }
  to {
	opacity: 1
  }
}

@-webkit-keyframes fadeIn {
  from {
	opacity: 0
  }
  to {
	opacity: 1
  }
}

@-o-keyframes fadeIn {
  from {
	opacity: 0
  }
  to {
	opacity: 1
  }
}

@keyframes fadeIn {
  from {
	opacity: 0
  }
  to {
	opacity: 1
  }
}

@-moz-keyframes fadeOut {
  from {
	opacity: 1
  }
  to {
	opacity: 0
  }
}

@-webkit-keyframes fadeOut {
  from {
	opacity: 1
  }
  to {
	opacity: 0
  }
}

@-o-keyframes fadeOut {
  from {
	opacity: 1
  }
  to {
	opacity: 0
  }
}

@keyframes fadeOut {
  from {
	opacity: 1
  }
  to {
	opacity: 0
  }
}

@-moz-keyframes fadeOutDown {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	-moz-transform: translate3d(0, 100%, 0);
	-ms-transform: translate3d(0, 100%, 0);
	-o-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0)
  }
}

@-webkit-keyframes fadeOutDown {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	-moz-transform: translate3d(0, 100%, 0);
	-ms-transform: translate3d(0, 100%, 0);
	-o-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0)
  }
}

@-o-keyframes fadeOutDown {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	-moz-transform: translate3d(0, 100%, 0);
	-ms-transform: translate3d(0, 100%, 0);
	-o-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0)
  }
}

@keyframes fadeOutDown {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	-moz-transform: translate3d(0, 100%, 0);
	-ms-transform: translate3d(0, 100%, 0);
	-o-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0)
  }
}

@-moz-keyframes fadeOutLeft {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	-ms-transform: translate3d(-100%, 0, 0);
	-o-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0)
  }
}

@-webkit-keyframes fadeOutLeft {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	-ms-transform: translate3d(-100%, 0, 0);
	-o-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0)
  }
}

@-o-keyframes fadeOutLeft {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	-ms-transform: translate3d(-100%, 0, 0);
	-o-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0)
  }
}

@keyframes fadeOutLeft {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	-ms-transform: translate3d(-100%, 0, 0);
	-o-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0)
  }
}

@-moz-keyframes fadeOutRight {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	-moz-transform: translate3d(100%, 0, 0);
	-ms-transform: translate3d(100%, 0, 0);
	-o-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
  }
}

@-webkit-keyframes fadeOutRight {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	-moz-transform: translate3d(100%, 0, 0);
	-ms-transform: translate3d(100%, 0, 0);
	-o-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
  }
}

@-o-keyframes fadeOutRight {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	-moz-transform: translate3d(100%, 0, 0);
	-ms-transform: translate3d(100%, 0, 0);
	-o-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
  }
}

@keyframes fadeOutRight {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	-moz-transform: translate3d(100%, 0, 0);
	-ms-transform: translate3d(100%, 0, 0);
	-o-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
  }
}

@-moz-keyframes fadeOutUp {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	-moz-transform: translate3d(0, -100%, 0);
	-ms-transform: translate3d(0, -100%, 0);
	-o-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0)
  }
}

@-webkit-keyframes fadeOutUp {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	-moz-transform: translate3d(0, -100%, 0);
	-ms-transform: translate3d(0, -100%, 0);
	-o-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0)
  }
}

@-o-keyframes fadeOutUp {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	-moz-transform: translate3d(0, -100%, 0);
	-ms-transform: translate3d(0, -100%, 0);
	-o-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0)
  }
}

@keyframes fadeOutUp {
  from {
	opacity: 1
  }
  to {
	opacity: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	-moz-transform: translate3d(0, -100%, 0);
	-ms-transform: translate3d(0, -100%, 0);
	-o-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0)
  }
}

@-moz-keyframes fadeInDownBig {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, -2000px, 0);
	-moz-transform: translate3d(0, -2000px, 0);
	-ms-transform: translate3d(0, -2000px, 0);
	-o-transform: translate3d(0, -2000px, 0);
	transform: translate3d(0, -2000px, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-webkit-keyframes fadeInDownBig {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, -2000px, 0);
	-moz-transform: translate3d(0, -2000px, 0);
	-ms-transform: translate3d(0, -2000px, 0);
	-o-transform: translate3d(0, -2000px, 0);
	transform: translate3d(0, -2000px, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-o-keyframes fadeInDownBig {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, -2000px, 0);
	-moz-transform: translate3d(0, -2000px, 0);
	-ms-transform: translate3d(0, -2000px, 0);
	-o-transform: translate3d(0, -2000px, 0);
	transform: translate3d(0, -2000px, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@keyframes fadeInDownBig {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, -2000px, 0);
	-moz-transform: translate3d(0, -2000px, 0);
	-ms-transform: translate3d(0, -2000px, 0);
	-o-transform: translate3d(0, -2000px, 0);
	transform: translate3d(0, -2000px, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-moz-keyframes fadeInUp {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	-moz-transform: translate3d(0, 100%, 0);
	-ms-transform: translate3d(0, 100%, 0);
	-o-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-webkit-keyframes fadeInUp {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	-moz-transform: translate3d(0, 100%, 0);
	-ms-transform: translate3d(0, 100%, 0);
	-o-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-o-keyframes fadeInUp {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	-moz-transform: translate3d(0, 100%, 0);
	-ms-transform: translate3d(0, 100%, 0);
	-o-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@keyframes fadeInUp {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, 100%, 0);
	-moz-transform: translate3d(0, 100%, 0);
	-ms-transform: translate3d(0, 100%, 0);
	-o-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-moz-keyframes fadeInDown {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	-moz-transform: translate3d(0, -100%, 0);
	-ms-transform: translate3d(0, -100%, 0);
	-o-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-webkit-keyframes fadeInDown {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	-moz-transform: translate3d(0, -100%, 0);
	-ms-transform: translate3d(0, -100%, 0);
	-o-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-o-keyframes fadeInDown {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	-moz-transform: translate3d(0, -100%, 0);
	-ms-transform: translate3d(0, -100%, 0);
	-o-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@keyframes fadeInDown {
  from {
	opacity: 0;
	-webkit-transform: translate3d(0, -100%, 0);
	-moz-transform: translate3d(0, -100%, 0);
	-ms-transform: translate3d(0, -100%, 0);
	-o-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-moz-keyframes fadeInLeft {
  from {
	opacity: 0;
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	-ms-transform: translate3d(-100%, 0, 0);
	-o-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-webkit-keyframes fadeInLeft {
  from {
	opacity: 0;
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	-ms-transform: translate3d(-100%, 0, 0);
	-o-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-o-keyframes fadeInLeft {
  from {
	opacity: 0;
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	-ms-transform: translate3d(-100%, 0, 0);
	-o-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@keyframes fadeInLeft {
  from {
	opacity: 0;
	-webkit-transform: translate3d(-100%, 0, 0);
	-moz-transform: translate3d(-100%, 0, 0);
	-ms-transform: translate3d(-100%, 0, 0);
	-o-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-moz-keyframes fadeInRight {
  from {
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	-moz-transform: translate3d(100%, 0, 0);
	-ms-transform: translate3d(100%, 0, 0);
	-o-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-webkit-keyframes fadeInRight {
  from {
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	-moz-transform: translate3d(100%, 0, 0);
	-ms-transform: translate3d(100%, 0, 0);
	-o-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-o-keyframes fadeInRight {
  from {
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	-moz-transform: translate3d(100%, 0, 0);
	-ms-transform: translate3d(100%, 0, 0);
	-o-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@keyframes fadeInRight {
  from {
	opacity: 0;
	-webkit-transform: translate3d(100%, 0, 0);
	-moz-transform: translate3d(100%, 0, 0);
	-ms-transform: translate3d(100%, 0, 0);
	-o-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
  }
  to {
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none
  }
}

@-moz-keyframes zoomIn {
  from {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  50% {
	opacity: 1
  }
}

@-webkit-keyframes zoomIn {
  from {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  50% {
	opacity: 1
  }
}

@-o-keyframes zoomIn {
  from {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  50% {
	opacity: 1
  }
}

@keyframes zoomIn {
  from {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  50% {
	opacity: 1
  }
}

@-moz-keyframes zoomOut {
  from {
	opacity: 1
  }
  50% {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  to {
	opacity: 0
  }
}

@-webkit-keyframes zoomOut {
  from {
	opacity: 1
  }
  50% {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  to {
	opacity: 0
  }
}

@-o-keyframes zoomOut {
  from {
	opacity: 1
  }
  50% {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  to {
	opacity: 0
  }
}

@keyframes zoomOut {
  from {
	opacity: 1
  }
  50% {
	opacity: 0;
	-webkit-transform: scale3d(.3, .3, .3);
	-moz-transform: scale3d(.3, .3, .3);
	-ms-transform: scale3d(.3, .3, .3);
	-o-transform: scale3d(.3, .3, .3);
	transform: scale3d(.3, .3, .3)
  }
  to {
	opacity: 0
  }
}

@-moz-keyframes flipInX {
  from {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-webkit-animation-timing-function: ease-in;
	-moz-animation-timing-function: ease-in;
	-ms-animation-timing-function: ease-in;
	-o-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
	opacity: 0
  }
  40% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-webkit-animation-timing-function: ease-in;
	-moz-animation-timing-function: ease-in;
	-ms-animation-timing-function: ease-in;
	-o-animation-timing-function: ease-in;
	animation-timing-function: ease-in
  }
  60% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	opacity: 1
  }
  80% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
  }
  to {
	-webkit-transform: perspective(400px);
	-moz-transform: perspective(400px);
	-ms-transform: perspective(400px);
	-o-transform: perspective(400px);
	transform: perspective(400px)
  }
}

@-webkit-keyframes flipInX {
  from {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-webkit-animation-timing-function: ease-in;
	-moz-animation-timing-function: ease-in;
	-ms-animation-timing-function: ease-in;
	-o-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
	opacity: 0
  }
  40% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-webkit-animation-timing-function: ease-in;
	-moz-animation-timing-function: ease-in;
	-ms-animation-timing-function: ease-in;
	-o-animation-timing-function: ease-in;
	animation-timing-function: ease-in
  }
  60% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	opacity: 1
  }
  80% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
  }
  to {
	-webkit-transform: perspective(400px);
	-moz-transform: perspective(400px);
	-ms-transform: perspective(400px);
	-o-transform: perspective(400px);
	transform: perspective(400px)
  }
}

@-o-keyframes flipInX {
  from {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-webkit-animation-timing-function: ease-in;
	-moz-animation-timing-function: ease-in;
	-ms-animation-timing-function: ease-in;
	-o-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
	opacity: 0
  }
  40% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-webkit-animation-timing-function: ease-in;
	-moz-animation-timing-function: ease-in;
	-ms-animation-timing-function: ease-in;
	-o-animation-timing-function: ease-in;
	animation-timing-function: ease-in
  }
  60% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	opacity: 1
  }
  80% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
  }
  to {
	-webkit-transform: perspective(400px);
	-moz-transform: perspective(400px);
	-ms-transform: perspective(400px);
	-o-transform: perspective(400px);
	transform: perspective(400px)
  }
}

@keyframes flipInX {
  from {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
	-webkit-animation-timing-function: ease-in;
	-moz-animation-timing-function: ease-in;
	-ms-animation-timing-function: ease-in;
	-o-animation-timing-function: ease-in;
	animation-timing-function: ease-in;
	opacity: 0
  }
  40% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
	-webkit-animation-timing-function: ease-in;
	-moz-animation-timing-function: ease-in;
	-ms-animation-timing-function: ease-in;
	-o-animation-timing-function: ease-in;
	animation-timing-function: ease-in
  }
  60% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
	opacity: 1
  }
  80% {
	-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-moz-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-ms-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	-o-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
  }
  to {
	-webkit-transform: perspective(400px);
	-moz-transform: perspective(400px);
	-ms-transform: perspective(400px);
	-o-transform: perspective(400px);
	transform: perspective(400px)
  }
}

@-moz-keyframes spin {
  from {
	-webkit-transform: rotate(0);
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0);
	transform: rotate(0)
  }
  to {
	-webkit-transform: rotate(359deg);
	-moz-transform: rotate(359deg);
	-ms-transform: rotate(359deg);
	-o-transform: rotate(359deg);
	transform: rotate(359deg)
  }
}

@-webkit-keyframes spin {
  from {
	-webkit-transform: rotate(0);
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0);
	transform: rotate(0)
  }
  to {
	-webkit-transform: rotate(359deg);
	-moz-transform: rotate(359deg);
	-ms-transform: rotate(359deg);
	-o-transform: rotate(359deg);
	transform: rotate(359deg)
  }
}

@-o-keyframes spin {
  from {
	-webkit-transform: rotate(0);
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0);
	transform: rotate(0)
  }
  to {
	-webkit-transform: rotate(359deg);
	-moz-transform: rotate(359deg);
	-ms-transform: rotate(359deg);
	-o-transform: rotate(359deg);
	transform: rotate(359deg)
  }
}

@keyframes spin {
  from {
	-webkit-transform: rotate(0);
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0);
	transform: rotate(0)
  }
  to {
	-webkit-transform: rotate(359deg);
	-moz-transform: rotate(359deg);
	-ms-transform: rotate(359deg);
	-o-transform: rotate(359deg);
	transform: rotate(359deg)
  }
}

@-moz-keyframes ripple {
  100% {
	opacity: 0;
	-webkit-transform: scale(2.5);
	-moz-transform: scale(2.5);
	-ms-transform: scale(2.5);
	-o-transform: scale(2.5);
	transform: scale(2.5)
  }
}

@-webkit-keyframes ripple {
  100% {
	opacity: 0;
	-webkit-transform: scale(2.5);
	-moz-transform: scale(2.5);
	-ms-transform: scale(2.5);
	-o-transform: scale(2.5);
	transform: scale(2.5)
  }
}

@-o-keyframes ripple {
  100% {
	opacity: 0;
	-webkit-transform: scale(2.5);
	-moz-transform: scale(2.5);
	-ms-transform: scale(2.5);
	-o-transform: scale(2.5);
	transform: scale(2.5)
  }
}

@keyframes ripple {
  100% {
	opacity: 0;
	-webkit-transform: scale(2.5);
	-moz-transform: scale(2.5);
	-ms-transform: scale(2.5);
	-o-transform: scale(2.5);
	transform: scale(2.5)
  }
}

@-moz-keyframes delayOpacity {
  0%, 100%, 50% {
	opacity: 1
  }
  25%, 75% {
	opacity: .2
  }
}

@-webkit-keyframes delayOpacity {
  0%, 100%, 50% {
	opacity: 1
  }
  25%, 75% {
	opacity: .2
  }
}

@-o-keyframes delayOpacity {
  0%, 100%, 50% {
	opacity: 1
  }
  25%, 75% {
	opacity: .2
  }
}

@keyframes delayOpacity {
  0%, 100%, 50% {
	opacity: 1
  }
  25%, 75% {
	opacity: .2
  }
}

@-moz-keyframes fundacion {
  0% {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0)
  }
  100%, 80% {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1)
  }
}

@-webkit-keyframes fundacion {
  0% {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0)
  }
  100%, 80% {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1)
  }
}

@-o-keyframes fundacion {
  0% {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0)
  }
  100%, 80% {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1)
  }
}

@keyframes fundacion {
  0% {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0)
  }
  100%, 80% {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1)
  }
}

@-moz-keyframes fundacion-text {
  0%, 80% {
	opacity: 0
  }
  100% {
	opacity: 1
  }
}

@-webkit-keyframes fundacion-text {
  0%, 80% {
	opacity: 0
  }
  100% {
	opacity: 1
  }
}

@-o-keyframes fundacion-text {
  0%, 80% {
	opacity: 0
  }
  100% {
	opacity: 1
  }
}

@keyframes fundacion-text {
  0%, 80% {
	opacity: 0
  }
  100% {
	opacity: 1
  }
}

@-moz-keyframes ranking {
  0% {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transform-origin: 50% 100%;
	-moz-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-o-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	opacity: 1
  }
  100% {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transform-origin: 50% 100%;
	-moz-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-o-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	opacity: 1
  }
}

@-webkit-keyframes ranking {
  0% {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transform-origin: 50% 100%;
	-moz-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-o-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	opacity: 1
  }
  100% {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transform-origin: 50% 100%;
	-moz-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-o-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	opacity: 1
  }
}

@-o-keyframes ranking {
  0% {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transform-origin: 50% 100%;
	-moz-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-o-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	opacity: 1
  }
  100% {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transform-origin: 50% 100%;
	-moz-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-o-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	opacity: 1
  }
}

@keyframes ranking {
  0% {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transform-origin: 50% 100%;
	-moz-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-o-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	opacity: 1
  }
  100% {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transform-origin: 50% 100%;
	-moz-transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-o-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	opacity: 1
  }
}

@-moz-keyframes calendarHand {
  0% {
	right: 10px
  }
  100% {
	right: 90px
  }
}

@-webkit-keyframes calendarHand {
  0% {
	right: 10px
  }
  100% {
	right: 90px
  }
}

@-o-keyframes calendarHand {
  0% {
	right: 10px
  }
  100% {
	right: 90px
  }
}

@keyframes calendarHand {
  0% {
	right: 10px
  }
  100% {
	right: 90px
  }
}

@-moz-keyframes calendarMonths {
  0% {
	opacity: 0;
	left: 0
  }
  1% {
	opacity: 1;
	left: 0
  }
  100%, 8% {
	opacity: 1;
	left: -100%
  }
}

@-webkit-keyframes calendarMonths {
  0% {
	opacity: 0;
	left: 0
  }
  1% {
	opacity: 1;
	left: 0
  }
  100%, 8% {
	opacity: 1;
	left: -100%
  }
}

@-o-keyframes calendarMonths {
  0% {
	opacity: 0;
	left: 0
  }
  1% {
	opacity: 1;
	left: 0
  }
  100%, 8% {
	opacity: 1;
	left: -100%
  }
}

@keyframes calendarMonths {
  0% {
	opacity: 0;
	left: 0
  }
  1% {
	opacity: 1;
	left: 0
  }
  100%, 8% {
	opacity: 1;
	left: -100%
  }
}

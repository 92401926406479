$capaPadre: area, subarea, formato;

.capaId {
  position: absolute;
  top: 110px;
  left: 8px;
  width: 600px;
  min-height: 120px;
  z-index: 2;
  border-radius: 0;
  border: 1px solid $dark-grey;
  .contentId {
    color: $white;
    background-color: $black;
    padding: 10px;
    border-radius: 0;
    min-height: 120px;

    &::before {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      /* izquierda flecha */
      border-right: 15px solid transparent;
      /* derecha flecha */
      border-bottom: 15px solid $black;
      /* base flecha y color*/
      font-size: 0;
      line-height: 0;
      content: "";
      margin-top: -14px;
      top: 0;
      z-index: 3;
      position: absolute;
      left: 350px;
    }
    &::after {
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      /* izquierda flecha */
      border-right: 16px solid transparent;
      /* derecha flecha */
      border-bottom: 16px solid $dark-grey;
      /* base flecha y color*/
      font-size: 0;
      line-height: 0;
      content: "";
      margin-top: -14px;
      top: -2px;
      z-index: 2;
      position: absolute;
      left: 349px;
    }

    .switchAll {
      flex: 0 1 100%;
      padding: 10px;
    }

    .header-popup {
      display: flex;
      justify-content: space-between;

      .switchAll {
        flex: none;
      }
    }

    & > p {
      padding: 0 10px;
      margin-bottom: 0;
    }
  }

  @each $nombre in $capaPadre {
    @if $nombre == "area" {
      .#{$nombre} > & {
        left: 0;
        .contentId {
          &::before {
            left: 50px;
          }
          &::after {
            left: 49px;
          }
        }
      }
    }
    @if $nombre == "subarea" {
      .#{$nombre} > & {
        left: 10%;
        .contentId {
          &::before {
            left: 225px;
          }
          &::after {
            left: 224px;
          }
        }
      }
    }
  }
}

/* responsive  */

@media all and (max-width: $media-tablet-plus) {
  .capaId {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 10px;
    width: 100%;
    z-index: 2;
    border-radius: 0px;
    border: none;
    .contentId {
      .formatosCheck {
        .checkContainer {
          flex: 0 1 45%;
        }
      }
    }
    @each $nombre in $capaPadre {
      @if $nombre == "area" {
        .#{$nombre} > & {
          left: 0;
          .contentId {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
        }
      }
      @if $nombre == "subarea" {
        .#{$nombre} > & {
          left: 0;
          .contentId {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

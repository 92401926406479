.series-section {
  padding: 40px 0;

  .series-section-title {
    margin-top: 0;
    display: flex;
    font-size: 26px;

    &:after {
      content: "";
      flex-grow: 1;
      border-bottom: 1px solid #ccc;
      margin: auto 0 auto 20px;

      @include mobile {
        border: 0;
      }
    }

  }

}
.series-element {

  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all .4s;

  &:hover {
    box-shadow: 0 5px 15px -6px #333;
  }

  .single-element-header {

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 200px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    opacity: 0.8;
    transition: all .25s;

    &:hover {
      opacity: 1;
    }

  }

  .single-element-body {
    padding: 10px 20px;

    a {
      color: $black;
      transition: all .25s;

      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }

    .single-element-title {
      font-family: Oswald, sans-serif;
      font-weight: bold;
      margin: 10px 0 20px;
    }
  }

  .single-element-footer {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    font-family: bentonsansBold, sans-serif;
    font-size: 13px;

    .series-start-date {
      color: #999;
    }
  }
}
.ciclos-eventos{
  text-decoration: none!important;
}
.ciclos-eventos:hover{
  color:gray!important;
}
//Listado de blogs mixtos en modo masonry
/* masonry --- 3 columns by default */
.lst-entradas {
  margin-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid $grey-light-blog-2;
  @include tablet {
    padding-bottom: 0px;
    border-bottom: 0px solid $grey-light-blog-2;
  }

  & .blog-list__title {
    font-family: $font_bentonsansBold;
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    padding: 0 0 24px;
    @include desktop-small {
      text-align: center;
      font-size: 35px;
    }
    @include mobile {
      font-size: 25px;
    }
  }
}


 //cambio wp
.grid {
  max-width: 1240px;
  counter-reset: grid-item;

  &.blog-list__mixed {
    @include desktop-small {
      max-width: $media-desktop-small;
      margin-left: calc(100% - 103%);
      margin: 0 auto;
    }

    @include tablet {
      max-width: $media-tablet;
      margin-left: calc(100% - 103%);
    }
    @include mobile {
      max-width: $media-mobile-landscape;
      margin-left: calc(100% - 107%);
    }
    @include tablet-plus {
      max-width: $media-tablet-plus;
      margin-left: calc(100% - 105%);
    }
    @include mobile-medium {
      max-width: $media-mobile-landscape2;
      margin-left: calc(100% - 105%);
    }
    @include mobile-small {
      max-width: $media-mobile-portrait;
      margin-left: calc(100% - 107%);
    }
  }
} // fin cambio wp

/* clearfix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- grid-item ---- */
.grid-3column {
  .grid-item {
    width: 390px;
    height: auto;
    float: left;
    margin-bottom: 30px;
    margin-right:20px; //cambio wp
    background: #f8f8f8;

    @include desktop-small {
      width: 701px;
      margin-left: 0%;
    }
    @include tablet {
      width: $media-tablet;
    }

    @include mobile {
      width: $media-mobile-landscape;
    }
    @include tablet-plus {
      width: $media-tablet-plus;
    }
    @include mobile-medium {
      width: $media-mobile-landscape2;
    }
    @include mobile-small {
      width: $media-mobile-portrait;
    }
  
  }
}
.grid-2column {
  .grid-item {
    width: 580px;
    height: auto;
    float: left;
    margin-bottom: 30px;
    margin-right:20px; //cambio wp
    background: #f8f8f8;
    @include desktop-small {
      width: 740px;
      margin-left: -14%;
    }

    @include tablet {
      width: $media-tablet;
      margin-left: 0;
    }

    @include mobile {
      width: $media-mobile-landscape;
    }
    @include tablet-plus {
      width: $media-tablet-plus;
    }
    @include mobile-medium {
      width: $media-mobile-landscape2;
    }
    @include mobile-small {
      width: $media-mobile-portrait;
    }
  }
}

.grid-item:before {
  counter-increment: grid-item;
  content: counter(grid-item);
  display: block;
  color: white;
  padding-top: 0;
  text-align: center;
  font-size: 1.4rem;
}

.blog__item {
  padding-bottom: 0px;
  margin-bottom: 40px;

  .blog-item__title {
    margin: 0;
    padding: 18px 15px;
    background: $black;
    color: $white;
    font-family: Oswald;
    font-size: 24px;
    font-weight: $bold;
    text-transform: uppercase;
    text-align: center;
  }
  .blog__info {
    padding: 24px 12px;
    border-bottom: 1px solid $grey-light-blog;
    margin-bottom: 0;
    @include tablet-big {
      padding: 24px 51px;
    }
    @include tablet-portrait {
      padding: 24px 12px;
    }
  }
  .line-space {
    border-bottom: 1px solid $grey-light-blog;
    width: 90%;
    height: 1px;
    border-bottom: 1px solid #e0e0e0;
    margin: 0px 5%;
  }

  & .author {
    &__info {
      display: flex;
      justify-content: space-between;
      min-height: 100px;
      align-items: start; //cambio wp
      padding: 20px 30px 10px 30px; //cambio wp
      justify-content: start;

      .author__picture {
        padding-right: 10px;
        width: 100px;
        margin-top:0px; //cambio wp
        img {
          max-width: 90px;
          border-radius: 50%;
        }
      }

      & p {
        &.author__txt {
          margin: 0px;
          font-family: $font_bentonsansRegular;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .main-image {
    @include size(100%, 170px);
    padding: 0;
    margin: 0;
    background-size: cover;
  }

  .last-post {
    padding: 15px 15px 30px;
    font-family: $font_bentonsansRegular;

    .post {
      &__date {
        display: inline-block;
        font-size: 16px;
        color: $grey-mid-blog;
        padding-bottom: 6px;
        font-family: $font_bentonsansRegular;
      }
      &__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.33;
        margin: 0;
        font-family: $font_bentonsansRegular;
      }
      &__author {
        a {
          color: $blue;
        }
      }
    }
  }
}

img, legend {
  border: 0
}

legend, td, th {
  padding: 0
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}
body *, html * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

body, html {
  height: 100%;
  font-family: $font_bentonsansRegular;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5em;
  color: #333;
  scroll-behavior: smooth
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline
}

[hidden], template {
  display: none
}

a {
  background: 0 0
}

a {
  color: inherit
}

a:active, a:hover {
  outline: 0
}

abbr[title] {
  border-bottom: 1px dotted
}

dfn {
  font-style: italic
}

h1 {
  margin: .67em 0
}

mark {
  background: #ff0;
  color: #000
}

small {
  font-size: 80%
}

b, strong {
  font-weight: 600;
  font-family: 'bentonsansBold', sans-serif;
}

p {
  margin: 0 0 1em;

  b, strong {
    font-family: bentonsansBold, sans-serif;
  }
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -.5em
}

sub {
  bottom: -.25em
}

hr {
  -webkit-moz-box-sizing: content-box;
  -moz-moz-box-sizing: content-box;
  -ms-moz-box-sizing: content-box;
  -o-moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0
}

pre, textarea {
  overflow: auto
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0
}

button {
  overflow: visible
}

button, select {
  text-transform: none
}

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer
}

button[disabled], html input[disabled] {
  cursor: default
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input {
  line-height: normal
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  height: auto
}
/*
input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}
*/

optgroup {
  font-weight: 700
}

fieldset {
  margin: 0;
  border: 0;
  padding: 10px 10px 0
}

table {
  border-spacing: 0
}

img {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto
}

figure {
  margin: 1em 0
}

address {
  display: block;
  margin-bottom: 20px
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #eee
}

blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857
}

b, strong {
   font-weight: 600
 }

p {
  margin: 0 0 1em
}
@import '../heredity/components/custom-checkbox.scss';

.section-wrapper {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
}

.header-catalogo {
  position: relative;
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;

  @include tablet {
    padding: 20px;
  }

  @include mobile {
    height: 200px;
  }

  .header-content {
    max-width: 1240px;
    flex-grow: 1;
    margin: 0 auto;
    color: $white;
    font-size: 50px;
    font-family: Oswald, sans-serif;
    z-index: 1;

    .header-title {
      font-weight: bold;
      text-transform: uppercase;

      @include mobile {
        font-size: 28px;
      }
    }

    .header-subtitle {
      font-weight: 300;

      @include mobile {
        font-size: 28px;
      }
    }

  }

}

.search-form {
  background-color: black;
  padding: 40px 0;
  color: #aaa;

  @include tablet {
    padding: 40px;
  }

  @include mobile {
    padding: 10px;
  }

  a {
    color: #aaa;
  }

  .search-form-wrapper {

    .search-form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include mobile {
        flex-direction: column;
      }

      &.with-title {        
        .form-title {
          opacity: 1;
        }
      }

      .form-title {
        font-family: Oswald, sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        opacity: 0;
        cursor: default;

      }

      p {

        @include mobile {
          display: flex;
          flex-direction: column;
          width: 100%;
          text-align: left;
          margin-bottom: 10px;
        }

        & > a {
          transition: all .25s;

          &:hover {
            text-decoration: none;
            color: #fff;
          }
        }
      }

      .goto-author-index {
        margin-right: 20px;
        padding-right: 10px;
        border-right: solid 1px #ccc;

        @include mobile {
          border: 0;
        }

      }

      .simple-search-link {
        display: none;
      }

    }

    .search-form-body {

      .advanced-search {
        display: none;

        @include mobile {
          padding: 0;
        }
      }

      .wrapper-fields {
        display: flex;
        justify-content: space-between;

        @include mobile {
          flex-direction: column;
        }
      }

      .form-field {

        margin-bottom: 25px;
        position: relative;

        &.width-50 {
          width: 49%;

          @include mobile {
            width: 100%;
          }
        }

        &.submit-buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 30px;

          @include mobile {
            flex-direction: column-reverse;
          }

          button {
            @include mobile {
              width: 100%;
              margin-bottom: 10px;
            }
          }

          button[type="submit"] {
            font-size: 16px;
            text-transform: uppercase;
            padding: 15px 60px;

            .fa-spinner {
              display: none;
            }
          }

          button[type="reset"] {
            font-size: 14px;
            background-color: transparent;
            color: #aaa;
            border: 0;
            margin-right: 10px;
            transition: color .25s;

            &:hover {
              color: white;
            }
          }
        }

        .wrapper-options {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          &.inline-options {
            flex-direction: row;
          }
        }

        & > label {
          display: flex;
          justify-content: space-between;
          width: 100%;
          text-transform: uppercase;
          font-size: 14px;
          margin-bottom: 5px;

          @include mobile {
            flex-direction: column-reverse;
          }

          a {
            text-transform: none;
            transition: all .25s;

            &:hover {
              text-decoration: none;
              color: white;
            }
          }
        }

        input[type="text"], input[type="search"], .styled-select, .styled-options li {
          width: 100%;
          height: 55px;
          background: #2B2B2B;
          border: none;
          border-radius: 1px;
          border-bottom: 1px solid #7f7f7f;
          font-size: 18px;
          padding: 0 15px;
          color: #aaa;
        }

        .styled-select {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          transition: all .25s;
          
          &:hover {
            cursor: pointer;
            background-color: #111;
          }

          & > .fa {
            transition: all .25s;

            &.select-open {
              transform: rotate( 180deg );
            }
          }
        }

        .styled-options {
          list-style-type: none;
          margin: 0;
          padding: 0;
          max-height: 425px;
          overflow-y: auto;
          display: none;

          li {
            display: flex;
            align-items: center;
            transition: all .25s;

            &:hover {
              background-color: #111;
              cursor: pointer;
            }
          }

          &.checkbox-list {

            background-color: #2b2b2b;
            padding: 10px 20px;

            .parent-area {
              border-bottom: dashed 1px #aaa;
              padding-bottom: 5px;
              margin-bottom: 5px;

              & > label {
                text-transform: uppercase;
              }

              &:last-child {
                border: 0;
              }
            }
            .children-area {
              padding-left: 20px;
            }

          }
        }

      }

    }
  }
}

.table-results-section {

  padding: 40px 0;

  @include tablet {
    padding: 40px 20px;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    @include mobile {
      flex-direction: column;
    }

    .total-results {
      font-family: Oswald, sans-serif;
      font-size: 40px;
      text-transform: uppercase;

      @include mobile {
        font-size: 28px;
      }

      span {
        font-weight: bold;
      }
    }

    .table-actions {
      text-align: right;

      @include mobile {
        text-align: left;
      }

      select {
        height: 40px;
        border: solid 1px #ccc;
        border-radius: 5px;
        padding: 0 5px;
        font-size: 18px;
      }
    }
  }

  .table-results-body {

    .loading {
      margin: 30px 0;
      text-align: center;
      font-size: 20px;
    }

    .table-results {

      .single-row {
        display: grid;
        grid-template-columns: 0.7fr 2fr 2fr 1fr 1fr 2fr 0.5fr;
        column-gap: 10px;
        padding: 15px 10px;
        line-height: 1.3em;
        transition: all .25s;

        @include tablet {
          font-size: 12px;
        }

        @include mobile {
          font-size: 16px;
          grid-template-columns: 1fr;
          row-gap: 10px;
        }

        &:hover {
          background-color: #ddd;
        }

        &:nth-child(even) {
          background-color: #f4f4f4;

          &:hover {
            background-color: #ddd;
          }  
        }

        a {
          transition: color .25s;
        }

        a:hover {
          text-decoration: none;
          color: $blue;
        }

        p {
          margin-bottom: 5px;

          &.area {
            text-transform: uppercase;
            font-size: 12px;

            @include tablet {
              font-size: 11px;
            }

            @include mobile {
              font-size: 12px;
            }
          }

          &.subarea {
            font-size: 14px;

            @include tablet {
              font-size: 12px;
            }

            @include mobile {
              font-size: 14px;
            }

          }

          .open-modal-authors {
            color: $blue;
            font-size: 13px;
          }
        }

        .single-cell {
          &:last-child {
            display: flex;
          }
        }
      }
      @media screen and (max-width: 700px) {
        .tamanio-movil{
          font-size: 14px;
        }

        .strong{
          font-weight: 600;
          font-family: bentonsansBold, sans-serif;
        }

        .ocultar-en-movil{
          display: none;
        }
        .iconos-movil{
          flex-direction: row-reverse;
          font-size: large;
        }
      }

      &.header {
        font-family: Oswald, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: solid 1px #ddd;

        @include tablet {
          padding: 0;
        }
        
        .single-row {
          background-color: #fff;

          @include mobile {
            grid-template-columns: 1fr 1fr;
          }
        }

        p {
          display: flex;
          align-items: center;

          @include mobile {
            display: none;

            &.show-in-mobile {
              display: flex;
            }
          }

          span {
            display: flex;
            flex-direction: column;
            margin-left: 5px;

            .fa {
              cursor: pointer;
              transition: color .25s;
              font-size: 20px;
              color: #ccc;
              line-height: 0.7em;

              &:hover {
                color: $blue;
              }

              &:first-child {
                margin-bottom: 0;
              }

              &.active {
                color: $blue;
              }
            }
          }
        }
      }
    }
  }
}

.modal-authors {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba( 0, 0, 0, 0.5 );
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .authors-wrapper {
    background-color: white;
    width: 100%;
    max-width: 600px;
    max-height: 600px;
    height: 65vh;
    overflow-y: auto;
    padding: 40px;

    header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .modal-title {
        font-size: 24px;
      }
    }

    p {
      margin-bottom: 10px;
      line-height: 1.5em;
    }
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: center;

  button {
    margin: 0 10px;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 3px;

    &.goto-prev {
      display: none;
    }

    i {
      font-size: 13px;
    }
  }
}
//$$ Bloque navegación alternativo

.nav-top {
  /*@include size(100%,100%);*/
  @include transition(top, .3s, linear);
  position: fixed;
  top: -100vh;
	left: 0;
	width: 100%;
	max-width: 50%;
	height:100vh;
  overflow: auto;
  background: $white;
  padding: 20px;
	z-index: 100;
	display: flex;
	flex-direction: row;

  &.show-menu-top {
		top:0;
  }
  .left-side, .right-side {
		width: 100%;
		.logo {
			max-width: 177px;
			margin: 70px auto 0;
		}
	}
	
  .menu-nav {
		display: block;
		padding-top: 50px;
		&>ul {
			margin: 0;
			padding: 0;
			&>li {
			list-style: none;
			display: block;
			font-weight: $regular;
			font-size: 20px;
			line-height: 1.2em;
			color: $grey-dark;
			border-bottom: 1px solid $grey-light;
			&>a {
					&:hover {
						text-decoration: none;
					}
					&>span {
						@include transition(all,.3,ease);
						padding: 20px 0;
						display: block;
						&:hover {
							@include transition(all,3s,ease);
							color: $grey-mid;
						}
					}
				}
			}
		}
  }
  .nominations {
		display: block;
		color: $grey-dark;
		font-size: 14px;
		line-height: 1.2em;
		padding: 30px 0;
		border-bottom: 1px solid $grey-light;
		p {
			margin: 0 0 1em
		}
		.c_gray {
			font-size: 12px;
			line-height: 14px;
			letter-spacing: 1px
		}
		.no-list {
			font-size: 16px;
			line-height: 1.6em;
			font-family: Oswald;
			font-weight: 500;
			margin: 0;
			padding: 0;
			li {
			list-style: none;
			display: block
			}
		}
  }
  .social-block {
		display: block;
		padding: 20px 0;
		width:100%;
		&:after, &:before {
			visibility: hidden;
			display: block;
			font-size: 0;
			content: " ";
			height: 0;
		}
		.social ul {
			display: block;
			margin: 0;
			padding: 0;
			li {
			display: inline-block;
			vertical-align: middle;
			.icon-youtube {
				//font-size: 2.5em;
				&:before {
				//margin: 0;
				}
			}
	  }
	}

	.webmap {
		display: block;
		width:100%;
		clear: both;
		margin-bottom: 15px;
	}
	.private-area {
	  margin-right: 0;
	  display: inline-block;
	  float: right;
	  &:hover{
			color:$black;
	  }
	  &:before {
			display: inline-block;
			font-family: fontello;
			content: '\e833';
			padding-right: 8px;
			font-size: 16px;
			font-weight: $bold;
	  }
	}
		.social {
			display: flex;
			width: 100%;
			justify-content: center;
			li {
				@include transition(all,.3s,ease);
				color: $grey;
				margin: 12px 12px 0 0;
				&:hover {
					color: $black;
					@include transition(all,.3s,ease);
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
  }
  .close-nav-top {
		@include size(27px,19px);
		@include absolute($t:20px,$r:20px);
		display: inline-block;
		vertical-align: middle;
		margin-left: 0;
		cursor: pointer;
		&:after, &:before {
			@include absolute();
			@include size(0,3px);
			content: "";
			display: block;
			background: $black;
		}
		&:after {
			@include absolute($b:-1px,$l:5px);
			@include transform(rotate(-45deg));
			@include transform-origin(0 100%);
			@include transition(all,.3s,ease);

		}
		&:before {
			@include absolute($t:-1px,$l:5px);
			@include transform(rotate(45deg));
			@include transform-origin(0 0);
			@include transition(all,.3s,ease,.2s);
		}
		span {
			@include size(27px,3px);
			display: block;
			background: $black;
			margin-bottom: 5px;
			&:first-child {
				@include transition(all,.3s,ease,.8s);
			}
			&:nth-child(2) {
				@include transition(all,.3s,ease,.6s);
			}
			&:last-child {
				@include transition(all,.3s,ease,.4s);
				margin-bottom: 0;
			}
		}
		&.active {
			span {
					&:first-child {
						@include transition(all,.3s,ease);
						width: 0;
					}
					&:nth-child(2) {
						@include transition(all,.3s,ease,2s);
						width: 0;
					}
					&:last-child {
						@include transition(all,.3s,ease,4s);
						width: 0;
					}
				}
				&:before {
					@include transition(all,.3s,ease,6s);
					width: 27px;
				}
				&:after {
					@include transition(all,.3s,ease,8s);
					width: 27px;
				}
		}
  }
  @include desktop-small {
		max-width: 100%;
  }
}

/* Responsive */
@media all and (max-width:700px) {
	.nav-top {
		max-width:100%;
		flex-direction: column;
		.left-side, .right-side {
			width: 100%;
			.logo {
					max-width: 177px;
					margin: 20px 0px 0px 0px;
			}
			.menu-nav {
				display: block;
				padding-top: 20px;
			}
		}
	}
}

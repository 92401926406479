.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 14px;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .control_indicator {
    position: absolute;
    top: 6px;
    left: 0;
    height: 15px;
    width: 15px;
    background: #e6e6e6;
    border: 0px solid #000000;
    border-radius: 0px;

    &:after {
      box-sizing: unset;
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 3px;
      height: 8px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);  
    }
  }  
}
  
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #e6e6e6;
}

.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #2b2b2b;
}

.control input:checked ~ .control_indicator:after {
    display: block;
}

.switch-checkbox {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 0 10px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $blue;

      &:before {
        background-color: white;
        transform: translateX( 16px );
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: all .4s;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      top: 2px;
      left: 2px;
      border-radius: 100%;
      background-color: #777;
      transition: all .4s;
    }

    &.round {
      border-radius: 30px;
    }
    
  }

}
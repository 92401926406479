//$$ Página de tags

.tags__home {
	.tags__header {
		width: 100%;
		padding: 48px 0 0;
		text-align: center;
		font-size: 24px;
		margin-top: 72px;
		border-bottom: 1px solid $grey-blog-light;

		@include tablet-big {
			font-size: 20px;
			padding-top: 0;
			width: calc(100% + 40px);
			margin-left: -20px;
		}

		@include tablet-portrait {
			//padding: 32px 15px 40px;
			font-size: 18px;
		}

		.blog-name {
			display: inline-block;
			margin: 0 0 32px 0;
			padding: 18px 15px;
			background: $black;
			color: $white;
			font-family: Oswald;
			font-size: 20px;
			font-weight: $regular;
			text-transform: uppercase;
			text-align: center;

			&:before {
				content: "#";
				padding-right: 2px;
			}

			@include tablet-big {
				width: calc(100% + 40px);
				margin-left: -20px;
			}
		}

		.social-block {
			display: flex;
			flex-wrap: wrap;
			font-size: 28px;
			margin-bottom: 16px;

			.social-list {
				list-style: none;
				display: flex;
				justify-content: center;
				width: 100%;
				margin: 0;
				padding: 0;

				li {
					padding: 12px;
				}

				.icon {
					&:before {
						color: $grey-light;
					}

					&:hover {
						&:before {
							@include transition(all, .3s, ease);
							@include opacity(1);
							color: $black;
						}
					}
				}

				@include tablet-big {
					padding: 0;
				}
			}
		}
	}

	.all-entries {
		& .main {
			align-content: start;
		
			.entry__image {
				max-width: 20%;
				height: 90%;//cambio wp
				top:5%;//cambio wp
			}

			.column_12 {
				width: 100%;

				@include tablet {
					width: 90%;
				}

				@include mobile {
					width: 100%;
				}
			}

			.column_8 {
				@include tablet {
					width: 60%;
				}

			}

			.entry__author {
				max-width: 100%;
				width: 100%;
				line-height: 17px;

				@include mobile {
					width: 100%;
				}
			}

			.entry__date {
				max-width: 100%;
				width: 100%;
				color:$grey-mid-blog;
			}
			.entry__title{
				color:$grey-dark;
			}

			.more-blogs-block {
				.all-entries__title {
					margin-bottom: 30px;

				}
				.list-entries{
					.list-entries-item{
 					
					}
				}

			}



		}

	}
}
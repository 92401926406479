//$$ Cabecera de un post

.post__header {
  width: 100%;
  padding: 48px 0 0;
  text-align: center;
  font-size: 24px;
  margin-top: 72px;

  @include tablet-big {
    font-size: 20px;
    padding-top: 0;
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  @include tablet-portrait {
    //padding: 32px 15px 40px;
    font-size: 18px;
  }

  .blog-name {
    display: inline-block;
    margin: 0 0 32px 0;
    padding: 18px 15px;
    background: $black;
    color: $white;
    font-family: Oswald;
    font-size: 20px;
    font-weight: $regular;
    text-transform: uppercase;
    text-align: center;

    @include tablet-big {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }

  .post__header {
    &__title {
      font-size: 40px;
      font-weight: 700;
      line-height: 1.33;
      margin: 0;
      padding-bottom: 32px;

      @include tablet-big {
        font-size: 32px;
      }
    }

    &__image {
      width: 100%;
      max-height: 400px;
      margin-bottom: 18px;
      object-fit: cover;

      @include tablet-big {
        max-height: 300px;
      }
    }
  }
}

//$$ Contenido del post

.post__content {
	color:$grey-mid-blog;
	padding-bottom: 24px;
	border-bottom: 1px solid $grey-light-blog;
	.aside {
		&__left {
			.author {
				.author__picture {
					@include size(200px, 200px);
					margin-bottom: 32px;
					img {
						@include size(100%,100%);
						@include border-radius(50%);
					}
					@include tablet-big {
						@include size(92px, 92px);
						margin: 0;
					}
				}
				.author__info {
					.author__name {
						font-size: 18px;
						font-weight: 700;
						margin-top: 0;
						margin-bottom: 6px;
					}
					@include tablet-big {
						width: calc(100% - 92px);
						padding-left: 16px;
					}
				}
				@include tablet-big {
					@include center;
					margin:16px 0 32px;
				}
			}
			.tag-cloud, .social-block {
				display: none;
			}
			@include tablet-big {
				.tag-cloud, .social-block {
					display: flex;
				}
			}
		}
		&__right {
			color:$black;
			.title {
				font-family: bentonsans, Helvetica, Arial, Sans-serif;
				font-size: 21px;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0;
			}
			.tag-cloud, .social-block {
				display: flex;
			}
			@include tablet-big {
				.tag-cloud, .social-block {
					display: none;
				}
			}
		}
		&__left, &__right {
			article {
				margin-bottom: 32px;
			}
		}
	}
	.main {
		padding: 0 15px;
		.entry__date {
			display: block;
			margin-bottom: 16px;
		}
		.main__text {
			line-height: 28px;
			font-size: 18px;
			font-weight: 300;
			&.bold {
				font-weight: 600;
			}
			&.blockquote {
				padding:0 20px;
				margin: 24px 0;
				font-family: Times;
				font-style: italic;
				border-left: 4px solid $grey-light-blog;
				&--black {
					color:$black;
					font-family: bentonsans, Helvetica, Arial, Sans-serif;
					font-weight: 400;
					&:after {
						content: "";
						display: block;
						width: 75px;
						height: 4px;
						background-color: $black;
						margin-top: 18px;
					}
				}
				label {
					display: block;
					text-align: right;
				}
			}
		}
		.post__image {
			figcaption {
				padding-top: 12px;
				font-size: 16px;
			}
		}
		@include tablet-big {
			margin-bottom: 32px;
		}
	}
	@include tablet-big {
		padding: 0 15%;
	}
	@include tablet-portrait {
		padding: 0;
	}
	.social-block {
		display: flex;
		flex-wrap: wrap;
		font-size: 28px;
		margin-bottom: 16px;
		.social-list {
			list-style: none;
			display: flex;
			width: 100%;
			margin: 0;
			padding: 0;
			li {
				padding-right: 12px;
			}
			.icon {
				&:before {
					color: $grey-light;
				}
				&:hover {
					&:before {
						@include transition(all,.3s,ease);
						@include opacity(1);
						color: $black;
					}
				}
			}
			@include tablet-big {
				padding: 0;
			}
		}
	}
	.tag-cloud {
		display: flex;
		flex-wrap: wrap;
		.tag {
			@include border-radius(15px);
			padding: 4px 12px;
			margin: 8px 8px 0 0;
			font-size: 16px;
			font-weight: 200;
			color:$white;
			background:$grey-mid-blog;
			&:before {
				content: "#";
			}
			@include tablet-big {
				margin-left: 0;
			}
		}
	}
	.post-gallery {
		position: relative;
		.ctrls-gallery {
			@include absolute($t:16px,$r:16px);
			padding: 12px 8px;
			background-color: $black;
			.slick-next, .slick-prev {
				&:before {
					color:$white;
				}
			}
			.slick-dots {
				li {
					button {
						border-color: $white;
						background-color: $white;

					}
					&.slick-active {
						button {
							background-color: $black;
						}
					}
				}
			}
		}
		.main-image {
			margin: 0;
			img {
				padding-bottom: 8px;
			}
		}
	}
	.wp-caption {
		width: 100% !important;
	}
}

.all-entries {
  .more-blogs-block {
    @include tablet-big {
      padding: 0 15%;
    }

    @include tablet-portrait {
      padding: 0 20px;
    }
  }

  & .main {
    &.show-blog {
      &.list-entries-item {
        align-content: start;
        padding: 15px 0;
        height: auto;

        & .infoPost {
          & .entry__title {
            max-width: 100%;
            width: 100%;
            color: $black;//cambio wp
          }
          &.column_12 {
            max-width: 100%;
          }
          &.column_8 {
            max-width: 100%;
          }
        }
        .entry__image {
          max-width: 100%;
          top: 5%;//cambio wp
          height: 90%;//cambio wp
          @include mobile {
            max-width: 90px;
          }
        }
        .entry__author {
          max-width: 100%;
          width: 100%;
        }
        .entry__date {
          max-width: 100%;
          width: 100%;
          color:$grey-mid-blog;
        }
      }
    }
  }
}

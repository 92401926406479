.searchBBVA {
    display: block;
    width: 100%;
    float: left;
    padding: 20px;
    box-sizing: border-box
}

.searchBBVA .searchWrapper {
    width: 100%;
    margin-bottom: 20px;
    float: left
}

.searchBBVA .inputCol {
    border-bottom: 1px solid #cde8f9;
    width: 90%;
    float: left
}

.searchBBVA .buttonCol {
    width: 10%;
    float: left
}

.searchBBVA input.term {
    border: none;
    margin: 0;
    line-height: 3em;
    width: 100%;
    padding: 0 10px
}

.searchBBVA .but {
    background: url(../images/ico-arrow.png) center no-repeat;
    width: 100%;
    height: 3em;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    cursor: pointer
}

.searchBBVA .but:hover {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg)
}

.searchBBVA .historyBBVA {
    width: 100%;
    float: left;
    margin-bottom: 20px
}

.searchBBVA .historyBBVA ul {
    margin: 0;
    padding: 0
}

.searchBBVA .historyBBVA li.itemHistoryBBVA {
    display: inline-block;
    background-color: #84c9ea;
    color: #fff;
    font-size: 13px;
    border-radius: 20px;
    border: 1px solid #84c9ea;
    padding: 8px 20px;
    margin: 0 4px 10px;
    -webkit-font-smoothing: auto;
    letter-spacing: .4px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer
}

.searchBBVA .resultsWrapper {
    float: left;
    width: 100%;
    margin-bottom: 20px
}

.searchBBVA .resultsWrapper ul.resultsBBVA {
    display: block;
    width: 100%;
    float: left;
    margin: 0;
    padding: 10px
}

.searchBBVA .resultsWrapper ul.resultsBBVA li {
    list-style: none;
    margin-bottom: 15px;
    display: block;
    width: 100%;
    float: left
}

.searchBBVA .resultsWrapper ul.resultsBBVA li a {
    color: #0492d4;
    text-decoration: none;
    font-weight: 700
}

.searchBBVA .resultsWrapper ul.resultsBBVA li div {
    list-style: none
}

.searchBBVA .searchBBVA_img {
    width: 300px;
    height: 120px;
    display: block;
    position: relative;
    overflow: hidden;
    float: left;
    margin-right: 30px
}

.searchBBVA .searchBBVA_img img {
    max-height: 120px
}

.paginateBBVA {
    float: left;
    width: 100%;
    text-align: center
}

.paginateBBVA ul {
    display: inline-block
}

.paginateBBVA ul li {
    float: left;
    list-style: none;
    margin: 0 10px
}

.paginateBBVA ul li.current {
    font-weight: 700
}

.paginateBBVA ul li.pageGoTo {
    cursor: pointer;
    text-decoration: underline
}

.nomination-formulary, .standar-formulary {
    display: block;
    margin-top: 100px
}

.nomination-formulary .nominator, .standar-formulary .nominator {
    padding: 0 40px;
    display: none
}

.nomination-formulary .nominator:after, .nomination-formulary .nominator:before, .standar-formulary .nominator:after, .standar-formulary .nominator:before {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    height: 0
}

.nomination-formulary .nominator.active, .standar-formulary .nominator.active {
    display: block
}

.nomination-formulary .step, .standar-formulary .step {
    display: none;
    font-size: 14px;
    line-height: 1.2em
}

.nomination-formulary .step.active, .standar-formulary .step.active {
    display: block
}

.nomination-formulary hr, .standar-formulary hr {
    border: 0;
    margin: 30px 0;
    border-top: 1px solid #dedede
}

.nomination-formulary .content-form, .standar-formulary .content-form {
    display: block;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    padding: 40px 0;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.2em
}

.nomination-formulary .content-form.no-border-bottom, .standar-formulary .content-form.no-border-bottom {
    border-bottom: 0
}

.nomination-formulary .content-form p, .standar-formulary .content-form p {
    letter-spacing: 1px;
    margin-bottom: 40px
}

.nomination-formulary .content-form .lg_6:nth-child(odd), .standar-formulary .content-form .lg_6:nth-child(odd) {
    padding-right: 20px
}

.nomination-formulary .content-form .lg_6:nth-child(even), .standar-formulary .content-form .lg_6:nth-child(even) {
    padding-left: 20px
}

.nomination-formulary .align-forms, .standar-formulary .align-forms {
    font-size: 0
}

.nomination-formulary .form-group, .nomination-formulary .form-section, .standar-formulary .form-group, .standar-formulary .form-section {
    display: block;
    font-size: 0
}

.nomination-formulary .form-group+.form-section, .nomination-formulary .form-section+.form-section, .standar-formulary .form-group+.form-section, .standar-formulary .form-section+.form-section {
    margin-top: 30px
}

.nomination-formulary .form-group.double, .nomination-formulary .form-section.double, .standar-formulary .form-group.double, .standar-formulary .form-section.double {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 30px
}

.nomination-formulary .form-group.step2, .nomination-formulary .form-group.step3, .nomination-formulary .form-section.step2, .nomination-formulary .form-section.step3, .standar-formulary .form-group.step2, .standar-formulary .form-group.step3, .standar-formulary .form-section.step2, .standar-formulary .form-section.step3 {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 30px
}

.nomination-formulary .form-group.step2.full, .nomination-formulary .form-group.step3.full, .nomination-formulary .form-section.step2.full, .nomination-formulary .form-section.step3.full, .standar-formulary .form-group.step2.full, .standar-formulary .form-group.step3.full, .standar-formulary .form-section.step2.full, .standar-formulary .form-section.step3.full {
    width: 100%
}

.nomination-formulary .form-control, .nomination-formulary .form-ctrl, .standar-formulary .form-control, .standar-formulary .form-ctrl {
    display: block;
    width: 100%;
    height: 48px;
    padding: 10px 15px;
    border: 1px solid #e0e0e0;
    outline: 0;
    font-size: 14px;
    line-height: 1.2em;
    color: #878787;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

.nomination-formulary .form-control.error, .nomination-formulary .form-ctrl.error, .standar-formulary .form-control.error, .standar-formulary .form-ctrl.error {
    border-color: #da3851
}

.nomination-formulary .form-control:focus, .nomination-formulary .form-ctrl:focus, .standar-formulary .form-control:focus, .standar-formulary .form-ctrl:focus {
    border-color: #009ed6;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

.nomination-formulary textarea.form-control, .nomination-formulary textarea.form-ctrl, .standar-formulary textarea.form-control, .standar-formulary textarea.form-ctrl {
    resize: none;
    width: 100%;
    min-height: 232px
}

.nomination-formulary select.form-control, .nomination-formulary select.form-ctrl, .standar-formulary select.form-control, .standar-formulary select.form-ctrl {
    appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    background: url(../img/arrow.png) right 18px top 50% no-repeat
}

.nomination-formulary select::-ms-expand, .standar-formulary select::-ms-expand {
    display: none
}

.nomination-formulary .label-information, .standar-formulary .label-information {
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    width: 220px;
    padding-right: 20px;
    padding-left: 20px;
    letter-spacing: 1px;
    font-weight: 400
}

.nomination-formulary .label-information p, .standar-formulary .label-information p {
    margin: 10px 0 0;
    font-size: 11px;
    line-height: 1.2em;
    font-weight: 300;
    color: #878787
}

.nomination-formulary .label-information.middle-align, .nomination-formulary .label-information.middle-align+.form-inputs, .standar-formulary .label-information.middle-align, .standar-formulary .label-information.middle-align+.form-inputs {
    vertical-align: middle
}

.nomination-formulary .form-inputs, .standar-formulary .form-inputs {
    display: inline-block;
    vertical-align: top;
    width: -webkit-calc(100% - 220px);
    width: -moz-calc(100% - 220px);
    width: calc(100% - 220px);
    font-size: 18px;
    line-height: 1.2em
}

.nomination-formulary .form-inputs.full, .standar-formulary .form-inputs.full {
    width: 100%
}

.nomination-formulary .form-inputs span.inline-block, .standar-formulary .form-inputs span.inline-block {
    display: inline-block;
    vertical-align: top;
    width: -webkit-calc(100% - 40px);
    width: -moz-calc(100% - 40px);
    width: calc(100% - 40px)
}

.nomination-formulary label, .standar-formulary label {
    display: block;
    font-weight: 600;
    margin-bottom: 1em;
    cursor: pointer
}

.nomination-formulary label input[type=checkbox], .nomination-formulary label input[type=radio], .standar-formulary label input[type=checkbox], .standar-formulary label input[type=radio] {
    overflow: hidden;
    width: 0;
    height: 0;
    visibility: hidden
}

.nomination-formulary label input[type=checkbox]~i, .nomination-formulary label input[type=radio]~i, .standar-formulary label input[type=checkbox]~i, .standar-formulary label input[type=radio]~i {
    display: inline-block;
    vertical-align: middle;
    width: 17px;
    height: 17px;
    background: #f5f5f5;
    border: 1px solid #dedede;
    border-radius: 50%;
    margin-right: 18px;
    position: relative
}

.nomination-formulary label input[type=checkbox]~i:before, .nomination-formulary label input[type=radio]~i:before, .standar-formulary label input[type=checkbox]~i:before, .standar-formulary label input[type=radio]~i:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 70%;
    background: #009ed6;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    border-radius: 50%;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

.nomination-formulary label input[type=checkbox]:checked~i:before, .nomination-formulary label input[type=radio]:checked~i:before, .standar-formulary label input[type=checkbox]:checked~i:before, .standar-formulary label input[type=radio]:checked~i:before {
    opacity: 1;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

.nomination-formulary label input[type=checkbox]~i, .nomination-formulary label input[type=checkbox]~i:before, .standar-formulary label input[type=checkbox]~i, .standar-formulary label input[type=checkbox]~i:before {
    border-radius: 0
}

.nomination-formulary .step-label, .standar-formulary .step-label {
    margin: 0 0 30px;
    position: relative
}

.nomination-formulary .step-label:after, .nomination-formulary .step-label:before, .standar-formulary .step-label:after, .standar-formulary .step-label:before {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    height: 0
}

.nomination-formulary .step-label .title, .standar-formulary .step-label .title {
    margin: 0;
    font-size: 25px;
    line-height: 1.2em;
    position: relative;
    width: 100%;
    text-align: center
}

.nomination-formulary .step-label .title b, .standar-formulary .step-label .title b {
    color: #009ed6;
    font-weight: 700
}

.nomination-formulary .step-label .subtitle, .standar-formulary .step-label .subtitle {
    font-size: 22px;
    color: #009ed6
}

.nomination-formulary .next-btn, .standar-formulary .next-btn {
    float: right
}

.nomination-formulary .prev-btn, .standar-formulary .prev-btn {
    float: left
}

.nomination-formulary .confirm-btn, .standar-formulary .confirm-btn {
    float: right
}

.nomination-formulary .file-pick, .standar-formulary .file-pick {
    display: inline-block;
    vertical-align: middle;
    margin: 0
}

.nomination-formulary .file-pick input[type=file], .standar-formulary .file-pick input[type=file] {
    overflow: hidden;
    width: 1px;
    height: 1px;
    outline: 0;
    padding: 0;
    border: 0
}

.nomination-formulary .status-file, .standar-formulary .status-file {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.2em;
    font-weight: 600;
    margin-left: 40px;
    letter-spacing: 1px
}

.nomination-formulary .status-file .icon, .standar-formulary .status-file .icon {
    font-size: 22px;
    line-height: 1.2em;
    display: inline-block;
    vertical-align: middle
}

.nomination-formulary .status-file .icon.icon-delete, .standar-formulary .status-file .icon.icon-delete {
    color: #009ed6;
    cursor: pointer;
    border-left: 1px solid #dedede;
    margin-left: 5px;
    padding-left: 5px
}

.nomination-formulary .block-options, .standar-formulary .block-options {
    display: inline-block;
    vertical-align: middle
}

.nomination-formulary .ctrls-steps-form, .standar-formulary .ctrls-steps-form {
    float: right;
    margin-top: -12px;
    position: absolute;
    right: 0;
    top: 0
}

.nomination-formulary .details, .standar-formulary .details {
    display: block
}

.nomination-formulary .details .info, .standar-formulary .details .info {
    display: block;
    padding: 30px 20px;
    font-size: 0
}

.nomination-formulary .details .info:after, .nomination-formulary .details .info:before, .standar-formulary .details .info:after, .standar-formulary .details .info:before {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    height: 0
}

.nomination-formulary .details .info p, .standar-formulary .details .info p {
    text-align: center;
    font-size: 18px;
    line-height: 1.2em;
    font-weight: 600
}

.nomination-formulary .details .info>.label-description, .standar-formulary .details .info>.label-description {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    margin-bottom: 10px
}

.nomination-formulary .details .detail-block, .standar-formulary .details .detail-block {
    display: block
}

.nomination-formulary .details .title, .standar-formulary .details .title {
    display: block;
    margin: 0;
    background: #f5f5f5;
    padding: 30px 20px;
    text-align: center;
    font-size: 20px;
    line-height: 1.2em
}

.nomination-formulary .details .label-description, .standar-formulary .details .label-description {
    display: block;
    min-height: 70px;
    font-size: 0
}

.nomination-formulary .details .label-name, .standar-formulary .details .label-name {
    display: inline-block;
    vertical-align: top;
    width: 200px;
    font-size: 14px;
    line-height: 1.2em;
    color: #878787;
    letter-spacing: 1px;
    text-align: right;
    padding: 0 20px
}

.nomination-formulary .details .label-info, .standar-formulary .details .label-info {
    display: inline-block;
    vertical-align: top;
    width: -webkit-calc(100% - 200px);
    width: -moz-calc(100% - 200px);
    width: calc(100% - 200px);
    font-size: 18px;
    line-height: 1.2em;
    font-weight: 600;
    padding: 0 20px
}

.nomination-formulary .small, .standar-formulary .small {
    font-size: 12px;
    line-height: 1.2em;
    font-weight: 300
}

.standar-formulary {
    margin: 50px 0
}


@media all and (max-width:1023px) {
    .nomination-formulary .ctrls-steps-form, .standar-formulary .ctrls-steps-form {
        position: relative;
        margin-top: 10px;
        width: 100%;
        text-align: center;
    }
}

@media all and (max-width:970px) {
    .nomination-formulary .content-form .lg_6:nth-child(odd), .standar-formulary .content-form .lg_6:nth-child(odd) {
        padding-right: 0
    }
    .nomination-formulary .content-form .lg_6:nth-child(even), .standar-formulary .content-form .lg_6:nth-child(even) {
        padding-left: 0
    }
}
@media all and (max-width:900px) {
    .nomination-formulary .form-group.double, .nomination-formulary .form-section.double, .standar-formulary .form-group.double, .standar-formulary .form-section.double {
        width: 100%
    }
    .nomination-formulary .form-group.double:empty, .nomination-formulary .form-section.double:empty, .standar-formulary .form-group.double:empty, .standar-formulary .form-section.double:empty {
        display: none
    }
}

@media all and (max-width: 700px) {
    .nomination-formulary .label-information, .standar-formulary .label-information {
        width: 100%;
        margin-bottom: 20px;
        padding: 0
    }
    .nomination-formulary .label-information.text-right, .standar-formulary .label-information.text-right {
        text-align: left !important
    }
    .nomination-formulary .form-inputs, .standar-formulary .form-inputs {
        width: 100%;
        font-size: 14px
    }
    .nomination-formulary .step-label, .standar-formulary .step-label {
        margin-bottom: 20px
    }
    .nomination-formulary .step-label .title, .standar-formulary .step-label .title {
        display: block;
        font-size: 20px;
        float: none;
        width: 100%
    }
    .nomination-formulary .ctrls-steps-form, .standar-formulary .ctrls-steps-form {
        float: none;
        display: block;
        margin-top: 10px
    }
    .nomination-formulary .ctrls-steps-form .btn, .standar-formulary .ctrls-steps-form .btn {
        margin-bottom: 0
    }
    .nomination-formulary .btn, .standar-formulary .btn {
        margin-bottom: 10px
    }
    .nomination-formulary .block-options, .standar-formulary .block-options {
        display: block;
        width: 100%;
        text-align: center
    }
    .nomination-formulary .details .title, .standar-formulary .details .title {
        padding: 15px
    }
    .nomination-formulary .details .label-name, .standar-formulary .details .label-name {
        width: 100%;
        text-align: left;
        padding: 0;
        margin-bottom: 5px
    }
    .nomination-formulary .details .label-description, .standar-formulary .details .label-description {
        min-height: initial;
        margin-bottom: 20px;
        width: 100%;
        padding: 0
    }
    .nomination-formulary .details .label-info, .standar-formulary .details .label-info {
        width: 100%;
        padding: 0 0 0 20px;
        font-size: 14px
    }
    .nomination-formulary .details .info p, .standar-formulary .details .info p {
        font-size: 14px
    }
    .generic-article .generic-content blockquote .title, .generic-article .generic-content blockquote .title2, .generic-article .generic-title .title, .generic-article .generic-title .title2 {
        font-size: 30px
    }
    .nomination-formulary .confirm-btn, .nomination-formulary .next-btn, .nomination-formulary .prev-btn, .standar-formulary .confirm-btn, .standar-formulary .next-btn, .standar-formulary .prev-btn {
        float: none
    }
    .nomination-formulary .confirm-btn, .standar-formulary .confirm-btn {
        display: block;
        margin: 0 auto
    }
}

@media all and (max-width: 480px) {
    .nomination-formulary .content-form.ph40, .standar-formulary .content-form.ph40 {
        padding: 30px 0 !important
    }
    .nomination-formulary .nominator, .standar-formulary .nominator {
        padding: 0
    }
    .nomination-formulary .ctrls-steps-form, .standar-formulary .ctrls-steps-form {
        text-align: center
    }
    .nomination-formulary .ctrls-steps-form>.btn, .standar-formulary .ctrls-steps-form>.btn {
        display: block;
        max-width: 240px;
        margin: 0 auto 10px;
        text-align: center
    }
}
.islands-menu {
  @include transition(right,.3s,linear);
  @include size(100%,100%);
  @include box-shadow(-2px, 0, 24px, -5px, rgba(0,0,0,0.51));
  position: fixed;
  top: 72px;
  right: -500px;
  max-width: 500px;
  overflow: auto;
  background: $white;
  z-index: 9;
  padding: 100px 22px 22px;
  .close-nav {
    @include absolute($t:49px,$l:20px);
    position: absolute;
    left:16px;
    top:49px;
    &:before {
      @include transform(rotate(0deg));
      font-family: fontello;
      content: '\E845';
      font-size: 30px;
      background: $white;
    }
    &:after {
    display: none;
    }
    @include tablet {
      position: absolute;
    }
  }
  .nav--islands {
    display: none;
    padding-bottom: 32px;
    .title {
      font-family: Oswald;
      display: inline-block;
      line-height:1.5;
      font-size: 24px;
      font-weight:600;
      text-transform: uppercase;
      padding:0 0 24px 0px;
      margin: 0;
      @include transition(color,.1s,ease-in);
    }
    .content--title {
      font-size: 16px;
      display: block;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 18px;
    }
    .content__list {
      list-style: none;
      padding: 0;
      line-height: 2;
      margin: 0 0 32px 0;
      .content__list__item {
        position: relative;
        ul {
          list-style: none;
          padding-left: 25px;
        }
        a {
          @include transition(color,.25s,ease-out);
          &:hover, &:focus, &:active {
            color:$blue;
          }
        }
      }
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &.leonardo {
    .title {
      color: $red-leonardo;
      &:hover, &:focus, &:active {
        color: darken($red-leonardo, 10%);
      }
    }
    .nav--islands {
      &.leonardo {
        display: block;
      }
    }
  }
  &.multiverso {
    .title {
      color: $blue-multiverso;
      &:hover, &:focus, &:active {
        color: darken($blue-multiverso, 10%);
      }
    }
    .nav--islands {
      &.multiverso {
        display: block;
      }
    }
  }
  &.contrapunto {
    .title {
      color: $gold;
      &:hover, &:focus, &:active {
        color: darken($gold, 10%);
      }
    }
    .nav--islands {
      &.contrapunto {
        display: block;
      }
    }
  }
  &.biophilia {
    .title {
      color: $orange;
      &:hover, &:focus, &:active {
        color: darken($orange, 10%);
      }
    }
    .nav--islands {
      &.biophilia {
        display: block;
      }
    }
  }
  &.awards {
    .title {
      color: $blue-awards-dark;
    }
    .nav--islands {
      &.awards {
        display: block;
      }
    }
  }
  &.show-menu-islands {
    @include transition(right,.3s,linear);
    right: 0;
  }
  @include tablet {
    top:104px;
    max-width: 75%;
    right: -100%;
    //box-shadow: -2px 0px 24px -5px rgba(0,0,0,0.51);
  }
}

//Cabecera de apertura. Por defecto es la de colectivos

  .blog-header {

    background-color: $black;
    color: $white;
    position: relative;

    //padding-bottom: 25px;
    @include tablet-big {
      height: auto;
      color: $black;
      background-color: $white;
      padding-top: 128px;
    }

    .blog-header {
      &__data {
        padding: 40px;

        @include tablet-big {
          padding: 0;
        }
      }

      &__info {
        @include opacity(1);
        padding: 40px;
        position: relative;
        z-index: 1;

        /*display: flex;
      justify-content: space-between;
      flex-direction: column;*/
        .row {
          flex-grow: 1;

          @include tablet-big {
            width: 75%;
            margin: 0 auto;
            padding: 0 0 24px;
            max-height: 100%;
          }

          @include tablet-portrait {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
          }
        }

        @include tablet-big {
          padding: 0;
        }
      }

      &__title {
        margin-top: 0;
        margin-bottom: 24px;
        font-family: Oswald;
        font-size: 58px;
        font-weight: $bold;
        text-transform: uppercase;

        @include tablet-big {
          text-align: center;
          color: $white;
          background-color: $black;
          font-size: 24px;
          font-weight: 600;
          padding: 18px 15px;
        }
      }

      &__text {
        @include txt-ellipsis-lastline;
        font-family: $font_bentonsansRegular;
        font-size: 22px;
        font-weight: 200;
        height: auto;
        max-height: 100%;

        a {
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }

        @include tablet-big {
          font-size: 16px;
          width: 75%;
          margin: 0 auto 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid $grey-light-blog;
          max-height: 100%;
        }

        @include tablet-portrait {
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      &__tags {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        @include tablet-big {
          padding: 0;
        }

        .tag {
          @include border-radius(15px);
          padding: 4px 12px;
          margin: 8px 8px 0;
          font-size: 15px;
          font-weight: 200;
          color: $white;
          font-family: $font_bentonsansLight;
          background: $grey-mid-blog;

          &:before {
            content: "#";
          }

          @include tablet-big {
            margin-left: 0;
          }
        }
      }

      &__social {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        font-size: 28px;

        .social-list {
          list-style: none;
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin: 0;

          & li {
            color: $grey-light;
          }

          .icon {
            &:before {
              @include tablet-big {
                color: $grey-light;
              }
            }

            &:hover {
              &:before {
                @include transition(all, .5s, ease);
                @include opacity(.5);

                @include tablet-big {
                  color: black;
                  @include opacity(1);
                }
              }
            }
          }

          @include tablet-big {
            padding: 0;
          }
        }

        @include tablet-portrait {
          display: none;
        }
      }
    }

    .main-image {
      @include absolute($t: 0, $r:0);
      @include size(100%, 100%);
      @include opacity(.6);
      z-index: 0;
      padding: 0;
      background-size: cover;
      margin: 0;

      @include tablet-big {
        z-index: 2;
        opacity: 1;
        height: 128px;
      }
    }
  }


@import "solo";
@import "home";
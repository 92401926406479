li.atcb-item a {
  text-decoration: none
}


img.auto, img.autoright {
  margin-left: auto
}

img.auto, img.autoleft {
  margin-right: auto
}

img.size-full, table.v-align {
  width: 100%
}

.table, table.v-align {
  height: 100%;
  table-layout: fixed
}

table.v-align tbody tr {
  vertical-align: middle;
  width: 768px;
  margin-right: auto
}

address {
  display: block;
  margin-bottom: 20px
}


#ui-datepicker-div {
  font-size: 15px
}

.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden
}

.nano>.nano-content {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0 !important;
  bottom: 0;
  left: 0;
  padding-right: 12px
}

.nano>.nano-content:focus {
  outline: dotted thin
}

.nano>.nano-content::-webkit-scrollbar {
  display: none
}

.nano>.nano-pane {
  background: 0 0;
  position: absolute;
  width: 12px;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: visible;
  opacity: 1;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px
}

.nano>.nano-pane:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  z-index: 0;
  background: rgba(60, 60, 59, .7)
}

.nano>.nano-pane>.nano-slider {
  background: #ff8a00;
  position: relative;
  margin: 0 1px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px
}

.nano .nano-pane.active, .nano .nano-pane.flashed, .nano:hover>.nano-pane {
  visibility: visible;
  opacity: .99
}



.border-top-item, .module.border-top {
  border-top: 1px solid #dedede
}


.link.video:before, .search-results .searchBBVA .buttonCol:hover {
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.link.video:before {
  display: block;
  position: absolute
}


.border-top-item {
  padding-top: 40px
}

.fixed-on-block {
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out
}

.fixed-on-scroll {
  display: block;
  width: 100%
}

.fixed-on-scroll.anchor {
  position: fixed;
  z-index: 20;
  & + .anchor {
    z-index: 19;
  }
}

.link.video:before {
  content: '\e821';
  font-family: fontello;
  font-size: 95px;
  line-height: 1em;
  width: 96px;
  height: 96px;
  opacity: .6;
  top: 50%;
  left: 50%;
  color: #fff;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}
.link.video:hover:before {
  opacity: 1;
  transition: all .3s ease
}
.link.video:hover:before {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.video.absolute {
  position: absolute;
  height: 100%
}

.video.relative {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 52.96%;
  overflow: hidden
}

.video iframe {
  position: absolute;
  height: 100%
}

.video .close-video {
  color: #000;
  font-family: Arial, sans-serif;
  font-size: 25px;
  background: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  height: 30px;
  z-index: 15;
  text-align: center;
  cursor: pointer;
  padding: 5px 8px
}
.video .close-video:before {
  content: 'X';
  font-family: Arial, sans-serif;
  color: #000;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.video iframe, .video.absolute {
  top: 0;
  width: 100%;
  z-index: 10;
  left: 0
}

[data-background] .dynamic-background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.dynamic-background-mobile {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.hide-dynamic-bg-responsive .dynamic-background {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.slick-slide img.responsive-image {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.embed-video [data-background] .dynamic-background {
  height: 500px;

  @media screen and (max-width: 480px) {
    height: 300px;
  }
}

.module.padding.agenda {

  @media screen and (max-width: 480px) {
    padding-bottom:0;
    margin-bottom: 80px;
    min-height: 400px;
  }

  .dynamic-background {
    @media screen and (max-width: 480px) {
      height: 100%;
      position: absolute;
      top:0;
      width: 100%;
    }
  }
}


[data-background] .dynamic-background.hover-effect {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}


.error {
  padding: 25px 0;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 500;
  display: none;
}

.error ul {
  font-weight: 300;
  padding-left: 70px
}

.error li {
  margin-bottom: 10px
}

.error .icon {
  font-size: 24px;
  line-height: 1em;
  display: inline-block
}

.error.show {
  display: block
}


.add-info-banner {
  display: block;
  background: rgba(0, 0, 0, .7);
  position: relative;
  z-index: 1;
  font-size: 18px;
  line-height: 1.6em;
  font-weight: 400
}

.add-info-banner div[class^=lg] {
  padding: 0 10px;
  margin-bottom: 20px
}

.add-info-banner div[class^=lg]:first-of-type {
  padding-left: 0
}

.add-info-banner div[class^=lg]:last-of-type {
  padding-right: 0
}

.add-info-banner .container {
  height: auto;
  padding-top: 45px;
  padding-bottom: 45px
}

.add-info-banner h5 {
  font-size: 19px;
  line-height: 1.2em;
  font-weight: 700;
  margin: 0
}

.add-info-banner .social {
  display: block
}

.add-info-banner .social ul {
  margin: 0;
  padding: 0;
  font-size: 0
}

.add-info-banner .social li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  font-size: 19px;
  color: #dedede;
  margin-right: 1em
}

.add-info-banner .social li:last-of-type {
  margin-right: 0
}


@media all and (max-width:970px) {
  .add-info-banner div[class^=lg] {
    padding: 0
  }
}



.header-page {

    .header-content {

        .page-title {
            text-transform: uppercase;
        }
        
        @include mobile {
            text-align: center;
        }
    }
}
a[name] {
    display: block;
    position: relative;
    top: -72px;
}

.all-link-letters {
    background-color: #eee;
    padding: 30px 10px;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;

        @include tablet {
            flex-wrap: wrap;
        }

        li {
            a {
                color: $blue;
                border: solid 1px #aaa;
                padding: 0px;
                width: 25px;
                display: block;
                text-align: center;
                margin: 3px;
                border-radius: 3px;
                background-color: white;
                transition: all .25s;

                @include tablet {
                    width: 40px;
                    padding: 10px;
                    font-size: 24px;
                }

                &:hover {
                    background-color: $blue;
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}

.authors-per-letter {

    .section-content {
        max-width: 90%;
        margin: 40px auto;
        border-bottom: solid 1px #ddd;

        .letter-title {
            font-size: 50px;
            font-family: 'Oswald', sans-serif;
            font-weight: bold;
        }

        .authors-list {
            list-style-type: none;
            padding: 0;
            margin: 0 0 40px;
            column-count: 4;

            @include tablet {
                column-count: 2;
            }

            @include mobile {
                column-count: 1;
            }

        }

        .back-to-top {
            text-align: right;
            font-size: 10px;
            text-transform: uppercase;
            color: $blue;

            a {
                color: $blue;
                text-decoration: none;
            }
        }
    }
}
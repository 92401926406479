.announcement {
  &_btn_request {
    padding: 17px 50px;
    background-color: #0059A3;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
  }

  &_status {
    padding: 9px 0;
    text-align: center;

    p {
      margin-bottom: 0;
      letter-spacing: 1px;
      font-size: 20px;
      color: #FFFFFF;
      text-transform: uppercase;
    }

    &.open {
      background-color: #029ED6;
    }

    &.close {
      background-color: #DC3545;
    }

    &.anchor {
      margin-top: 89px;
    }
  }

  &_diary {
    margin-bottom: 32px;

    dt {
      font-weight: bold;

      &::before {
        content: " ";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border-radius: 50%;
        background-color: #9B9B9B;
      }

      &.active {
        &::before {
          background-color: #029ED6;
        }
      }
    }

    dd {
      margin-left: 0;
      position: relative;
      padding: 8px 16px 20px 20px;

      &::before {
        content: " ";
        position: absolute;
        height: 100%;
        width: 2px;
        background: #d3d3d3;
        left: 4px;
        top: -1px;
      }
    }
  }
}

.exposition-description .location-description .title {
  text-transform: uppercase;
  font-weight: bold;
}

.exposition-description .location-description a {
  color: #009ed6;
}
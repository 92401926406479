.open-modal {
  cursor: pointer
}

.modalbox {
  background: rgba(0, 0, 0, .6);
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  overflow: auto;
  display: none
}

.modalbox .table-cell {
  table-layout: fixed
}

.modalbox .content {
  max-width: 768px;
  padding: 60px 10px;
  background: #fff;
  position: relative;
  margin: 0 auto;
  text-align: center
}

.modalbox .close-modal {
  color: #000;
  font-family: Helvetica, arial, sans-serif;
  font-size: 25px;
  background: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  height: 30px;
  z-index: 15;
  cursor: pointer;
  padding: 5px 8px
}

.modalbox .close-modal:before {
  content: '';
  background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDIxLjkgMjEuOSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjEuOSAyMS45IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KICA8cGF0aCBkPSJNMTQuMSwxMS4zYy0wLjItMC4yLTAuMi0wLjUsMC0wLjdsNy41LTcuNWMwLjItMC4yLDAuMy0wLjUsMC4zLTAuN3MtMC4xLTAuNS0wLjMtMC43bC0xLjQtMS40QzIwLDAuMSwxOS43LDAsMTkuNSwwICBjLTAuMywwLTAuNSwwLjEtMC43LDAuM2wtNy41LDcuNWMtMC4yLDAuMi0wLjUsMC4yLTAuNywwTDMuMSwwLjNDMi45LDAuMSwyLjYsMCwyLjQsMFMxLjksMC4xLDEuNywwLjNMMC4zLDEuN0MwLjEsMS45LDAsMi4yLDAsMi40ICBzMC4xLDAuNSwwLjMsMC43bDcuNSw3LjVjMC4yLDAuMiwwLjIsMC41LDAsMC43bC03LjUsNy41QzAuMSwxOSwwLDE5LjMsMCwxOS41czAuMSwwLjUsMC4zLDAuN2wxLjQsMS40YzAuMiwwLjIsMC41LDAuMywwLjcsMC4zICBzMC41LTAuMSwwLjctMC4zbDcuNS03LjVjMC4yLTAuMiwwLjUtMC4yLDAuNywwbDcuNSw3LjVjMC4yLDAuMiwwLjUsMC4zLDAuNywwLjNzMC41LTAuMSwwLjctMC4zbDEuNC0xLjRjMC4yLTAuMiwwLjMtMC41LDAuMy0wLjcgIHMtMC4xLTAuNS0wLjMtMC43TDE0LjEsMTEuM3oiIGZpbGw9IiMwMDAwMDAiLz4KPC9zdmc+Cg==) 50% no-repeat;
  background-size: 60%;
  font-family: Arial;
  color: #000;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.modalbox .close {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px;
  border-radius: 4px;
  background: rgba(8, 26, 53, .6);
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  z-index: 10
}

.modalbox[data-modal=eventos] .content {
  padding: 30px 0;
  background: #fff;
  color: #333;
  max-width: 320px
}

.modalbox[data-modal=eventos] .title {
  font-size: 30px;
  margin: 10px 0
}

.modalbox[data-modal=gallery] .content {
  max-width: 1024px;
  padding: 0;
  background: 0 0
}

.modalbox[data-modal=gallery] .modal-slider {
  padding: 0 50px
}

.modalbox[data-modal=gallery] .cover-slider {
  position: relative;
  display: block
}

.modalbox[data-modal=gallery] .slide {
  position: relative;
  outline: 0;
  overflow: hidden
}

.modalbox[data-modal=gallery] .slide+.slide {
  position: absolute;
  top: 0;
  left: 0
}

.modalbox[data-modal=gallery] .slide.slick-slide {
  position: relative
}

.modalbox[data-modal=gallery] img {
  display: block;
  margin: 0 auto
}

.modalbox[data-modal=gallery] .close-modal {
  top: -30px;
  right: 0
}

.modalbox[data-modal=gallery] .slick-next {
  right: -50px
}

.modalbox[data-modal=gallery] .slick-next:before {
  content: '\e81c'
}

.modalbox[data-modal=gallery] .slick-prev {
  left: -50px
}

.modalbox[data-modal=gallery] .slick-prev:before {
  content: '\e81d'
}

.modalbox[data-modal=gallery] .slick-next, .modalbox[data-modal=gallery] .slick-prev {
  width: auto;
  height: auto
}

.modalbox[data-modal=gallery] .slick-next:before, .modalbox[data-modal=gallery] .slick-prev:before {
  font-family: fontello;
  font-size: 35px;
  line-height: 1em;
  opacity: .6;
  color: #fff;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.modalbox[data-modal=gallery] .slick-next:hover:before, .modalbox[data-modal=gallery] .slick-prev:hover:before {
  opacity: 1;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.modalbox[data-modal=video] .content {
  max-width: 1200px;
  padding: 0;
  background: 0 0
}

.modalbox[data-modal=video] .video {
  margin: 0;
  width: 100%;
  padding-bottom: 56.92%;
  position: relative
}

.modalbox[data-modal=video] .iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0
}

.modalbox[data-modal=audio] .content {
  padding: 30px 20px;
  background: 0 0;
  display: block;
  max-width: 768px
}

.modalbox[data-modal=audio] .audio {
  display: block;
  width: 100%;
  margin: 30px 0
}

.modalbox[data-modal=form] [data-component-bbva=form] form {
  display: block;
  max-width: 500px;
  margin: 0 auto
}

.modalbox .form-group, .modalbox .form-section {
  display: block;
  font-size: 0;
  margin-bottom: 10px
}

.modalbox .form-group.has-error .form-control, .modalbox .form-group.has-error .form-ctrl, .modalbox .form-section.has-error .form-control, .modalbox .form-section.has-error .form-ctrl {
  border-color: #da3851
}

.modalbox .form-group+.form-section, .modalbox .form-section+.form-section {
  margin-top: 30px
}

.modalbox .form-group.double, .modalbox .form-section.double {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 30px
}

.modalbox .form-group.step2, .modalbox .form-group.step3, .modalbox .form-section.step2, .modalbox .form-section.step3 {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 30px
}

.modalbox .form-group.step2.full, .modalbox .form-group.step3.full, .modalbox .form-section.step2.full, .modalbox .form-section.step3.full {
  width: 100%
}

.modalbox .form-control, .modalbox .form-ctrl {
  display: block;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  outline: 0;
  font-size: 14px;
  line-height: 1.2em;
  color: #878787;
  transition: all .3s ease
}

.modalbox .form-control.error, .modalbox .form-ctrl.error {
  border-color: #da3851
}

.modalbox .form-control:focus, .modalbox .form-ctrl:focus {
  border-color: #009ed6;
  transition: all .3s ease
}

.modalbox .help-block {
  font-size: 12px;
  color: #da3851;
  text-align: left;
  display: block;
  padding: 0 15px
}

.modalbox label {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  text-align: left
}

.modalbox label input[type=checkbox], .modalbox label input[type=radio] {
  overflow: hidden;
  width: 0;
  height: 0;
  visibility: hidden
}

.modalbox label input[type=checkbox]~i, .modalbox label input[type=radio]~i {
  display: inline-block;
  vertical-align: middle;
  width: 17px;
  height: 17px;
  background: #f5f5f5;
  border: 1px solid #dedede;
  border-radius: 50%;
  margin-right: 18px;
  position: relative
}

.modalbox label input[type=checkbox]~i:before, .modalbox label input[type=radio]~i:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 70%;
  background: #009ed6;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  border-radius: 50%;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.modalbox label input[type=checkbox]:checked~i:before, .modalbox label input[type=radio]:checked~i:before {
  opacity: 1;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease
}

.modalbox input[type=submit], .multiple-block .multiple-options>div {
  cursor: pointer;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease
}

.modalbox label input[type=checkbox]~i {
  border-radius: 0
}

.modalbox input[type=submit] {
  display: inline-block;
  vertical-align: middle;
  background: #009ed6;
  border: 1px solid #009ed6;
  color: #fff;
  font-size: 14px;
  line-height: 1.2em;
  letter-spacing: 1px;
  position: relative;
  transition: all .3s ease;
  overflow: hidden;
  outline: 0;
  width: auto;
  padding: 15px 30px
}
.ayudas.eventos {

  header {

    .banner {

      height: 75vh;

      &.short {
        height: auto;
        min-height: 250px;
      }

      @include desktop-small {
        display: none;
      }

      .overlay {

        align-items: flex-end;

        .overlay-content {
          padding: 0 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .title {
            width: 100%;
            margin-bottom: 10px;
          }

          .date-in-banner {

            margin-left: 50px;

            &:last-child {
              margin-left: 0;
            }

            p {
              font-size: 40px;
              line-height: 1.0em;
            }

            .date {
              font-size: 90px;
              line-height: .8em;
              font-weight: 600;
            }

            .month {
              font-size: 80px;
              line-height: .8em;
              font-weight: 600;
            }

            .year {
              font-size: 20px;
            }
          }

          .series {
            margin-top: 30px;

            span {
              text-transform: uppercase;
              font-family: bentonsansBold, sans-serif;
            }
          }

          a {
            &:hover {
              text-decoration: none;
            }
          }
          
          h2 {
            font-size: 20px;
            margin-bottom: 10px;
            margin-top: 0;
          }
          
          h3 {
            font-size: 20px;
            margin-bottom: 10px;
          }

        }

      }

    }

    .banner-mobile {
      display: none;

      @include desktop-small {
        display: block;

        .banner-mobile-content {
          padding: 20px;

          p {
            margin: 0 0 10px;

            &.alert {
              a {
                color: $blue;
                display: flex;

                .icon {
                  margin-right: 5px;
                  width: 20px;
                }
              }
            }
            &.date {
              font-family: Oswald, sans-serif;
              font-size: 20px;
            }
          }

          .title {
            margin: 0 0 10px;
            font-size: 28px;
            font-family: bentonsansBold, sans-serif;
            font-weight: bold;
          }
          
          .epigraph.speaker{
            font-size: 22px;
          }
          .epigraph.series{
            font-size: 22px;
          }
        }

      }
    }

    .navigation {

      nav {

        .nav-left {
          font-size: 30px;

          a {
            &:hover {
              text-decoration: none;
            }
          }
        }

      }

    }

  }

  article {

    .body {

      flex-wrap: nowrap;

      @media screen and ( max-width: 1024px ) {
        flex-wrap: wrap;
      }

      &.full-width {
        width: 1200px;
        margin: 0 auto;
        background-color: white;
        padding-left: 0;

        @include desktop-small {
          padding: 15px;
          flex-direction: column-reverse;
        }

        .description {
          width: calc( 100% - 150px );
          max-width: 100%;

          @include desktop-small {
            width: 100%;

          }

        }
        .assets {
          width: 150px;
        }
      }

      header {

        justify-content: flex-end;

      }

      .description {

        width: 70%;

        @media screen and (max-width: 1024px) {
          width: 100%;
        }

        .title {
          margin: 45px 0 10px;
          font-size: 20px;

          &:first-child {
            margin-top: 0;
          }
        }

      }

      .assets {

        .social {
          margin-bottom: 30px;
          font-size: 20px;
        }

      }

    }

  }

}